import { useMutation, useQuery } from '@apollo/client';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useUserContext } from '@src/contexts/UserContext/useUserContext';
import { colors } from '@style/colors';
import { Button } from '@components/Button/Button';
import { Error } from '@components/Error/Error';
import { TrashIcon } from '@components/Icon/TrashIcon';
import { Loader } from '@components/Loader/Loader';
import { Text } from '@components/Text/Text';
import {
  listWorkspaceUsersQuery,
  removeUserFromWorkspaceMutation,
} from './UserList.query';

export const UserList: FC = () => {
  const { t } = useTranslation();
  const me = useUserContext();
  const { data, loading, error } = useQuery(listWorkspaceUsersQuery);
  const [removeUser] = useMutation(removeUserFromWorkspaceMutation, {
    refetchQueries: ['listWorkspaceUsers'],
  });

  if (error) return <Error />;
  if (loading || !data) return <Loader />;

  const workspace = data.me.mainWorkspace;
  if (!workspace) return <Error />;

  const users = workspace.users;

  return (
    <Grid>
      <Cell>
        <Text color="dark500">{t('userList.column.email')}</Text>
      </Cell>
      <Cell>
        <Text color="dark500">{t('userList.column.status')}</Text>
      </Cell>
      <Cell />
      {users.map((user) => {
        return (
          <Fragment key={user.id}>
            <Cell>{user.email}</Cell>
            <Cell>{user.status}</Cell>
            <Cell>
              {user.id !== me.id && (
                <Button
                  size="small"
                  icon={<TrashIcon />}
                  onClick={() => removeUser({ variables: { id: user.id } })}
                />
              )}
            </Cell>
          </Fragment>
        );
      })}
    </Grid>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 42px;
`;

const Cell = styled.div`
  padding: 12px 12px 12px 0;
  border-bottom: 1px solid ${colors.dark100};
`;

import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

export const useToast = () => {
  /* Vars */

  const { t } = useTranslation();

  return {
    createAsyncToast: (promise: Promise<any>) =>
      toast.promise(
        promise,
        {
          loading: t('common.loading'),
          success: t('common.success'),
          error: t('common.error'),
        },
        {
          position: 'bottom-right',
          duration: 3000,
        }
      ),
    createToast: (text: string) =>
      toast(text, {
        duration: 3000,
        position: 'bottom-right',
      }),
  };
};

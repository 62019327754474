import { Icon, IconComponent } from './index';

export const DragIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16">
        <circle r="0.8" cx="5.2" cy="3" strokeWidth="1.6" />
        <circle r="0.8" cx="5.2" cy="8" strokeWidth="1.6" />
        <circle r="0.8" cx="5.2" cy="13" strokeWidth="1.6" />
        <circle r="0.8" cx="10.8" cy="3" strokeWidth="1.6" />
        <circle r="0.8" cx="10.8" cy="8" strokeWidth="1.6" />
        <circle r="0.8" cx="10.8" cy="13" strokeWidth="1.6" />
      </svg>
    </Icon>
  );
};

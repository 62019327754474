import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Card,
  Typography,
  CircleProgressBar,
  DatePicker,
} from '@src/newComponents';
import { TargetIcon } from '@src/components/Icon/TargetIcon';
import { Plan } from '@src/graphql/generated/graphql';
import { formatDate } from '@src/helpers/date';

export const DatePlan = ({
  plan,
  isPreviewMode,
}: {
  plan?: Plan;
  isPreviewMode: boolean;
}) => {
  /* Vars */

  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Card backgroundColor="beige-300" isDoubleBorder>
      <div className="flex justify-between items-center">
        <div className="flex gap-4 items-center">
          {isPreviewMode ? (
            <Typography>{`${t('plan.header.start')}: ${
              plan?.startDate
                ? formatDate(plan.startDate)
                : t('plan.taskDetails.dueDate.placeholder')
            }`}</Typography>
          ) : (
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  label={t('plan.header.start')}
                  placeholder={t('plan.taskDetails.dueDate.placeholder')}
                  icon={<TargetIcon size="small" />}
                  {...field}
                />
              )}
            />
          )}

          <div className="h-2 w-2 rounded-full bg-dark-200" />

          {isPreviewMode ? (
            <Typography>{`${t('plan.header.start')}: ${
              plan?.endDate
                ? formatDate(plan.endDate)
                : t('plan.taskDetails.dueDate.placeholder')
            }`}</Typography>
          ) : (
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  label={t('plan.header.target')}
                  placeholder={t('plan.taskDetails.dueDate.placeholder')}
                  icon={<TargetIcon size="small" />}
                  {...field}
                />
              )}
            />
          )}
        </div>

        <div className="flex justify-end items-center gap-4">
          <Typography fontWeight="medium" className="text-primary">
            {t('plan.date.completed', {
              count: plan?.completion?.completionRate,
            })}
          </Typography>

          <CircleProgressBar
            percentage={plan?.completion?.completionRate || 0}
            size="sm"
          />
        </div>
      </div>
    </Card>
  );
};

import { FC } from 'react';
import { components } from 'react-select';
import { Flex } from '../Flex/Flex';

export const IconSingleValue: FC = (props: any) => {
  const { Icon, label } = props.getValue()?.[0] || {};

  return (
    <components.SingleValue {...props}>
      <Flex alignItems="flex-end" gap="8px">
        {Icon && <Icon />}
        <span>{label}</span>
      </Flex>
    </components.SingleValue>
  );
};

import noop from 'lodash/noop';
import { createContext } from 'react';

type Tracking = {
  userId: string;
  trackEvent: (event: string) => void;
};

export const TrackingContext = createContext<Tracking>({
  userId: '',
  trackEvent: noop,
});

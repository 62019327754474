import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { colors } from '@style/colors';
import { resetButtonStyles } from '../Button/TransparentButton';

type DropButtonProps = {
  onClick?: (e: React.MouseEvent) => void;
};

export const DropButton: FC<PropsWithChildren<DropButtonProps>> = ({
  children,
  onClick,
}) => {
  return (
    <Button type="button" onClick={onClick}>
      {children}
    </Button>
  );
};

const Button = styled.button`
  ${resetButtonStyles};
  cursor: pointer;

  padding: 6px;
  text-align: left;
  width: 100%;
  border-radius: 6px;
  transition: background-color 180ms ease-out;

  &:hover {
    background-color: ${colors.dark50};
  }
`;

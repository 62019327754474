import type { Ref } from 'react';
import { useMemo } from 'react';
import type { GroupBase } from 'react-select';
import RawAsyncSelect from 'react-select/async';
import type Select from 'react-select/base';
import { getCustomSelectComponents } from './customSelectComponents';
import type { SelectOption, SelectProps } from './Select';
import { getSelectStyles } from './selectStyles';

export type AsyncSelectProps<
  Value = string,
  IsMulti extends boolean = false
> = SelectProps<Value, IsMulti> & {
  loadOptions: (
    search: string
  ) => Promise<Array<SelectOption<Value> | GroupBase<SelectOption<Value>>>>;
  customRef?: Ref<Select<SelectOption<Value>, IsMulti>>;
};

export function AsyncSelect<Value = string, IsMulti extends boolean = false>({
  size = 'normal',
  styles,
  customRef,
  ...rest
}: AsyncSelectProps<Value, IsMulti>) {
  const components = useMemo(
    () => getCustomSelectComponents<Value, IsMulti>(),
    []
  );

  return (
    <RawAsyncSelect<SelectOption<Value>, IsMulti>
      ref={customRef}
      components={components}
      styles={getSelectStyles<SelectOption<Value>, IsMulti>({ size, styles })}
      {...rest}
    />
  );
}

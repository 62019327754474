import type { FC, MouseEventHandler, ReactNode } from 'react';
import React from 'react';
import { Flex } from '@components/Flex/Flex';
import { Text } from '@components/Text/Text';
import type { IconComponent } from '@components/Icon';
import styled from 'styled-components';
import { colors } from '@style/colors';
import { TrashIcon } from '@components/Icon/TrashIcon';
import { Button } from '@components/Button/Button';
import { Loader } from '@components/Loader/Loader';
import { TransparentButton } from '@components/Button/TransparentButton';

type AssetProps = {
  title: string;
  rightText?: string;
  rightIcon?: ReactNode;
  icon?: IconComponent;
  annotation?: React.ReactNode | null;
  isLoading?: boolean;
  href?: string;
  handleClick?: () => void;
  handleRemoveClick?: MouseEventHandler<HTMLButtonElement>;
};

export const Asset: FC<AssetProps> = ({
  icon: Icon,
  title,
  annotation,
  rightText,
  rightIcon,
  isLoading,
  href,
  handleClick,
  handleRemoveClick,
}) => {
  return (
    <Container
      as={href ? 'a' : undefined}
      href={href}
      target={href ? '_blank' : undefined}
      alignItems={'center'}
      p={'12px'}
      gap={'12px'}
      onClick={handleClick}
    >
      {Icon && <Icon size={'small'} />}
      <Flex flexDirection={'column'} gap={'2px'} flex={1} overflow="hidden">
        <TruncatedText size={'sm'}>{title}</TruncatedText>
        {annotation}
      </Flex>
      {isLoading ? (
        <Loader size={18} />
      ) : (
        <Right
          alignItems={'center'}
          gap={'8px'}
          translateRight={!!handleRemoveClick}
        >
          {rightText && (
            <TruncatedText size={'xs'} color={'dark700'} maxWidth={'180px'}>
              {rightText}
            </TruncatedText>
          )}
          {rightIcon && <TransparentButton icon={rightIcon} />}
          {handleRemoveClick && (
            <FadingButton
              type={'button'}
              size={'small'}
              icon={<TrashIcon size={'small'} />}
              onClick={(e) => {
                e.stopPropagation(); // Prevent event from bubbling up
                e.preventDefault(); // Prevent Container onClick or href from firing
                handleRemoveClick(e);
              }}
            />
          )}
        </Right>
      )}
    </Container>
  );
};

const FadingButton = styled(Button)`
  opacity: 0;
  transition: opacity 180ms ease-out;
`;

const Right = styled(Flex)<{ translateRight: boolean }>`
  transition: transform 180ms ease-out;

  ${({ translateRight }) => translateRight && `transform: translateX(40px);`}
`;

const Container = styled(Flex)`
  border-radius: 12px;
  border: 1px solid ${colors.dark100};
  overflow: hidden;

  &:hover {
    border-color: ${colors.dark200};
    cursor: pointer;

    ${Right} {
      transform: translateX(0);
      ${FadingButton} {
        opacity: 1;
      }
    }
  }
`;

const TruncatedText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

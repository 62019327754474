import { useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  deleteCommentMutation,
  updateCommentMutation,
} from '../TaskPanel/TaskDetails.query';
import { useUserContext } from '@src/contexts/UserContext/useUserContext';
import { GetTaskQuery } from '@src/graphql/generated/graphql';
import { Trans, useTranslation } from 'react-i18next';
import { RichTextEditor } from './RichTextEditor';
import { Button } from '../Button/Button';
import { Text } from '../Text/Text';
import { formatRelativeDate } from '@src/helpers/date';
import { Flex } from '../Flex/Flex';
import {
  Dialog,
  DialogContent,
  DialogHeading,
  DialogDescription,
  DialogClose,
  DialogConfirm,
} from '../Dialog/Dialog';
import { Divider } from '../Divider/Divider';
import { Avatar } from '../Avatar/Avatar';
import { Div } from '../Flex/Div/Div';

type CommentProps = {
  taskId: string;
  comment: GetTaskQuery['task']['comments'][number];
};

export const Comment = ({ taskId, comment }: CommentProps) => {
  const { id: userId, locale } = useUserContext();
  const { t } = useTranslation();
  const [deleteComment] = useMutation(deleteCommentMutation);
  const [updateComment] = useMutation(updateCommentMutation);
  const [isEditing, setIsEditing] = useState(false);
  const content = useMemo(() => JSON.parse(comment.content), [comment]);
  const [value, setValue] = useState(content);
  const [deleteTaskModalOpen, setDeleteTaskModalOpen] = useState(false);

  return (
    <Div paddingTop={24}>
      <Dialog open={deleteTaskModalOpen} onOpenChange={setDeleteTaskModalOpen}>
        <DialogContent>
          <DialogHeading mt="8px" mx="24px">
            {t('plan.taskDetails.comments.deleteModal.title')}
          </DialogHeading>
          <DialogDescription my="6px" mx="24px">
            <Trans
              i18nKey="plan.taskDetails.comments.deleteModal.subtitle"
              components={{ 1: <br /> }}
            />
          </DialogDescription>
          <Divider my="8px" />
          <Flex my="6px" mx="24px" justifyContent="center" gap="16px">
            <DialogClose>
              {t('plan.taskDetails.comments.deleteModal.cancel.label')}
            </DialogClose>
            <DialogConfirm
              onConfirm={async () => {
                await deleteComment({
                  variables: {
                    id: comment.id,
                  },
                });
              }}
            >
              {t('plan.taskDetails.comments.deleteModal.confirm.label')}
            </DialogConfirm>
          </Flex>
        </DialogContent>
      </Dialog>
      <div>
        <Flex gap="8px" alignItems="center">
          <Avatar size="small" user={comment.author} />
          <Text size="sm" weight="semibold">
            {comment.author.displayName}
          </Text>
          <Text size="xs">
            {formatRelativeDate(
              comment.createdAt,
              locale === 'fr' ? locale : 'en'
            )}
          </Text>
          {comment.author.id === userId && (
            <Flex justifySelf={'flex-end'} marginLeft="-4px">
              {!isEditing && (
                <Button
                  variant="invisible"
                  size="small"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  <Text size="xs" weight="semibold" color="primary">
                    {t('plan.taskDetails.comments.edit')}
                  </Text>
                  {/* {t("taskPanel.editComment")} */}
                </Button>
              )}
              <Button
                variant="invisible"
                size="small"
                marginLeft="-4px"
                onClick={() => {
                  setDeleteTaskModalOpen(true);
                }}
              >
                <Text size="xs" weight="semibold" color="primary">
                  {t('plan.taskDetails.comments.delete')}
                </Text>
              </Button>
            </Flex>
          )}
        </Flex>
        <Div height={8} />
        <RichTextEditor
          onValueChange={setValue}
          initialValue={content}
          readOnly={!isEditing}
        />
        {comment.author.id === userId && isEditing && (
          <Button
            marginTop={16}
            variant="secondary"
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              if (value === content) {
                setIsEditing(!isEditing);
                return;
              }
              updateComment({
                variables: {
                  id: comment.id,
                  input: {
                    taskId,
                    content: JSON.stringify(value),
                  },
                },
              });
              setIsEditing(false);
            }}
          >
            {t('plan.taskDetails.comments.save')}
          </Button>
        )}
      </div>
    </Div>
  );
};

import { useMutation } from '@apollo/client';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components/Button/Button';
import { Flex } from '@components/Flex/Flex';
import { InputField } from '@components/Form/InputField';
import { DecodeWorkspaceInvitationTokenQuery } from '@graphql/generated/graphql';
import { setSessionToken } from '@src/helpers/auth';
import { Form } from '@components/Form/Form';
import { signupFromInvitationMutation } from '../../JoinWorkspace.query';

type FormValues = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export const JoinWorkspaceForm: FC<{
  user: DecodeWorkspaceInvitationTokenQuery['decodeWorkspaceInvitationToken']['user'];
  token: string;
}> = ({ user, token }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [signUp] = useMutation(signupFromInvitationMutation);
  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { email: user.email },
  });

  const handleSubmit = async ({
    password,
    firstName,
    lastName,
  }: FormValues) => {
    const { data } = await signUp({
      variables: { token, input: { firstName, lastName, password } },
    });
    if (data?.signupFromInvitation.token) {
      setSessionToken(
        data.signupFromInvitation.token,
        data.signupFromInvitation.maxAge
      );
      navigate('/');
    }
  };

  return (
    <Form onSubmit={handleSubmit} methods={methods}>
      <Flex width={320} flexDirection="column" mt={32} gap="8px">
        <InputField disabled type="text" name="email" size={'big'} />
        <Flex gap="8px">
          <div>
            <InputField
              required
              name="firstName"
              placeholder={t('form.firstName.placeholder')}
              size={'big'}
            />
          </div>
          <div>
            <InputField
              required
              name="lastName"
              placeholder={t('form.lastName.placeholder')}
              size={'big'}
            />
          </div>
        </Flex>
        <InputField
          required
          type="password"
          name="password"
          placeholder={t('form.password.placeholder')}
          size={'big'}
        />
        <Button
          type="submit"
          disabled={!methods.formState.isDirty || !methods.formState.isValid}
          variant="primary"
          mt={8}
          size="big"
        >
          {t('joinWorkspace.submitButton')}
        </Button>
      </Flex>
    </Form>
  );
};

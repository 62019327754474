import { MouseEvent, type FC } from 'react';
import clsx from 'clsx';
import { CheckIcon } from '@src/components/Icon/CheckIcon';

interface RadioButtonProps {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
}

export const RadioButton: FC<RadioButtonProps> = ({ isChecked, onChange }) => {
  /* Functions */

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onChange(!isChecked);
  };

  return (
    <button
      onClick={handleClick}
      onMouseMove={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      type="button"
      className={clsx(
        'min-h-[24px] min-w-[24px] rounded-full border-2 flex justify-center items-center',
        {
          'bg-green-700 border-black-opacity-3': isChecked,
          'border-dark-200': !isChecked,
        }
      )}
    >
      {isChecked && <CheckIcon size="xsmall" color="white" />}
    </button>
  );
};

import { FC } from 'react';
import styled from 'styled-components';

import { colors } from '@src/style/colors';
import { type Activity, ActivityType } from '@src/graphql/generated/graphql';

interface PointProps {
  activity: Activity;
}

function getEmoji(activityType: ActivityType, status: string) {
  switch (activityType) {
    case ActivityType.TaskComment:
      return '💬';

    case ActivityType.TaskStatus:
      return status === 'DONE' ? '✅' : '⏳';

    case ActivityType.TaskAddFile:
      return '📄';

    case ActivityType.TaskReminder:
      return '🔄';

    default:
      return '';
  }
}

export const Point: FC<PointProps> = ({ activity }) => {
  return (
    <Circle>{getEmoji(activity.type, activity.params?.status || '')}</Circle>
  );
};

const Circle = styled.div`
  position: absolute;
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid ${colors.dark100};
  left: -60px;
  border-radius: 44px;
`;

import { Priority } from '@src/helpers/priority';
import { TripleDotsIcon } from '../Icon/TripleDotsIcon';
import { Div } from '../Flex/Div/Div';
import {
  PriorityHighIcon,
  PriorityLowIcon,
  PriorityMediumIcon,
  PriorityUrgentIcon,
} from '../Icon/Priority';

export const getPriorityIcon = (priority: Priority) => {
  switch (priority) {
    case Priority.NO_PRIORITY:
      return () => <TripleDotsIcon size="xsmall" color="dark500" />;
    case Priority.LOW:
      return () => (
        <Div padding={2} paddingBottom={1}>
          <PriorityLowIcon size="xsmall" color="dark500" />
        </Div>
      );
    case Priority.MEDIUM:
      return () => (
        <Div padding={2} paddingBottom={1}>
          <PriorityMediumIcon size="xsmall" color="dark500" />
        </Div>
      );
    case Priority.HIGH:
      return () => (
        <Div padding={2} paddingBottom={1}>
          <PriorityHighIcon size="xsmall" color="dark500" />
        </Div>
      );
    case Priority.URGENT:
      return () => (
        <Div padding={2} paddingBottom={1}>
          <PriorityUrgentIcon size="xsmall" color="dark500" />
        </Div>
      );
    default:
      return () => <TripleDotsIcon size="xsmall" color="dark500" />;
  }
};

import { Icon, IconComponent } from './index';

export const DotIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" fill="none">
        <path
          fill="#5B5B5B"
          stroke="#5B5B5B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M2.167 2.833a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666Z"
        />
      </svg>
    </Icon>
  );
};

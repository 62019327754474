import { getUrlMimeType, youtubeUrlToEmbed } from '@src/helpers/url';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

type UrlEmbedProps = {
  url: string;
  description: string;
};

export const UrlEmbed = ({ url, description }: UrlEmbedProps) => {
  const [mimeType, setMimeType] = useState<string>();
  const calendlyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const f = async () => {
      const urlMimeType = await getUrlMimeType(url);
      if (urlMimeType) setMimeType(urlMimeType);
    };

    f();
  }, [url]);

  useEffect(() => {
    // This is triggered two times with the same url dependency, so we check if Calendly is already initialized
    if (
      url.startsWith('https://calendly.com') &&
      !calendlyRef.current?.hasChildNodes()
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).Calendly.initInlineWidget({
        url,
        parentElement: document.getElementById('calendly-inline-widget'),
      });
    }
  }, [url]);

  if (url.startsWith('https://calendly.com')) {
    return <Calendly id="calendly-inline-widget" ref={calendlyRef} />;
  }
  if (url.startsWith('https://www.youtube.com')) {
    return (
      <Iframe
        src={youtubeUrlToEmbed(url)}
        aria-description={description}
        loading="lazy"
        allowFullScreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
      />
    );
  }
  if (url.startsWith('https://forms.clickup.com')) {
    return (
      <Iframe
        src={url}
        height="100%"
        loading="lazy"
        aria-description={description}
      />
    );
  }
  if (url.startsWith('https://docs.google.com/presentation')) {
    return (
      <Iframe
        src={url}
        height="100%"
        loading="lazy"
        allowFullScreen
        aria-description={description}
      />
    );
  }
  if (url.startsWith('https://www.loom.com')) {
    return <Iframe src={url} allowFullScreen aria-description={description} />;
  }

  if (!mimeType) {
    return (
      <Iframe
        src={url}
        aria-description={description}
        width="100%"
        loading="lazy"
      />
    );
  }

  if (mimeType.startsWith('video'))
    return (
      <Video
        width="100%"
        controls
        aria-description={description}
        disablePictureInPicture
      >
        <source src={url} type={mimeType} />
      </Video>
    );
  if (mimeType.startsWith('image'))
    return <img src={url} alt={description} width="100%" />;
  if (mimeType.startsWith('text/html'))
    return (
      <Iframe
        src={url}
        aria-description={description}
        loading="lazy"
        allowFullScreen
      />
    );
};

const Iframe = styled.iframe`
  width: 100%;
  height: 70vh;
  border: none;
`;
const Calendly = styled.div`
  width: 100%;
  height: 90vh;
`;
const Video = styled.video`
  border: none;
`;

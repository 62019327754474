import styled from 'styled-components';
import type { MarginProps, PaddingProps } from 'styled-system';
import { space } from 'styled-system';

export type ImageSize =
  | 'xsmall'
  | 'small'
  | 'normal'
  | 'medium'
  | 'large'
  | 'xl';

type ImageProps = {
  src?: string;
  size?: ImageSize;
  backgroundColor?: string;
};

const Sizes: Record<ImageSize, string> = {
  xsmall: '16px',
  small: '20px',
  normal: '24px',
  medium: '36px',
  large: '48px',
  xl: '60px',
};

const DEFAULT_SIZE = 'normal';

export const Image = styled('div')<ImageProps & MarginProps & PaddingProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  width: ${({ size = DEFAULT_SIZE }) => Sizes[size]};
  height: ${({ size = DEFAULT_SIZE }) => Sizes[size]};
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px rgba(38, 38, 38, 0.05);
  flex-shrink: 0;
  ${({ src }) =>
    src &&
    `
      background: url("${src}") center center;
      background-size: cover;
  `}
  ${({ backgroundColor }) =>
    backgroundColor &&
    `
      background-color: ${backgroundColor};
  `};

  // Margin
  ${space}
`;

import type { RefObject } from 'react';
import { useEffect } from 'react';

/**
 * Hook that alerts clicks outside the passed ref
 */
export function useOutsideClick<T extends HTMLElement>(
  ref: RefObject<T>,
  callback: () => void,
  exclude?: HTMLElement | null
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        exclude &&
        (exclude.contains(event.target as Node) || exclude === event.target)
      ) {
        return;
      }
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback, exclude]);
}

import { Icon, IconComponent } from './index';

export const LinkIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
      >
        <path
          stroke="#5B5B5B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="m10.59 15.303-1.18 1.179a4.166 4.166 0 1 1-5.892-5.893l1.179-1.178m10.606 1.178 1.179-1.178a4.167 4.167 0 0 0-5.893-5.893L9.411 4.697m-2.328 8.22 5.834-5.834"
        />
      </svg>
    </Icon>
  );
};

import { DASHBOARD_EMBED_ORIGIN } from '@src/config';
import IframeResizer from 'iframe-resizer-react';

type DashboardProps = {
  url: string;
};

export const Dashboard = ({ url }: DashboardProps) => (
  <IframeResizer
    log={process.env.NODE_ENV !== 'production'}
    src={url}
    // withd and minWidth works around an issue in iOS that can prevent the iFrame from sizing correctly
    style={{ width: '1px', minWidth: '100%', border: 0 }}
    checkOrigin={[DASHBOARD_EMBED_ORIGIN]}
  />
);

import { useMutation } from '@apollo/client';
import { useMemo, type FC } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { type TFunction } from 'i18next';
import { emailRule, passwordRule, requiredRule } from '@src/validation/rules';
import * as z from 'zod';

import Logo from '@assets/images/jamat-logo.png';
import { Button } from '@components/Button/Button';
import { Flex } from '@components/Flex/Flex';
import { Form } from '@components/Form/Form';
import { InputField } from '@components/Form/InputField';
import { PageLayout } from '@components/PageLayout';
import { SignupMutationVariables } from '@graphql/generated/graphql';
import { setSessionToken } from '@src/helpers/auth';
import { ErrorCode, hasErrorCode } from '@src/helpers/errors';
import { colors } from '@style/colors';
import { signupMutation } from './SignUp.query';

type FormValues = {
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

const getValidationSchema = (t: TFunction) =>
  z.object({
    companyName: requiredRule(t),
    firstName: requiredRule(t),
    lastName: requiredRule(t),
    email: emailRule(t),
    password: passwordRule(t),
  });

export const SignUpPage: FC = () => {
  const { t } = useTranslation();
  const signUp = useSignUp();
  const navigate = useNavigate();
  const schema = useMemo(() => getValidationSchema(t), [t]);
  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
  });

  const handleSubmit = async ({
    email,
    password,
    companyName,
    firstName,
    lastName,
  }: FormValues) => {
    try {
      const { token, maxAge } = await signUp({
        email,
        password,
        firstName,
        lastName,
        companyName,
      });
      setSessionToken(token, maxAge);
      navigate('/');
    } catch (error) {
      if (hasErrorCode(error, ErrorCode.EMAIL_ALREADY_TAKEN)) {
        methods.setError('email', { message: 'Email already taken' });
        return;
      }
      throw error;
    }
  };

  return (
    <PageLayout vCenter>
      <Container>
        <img src={Logo} alt="Jamat logo" height={56} />
        <Title>{t('signup.title')}</Title>
        <Subtitle>{t('signup.subtitle')}</Subtitle>
        <Form onSubmit={handleSubmit} methods={methods}>
          <FormContent>
            <InputField
              required
              name="companyName"
              placeholder={t('form.companyName.placeholder')}
              size={'big'}
            />
            <Flex gap="8px">
              <div>
                <InputField
                  required
                  name="firstName"
                  placeholder={t('form.firstName.placeholder')}
                  size={'big'}
                />
              </div>
              <div>
                <InputField
                  required
                  name="lastName"
                  placeholder={t('form.lastName.placeholder')}
                  size={'big'}
                />
              </div>
            </Flex>
            <InputField
              required
              name="email"
              placeholder={t('form.email.placeholder')}
              size={'big'}
            />
            <InputField
              required
              type="password"
              name="password"
              placeholder={t('form.password.placeholder')}
              size={'big'}
            />
            <Button type="submit" variant="primary" mt={8} size="big">
              {t('signup.button')}
            </Button>
            <SubButtonLink>
              <Trans
                i18nKey="signup.loginLink"
                components={[<Link to="/login" />]}
              />
            </SubButtonLink>
          </FormContent>
        </Form>
      </Container>
    </PageLayout>
  );
};

function useSignUp() {
  const [signUp] = useMutation(signupMutation);

  return async (input: SignupMutationVariables['input']) => {
    const { data } = await signUp({ variables: { input } });
    if (!data?.signup?.token || !data?.signup?.maxAge) {
      throw new Error('Missing token');
    }

    return { token: data?.signup?.token, maxAge: data?.signup?.maxAge };
  };
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  margin: 32px 0 0;
  font-size: 24px;
  font-weight: 600;
`;

const Subtitle = styled.p`
  color: ${colors.dark700};
  margin: 8px 0 0;
`;

const FormContent = styled.div`
  margin-top: 32px;
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SubButtonLink = styled.div`
  font-size: 12px;
  color: ${colors.dark700};
  margin-top: 8px;
  text-align: center;
`;

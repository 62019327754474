import { useQuery } from '@apollo/client';
import { Button } from '@components/Button/Button';
import { Card } from '@components/Card/Card';
import { Divider } from '@components/Divider/Divider';
import { Flex } from '@components/Flex/Flex';
import { Loader } from '@components/Loader/Loader';
import { TaskRow } from '@components/Plan/TaskRow';
import { Text } from '@components/Text/Text';
import { useWorkspaceRouting } from '@graphql/hooks/useWorkspaceRouting';
import { CustomersFilter } from '@src/components/Filter/CustomersFilter';
import { UsersFilter } from '@src/components/Filter/UsersFilter';
import { useUserContext } from '@src/contexts/UserContext/useUserContext';
import React, { FC, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getNextTasks } from './Weekly.query';

const TASK_LIMIT = 5;
const MAX_TASKS = 15;

export const Weekly: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useUserContext();
  const [userIds, setUsersIds] = React.useState<string[]>([user.id]);
  const [customerIds, setCustomersIds] = React.useState<string[]>([]);
  const { pathTo } = useWorkspaceRouting();
  const { data, loading, fetchMore } = useQuery(getNextTasks, {
    fetchPolicy: 'cache-and-network', // TODO: remove this when we have subscriptions
    variables: {
      limit: TASK_LIMIT,
      offset: 0,
      userIds: userIds,
      customerIds: customerIds,
    },
    onCompleted: (data) => {
      if (data.nextTasks.length < TASK_LIMIT) {
        setHasMore(false);
      }
    },
  });
  const [hasMore, setHasMore] = React.useState(true);
  const [isFetchingMore, setIsFetchingMore] = React.useState(false);
  const length = data?.nextTasks.length ?? 0;

  const handleSeeMore = useCallback(() => {
    setIsFetchingMore(true);
    fetchMore({
      variables: {
        limit: TASK_LIMIT,
        offset: length,
      },
      updateQuery: (previousQueryResult, options) => {
        setIsFetchingMore(false);
        if (!options.fetchMoreResult) {
          return previousQueryResult;
        }
        if (!options.fetchMoreResult?.nextTasks.length) {
          setHasMore(false);
          return previousQueryResult;
        }
        if (options.fetchMoreResult.nextTasks.length < 5) {
          setHasMore(false);
        }
        return {
          nextTasks: [
            ...previousQueryResult.nextTasks,
            ...options.fetchMoreResult.nextTasks,
          ],
        };
      },
    });
  }, [length, fetchMore]);

  return (
    <Flex flexDirection="column" paddingY="24px" gap="24px">
      <Flex justifyContent="space-between" alignItems="center">
        <Text size="2xl" weight="bold">
          {user.firstName
            ? t('dashboard.greetings.firstName', { firstName: user.firstName })
            : t('dashboard.greetings')}
        </Text>

        <Flex gap="5px">
          <UsersFilter
            name={t('dashboard.weekly.users')}
            defaultUserIds={[user.id]}
            onChange={(ids) => setUsersIds(ids)}
          />
          <CustomersFilter
            name={t('dashboard.weekly.customers')}
            onChange={(ids) => setCustomersIds(ids)}
          />
        </Flex>
      </Flex>
      <Card padding={'24px'}>
        <Text size="sm" weight="regular" color={'dark500'} mb={'8px'}>
          {t('dashboard.weekly.title')}
        </Text>
        {loading && !data && (
          <Flex justifyContent={'center'} paddingY={'24px'}>
            <Loader />
          </Flex>
        )}
        {!length && (
          <Text
            size="lg"
            weight="regular"
            color={'dark900'}
            textAlign={'center'}
          >
            {t('dashboard.weekly.noTasks')}
          </Text>
        )}
        {data?.nextTasks?.map((task, index, array) => (
          <Fragment key={task.id}>
            <TaskRow
              planTitle={task.plan.title ?? undefined}
              planStartDate={task.plan.startDate ?? undefined}
              arrowAlwaysVisible
              task={task}
              onClick={() => {
                navigate(pathTo(`/plans/${task.plan.id}?task=${task.id}`));
              }}
              workspaceName={task.plan.workspace.name}
              customerName={task.plan.customer.name}
            />
            {index !== array.length - 1 && <Divider />}
          </Fragment>
        ))}
        {!loading && hasMore && length < MAX_TASKS && (
          <Flex justifyContent={'center'}>
            <Button
              disabled={isFetchingMore}
              loading={isFetchingMore}
              onClick={handleSeeMore}
              variant="invisible"
            >
              {t('dashboard.weekly.seeMore')}
            </Button>
          </Flex>
        )}
      </Card>
    </Flex>
  );
};

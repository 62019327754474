import type { FC, HTMLProps } from 'react';
import { forwardRef } from 'react';
import styled from 'styled-components';
import { baseGhostInputStyle } from './inputMixins';

export const GhostInputSizes = ['xsmall', 'small', 'normal', 'big'] as const;
export type GhostInputSize = (typeof GhostInputSizes)[number];

type GhostInputProps = {
  size?: GhostInputSize;
  alignLeft?: boolean;
} & Pick<
  HTMLProps<HTMLInputElement>,
  | 'type'
  | 'placeholder'
  | 'name'
  | 'value'
  | 'onChange'
  | 'onClick'
  | 'onBlur'
  | 'disabled'
  | 'autoFocus'
>;

export const GhostInput: FC<GhostInputProps> = forwardRef<
  HTMLInputElement,
  GhostInputProps
>(function GhostInput({ size, ...rest }, ref) {
  return <StyledInput ref={ref} $size={size} {...rest} />;
});

export const StyledInput = styled.input<{
  $size?: GhostInputSize;
  alignLeft?: boolean;
}>`
  ${baseGhostInputStyle};
`;

import { FC } from 'react';
import styled from 'styled-components';
import { colors } from '../style/colors';
import { PlusIcon } from './Icon/PlusIcon';

export const PlusCircle: FC = () => {
  return (
    <Circle>
      <PlusIcon size="small" />
    </Circle>
  );
};

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 1px solid ${colors.dark200};
  color: var(--iconColor, ${colors.dark200});
  transition: color 180ms ease-out;
  background-color: ${colors.white};
`;

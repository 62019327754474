import { type ReactNode, type FC, useState } from 'react';
import clsx from 'clsx';
import { Collapse as RNCollapse } from 'react-collapse';

import { Icon } from '../icon';
import { Typography } from '../../atoms';
import { Divider } from '../divider';

interface CollapseProps {
  defaultOpened?: boolean;
  title?: string | ReactNode;
  description?: string | ReactNode;
  children: ReactNode;
  contentRight?: ReactNode;
}

export const Collapse: FC<CollapseProps> = ({
  defaultOpened = false,
  title,
  description,
  children,
  contentRight,
}) => {
  /* Vars */

  const [isOpened, setIsOpened] = useState(defaultOpened);

  return (
    <div className="flex flex-col gap-8 w-full">
      <div className="flex justify-between gap-10">
        <div
          className="flex gap-4 flex-1"
          onClick={() => setIsOpened(!isOpened)}
        >
          <Icon
            name="chevron-up-outline"
            className={clsx(
              'transform transition-transform duration-300 text-dark-800 mt-3',
              {
                ['rotate-180']: isOpened,
                ['rotate-90']: !isOpened,
              }
            )}
          />

          <div className="flex flex-col gap-5 flex-1">
            <Typography size="lg" fontWeight="medium" className="text-dark-800">
              {title}
            </Typography>

            <Typography size="sm" fontWeight="medium" className="text-grey-600">
              {description}
            </Typography>
          </div>
        </div>

        {contentRight}
      </div>

      <Divider />

      <RNCollapse isOpened={isOpened}>{children}</RNCollapse>
    </div>
  );
};

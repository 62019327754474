import { Icon, IconComponent } from './index';

export const FileIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
      >
        <g clipPath="url(#a)">
          <path
            stroke="#888785"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M9.253 5.583 5.596 9.241a1.41 1.41 0 0 0 1.994 1.994l4.822-4.821a2.586 2.586 0 1 0-3.658-3.657l-4.82 4.82a3.761 3.761 0 1 0 5.319 5.32l2.926-2.925"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
};

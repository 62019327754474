import { isApolloError } from '@apollo/client';

export enum ErrorCode {
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  LOGIN_REQUIRED = 'LOGIN_REQUIRED',
  EMAIL_ALREADY_TAKEN = 'EMAIL_ALREADY_TAKEN',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
}

export function hasErrorCode(error: unknown, expectedCode: ErrorCode): boolean {
  return expectedCode === getErrorCode(error);
}

function getErrorCode(error: unknown): ErrorCode | undefined {
  if (error instanceof Error && isApolloError(error)) {
    const rawCode = error.graphQLErrors?.[0]?.extensions?.code as string;
    if (rawCode in ErrorCode) {
      return rawCode as ErrorCode;
    }
  }
}

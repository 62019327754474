import { rgba } from 'polished';
import styled from 'styled-components';
import type { MarginProps, PaddingProps } from 'styled-system';
import { margin, padding } from 'styled-system';
import { colors } from '@style/colors';

type CardProps = {
  floating?: boolean;
};

export const Card = styled.div<MarginProps & PaddingProps & CardProps>`
  background-color: ${colors.white};
  border-radius: 12px;
  ${({ floating }) => !floating && `border: 1px solid ${colors.dark100};`}
  ${({ floating }) =>
    floating &&
    `
      box-shadow: 0 4px 24px ${rgba(colors.dark900, 0.1)},
        0 2px 6px ${rgba(colors.dark900, 0.04)},
        0 0 0 1px ${rgba(colors.dark900, 0.08)};
  `}

  ${padding}
  ${margin}
  overflow: hidden;
`;

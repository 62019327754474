import React, { type FC } from 'react';
import IonIcon from '@reacticons/ionicons';

interface IconProps {
  name: string;
  size?: number;
  className?: string;
}

export const Icon: FC<IconProps> = ({ name, size = 24, className }) => {
  return (
    <IonIcon
      // @ts-expect-error - IonIcon name props is not typed
      name={name}
      style={{ height: size, width: size }}
      className={className}
    />
  );
};

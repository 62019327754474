import { graphql } from '@graphql/generated';

export const loginMutation = graphql(`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      maxAge
    }
  }
`);

import { Select, SelectOption } from '@components/Select/Select';
import { Button } from '@src/components/Button/Button';
import { Flex } from '@src/components/Flex/Flex';
import { ChevronDownIcon } from '@src/components/Icon/ChevronDownIcon';
import { useEffect, useState } from 'react';
import { type MultiValue } from 'react-select';
import styled from 'styled-components';
import { Popup } from '../Popup/Popup';

type Option = {
  value: string;
  label: string;
};

type FilterProps<O extends Option> = {
  defaultValue: MultiValue<Option>;
  options: O[];
  onChange: (options: readonly SelectOption<O['value']>[]) => void;
  name: string;
};

export const Filter = <O extends Option>({
  defaultValue,
  options,
  onChange,
  name,
}: FilterProps<O>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptionsCount, setSelectedOptionsCount] = useState<number>();

  useEffect(() => {
    // We want to set selectedOptionsCount to the default value only once, when it is defined
    if (
      selectedOptionsCount === undefined &&
      defaultValue !== undefined &&
      defaultValue.length !== 0
    ) {
      setSelectedOptionsCount(defaultValue.length);
    }
  }, [defaultValue]);

  return (
    <Flex flexDirection={'column'} gap={'8px'}>
      <Popup
        trigger={
          <Button
            fullWidth
            icon={<RotatingChevron isOpen={isOpen} />}
            variant={options.length === 0 ? 'secondary' : 'light'}
          >
            {name}
            {!selectedOptionsCount ? '' : ': ' + selectedOptionsCount}
          </Button>
        }
        onOpenChange={(open) => setIsOpen(open)}
      >
        <Flex flexDirection={'column'} width={350} zIndex={isOpen ? 200000 : 1}>
          <Select<Option['value'], true>
            size="normal"
            isMulti
            name="users"
            options={options}
            defaultValue={defaultValue}
            closeMenuOnSelect={false}
            onChange={(newValue) => {
              onChange(newValue);
              setSelectedOptionsCount(newValue.length);
            }}
          />
        </Flex>
      </Popup>
    </Flex>
  );
};

const RotatingChevron = styled(ChevronDownIcon)<{ isOpen: boolean }>`
  transition: transform 0.2s ease;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

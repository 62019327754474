import { graphql } from '@graphql/generated';

export const listCustomersQuery = graphql(`
  query listCustomers($filters: CustomerFilters) {
    workspace {
      id
      customers(filters: $filters) {
        id
        name
        website
        logo
      }
    }
  }
`);

export const createCustomerMutation = graphql(`
  mutation createCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      id
      name
      website
      logo
    }
  }
`);

export const createDefaultPlanMutation = graphql(`
  mutation createDefaultPlan($customerId: ID!) {
    createDefaultPlan(customerId: $customerId) {
      id
      title
      description
      sections {
        id
        title
        tasks {
          id
          title
          description
          status
        }
      }
      workspace {
        id
        plans {
          ...HomePagePlan
        }
      }
    }
  }
`);

export const duplicatePlanMutation = graphql(`
  mutation duplicatePlan($planId: ID!, $customerId: ID!) {
    duplicatePlan(planId: $planId, customerId: $customerId) {
      id
      title
      description
      sections {
        id
        title
        tasks {
          id
          title
          description
          status
        }
      }
      workspace {
        id
        plans {
          ...HomePagePlan
        }
      }
    }
  }
`);

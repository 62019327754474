import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { NotificationMessage } from '../types';
import { getNotificationLinkUrl } from '@src/helpers/notifications';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useInbox } from '@trycourier/react-hooks';
import { colors } from '@src/style/colors';
import { Text } from '@src/components/Text/Text';
import {
  offset,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react';
import { ReadButton } from './ReadButton';
import { useIntersection } from 'react-use';
import { useNotificationIcon } from './hooks/useNotificationIcon';
import { formatRelativeDate } from '@src/helpers/date';
import { Trans } from 'react-i18next';
import { CompanyLogo } from '@src/components/CompanyLogo/CompanyLogo';
import { useUserContext } from '@src/contexts/UserContext/useUserContext';

type MessageProps = {
  message: NotificationMessage;
  pathTo: (url: string) => string;
  onClosed?: () => void;
  isVisible: boolean;
  rootRef?: React.RefObject<HTMLElement>;
};
export const Message = ({
  message,
  pathTo,
  onClosed,
  rootRef,
  isVisible,
}: PropsWithChildren<MessageProps>) => {
  const inbox = useInbox();
  const [isOpen, setIsOpen] = useState(false);
  const elementRef = useRef<HTMLElement | null>(null);
  const observer = useIntersection(elementRef, {
    root: rootRef?.current ?? null,
    rootMargin: '0px',
    threshold: 1,
  });
  const { locale } = useUserContext();
  useEffect(() => {
    if (!isVisible) {
      return;
    }
    if (observer) {
      if (observer.isIntersecting) {
        if (!message.opened) {
          inbox.markMessageOpened(message.messageId);
        }
      }
    }
  }, [observer, isVisible, message]);

  const icon = useNotificationIcon(message.data);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset({
        mainAxis: -20,
        crossAxis: 12,
      }),
    ],
    placement: 'right-start',
  });

  const hover = useHover(context, {
    delay: 50,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  const handleReadMessage = useCallback(
    async (event?: React.MouseEvent) => {
      event?.preventDefault();
      event?.stopPropagation();
      await inbox.markMessageRead(message.messageId);

      if (message.pinned) {
        await inbox.unpinMessage(message.messageId);
      }
    },
    [inbox.markMessageRead, inbox.unpinMessage, message]
  );

  const handleUnreadMessage = useCallback(
    async (event?: React.MouseEvent) => {
      event?.preventDefault();
      event?.stopPropagation();
      await inbox.markMessageUnread(message.messageId);
    },
    [inbox.markMessageUnread, message]
  );
  if (!message) {
    return null;
  }
  const { data, title, read, preview } = message;
  return (
    <LinkWrapper
      onClick={() => {
        if (!read) {
          handleReadMessage();
        }
        onClosed?.();
      }}
      notificationData={data}
      pathTo={pathTo}
    >
      <NotificationContainer
        isRead={!!read}
        ref={(ref) => {
          refs.setReference(ref);
          elementRef.current = ref;
        }}
        {...getReferenceProps()}
      >
        <NotificationIconContainer>
          {icon?.type === 'customer' && icon.logo && (
            <CompanyLogo name={icon.name} logo={icon.logo} />
          )}
        </NotificationIconContainer>
        <Content>
          {isOpen && (
            <ReadButton
              ref={refs.setFloating}
              isRead={!!read}
              handleRead={handleReadMessage}
              handleUnread={handleUnreadMessage}
              {...getFloatingProps()}
              style={floatingStyles}
            />
          )}
          <NotificationTitle size="sm">
            <Trans i18nKey={title ?? ('' as any)} values={message.data} />
          </NotificationTitle>
          <NotificationPreview size="xs">{preview}</NotificationPreview>
          <Text textAlign="right" size="xs" color={'dark300'}>
            {formatRelativeDate(
              message.created,
              locale === 'fr' ? locale : 'en'
            )}
          </Text>
        </Content>
      </NotificationContainer>
    </LinkWrapper>
  );
};

type LinkWrapperProps = {
  notificationData?: Record<string, any> | undefined;
  pathTo: (link: string) => string;
  onClick?: (event: React.MouseEvent) => void;
};

const LinkWrapper: FC<PropsWithChildren<LinkWrapperProps>> = ({
  notificationData,
  pathTo,
  children,
  onClick,
}) => {
  const link = useMemo(() => {
    const notificationLink = getNotificationLinkUrl(notificationData);
    if (!notificationLink) {
      return null;
    }
    return pathTo(notificationLink);
  }, [notificationData]);

  if (!link) {
    return (
      <div
        onClick={(e) => {
          onClick?.(e);
        }}
      >
        {children}
      </div>
    );
  }

  return (
    <Link
      onClick={(e) => {
        onClick?.(e);
      }}
      to={link}
    >
      {children}
    </Link>
  );
};

const NotificationContainer = styled.div<{ isRead: boolean }>`
  padding: 10px;
  padding-right: 20px;
  border-bottom: solid 1px ${colors.dark200};
  &: (: first-of-type) {
    border-top: solid 1px ${colors.dark200};
  }
  ${({ isRead }) =>
    !isRead ? `border-left: solid 3px ${colors.primary} ;` : ''}
  display: flex;
  gap: 10px;
  &:hover {
    background-color: ${colors.dark25};
  }
`;

const NotificationIconContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 0;
`;

const NotificationTitle = styled(Text)`
  text-overflow: ellipsis;
  max-height: 40px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const NotificationPreview = styled(Text)`
  font-style: italic;
  text-overflow: ellipsis;
  max-height: 32px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Content = styled.div`
  flex-grow: 1;
`;

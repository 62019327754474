import type { FC, HTMLProps } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { InputSize } from '../Input/Input';
import { Input } from '../Input/Input';
import { FieldError } from './FieldError';
import { isNil } from 'lodash';

type InputFieldProps = {
  name: string;
  type?: string;
  placeholder?: string;
  required?: boolean;
  size?: InputSize;
  disabled?: boolean;
  min?: number;
} & Pick<HTMLProps<HTMLInputElement>, 'onChange'>;

export const InputField: FC<InputFieldProps> = ({
  name,
  placeholder,
  type = 'text',
  required,
  size,
  disabled = false,
  min,
  onChange,
}) => {
  const { t } = useTranslation();
  const { register, formState, getFieldState } = useFormContext();
  const { error } = getFieldState(name, formState);
  const registered = register(name, {
    required: required ? t('shared.input.requiredErrorMessage') : false,
    min,
    setValueAs: (value) => {
      return type === 'number'
        ? value === '' || isNil(value)
          ? null
          : Number(value)
        : value;
    },
  });

  return (
    <>
      <Input
        {...registered}
        onChange={(event) => {
          registered.onChange(event);
          onChange?.(event);
        }}
        placeholder={placeholder}
        type={type}
        size={size}
        disabled={disabled}
        min={min}
      />
      {error && <FieldError error={error.message} />}
    </>
  );
};

import { graphql } from '@graphql/generated';

export const signupMutation = graphql(`
  mutation signup($input: SignupInput!) {
    signup(input: $input) {
      token
      maxAge
    }
  }
`);

import { useMutation } from '@apollo/client';
import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import styled from 'styled-components';

import Logo from '@assets/images/jamat-logo.png';
import { Button, LinkButton } from '@components/Button/Button';
import { Flex } from '@components/Flex/Flex';
import { Form } from '@components/Form/Form';
import { InputField } from '@components/Form/InputField';
import { PageLayout } from '@components/PageLayout';
import { Text } from '@components/Text/Text';
import { toastSuccess } from '@components/Toast/toast';
import { requestResetPasswordLinkMutation } from './ForgotPassword.query';
import { emailRule } from '@src/validation/rules';
import { type TFunction } from 'i18next';

type FormValues = { email: string };

const getValidationSchema = (t: TFunction) =>
  z.object({
    email: emailRule(t),
  });

export const ForgotPasswordPage: FC = () => {
  const { t } = useTranslation();
  const schema = useMemo(() => getValidationSchema(t), [t]);
  const [requestResetPasswordLink] = useMutation(
    requestResetPasswordLinkMutation
  );
  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
  });

  const handleSubmit = async ({ email }: FormValues) => {
    await requestResetPasswordLink({ variables: { email } });
    toastSuccess(t('forgotPassword.successToast'));
  };

  return (
    <PageLayout vCenter>
      <Flex flexDirection="column" alignItems="center">
        <img src={Logo} alt="Jamat logo" height={56} />
        <Text size="2xl" weight="bold" mt={32}>
          {t('forgotPassword.title')}
        </Text>
        <Text mt={8}>{t('forgotPassword.subtitle')}</Text>
        <Form onSubmit={handleSubmit} methods={methods}>
          <Flex flexDirection="column" width={320} mt={32}>
            <InputField
              name="email"
              placeholder={t('form.email.placeholder')}
              required
              size="big"
            />
            <ButtonContainer justifyContent="space-between" gap="24px">
              <LinkButton to="/login" variant="secondary" size="big" mt={8}>
                {t('forgotPassword.backButton')}
              </LinkButton>
              <Button type="submit" variant="primary" size="big" mt={8}>
                {t('forgotPassword.button')}
              </Button>
            </ButtonContainer>
          </Flex>
        </Form>
      </Flex>
    </PageLayout>
  );
};

const ButtonContainer = styled(Flex)`
  > * {
    flex: 1 1 0;
  }
`;

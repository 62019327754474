import React, { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { hasSessionToken } from '../helpers/auth';
import { UserWrapper } from '@src/contexts/UserContext/UserWrapper';
import { TrackingProvider } from '../contexts/TrackingContext/TrackingProvider';

export const CustomerAuthenticatedRoot: FC = () => {
  const location = useLocation();

  if (!hasSessionToken()) {
    return <Navigate to="/customer/login" state={{ from: location }} />;
  }

  return (
    <UserWrapper>
      <TrackingProvider>
        <Outlet />
      </TrackingProvider>
    </UserWrapper>
  );
};

import { useEffect, useRef, useState } from 'react';
import { type Descendant } from 'slate';

import { useMutation } from '@apollo/client';
import { createCommentMutation } from './TaskDetails.query';
import { useTranslation } from 'react-i18next';
import { GetTaskQuery } from '@src/graphql/generated/graphql';
import { Comment } from '../Comment/Comment';
import { RichTextEditor, RichTextEditorRef } from '../Comment/RichTextEditor';
import { Div } from '../Flex/Div/Div';
import { Button } from '../Button/Button';
import { Text } from '../Text/Text';
import { useSearchParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { Divider } from '../Divider/Divider';

type TaskCommentsProps = {
  taskId: string;
  comments: GetTaskQuery['task']['comments'];
};

export const TaskComments = ({ taskId, comments }: TaskCommentsProps) => {
  const { t } = useTranslation();
  const [addComment] = useMutation(createCommentMutation);
  const [value, setValue] = useState<Descendant[]>([
    { children: [{ text: '' }] },
  ]);
  const ref = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const anchor = searchParams.get('anchor');
  const editorRef = useRef<RichTextEditorRef>(null);
  const [shouldShowEditor, setShouldShowEditor] = useState(true);

  useEffect(() => {
    if (ref.current && anchor === 'comments') {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [comments, ref.current, anchor]);
  return (
    <div ref={ref}>
      <Divider />
      {/* TODO: add translation  */}
      <Text size="xl" paddingTop={24} weight="semibold">
        {t('plan.taskDetails.comments.title')}
      </Text>
      <div>
        {comments.map((comment) => (
          <Comment taskId={taskId} comment={comment} />
        ))}
      </div>
      <Div height={24} />
      {shouldShowEditor && (
        <RichTextEditor
          editorRef={editorRef}
          readOnly={false}
          onValueChange={setValue}
        />
      )}
      <Button
        marginTop={16}
        variant="secondary"
        onClick={async (ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          if (isEqual(value, [{ children: [{ text: '' }] }])) {
            return;
          }
          await addComment({
            variables: {
              taskId,
              input: {
                taskId,
                content: JSON.stringify(value),
              },
            },
          });
          if (editorRef.current) {
            setShouldShowEditor(false);
            setValue([{ children: [{ text: '' }] }]);
            // editorRef.current.resetForm();
            setTimeout(() => {
              setShouldShowEditor(true);
            }, 30);
          }
        }}
      >
        {t('plan.taskDetails.comments.addComment')}
      </Button>
    </div>
  );
};

import { useQuery } from '@apollo/client';
import { FC } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Error } from '@components/Error/Error';
import { Loader } from '@components/Loader/Loader';
import { PageLayout } from '@components/PageLayout';
import { getCustomerPlansQuery } from './CustomerDashboard.query';

export const CustomerDashboard: FC = () => {
  const { data, loading, error } = useQuery(getCustomerPlansQuery);

  if (error) return <Error />;
  if (loading || !data) return <Loader />;

  // If the user has only one plan, we redirect him to the plan page
  if (data.me.plans.length === 1) {
    const plan = data.me.plans[0];
    return <Navigate to={`/customer/plans/${plan.id}`} replace />;
  }

  return (
    <PageLayout>
      <h1>Customer dashboard</h1>
      <ul>
        {data.me.plans.map((plan) => (
          <li key={plan.id}>
            <Link to={`/customer/plans/${plan.id}`}>{plan.title}</Link>
          </li>
        ))}
      </ul>
    </PageLayout>
  );
};

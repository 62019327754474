import { useNavigate } from 'react-router-dom';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Typography, Avatar, Popover } from '@src/newComponents';
import { BellIcon } from '@src/components/Icon/BellIcon';
import { MessageCircleIcon } from '@src/components/Icon/MessageCircleIcon';
import { FoldersIcon } from '@src/components/Icon/FoldersIcon';
import { GearIcon } from '@src/components/Icon/GearIcon';
import { useLogout } from '@src/helpers/hooks/useLogout';
import { Notifications } from '@src/components/Notifications/Notifications';
import { LogoutIcon } from '@src/components/Icon/LogoutIcon';
import { useInitSleekplan } from '@src/components/Sleekplan/useInitSleekplan';
import { useWorkspaceRouting } from '@src/graphql/hooks/useWorkspaceRouting';

interface AvatarPopoverMenuProps {
  user: UserPopoverMenu;
}

interface UserPopoverMenu {
  avatarUrl?: string | null;
  displayName?: string;
  email?: string;
}

export const AvatarPopoverMenu = ({ user }: AvatarPopoverMenuProps) => {
  /* Vars */

  const { t } = useTranslation();
  const navigate = useNavigate();
  const logout = useLogout();
  const { pathTo } = useWorkspaceRouting();

  useInitSleekplan();

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-4 py-6 px-8">
        <Avatar
          picture={user.avatarUrl}
          fullName={user.displayName}
          email={user.email}
          size={42}
        />

        <div className="flex flex-col">
          <Typography size="sm" fontWeight="semibold" className="text-blue-700">
            {user.displayName}
          </Typography>

          <Typography size="sm" className="text-blue-600">
            {user.email}
          </Typography>
        </div>
      </div>

      <Divider />

      <Popover position="left" content={<Notifications />}>
        <AvatarPopoverMenuItem
          icon={<BellIcon />}
          label={t('nav.notifications')}
        />
      </Popover>

      <Divider />

      <AvatarPopoverMenuItem
        onClick={() => navigate(pathTo('/settings'))}
        icon={<GearIcon />}
        label={t('nav.settings')}
      />

      <Divider />

      <AvatarPopoverMenuItem
        onClick={() => (window as any).$sleek.toggle('feedback.add')}
        icon={<MessageCircleIcon />}
        label={t('nav.feedback')}
      />

      <Divider />

      <AvatarPopoverMenuItem
        onClick={() =>
          window.open(
            'https://foregoing-apparel-869.notion.site/Centre-d-aide-d20b5e1496cf49dab70f22d943e50f42',
            '_blank'
          )
        }
        icon={<FoldersIcon />}
        label={t('nav.helpCenter')}
      />

      <Divider />

      <AvatarPopoverMenuItem
        onClick={() => logout()}
        icon={<LogoutIcon />}
        label={t('nav.logout')}
      />
    </div>
  );
};

interface AvatarPopoverMenuItemProps {
  onClick?: () => void;
  icon: ReactNode;
  label: string;
}

export const AvatarPopoverMenuItem = ({
  onClick,
  icon,
  label,
}: AvatarPopoverMenuItemProps) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center gap-8 py-5 px-8 text-blue-700 hover:text-dark-1000"
    >
      {icon}

      <Typography size="sm" fontWeight="medium">
        {label}
      </Typography>
    </button>
  );
};

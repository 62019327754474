import { useMutation } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { GetTaskQuery, UserAvatarFragment } from '@graphql/generated/graphql';
import { colors } from '@style/colors';
import { TransparentButton } from '../Button/TransparentButton';
import { Flex } from '../Flex/Flex';
import { CheckIcon } from '../Icon/CheckIcon';
import { Pill } from '../Pill/Pill';
import { PlusCircle } from '../PlusCircle';
import { Popup } from '../Popup/Popup';
import { Text } from '../Text/Text';
import {
  assignUserToTaskMutation,
  removeUserFromTaskMutation,
} from './TaskDetails.query';
import { Avatar } from '../Avatar/Avatar';
import { Input } from '../Input/Input';
import { addInviteeMutation } from '../../pages/Plan/Plan.query';
import { isValidEmail } from '../../helpers/utils';
import { Button } from '../Button/Button';

interface TaskAssigneeProps {
  planId: string;
  taskId: string;
  assignees: GetTaskQuery['task']['assignees'];
  invitees: GetTaskQuery['task']['plan']['invitees'];
  workspaceUsers: GetTaskQuery['task']['plan']['workspace']['users'];
  isCustomer?: boolean;
}

export const TaskAssignees = ({
  planId,
  taskId,
  assignees,
  invitees,
  workspaceUsers,
  isCustomer,
}: TaskAssigneeProps) => {
  const { t } = useTranslation();
  const [assignUserToTask] = useMutation(assignUserToTaskMutation);
  const [removeUserFromTask] = useMutation(removeUserFromTaskMutation);
  const [addInvitee] = useMutation(addInviteeMutation);
  const [email, setEmail] = useState('');
  const onInviteeSubmit = async () => {
    if (!isValidEmail(email)) {
      return;
    }

    // Send invite
    const { data } = await addInvitee({
      variables: {
        planId,
        email,
      },
    });
    const inviteeId = data?.addPlanInvitee.invitees.find(
      (invitee) => invitee.email === email
    )?.id;
    if (!inviteeId) {
      return;
    }
    await assignUserToTask({
      variables: {
        taskId,
        userId: inviteeId,
      },
    });
    setEmail('');
  };

  const toggleUserFromTask = useCallback(
    async (userId: string) => {
      const isAssignee = assignees.some((assignee) => assignee.id === userId);
      const options = {
        variables: {
          taskId,
          userId,
        },
      };

      return isAssignee
        ? await removeUserFromTask(options)
        : await assignUserToTask(options);
    },
    [assignUserToTask, removeUserFromTask, taskId, assignees]
  );

  return (
    <Flex flexDirection={'column'} alignItems={'flex-start'} gap={'8px'}>
      <Text size={'xs'} color={'dark600'}>
        {t('plan.taskDetails.assignees.label')}
      </Text>
      {assignees.length > 0 && (
        <Flex flexDirection={'column'} ml={'-6px'} gap={'8px'}>
          {assignees.map((assignee) => (
            <Flex key={assignee.id} alignSelf={'flex-start'}>
              <Pill
                user={assignee}
                disabled={isCustomer}
                onClose={() =>
                  removeUserFromTask({
                    variables: {
                      taskId,
                      userId: assignee.id,
                    },
                  })
                }
              >
                {assignee.displayName}
              </Pill>
            </Flex>
          ))}
        </Flex>
      )}
      <Popup
        trigger={
          <TransparentButton type={'button'} icon={<PlusCircle />} mt={'4px'}>
            <Text size={'sm'}>
              {t('plan.taskDetails.assignees.addButton.label')}
            </Text>
          </TransparentButton>
        }
      >
        <Flex flexDirection={'column'} gap={'2px'} width={'270px'}>
          <Flex flexDirection={'column'} padding={'6px'} gap={'4px'}>
            {invitees.map((user) => (
              <Assignee
                key={user.id}
                user={user}
                isSelected={assignees.some(
                  (assignee) => assignee.id === user.id
                )}
                onClick={() => toggleUserFromTask(user.id)}
              />
            ))}
            {workspaceUsers.map((user) => (
              <Assignee
                key={user.id}
                user={user}
                isSelected={assignees.some(
                  (assignee) => assignee.id === user.id
                )}
                onClick={() => toggleUserFromTask(user.id)}
              />
            ))}

            <Flex paddingLeft={'8px'} gap={'8px'}>
              <Input
                name={'invitee'}
                placeholder={t('plan.taskDetails.assignees.input.placeholder')}
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
              <Button
                variant={'primary'}
                onClick={onInviteeSubmit}
                disabled={!isValidEmail(email)}
                type="button"
              >
                {t('plan.taskDetails.assignees.button.label')}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Popup>
    </Flex>
  );
};

type AssigneeProps = {
  user: UserAvatarFragment;
  isSelected?: boolean;
  onClick?: () => void;
};

export const Assignee = ({ user, isSelected, onClick }: AssigneeProps) => {
  return (
    <Container
      padding={'4px 6px'}
      gap={'8px'}
      alignItems={'center'}
      onClick={onClick}
    >
      <Avatar user={user} size={'small'} />
      <Flex flex={1}>
        <Text size={'sm'}>{user.displayName}</Text>
      </Flex>
      <CustomCheckIcon size={'xsmall'} isSelected={isSelected} />
    </Container>
  );
};

const CustomCheckIcon = styled(CheckIcon)<{ isSelected?: boolean }>`
  ${(props) => !props.isSelected && 'display :none'};
`;

const Container = styled(Flex)`
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 180ms ease-out;

  &:hover {
    background-color: ${colors.dark50};
  }
`;

import React, { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormField from './FormField';
import { GhostTextarea } from '../GhostTextarea/GhostTextarea';
import type { GhostInputSize } from '../Input/GhostInput';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button/Button';
import { Flex } from '../Flex/Flex';
import styled from 'styled-components';

type GhostTextareaFieldProps = {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  size?: GhostInputSize;
  disabled?: boolean;
  shouldResize?: boolean;
  moreContent?: boolean;
  defaultMaxHeight?: number;
};

const DEFAULT_MAX_HEIGHT = 200;

export const GhostTextareaField: FC<GhostTextareaFieldProps> = ({
  name,
  label,
  placeholder,
  required,
  size,
  disabled,
  shouldResize = false,
  moreContent = false,
  defaultMaxHeight = DEFAULT_MAX_HEIGHT,
  ...rest
}) => {
  const [maxHeight, setMaxHeight] = React.useState<number | undefined>(
    defaultMaxHeight
  );
  const { t } = useTranslation();
  const { register, watch } = useFormContext();
  const fieldProps = register(name, {
    required: required ? t('shared.input.requiredErrorMessage') : false,
  });
  const [hasMoreContent, setHasMoreContent] = useState(false);

  const value = watch(name);

  const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null);
  const hiddenTextAreaRef = React.useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (!shouldResize) {
      return;
    }
    if (textAreaRef.current && hiddenTextAreaRef.current) {
      // Set the height to 0px so that the scrollHeight can be measured on the hidden textarea.
      hiddenTextAreaRef.current.style.height = '0px';
      const scrollHeight = hiddenTextAreaRef.current.scrollHeight;

      // Use the calculated scrollHeight to set the height of both the visible and hidden textarea.
      const height = maxHeight
        ? Math.min(maxHeight, scrollHeight)
        : scrollHeight;

      if (height < defaultMaxHeight) {
        setMaxHeight(defaultMaxHeight);
      }
      textAreaRef.current.style.height = height + 'px';
      hiddenTextAreaRef.current.style.height = height + 'px';

      const newHasMoreContent =
        textAreaRef.current.scrollHeight > textAreaRef.current.clientHeight;
      if (newHasMoreContent !== hasMoreContent) {
        setHasMoreContent(newHasMoreContent);
      }
    }
  });

  return (
    <>
      <FormField name={name} label={label} alignInputLeft={!!label}>
        <GhostTextarea
          {...rest}
          {...fieldProps}
          ref={(ref) => {
            fieldProps.ref(ref);
            if (shouldResize) {
              textAreaRef.current = ref;
            }
          }}
          placeholder={placeholder}
          size={size}
          disabled={disabled}
        />
        {shouldResize && (
          <HiddenTextarea
            value={value}
            ref={(ref) => {
              if (shouldResize) {
                hiddenTextAreaRef.current = ref;
              }
            }}
            size={size}
            disabled={disabled}
          />
        )}
      </FormField>
      {moreContent && (
        <Flex justifyContent={'center'}>
          <>
            {hasMoreContent && (
              <Button size={'small'} onClick={() => setMaxHeight(undefined)}>
                {t('shared.seeMore')}
              </Button>
            )}
            {!hasMoreContent && maxHeight !== defaultMaxHeight && (
              <Button
                size={'small'}
                onClick={() => setMaxHeight(defaultMaxHeight)}
              >
                {t('shared.seeLess')}
              </Button>
            )}
          </>
        </Flex>
      )}
    </>
  );
};

const HiddenTextarea = styled(GhostTextarea)`
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden;
  resize: none;
`;

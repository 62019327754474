import React, { FC } from 'react';
import { formatDate } from '../helpers/date';
import { PlainDate } from '../types';
import { Flex } from './Flex/Flex';
import { TargetIcon } from './Icon/TargetIcon';
import { Text } from './Text/Text';
import { useTranslation } from 'react-i18next';
import { getDueDateColor } from '@src/helpers/tasks';

type TargetDateProps = {
  date?: PlainDate | null;
  editable?: boolean;
};

export const TargetDate: FC<TargetDateProps> = ({ date, editable }) => {
  const { t } = useTranslation();
  const color = getDueDateColor(date);
  let dateText = formatDate(date);
  if (editable) {
    dateText = date ? dateText : t('plan.taskDetails.dueDate.placeholder');
  }
  return (
    <Flex alignItems={'center'} gap={'6px'}>
      <TargetIcon size={'small'} color={color} />
      <Text size={'sm'} color={color} noWrap>
        {t('plan.header.target')}: {dateText}
      </Text>
    </Flex>
  );
};

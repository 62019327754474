import { useQuery } from '@apollo/client';
import {
  NotificationData,
  isNotificationData,
} from '@src/helpers/notifications';
import { getTaskCustomer } from './getComment.query';

type UseNotificationIconResult = {
  logo: string | null;
  name: string;
  type: 'customer';
} | null;

export const useNotificationIcon = (
  data: unknown
): UseNotificationIconResult => {
  let skipComment = true;

  if (isNotificationData(data)) {
    skipComment = false;
  }
  const { data: taskData } = useQuery(getTaskCustomer, {
    variables: { id: (data as NotificationData).taskId ?? '' },
    skip: skipComment,
  });

  if (!taskData) {
    return null;
  }

  return {
    logo: taskData.task.plan.customer.logo ?? null,
    name: taskData.task.plan.customer.name,
    type: 'customer',
  };
};

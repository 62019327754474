import { graphql } from '@graphql/generated';

export const resetPasswordMutation = graphql(`
  mutation resetPasswordMutation($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      token
      maxAge
    }
  }
`);

import { isSameDay } from 'date-fns';

import type { ActivityGroup } from '@src/@types/activities';
import type { Activity } from '@src/graphql/generated/graphql';

export function getActivitiesGroupedByDay(
  activities: Activity[]
): ActivityGroup[] {
  return activities.reduce((acc: ActivityGroup[], activity, accIndex) => {
    const activityDate = new Date(activity.createdAt);
    const lastGroup: ActivityGroup = acc[acc.length - 1] || {
      date: activityDate,
      activities: [],
    };

    if (isSameDay(lastGroup.date, activityDate) && accIndex > 0) {
      return acc.map((activityGroup, index) => {
        if (index === acc.length - 1) {
          return {
            ...lastGroup,
            activities: [...lastGroup.activities, activity],
          };
        }

        return activityGroup;
      });
    } else {
      return [
        ...acc,
        {
          date: activityDate,
          activities: [activity],
        },
      ];
    }
  }, []);
}

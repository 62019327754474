import { Icon, IconComponent } from './index';

export const FileDownloadIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 121 151"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M92.2247 72.0717C81.1789 72.0717 72.1908 81.0424 72.1908 92.0725C72.1913 103.1 81.1787 112.073 92.2244 112.073C103.274 112.073 112.258 103.1 112.258 92.0725C112.259 81.0422 103.273 72.0718 92.2247 72.0717ZM92.2247 114.432C79.8738 114.432 69.8274 104.403 69.8274 92.0725C69.8285 79.7421 79.8738 69.7124 92.2244 69.7124C104.575 69.7124 114.621 79.7422 114.621 92.0725C114.622 104.403 104.575 114.433 92.2247 114.432Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M92.2247 99.1038C91.9123 99.1038 91.6117 98.981 91.3901 98.7598L85.1824 92.5615C84.7193 92.0995 84.7186 91.3524 85.1814 90.8928C85.6421 90.4306 86.3902 90.4307 86.8506 90.8928L92.2247 96.2576L97.5984 90.8926C98.0593 90.4306 98.8073 90.4307 99.27 90.8928C99.731 91.3521 99.7304 92.0995 99.27 92.5615L93.062 98.7595C92.8406 98.9807 92.5397 99.1039 92.2247 99.1038Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M92.2247 99.1037C91.5721 99.1037 91.0428 98.5778 91.0428 97.924L91.0437 79.7961C91.0437 79.1422 91.5721 78.6165 92.2244 78.6165C92.8771 78.6163 93.4062 79.1424 93.4062 79.7961L93.4066 97.9239C93.4066 98.5778 92.877 99.1038 92.2247 99.1037Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M99.2517 105.528H85.2015C84.5468 105.528 84.0197 105 84.0197 104.349C84.0205 103.698 84.5465 103.169 85.2014 103.169L99.2517 103.169C99.9029 103.169 100.432 103.698 100.432 104.349C100.432 105 99.9029 105.529 99.2506 105.529L99.2517 105.528Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M95.3076 72.2933C94.655 72.2933 94.125 71.7675 94.125 71.1137L94.1255 22.8298C94.1255 21.7017 93.2044 20.7827 92.072 20.7827H43.8397C43.1839 20.7827 42.6571 20.2543 42.6571 19.603C42.6576 18.9518 43.1839 18.4234 43.8387 18.4234L92.0728 18.4231C94.5067 18.4231 96.4883 20.3993 96.4883 22.8299L96.4885 71.1135C96.4885 71.7674 95.9591 72.2934 95.3076 72.2933ZM92.2247 114.433H22.8703C20.4354 114.433 18.4532 112.454 18.4532 110.024L18.4536 43.7645C18.4536 43.1131 18.9824 42.5849 19.6347 42.5849C20.2881 42.5848 20.8163 43.1133 20.8163 43.7646L20.8165 110.024C20.8165 111.154 21.7372 112.073 22.8695 112.073L92.2247 112.073C92.8771 112.073 93.4062 112.602 93.4062 113.253C93.4066 113.904 92.877 114.433 92.2247 114.433Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.4883 42.5845H40.2152C41.5623 42.5845 42.6573 41.4909 42.6573 40.149L42.6578 22.451L22.4883 42.5845ZM40.2152 44.9438H19.6358C19.1583 44.9438 18.7266 44.6565 18.5444 44.2165C18.3607 43.7764 18.4607 43.2679 18.8003 42.9311L43.0024 18.7671C43.3402 18.4303 43.8487 18.3297 44.2895 18.5115C44.7327 18.6958 45.0181 19.126 45.0181 19.6028L45.0186 40.1489C45.0186 42.7935 42.864 44.944 40.2152 44.9438Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M74.9271 68.3215H29.4456C28.7931 68.3215 28.2638 67.7955 28.2638 67.1442C28.2647 66.4903 28.793 65.9645 29.4453 65.9645L74.9271 65.9644C75.5796 65.9644 76.1079 66.4904 76.1079 67.1442C76.1079 67.7953 75.5787 68.3215 74.9271 68.3215ZM65.2571 79.2851H29.4456C28.7931 79.2851 28.2638 78.7568 28.2638 78.1055C28.2647 77.4541 28.793 76.9258 29.4453 76.9258H65.2571C65.9096 76.9258 66.4382 77.4541 66.4382 78.1055C66.4391 78.7567 65.909 79.2852 65.2571 79.2851ZM61.998 90.2463H29.4456C28.7931 90.2463 28.2638 89.7181 28.2638 89.0667C28.2647 88.4154 28.793 87.8871 29.4453 87.8871L61.998 87.8869C62.6505 87.8869 63.1789 88.4155 63.1789 89.0667C63.1789 89.7179 62.6496 90.2465 61.998 90.2463ZM62.9087 101.21H29.4456C28.7931 101.21 28.2638 100.682 28.2638 100.03C28.2647 99.379 28.793 98.8508 29.4453 98.8508L62.9087 98.8506C63.5611 98.8506 64.0897 99.3792 64.0897 100.03C64.0906 100.682 63.5604 101.21 62.9087 101.21Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M85.4977 57.3601H29.4456C28.7931 57.3601 28.2638 56.8318 28.2638 56.1805C28.2647 55.5292 28.793 55.0008 29.4453 55.0008L85.4977 55.0007C86.15 55.0007 86.6787 55.5292 86.6787 56.1805C86.6795 56.8316 86.1494 57.3601 85.4977 57.3601Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.6358 102.377H10.7956C8.36065 102.377 6.37843 100.401 6.37843 97.9709L6.37884 10.7746C6.37884 8.34402 8.3601 6.36813 10.7948 6.36813L79.9982 6.36792C82.4319 6.36792 84.4135 8.34416 84.4135 10.7748L84.4137 19.6026C84.4137 20.254 83.8842 20.7825 83.2319 20.7825C82.5804 20.7825 82.0504 20.254 82.0504 19.6028L82.0509 10.7746C82.0509 9.64651 81.1295 8.72753 79.9971 8.72753L10.7956 8.72746C9.66237 8.72746 8.74173 9.64671 8.74173 10.7748V97.971C8.74173 99.0991 9.66237 100.018 10.7948 100.018H19.6358C20.2883 100.018 20.8165 100.547 20.8165 101.198C20.8167 101.849 20.2873 102.378 19.6358 102.377Z"
          fill="black"
        />
      </svg>
    </Icon>
  );
};

import { Icon, IconComponent } from './index';

export const ScheduleIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 121 151"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M85.14 17.7905H76.34C75.368 17.7905 74.58 17.0038 74.58 16.0335C74.58 15.0631 75.368 14.2764 76.34 14.2764H85.14C86.112 14.2764 86.9 15.0631 86.9 16.0335C86.9 17.0038 86.112 17.7905 85.14 17.7905Z"
          fill="black"
        />
        <path
          d="M30.58 17.7905H21.78C20.808 17.7905 20.02 17.0038 20.02 16.0335C20.02 15.0631 20.808 14.2764 21.78 14.2764H30.58C31.552 14.2764 32.34 15.0631 32.34 16.0335C32.34 17.0038 31.552 17.7905 30.58 17.7905Z"
          fill="black"
        />
        <path
          d="M100.98 64.1338C100.008 64.1338 99.22 63.3471 99.22 62.3767V17.7905H92.18C91.208 17.7905 90.42 17.0038 90.42 16.0335C90.42 15.0631 91.208 14.2764 92.18 14.2764H100.98C101.952 14.2764 102.74 15.0631 102.74 16.0335V62.3767C102.74 63.3471 101.952 64.1338 100.98 64.1338Z"
          fill="black"
        />
        <path
          d="M62.26 87.1956H5.94C4.96804 87.1956 4.18 86.4089 4.18 85.4385V16.0335C4.18 15.0631 4.96804 14.2764 5.94 14.2764H14.74C15.712 14.2764 16.5 15.0631 16.5 16.0335C16.5 17.0038 15.712 17.7905 14.74 17.7905H7.7V83.6815H62.26C63.232 83.6815 64.02 84.4682 64.02 85.4385C64.02 86.4089 63.232 87.1956 62.26 87.1956Z"
          fill="black"
        />
        <path
          d="M69.3 17.7905H37.62C36.648 17.7905 35.86 17.0038 35.86 16.0335C35.86 15.0631 36.648 14.2764 37.62 14.2764H69.3C70.272 14.2764 71.06 15.0631 71.06 16.0335C71.06 17.0038 70.272 17.7905 69.3 17.7905Z"
          fill="black"
        />
        <path
          d="M100.98 33.6043H5.94C4.96804 33.6043 4.18 32.8175 4.18 31.8472C4.18 30.8768 4.96804 30.0901 5.94 30.0901H100.98C101.952 30.0901 102.74 30.8768 102.74 31.8472C102.74 32.8175 101.952 33.6043 100.98 33.6043Z"
          fill="black"
        />
        <path
          d="M18.26 24.7091C15.3487 24.7091 12.98 22.3443 12.98 19.4378V12.4095C12.98 9.50301 15.3487 7.13818 18.26 7.13818C21.1713 7.13818 23.54 9.50301 23.54 12.4095V19.4378C23.54 22.3443 21.1713 24.7091 18.26 24.7091ZM18.26 10.6524C17.2896 10.6524 16.5 11.4406 16.5 12.4095V19.4378C16.5 20.4066 17.2896 21.1949 18.26 21.1949C19.2304 21.1949 20.02 20.4066 20.02 19.4378V12.4095C20.02 11.4406 19.2304 10.6524 18.26 10.6524Z"
          fill="black"
        />
        <path
          d="M34.1 24.7091C31.1887 24.7091 28.82 22.3443 28.82 19.4378V12.4095C28.82 9.50301 31.1887 7.13818 34.1 7.13818C37.0113 7.13818 39.38 9.50301 39.38 12.4095V19.4378C39.38 22.3443 37.0113 24.7091 34.1 24.7091ZM34.1 10.6524C33.1296 10.6524 32.34 11.4406 32.34 12.4095V19.4378C32.34 20.4066 33.1296 21.1949 34.1 21.1949C35.0704 21.1949 35.86 20.4066 35.86 19.4378V12.4095C35.86 11.4406 35.0704 10.6524 34.1 10.6524Z"
          fill="black"
        />
        <path
          d="M72.82 24.7091C69.9087 24.7091 67.54 22.3443 67.54 19.4378V12.4095C67.54 9.50301 69.9087 7.13818 72.82 7.13818C75.7313 7.13818 78.1 9.50301 78.1 12.4095V19.4378C78.1 22.3443 75.7313 24.7091 72.82 24.7091ZM72.82 10.6524C71.8496 10.6524 71.06 11.4406 71.06 12.4095V19.4378C71.06 20.4066 71.8496 21.1949 72.82 21.1949C73.7904 21.1949 74.58 20.4066 74.58 19.4378V12.4095C74.58 11.4406 73.7904 10.6524 72.82 10.6524Z"
          fill="black"
        />
        <path
          d="M88.66 24.7091C85.7487 24.7091 83.38 22.3443 83.38 19.4378V12.4095C83.38 9.50301 85.7487 7.13818 88.66 7.13818C91.5713 7.13818 93.94 9.50301 93.94 12.4095V19.4378C93.94 22.3443 91.5713 24.7091 88.66 24.7091ZM88.66 10.6524C87.6896 10.6524 86.9 11.4406 86.9 12.4095V19.4378C86.9 20.4066 87.6896 21.1949 88.66 21.1949C89.6304 21.1949 90.42 20.4066 90.42 19.4378V12.4095C90.42 11.4406 89.6304 10.6524 88.66 10.6524Z"
          fill="black"
        />
        <path
          d="M21.78 49.3084H14.74C13.768 49.3084 12.98 48.5217 12.98 47.5513V40.523C12.98 39.5526 13.768 38.7659 14.74 38.7659H21.78C22.752 38.7659 23.54 39.5526 23.54 40.523V47.5513C23.54 48.5217 22.752 49.3084 21.78 49.3084ZM16.5 45.7942H20.02V42.2801H16.5V45.7942Z"
          fill="black"
        />
        <path
          d="M35.86 49.3084H28.82C27.848 49.3084 27.06 48.5217 27.06 47.5513V40.523C27.06 39.5526 27.848 38.7659 28.82 38.7659H35.86C36.832 38.7659 37.62 39.5526 37.62 40.523V47.5513C37.62 48.5217 36.832 49.3084 35.86 49.3084ZM30.58 45.7942H34.1V42.2801H30.58V45.7942Z"
          fill="black"
        />
        <path
          d="M49.94 49.3084H42.9C41.928 49.3084 41.14 48.5217 41.14 47.5513V40.523C41.14 39.5526 41.928 38.7659 42.9 38.7659H49.94C50.912 38.7659 51.7 39.5526 51.7 40.523V47.5513C51.7 48.5217 50.912 49.3084 49.94 49.3084ZM44.66 45.7942H48.18V42.2801H44.66V45.7942Z"
          fill="black"
        />
        <path
          d="M64.02 49.3084H56.98C56.008 49.3084 55.22 48.5217 55.22 47.5513V40.523C55.22 39.5526 56.008 38.7659 56.98 38.7659H64.02C64.992 38.7659 65.78 39.5526 65.78 40.523V47.5513C65.78 48.5217 64.992 49.3084 64.02 49.3084ZM58.74 45.7942H62.26V42.2801H58.74V45.7942Z"
          fill="black"
        />
        <path
          d="M78.1 49.3084H71.06C70.088 49.3084 69.3 48.5217 69.3 47.5513V40.523C69.3 39.5526 70.088 38.7659 71.06 38.7659H78.1C79.072 38.7659 79.86 39.5526 79.86 40.523V47.5513C79.86 48.5217 79.072 49.3084 78.1 49.3084ZM72.82 45.7942H76.34V42.2801H72.82V45.7942Z"
          fill="black"
        />
        <path
          d="M92.18 49.3084H85.14C84.168 49.3084 83.38 48.5217 83.38 47.5513V40.523C83.38 39.5526 84.168 38.7659 85.14 38.7659H92.18C93.152 38.7659 93.94 39.5526 93.94 40.523V47.5513C93.94 48.5217 93.152 49.3084 92.18 49.3084ZM86.9 45.7942H90.42V42.2801H86.9V45.7942Z"
          fill="black"
        />
        <path
          d="M21.78 63.3651H14.74C13.768 63.3651 12.98 62.5783 12.98 61.608V54.5796C12.98 53.6092 13.768 52.8225 14.74 52.8225H21.78C22.752 52.8225 23.54 53.6092 23.54 54.5796V61.608C23.54 62.5783 22.752 63.3651 21.78 63.3651ZM16.5 59.8509H20.02V56.3367H16.5V59.8509Z"
          fill="black"
        />
        <path
          d="M35.86 63.3651H28.82C27.848 63.3651 27.06 62.5783 27.06 61.608V54.5796C27.06 53.6092 27.848 52.8225 28.82 52.8225H35.86C36.832 52.8225 37.62 53.6092 37.62 54.5796V61.608C37.62 62.5783 36.832 63.3651 35.86 63.3651ZM30.58 59.8509H34.1V56.3367H30.58V59.8509Z"
          fill="black"
        />
        <path
          d="M49.94 63.3651H42.9C41.928 63.3651 41.14 62.5783 41.14 61.608V54.5796C41.14 53.6092 41.928 52.8225 42.9 52.8225H49.94C50.912 52.8225 51.7 53.6092 51.7 54.5796V61.608C51.7 62.5783 50.912 63.3651 49.94 63.3651ZM44.66 59.8509H48.18V56.3367H44.66V59.8509Z"
          fill="black"
        />
        <path
          d="M64.02 63.3651H56.98C56.008 63.3651 55.22 62.5783 55.22 61.608V54.5796C55.22 53.6092 56.008 52.8225 56.98 52.8225H64.02C64.992 52.8225 65.78 53.6092 65.78 54.5796V61.608C65.78 62.5783 64.992 63.3651 64.02 63.3651ZM58.74 59.8509H62.26V56.3367H58.74V59.8509Z"
          fill="black"
        />
        <path
          d="M21.78 77.4219H14.74C13.768 77.4219 12.98 76.6352 12.98 75.6648V68.6365C12.98 67.6661 13.768 66.8794 14.74 66.8794H21.78C22.752 66.8794 23.54 67.6661 23.54 68.6365V75.6648C23.54 76.6352 22.752 77.4219 21.78 77.4219ZM16.5 73.9078H20.02V70.3936H16.5V73.9078Z"
          fill="black"
        />
        <path
          d="M35.86 77.4219H28.82C27.848 77.4219 27.06 76.6352 27.06 75.6648V68.6365C27.06 67.6661 27.848 66.8794 28.82 66.8794H35.86C36.832 66.8794 37.62 67.6661 37.62 68.6365V75.6648C37.62 76.6352 36.832 77.4219 35.86 77.4219ZM30.58 73.9078H34.1V70.3936H30.58V73.9078Z"
          fill="black"
        />
        <path
          d="M49.94 77.4219H42.9C41.928 77.4219 41.14 76.6352 41.14 75.6648V68.6365C41.14 67.6661 41.928 66.8794 42.9 66.8794H49.94C50.912 66.8794 51.7 67.6661 51.7 68.6365V75.6648C51.7 76.6352 50.912 77.4219 49.94 77.4219ZM44.66 73.9078H48.18V70.3936H44.66V73.9078Z"
          fill="black"
        />
        <path
          d="M88.66 113.662C73.1326 113.662 60.5 101.05 60.5 85.5483C60.5 70.0466 73.1326 57.4348 88.66 57.4348C93.2397 57.4348 97.7847 58.5572 101.803 60.6808C111.066 65.5666 116.82 75.0956 116.82 85.5483C116.82 101.05 104.187 113.662 88.66 113.662ZM88.66 60.949C75.0735 60.949 64.02 71.9842 64.02 85.5483C64.02 99.1124 75.0735 110.148 88.66 110.148C102.247 110.148 113.3 99.1124 113.3 85.5483C113.3 76.4017 108.264 68.0637 100.158 63.7874C96.6434 61.9303 92.668 60.949 88.66 60.949Z"
          fill="black"
        />
        <path
          d="M100.746 102.266C97.7335 102.266 86.6807 101.166 76.3974 86.1386C76.0014 85.5963 71.7763 79.6299 72.2678 72.6521C72.3109 72.0395 72.6713 71.4939 73.2182 71.213L80.3748 67.537C81.1769 67.1249 82.1612 67.385 82.6542 68.1394L87.5805 75.68C88.0543 76.4057 87.9278 77.3679 87.2826 77.9468L85.4852 79.559C86.0682 81.7292 88.1747 87.2058 94.7822 88.6368L96.2251 87.2502C96.813 86.6853 97.7117 86.5983 98.3974 87.038L105.74 91.751C106.484 92.2285 106.757 93.1826 106.379 93.9803L102.94 101.238C102.676 101.795 102.14 102.173 101.526 102.235C101.457 102.242 101.187 102.266 100.746 102.266ZM75.7486 73.8653C75.7823 79.3125 79.2128 84.0288 79.2495 84.0787C79.262 84.0956 79.2761 84.1153 79.2882 84.1327C88.1228 97.0578 97.3168 98.5951 100.23 98.7396L102.536 93.8729L97.6639 90.7455L96.5719 91.7947C96.1756 92.1751 95.6241 92.3486 95.0809 92.2652C89.0877 91.3343 85.7912 87.8285 84.0814 85.0514C82.2525 82.0813 81.8239 79.3099 81.8066 79.1935C81.7197 78.6104 81.9317 78.0224 82.3711 77.6281L83.8083 76.3391L80.5735 71.3872L75.7486 73.8653Z"
          fill="black"
        />
      </svg>
    </Icon>
  );
};

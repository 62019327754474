import { graphql } from '@graphql/generated';

export const getPlanGanttQuery = graphql(`
  query GetPlanGantt($planId: ID!) {
    plan(id: $planId) {
      id
      startDate
      workspace {
        id
        name
      }
      customer {
        name
      }
      owner {
        ...UserAvatar
      }
      tasksWithoutDates {
        ...TaskRow
        section {
          id
        }
      }
      sections {
        id
        title
        tasks {
          ...TaskRow
        }
      }
    }
  }
`);

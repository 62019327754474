import { useLazyQuery } from '@apollo/client';
import { CompanyLogo } from '@components/CompanyLogo/CompanyLogo';
import { SelectOption } from '@components/Select/Select';
import { useTranslation } from 'react-i18next';
import type { GroupBase } from 'react-select';
import { listCustomersQuery } from '../NewPlan.query';
import { NewPlanFormValues } from '../types';

export function useLoadCustomerGroupedOptions() {
  const { t } = useTranslation();
  const [listCustomers] = useLazyQuery(listCustomersQuery);

  return async (
    query: string
  ): Promise<GroupBase<SelectOption<NewPlanFormValues['customer']>>[]> => {
    const { data } = await listCustomers({
      variables: { filters: { search: query, limit: 10 } },
      fetchPolicy: 'no-cache',
    });

    const customers = data?.workspace.customers ?? [];

    return [
      {
        label: t('newPlan.form.customer.group.existing'),
        options: customers.map((c) => ({
          label: `${c.name} (${c.website})`,
          value: { id: c.id, logo: c.logo },
          leftContent: <CompanyLogo name={c.name} logo={c.logo} size={20} />,
        })),
      },
      {
        label: t('newPlan.form.customer.group.suggested'),
        options: (await getClearbitSuggestions(query)).filter(
          (c) =>
            !customers.find(
              (customer) =>
                customer.website && customer.website === c.value.website
            )
        ),
      },
      {
        label: t('newPlan.form.customer.group.create'),
        options: [
          {
            label: query,
            value: {
              name: query,
            },
          },
        ],
      },
    ];
  };
}

async function getClearbitSuggestions(query: string) {
  const params = new URLSearchParams({ query });
  const clearbitUrl = new URL(
    `https://autocomplete.clearbit.com/v1/companies/suggest?${params}`
  );
  const suggestions: {
    name: string;
    domain: string;
    logo: string;
  }[] = await (await fetch(clearbitUrl)).json();
  return suggestions.map((suggestion) => ({
    label: `${suggestion.name} (${suggestion.domain})`,
    value: {
      name: suggestion.name,
      website: suggestion.domain,
      logo: suggestion.logo,
    },
    leftContent: (
      <CompanyLogo name={suggestion.name} logo={suggestion.logo} size={20} />
    ),
  }));
}

import type { FC, MouseEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { GhostInputSize } from '../Input/GhostInput';
import { GhostInput } from '../Input/GhostInput';
import FormField from './FormField';

type GhostInputFieldProps = {
  name: string;
  label?: string;
  type?: string;
  placeholder?: string;
  required?: boolean;
  size?: GhostInputSize;
  onClick?: MouseEventHandler<HTMLInputElement>;
  disabled?: boolean;
  autoFocus?: boolean;
};

export const GhostInputField: FC<GhostInputFieldProps> = ({
  name,
  label,
  placeholder,
  type = 'text',
  required,
  size,
  onClick,
  disabled,
  autoFocus,
}) => {
  const { t } = useTranslation();
  const { register } = useFormContext();

  return (
    <FormField name={name} label={label} flex={1}>
      <GhostInput
        {...register(name, {
          required: required ? t('shared.input.requiredErrorMessage') : false,
        })}
        placeholder={placeholder}
        type={type}
        size={size}
        onClick={onClick}
        disabled={disabled}
        autoFocus={autoFocus}
      />
    </FormField>
  );
};

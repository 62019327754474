import { FC } from 'react';
import styled from 'styled-components';

import { formatDate } from '@src/helpers/date';
import { colors } from '@src/style/colors';

interface DayProps {
  date: Date;
}

export const Day: FC<DayProps> = ({ date }) => {
  return <DayContainer>{formatDate(date.toDateString())}</DayContainer>;
};

const DayContainer = styled.span`
  color: ${colors.dark900};
  text-align: center;
  margin-top: 20px;
  min-width: 80px;
  max-width: 80px;
`;

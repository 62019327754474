import { useQuery } from '@apollo/client';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import {
  ElementToProvideType,
  GetPlanResourcesQuery,
} from '@graphql/generated/graphql';
import { useWorkspaceRouting } from '@graphql/hooks/useWorkspaceRouting';
import { Asset } from '../Asset/Asset';
import { Flex } from '../Flex/Flex';
import { FileIcon } from '../Icon/FileIcon';
import { GlobeIcon } from '../Icon/GlobeIcon';
import { Loader } from '../Loader/Loader';
import { TabItem } from '../Tabs/TabItem';
import { Tabs } from '../Tabs/Tabs';
import { Text } from '../Text/Text';
import { getPlanResourcesQuery } from './PlanResources.query';

type PlanResourcesProps = {
  planId: string;
  isCustomer?: boolean;
  root?: string;
  isPreview?: boolean;
};

export const PlanResources: FC<PlanResourcesProps> = ({
  planId,
  isCustomer,
  isPreview,
  root = '/customer',
}) => {
  const { t } = useTranslation();
  const { pathTo } = !isCustomer
    ? useWorkspaceRouting()
    : {
        pathTo: (path: string) => path.replace(`/plans/${planId}`, root),
      };
  const { loading, data } = useQuery(getPlanResourcesQuery, {
    variables: { planId },
    context: {
      headers: {
        ...(isPreview && { 'X-Preview': true }),
      },
    },
  });

  const planFiles = data?.plan.files;
  const planLinks = data?.plan.links;
  const customerFiles = data?.plan.elementsToProvide
    .filter(
      (elementToProvide) =>
        elementToProvide.type === ElementToProvideType.File &&
        !!elementToProvide.file
    )
    .map((elementToProvide) => ({
      ...elementToProvide.file,
      task: elementToProvide.task,
    })) as GetPlanResourcesQuery['plan']['files'];
  const customerLinks = data?.plan.elementsToProvide
    .filter(
      (elementToProvide) =>
        elementToProvide.type === ElementToProvideType.Link &&
        !!elementToProvide.link
    )
    .map((elementToProvide) => ({
      ...elementToProvide.link,
      task: elementToProvide.task,
    })) as GetPlanResourcesQuery['plan']['links'];
  const allFiles = [...(planFiles || []), ...(customerFiles || [])];
  const allLinks = [...(planLinks || []), ...(customerLinks || [])];

  return (
    <div className="w-full max-w-[848px] flex flex-col gap-[24px]">
      <Tabs>
        <TabItem end to={pathTo(`/plans/${planId}/resources`)}>
          <Text size={'sm'}>{t('plan.resources.tab.all.title')}</Text>
        </TabItem>
        <TabItem to={pathTo(`/plans/${planId}/resources/plan`)}>
          <Text size={'sm'}>
            {t('plan.resources.tab.from.title', {
              companyName: data?.plan.workspace.name,
            })}
          </Text>
        </TabItem>
        <TabItem to={pathTo(`/plans/${planId}/resources/customer`)}>
          <Text size={'sm'}>
            {t('plan.resources.tab.from.title', {
              companyName: data?.plan.customer.name,
            })}
          </Text>
        </TabItem>
      </Tabs>
      {loading ? (
        <Loader centered />
      ) : (
        <Routes>
          <Route
            index
            element={<ResourcesContent files={allFiles} links={allLinks} />}
          />
          <Route
            path={'plan'}
            element={<ResourcesContent files={planFiles} links={planLinks} />}
          />
          <Route
            path={'customer'}
            element={
              <ResourcesContent files={customerFiles} links={customerLinks} />
            }
          />
        </Routes>
      )}
    </div>
  );
};

type ResourcesContentProps = {
  files?: GetPlanResourcesQuery['plan']['files'];
  links?: GetPlanResourcesQuery['plan']['links'];
};

const ResourcesContent: FC<ResourcesContentProps> = ({ files, links }) => {
  const { t } = useTranslation();
  return (
    <>
      <Flex flexDirection={'column'} gap={'8px'}>
        <Text size={'xs'} color={'dark600'}>
          {t('plan.resources.documents.title')}
        </Text>
        {files?.map((file) => (
          <Asset
            key={file.id}
            icon={FileIcon}
            title={file.name}
            rightText={file.url}
            href={file.url}
            annotation={
              <Text size={'xs'}>
                in{' '}
                <Text inline weight={'regular'} size={'xs'}>
                  {file.task?.title}
                </Text>
              </Text>
            }
          />
        ))}
      </Flex>
      <Flex flexDirection={'column'} gap={'8px'}>
        <Text size={'xs'} color={'dark600'}>
          {t('plan.resources.links.title')}
        </Text>
        {links?.map((link) => (
          <Asset
            key={link.id}
            icon={GlobeIcon}
            title={link.description}
            rightText={link.url}
            href={link.url}
            annotation={
              <Text size={'xs'}>
                {t('plan.resources.links.in')}{' '}
                <Text inline weight={'regular'} size={'xs'}>
                  {link.task?.title}
                </Text>
              </Text>
            }
          />
        ))}
      </Flex>
    </>
  );
};

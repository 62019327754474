import type { FC, PropsWithChildren } from 'react';
import React from 'react';
import styled from 'styled-components';
import type { Colors } from '@style/colors';
import { colors } from '@style/colors';
import type { IconComponent } from '../Icon';
import { CloseIcon } from '../Icon/CloseIcon';
import { Text } from '../Text/Text';
import { TextWeight } from '@style/typography';
import { Avatar } from '../Avatar/Avatar';
import { UserAvatarFragment } from '@graphql/generated/graphql';

type PillProps = {
  Icon?: IconComponent;
  user?: UserAvatarFragment;
  color?: Colors;
  weight?: TextWeight;
  disabled?: boolean;
  onClose?: () => void;
};

export const Pill: FC<PropsWithChildren<PillProps>> = ({
  Icon,
  user,
  onClose,
  color,
  weight,
  disabled,
  children,
  ...props
}) => {
  return (
    <Container disabled={disabled} {...props}>
      {Icon && (
        <AssetContainer>
          <Icon size={'small'} color={color} />
        </AssetContainer>
      )}
      {user && (
        <AssetContainer>
          <Avatar user={user} />
        </AssetContainer>
      )}
      <Text size={'sm'} color={color} weight={weight}>
        {children}
      </Text>
      {!disabled && onClose && (
        <CloseContainer onClick={onClose}>
          <CloseIcon size={'small'} color={'dark500'} />
        </CloseContainer>
      )}
    </Container>
  );
};

const Container = styled.div<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  border-radius: 12px;
  padding: 6px;
  transition: background-color 180ms ease-out;

  ${({ disabled }) =>
    !disabled &&
    `
      &:hover {
        background-color: ${colors.dark25};
      }
  `}
`;

const AssetContainer = styled.div`
  margin-right: 6px;
`;

const CloseContainer = styled.div`
  cursor: pointer;
  margin-left: 4px;
  opacity: 0;
  transition: opacity 180ms ease-out;

  ${Container}:hover & {
    opacity: 1;
  }
`;

import { Icon, IconComponent } from './index';

export const GlobeIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
      >
        <g
          stroke="#888785"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          clipPath="url(#a)"
        >
          <path d="M8 2c3.321 0 6 2.679 6 6s-2.679 6-6 6M8 14a5.99 5.99 0 0 1-6-6c0-3.321 2.679-6 6-6" />
          <path d="M6.779 2.707c-1.927 3.216-1.927 7.37 0 10.587a1.41 1.41 0 0 0 2.443 0c1.927-3.217 1.927-7.37 0-10.588a1.41 1.41 0 0 0-2.443 0ZM2 8h12" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
};

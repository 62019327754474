import styled from 'styled-components';
import type {
  BorderProps,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  TypographyProps,
} from 'styled-system';
import {
  borders,
  color,
  flexbox,
  layout,
  position,
  space,
  typography,
} from 'styled-system';

type FlexProps = LayoutProps &
  SpaceProps &
  PositionProps &
  BorderProps &
  ColorProps &
  TypographyProps &
  FlexboxProps & {
    gap?: string;
  };

export const Flex = styled.div<FlexProps>`
  display: flex;

  ${layout};
  ${space};
  ${position};
  ${borders};
  ${color};
  ${typography};
  ${flexbox};

  ${({ gap }) => gap && `gap: ${gap}`}
`;

import { AuthenticatedRoot } from '@components/AuthenticatedRoot';
import { CustomerAuthenticatedRoot } from '@components/CustomerAuthenticatedRoot';
import { NotFound } from '@components/NotFound/NotFound';
import { CustomerDashboard } from '@pages/customer/CustomerDashboard';
import { CustomerLoginPage } from '@pages/customer/CustomerLoginPage';
import { Dashboard } from '@pages/Dashboard/page';
import { ForgotPasswordPage } from '@pages/ForgotPassword/page';
import { JoinWorkspacePage } from '@pages/JoinWorspace/page';
import { LoginPage } from '@pages/Login/page';
import { NewPlanPage } from '@pages/NewPlan/page';
import { PlanPage } from '@pages/Plan/page';
import { ResetPasswordPage } from '@pages/ResetPassword/page';
import * as Sentry from '@sentry/react';
import { RedirectToMainWorkspace } from '@src/contexts/WorkspaceContext/RedirectToMainWorkspace';
import { WorkspaceWrapper } from '@src/contexts/WorkspaceContext/WorkspaceWrapper';
import { SettingsPage } from '@src/pages/Settings/page';
import { SignUpPage } from '@src/pages/SignUp/page';
import { Crisp } from 'crisp-sdk-web';
import type { FC } from 'react';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { CRISP_WEBSITE_ID, ENV } from './config';
import { InsightsPage } from './pages/Insights/page';
import { ActivitiesPage } from './pages/Activities/page';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const AppRouter: FC = () => {
  useEffect(() => {
    if (ENV === 'production') {
      Crisp.configure(CRISP_WEBSITE_ID);
    }
  }, []);
  return <RouterProvider router={router} />;
};

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <>
      {/* Customer routing */}
      <Route path="/customer" errorElement={<NotFound />}>
        <Route path="/customer/login" element={<CustomerLoginPage />} />
        <Route
          path="/customer"
          element={<CustomerAuthenticatedRoot />}
          errorElement={<NotFound />}
        >
          <Route path="/customer/" element={<CustomerDashboard />} />
          <Route
            path="/customer/plans/:planId/*"
            element={<PlanPage isCustomerLink />}
          />
        </Route>
      </Route>

      {/* User routing */}
      <Route element={<AuthenticatedRoot />} errorElement={<NotFound />}>
        <Route index element={<RedirectToMainWorkspace />} />
        <Route path="/:workspaceSlug" element={<WorkspaceWrapper />}>
          <Route index element={<Dashboard />} />
          <Route path="plans/new" element={<NewPlanPage />} />
          <Route
            path="plans/:planId/duplicate"
            element={<NewPlanPage duplicate />}
          />
          <Route
            path="plans/:planId/preview/*"
            element={<PlanPage isPreview />}
          />
          <Route path="plans/:planId/*" element={<PlanPage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="insights/*" element={<InsightsPage />} />
          <Route path="activities" element={<ActivitiesPage />} />
        </Route>
      </Route>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/workspace/join" element={<JoinWorkspacePage />} />
    </>
  )
);

import { graphql } from '@graphql/generated';

export const decodeWorkspaceInvitationTokenQuery = graphql(`
  query decodeWorkspaceInvitationToken($token: String!) {
    decodeWorkspaceInvitationToken(token: $token) {
      user {
        id
        email
        status
      }
      workspace {
        id
        name
        slug
      }
    }
  }
`);

export const signupFromInvitationMutation = graphql(`
  mutation signupFromInvitation(
    $token: String!
    $input: SignupFromInvitationInput!
  ) {
    signupFromInvitation(token: $token, input: $input) {
      token
      maxAge
    }
  }
`);

import styled from 'styled-components';
import { colors } from '@style/colors';

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${colors.dark100};
`;

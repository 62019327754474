import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

import {
  ActionType,
  PlanSection,
  Task,
  TaskStatus,
  TaskVisibility,
} from '@src/graphql/generated/graphql';
import {
  RadioButton,
  StackAvatar,
  Tag,
  MoreActions,
  AutoSave,
  useToast,
  Typography,
  Divider,
} from '@src/newComponents';
import { useEffect, useMemo, useState } from 'react';
import { NotVisibleIcon } from '@src/components/Icon/NotVisibleIcon';
import { useMutation } from '@apollo/client';
import {
  deleteTaskMutation,
  updateTaskMutation,
} from '@src/components/TaskPanel/TaskDetails.query';
import { RightPanel } from '@src/components/RightPanel/RightPanel';
import { TaskPanel } from '@src/components/TaskPanel/TaskPanel';
import { Overlay } from '@src/components/Overlay/Overlay';
import { formatShortDate } from '@src/helpers/date';
import { CalendarIcon } from '@src/components/Icon/CalendarIcon';
import {
  Dialog,
  DialogClose,
  DialogConfirm,
  DialogContent,
  DialogDescription,
  DialogHeading,
} from '@src/components/Dialog/Dialog';
import { Flex } from '@src/components/Flex/Flex';

interface SectionTaskProps {
  task: Task;
  sections: PlanSection[];
  refetch: () => void;
  createdTaskId: string | null;
  isPreviewMode?: boolean;
}

interface FormValues {
  status: TaskStatus;
}

export const SectionTask = ({
  task,
  sections,
  refetch,
  createdTaskId,
  isPreviewMode,
}: SectionTaskProps) => {
  /* Vars */

  const { t } = useTranslation();
  const { createAsyncToast } = useToast();
  const [selectedTask, setSelectedTask] = useState<string | null>(null);
  const [isShowDeleteTaskModal, setIsShowDeleteTaskModal] = useState(false);
  const taskValues = {
    status: task.status,
  };

  const methods = useForm<FormValues>({
    defaultValues: taskValues,
    values: taskValues,
  });

  const taskIsChecked = methods.watch('status') === TaskStatus.Done;

  /* Effects */

  useEffect(() => {
    if (createdTaskId) {
      setSelectedTask(createdTaskId);
    }
  }, [createdTaskId]);

  /* Queries */

  const [updateTask] = useMutation(updateTaskMutation);
  const [deleteTask] = useMutation(deleteTaskMutation);

  /* Memos */

  const actionColor = useMemo(() => {
    switch (task.actionType) {
      case ActionType.AskFile:
        return 'orange';
      case ActionType.BookDemo:
        return 'pink';
      case ActionType.Checklist:
        return 'purple';
      case ActionType.FillForm:
        return 'green';
      case ActionType.SendFile:
        return 'yellow';
      case ActionType.SendLink:
        return 'orange';
      default:
        return 'brown';
    }
  }, [task.actionType]);

  const avatars = useMemo(() => {
    const userAssignees = task.assignees.map((assignee) => ({
      picture: assignee.avatarUrl || undefined,
      fullName: assignee.displayName,
      email: assignee.email,
    }));

    const compagnyAssigne = {
      picture: task.companyAssigneeLogo || undefined,
      fullName: task.companyAssignee,
      email: task.companyAssignee,
    };

    return [...userAssignees, compagnyAssigne];
  }, [task.assignees]);

  const taskDueDateColor = useMemo(() => {
    if (task.computedDueDate) {
      const now = new Date();
      const due = new Date(task.computedDueDate);

      if (due.toDateString() === now.toDateString()) {
        return 'text-orange-500';
      }

      if (due < now) {
        return 'text-red-500';
      }
    }

    return 'text-grey-600';
  }, [task.computedDueDate]);

  const moreActionsLabels = useMemo(() => {
    let moreActionsLabels = [
      {
        label: t('plan.taskDetails.comments.edit'),
        onClick: () => setSelectedTask(task.id),
      },
    ];

    if (!isPreviewMode) {
      moreActionsLabels = [
        ...moreActionsLabels,
        {
          label: t('plan.taskDetails.comments.delete'),
          onClick: () => setIsShowDeleteTaskModal(true),
        },
      ];
    }

    return moreActionsLabels;
  }, [t, task]);

  /* Functions */

  const onSubmit = async (values: FormValues) => {
    await createAsyncToast(
      updateTask({
        variables: {
          id: task.id,
          input: values,
        },
      })
    );

    refetch();
  };

  return (
    <>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
        className="select-none"
      >
        <div
          className="flex justify-between items-center py-6 gap-6 rounded-lg cursor-pointer hover:pl-4 hover:bg-dark-50 duration-200"
          onClick={() => setSelectedTask(task.id)}
        >
          <div className="flex gap-6 items-center">
            <Controller
              name="status"
              control={methods.control}
              render={({ field }) => (
                <RadioButton
                  isChecked={taskIsChecked}
                  onChange={(isChecked) =>
                    field.onChange(
                      isChecked ? TaskStatus.Done : TaskStatus.Todo
                    )
                  }
                />
              )}
            />

            {task.visibility === TaskVisibility.Private && (
              <Tag size="sm" color="brown">
                <NotVisibleIcon size="small" />

                {t('plan.taskDetails.internal-task')}
              </Tag>
            )}

            <Typography
              size="base"
              fontWeight="semibold"
              className={clsx('text-nowrap truncate', {
                'text-dark-900': !taskIsChecked,
                'line-through text-dark-600': taskIsChecked,
              })}
            >
              {task.title || t('planSection.task.title.default')}
            </Typography>

            <Tag size="sm" color={actionColor}>
              {/* @ts-expect-error - i18n dynamic key */}
              {t(`plan.taskDetails.taskActions.${task.actionType}`)}
            </Tag>
          </div>

          <div className="flex gap-6 items-center justify-end">
            {task.computedDueDate && (
              <div className="flex items-center gap-3">
                <CalendarIcon size="small" className={taskDueDateColor} />

                <Typography
                  size="sm"
                  fontWeight="medium"
                  className={taskDueDateColor}
                >
                  {formatShortDate(task.computedDueDate)}
                </Typography>
              </div>
            )}

            <StackAvatar size={24} avatars={avatars} />

            <MoreActions actions={moreActionsLabels} />
          </div>

          <AutoSave
            onSubmit={onSubmit}
            defaultValues={taskValues}
            methods={methods}
            triggerKeys={['status']}
          />
        </div>
      </form>

      {selectedTask && (
        <Overlay onClose={() => setSelectedTask(null)}>
          <RightPanel isOpen close={() => setSelectedTask(null)}>
            <TaskPanel
              selectedTaskId={selectedTask}
              sections={sections || []}
              closeTaskPanel={() => setSelectedTask(null)}
              isCustomer={isPreviewMode}
            />
          </RightPanel>
        </Overlay>
      )}

      <Dialog
        open={isShowDeleteTaskModal}
        onOpenChange={setIsShowDeleteTaskModal}
      >
        <DialogContent>
          <DialogHeading mt="8px" mx="24px">
            {t('plan.taskDetails.dropdown.deleteModal.title')}
          </DialogHeading>

          <DialogDescription my="6px" mx="24px">
            <Trans
              i18nKey="plan.taskDetails.dropdown.deleteModal.subtitle"
              components={{ 1: <br /> }}
            />
          </DialogDescription>

          <Divider />

          <Flex my="6px" mx="24px" justifyContent="center" gap="16px">
            <DialogClose>
              {t('plan.taskDetails.dropdown.deleteModal.cancel.label')}
            </DialogClose>

            <DialogConfirm
              onConfirm={() => deleteTask({ variables: { taskId: task.id } })}
            >
              {t('plan.taskDetails.dropdown.deleteModal.confirm.label')}
            </DialogConfirm>
          </Flex>
        </DialogContent>
      </Dialog>
    </>
  );
};

import { type FC } from 'react';
import clsx from 'clsx';

import { Typography } from '../../atoms';

interface BadgeProps {
  text: string;
  type: 'normal' | 'success';
}

export const Badge: FC<BadgeProps> = ({ text, type }) => {
  return (
    <div
      className={clsx('rounded-lg border', {
        'border-dark-100': type === 'normal',
        'border-green-700': type === 'success',
      })}
    >
      <div
        className={clsx('px-4 py-3 border-white border rounded-lg', {
          'bg-black-opacity-3': type === 'normal',
          'bg-green-700': type === 'success',
        })}
      >
        <Typography
          fontWeight="semibold"
          size="xs"
          className={clsx({
            'text-grey-800': type === 'normal',
            'text-white': type === 'success',
          })}
        >
          {text}
        </Typography>
      </div>
    </div>
  );
};

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PlanStatus } from '@graphql/generated/graphql';
import { colors } from '@style/colors';
import { Flex } from '@components/Flex/Flex';
import { Text } from '@components/Text/Text';

type PlanStatusPillProps = {
  status: PlanStatus;
};

type Colors = {
  background: string;
  dot: string;
  dotShadow: string;
};

const statusColors: Record<PlanStatus, Colors> = {
  [PlanStatus.Unpublished]: {
    background: colors.dark25,
    dot: colors.dark400,
    dotShadow: colors.dark100,
  },
  [PlanStatus.Published]: {
    background: colors.green_10,
    dot: colors.green,
    dotShadow: colors.green_25,
  },
};

export const PlanStatusPill = ({ status }: PlanStatusPillProps) => {
  const { t } = useTranslation();
  const { background, dot, dotShadow } = statusColors[status];

  return (
    <Flex
      alignItems={'center'}
      padding={'4px 8px'}
      borderRadius={'80px'}
      gap={'6px'}
      backgroundColor={background}
    >
      <Dot backgroundColor={dot} shadowColor={dotShadow} />
      <Text noWrap size={'xs'} weight={'semibold'}>
        {t(
          status === PlanStatus.Published
            ? 'plan.status.published'
            : 'plan.status.unpublished'
        )}
      </Text>
    </Flex>
  );
};

const Dot = styled.div<{ backgroundColor: string; shadowColor: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: 0 0 0 3px ${({ shadowColor }) => shadowColor};
`;

import { useWorkspaceRouting } from '@graphql/hooks/useWorkspaceRouting';
import { useUserContext } from '@src/contexts/UserContext/useUserContext';
import { useMemo, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  Avatar,
  Badge,
  Button,
  Popover,
  StackAvatar,
  Typography,
} from '@src/newComponents';
import { AvatarPopoverMenu } from './components';

import JamatLogo from '../../assets/images/jamat-logo.svg';
import { Plan, PlanStatus } from '@src/graphql/generated/graphql';
import { LinkButton } from '../Button/Button';
import { HomeIcon } from '../Icon/HomeIcon';
import { ClockIcon } from '../Icon/ClockIcon';
import { InsightsIcon } from '../Icon/InsightsIcon';
import { InvitePopup } from '../InvitePopup/InvitePopup';

type TopBarProps = {
  backLink?: string;
  plan?: Plan;
  isPreviewMode?: boolean;
  togglePreviewMode?: (isPreview?: boolean) => void;
};

export const TopBar: FC<TopBarProps> = ({
  backLink,
  plan,
  isPreviewMode,
  togglePreviewMode,
}) => {
  /* Vars */

  const { t } = useTranslation();
  const { pathTo } = useWorkspaceRouting();
  const user = useUserContext();
  const [isOpenAvatarMenu, setIsOpenAvatarMenu] = useState(false);

  /* Memos */

  const avatars = useMemo(() => {
    return (plan?.invitees || []).map((user) => ({
      picture: user.avatarUrl || undefined,
      fullName: user.displayName,
      email: user.email,
    }));
  }, [plan?.invitees]);

  return (
    <div className="w-full p-8 flex items-center justify-between">
      <div className="flex items-center gap-8  flex-1">
        <Link to="/">
          <img src={JamatLogo} alt="Jamat logo" height={36} />
        </Link>

        {backLink && (
          <Button
            variant="outlined"
            leftIcon="chevron-back-outline"
            to={backLink}
          >
            {t('shared.back')}
          </Button>
        )}
      </div>

      {plan ? (
        <div className="flex items-center gap-6 flex-1 justify-center">
          <Typography
            size="sm"
            fontWeight="semibold"
            className="text-dark-1000"
          >
            {plan.title}
          </Typography>

          <Badge
            type={plan.status === PlanStatus.Published ? 'success' : 'normal'}
            text={t(
              plan.status === PlanStatus.Published
                ? 'plan.status.published'
                : 'plan.status.unpublished'
            )}
          />
        </div>
      ) : (
        <div className="flex items-center gap-6 flex-1 justify-center">
          <LinkButton icon={<HomeIcon />} variant="light" to={pathTo('/')}>
            {t('nav.home')}
          </LinkButton>

          <LinkButton
            icon={<ClockIcon size="normal" />}
            variant="light"
            to={pathTo('/activities')}
          >
            {t('nav.activities')}
          </LinkButton>

          <LinkButton
            icon={<InsightsIcon />}
            variant="light"
            to={pathTo('/insights')}
          >
            {t('nav.insights')}
          </LinkButton>
        </div>
      )}

      <div className=" flex-1 flex justify-end items-center gap-10">
        {plan && (
          <div className="flex items-center gap-8">
            <Button
              variant={isPreviewMode ? 'danger' : 'primary'}
              size={isPreviewMode ? 'xs' : 'sm'}
              onClick={() =>
                togglePreviewMode && togglePreviewMode(!isPreviewMode)
              }
            >
              {isPreviewMode
                ? t('plan.preview.backButton')
                : t('plan.menu.previewPlan.label')}
            </Button>

            {plan.invitees.length > 0 && (
              <StackAvatar size={24} avatars={avatars} />
            )}

            <Popover
              content={
                <InvitePopup
                  planId={plan.id}
                  invitees={plan.invitees}
                  status={plan.status}
                  isCustomer={false}
                />
              }
            >
              <Button variant="primary">
                {t('plan.sharePopup.trigger.label')}
              </Button>
            </Popover>
          </div>
        )}

        <Popover
          open={isOpenAvatarMenu}
          onClose={() => setIsOpenAvatarMenu(false)}
          content={<AvatarPopoverMenu user={user} />}
        >
          <button
            type="button"
            onClick={() => setIsOpenAvatarMenu(!isOpenAvatarMenu)}
          >
            <Avatar
              picture={user.avatarUrl}
              fullName={user.displayName}
              email={user.email}
              size={42}
              className="cursor-pointer"
            />
          </button>
        </Popover>
      </div>
    </div>
  );
};

import { debounce } from 'lodash';
import { useCallback } from 'react';
import {
  DeepPartialSkipArrayKey,
  FieldValues,
  SubmitHandler,
  useWatch,
} from 'react-hook-form';
import { useDeepCompareEffect } from 'react-use';

type AutoSaveProps<FormValues extends FieldValues> = {
  onSubmit: SubmitHandler<FormValues>;
  defaultValues: DeepPartialSkipArrayKey<FormValues>;
  methods: any;
  triggerKeys?: string[];
};

export function AutoSave<FormValues extends FieldValues>({
  onSubmit,
  defaultValues,
  methods,
  triggerKeys,
}: AutoSaveProps<FormValues>) {
  /* Vars */

  const watchedData = useWatch({
    control: methods.control,
    defaultValue: defaultValues,
  });

  /* Callbacks */

  const triggerSaveDebounced = useCallback(
    debounce(methods.handleSubmit(onSubmit), 3000),
    [methods.handleSubmit, onSubmit]
  );

  const triggerSaveImmediate = useCallback(() => {
    methods.handleSubmit(onSubmit)();
  }, [methods.handleSubmit, onSubmit]);

  useDeepCompareEffect(() => {
    if (methods.formState.isDirty) {
      if (triggerKeys) {
        const isFoundTrigger = Object.keys(watchedData).some((key) => {
          return triggerKeys.includes(key);
        });

        if (isFoundTrigger) {
          triggerSaveImmediate();
        } else {
          triggerSaveDebounced();
        }
      } else {
        triggerSaveDebounced();
      }
    }
  }, [watchedData, methods.formState.isDirty]);

  return null;
}

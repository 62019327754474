import { graphql } from '@graphql/generated';

export const requestLoginLinkMutation = graphql(`
  mutation requestLoginLink($email: String!) {
    requestLoginLink(email: $email)
  }
`);

export const loginByTokenMutation = graphql(`
  mutation loginByToken($token: String!) {
    loginByToken(token: $token) {
      token
      maxAge
    }
  }
`);

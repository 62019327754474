import { graphql } from '@src/graphql/generated';

export const getTaskCustomer = graphql(`
  query task($id: ID!) {
    task(id: $id) {
      id
      plan {
        customer {
          ...Customer
        }
      }
    }
  }
`);

import styled from 'styled-components';
import { colors } from '@style/colors';
import { NavLink } from 'react-router-dom';

export const PillTabItem = styled(NavLink)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 6px 8px;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 180ms ease-out, border-color 180ms ease-out;

  &.active {
    background-color: ${colors.dark50};
  }

  &:not(.active) {
    color: ${colors.dark700};
  }
`;

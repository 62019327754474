import { useQuery } from '@apollo/client';
import { useUserContext } from '@src/contexts/UserContext/useUserContext';
import { getWorkspaceUsers } from '@src/pages/Dashboard/components/Weekly/Weekly.query';

export const useUserOptions = () => {
  const { locale } = useUserContext();
  const { data: usersData } = useQuery(getWorkspaceUsers);
  const users = usersData?.workspace.users || [];
  const options = users
    .map((user) => ({
      value: user.id,
      label: user.displayName,
    }))
    .sort((a, b) =>
      a.label.localeCompare(b.label, locale, { ignorePunctuation: true })
    );
  return options;
};

import { TaskCompanyAssignee } from '@src/graphql/generated/graphql';
import { colors } from '@src/style/colors';
import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { CompanyLogo } from '../CompanyLogo/CompanyLogo';
import { Flex } from '../Flex/Flex';
import { PillTabs } from '../PillTabs/PillTabs';
import { Text } from '../Text/Text';
import { useEffect } from 'react';
import { FormValues } from '../TaskPanel/TaskDetails';

type CompanyAssigneeFieldProps = {
  csmCompany: {
    name: string;
    logo?: string;
  };
  customerCompany: {
    name: string;
    logo?: string;
  };
};

export const CompanyAssigneeField = ({
  csmCompany,
  customerCompany,
}: CompanyAssigneeFieldProps) => {
  const { watch, setValue, register } = useFormContext<FormValues>();
  const companyAssignee = watch('companyAssignee');

  useEffect(() => {
    register('companyAssignee', { required: true });
  }, []);

  return (
    <Flex flexDirection={'column'} alignItems={'flex-start'} gap={'8px'}>
      <Label size={'xs'} color={'dark600'}>
        {t('plan.taskDetails.companyAssignee.label')}
      </Label>
      <Flex>
        <TaskPillTabs>
          <Tab
            isSelected={companyAssignee === TaskCompanyAssignee.Csm}
            onClick={() => {
              setValue('companyAssignee', TaskCompanyAssignee.Csm, {
                // Options necessary to trigger the validation
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              });
            }}
          >
            <CompanyLogo
              name={csmCompany.name}
              logo={csmCompany.logo}
              size={40}
            />
          </Tab>

          <Tab
            isSelected={companyAssignee === TaskCompanyAssignee.Customer}
            onClick={() => {
              setValue('companyAssignee', TaskCompanyAssignee.Customer, {
                // Options necessary to trigger the validation
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              });
            }}
          >
            <CompanyLogo
              name={customerCompany.name}
              logo={customerCompany.logo}
              size={40}
            />
          </Tab>
        </TaskPillTabs>
      </Flex>
    </Flex>
  );
};

const Tab = styled(Flex)<{ isSelected?: boolean }>`
  align-items: center;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 12px;
  transition: background-color 180ms ease-out, border-color 180ms ease-out;
  gap: 6px;
  ${(props) => props.isSelected && `background-color: ${colors.dark50};`};
`;

const TaskPillTabs = styled(PillTabs)`
  width: max-content;
  background-color: white;
  gap: 8px;
`;

const Label = Text.withComponent('label');

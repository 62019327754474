import React, { type ReactNode, type FC } from 'react';
import clsx from 'clsx';

interface TagProps {
  size?: 'sm' | 'md';
  color: 'green' | 'purple' | 'pink' | 'yellow' | 'orange' | 'brown';
  children: ReactNode;
}

export const Tag: FC<TagProps> = ({ size = 'md', color, children }) => {
  return (
    <div
      className={clsx(
        'flex items-center gap-2 border font-general font-semibold text-nowrap',
        {
          ['rounded-full py-3 px-5 text-[14px]']: size === 'md',
          ['rounded-lg py-2 px-4 text-[12px]']: size === 'sm',
          ['bg-green-100 border-green-200']: color === 'green',
          ['bg-orange-100 border-orange-200']: color === 'orange',
          ['bg-pink-100 border-pink-200']: color === 'pink',
          ['bg-purple-100 border-purple-200']: color === 'purple',
          ['bg-yellow-100 border-yellow-200']: color === 'yellow',
          ['bg-dark-50 border-dark-100']: color === 'brown',
        }
      )}
    >
      {children}
    </div>
  );
};

import { graphql } from '../generated';

export const createFileMutation = graphql(`
  mutation createFile($input: FileInput!) {
    createFile(input: $input) {
      id
      uploadUrl
    }
  }
`);

import { useMutation } from '@apollo/client';
import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Logo from '@assets/images/jamat-logo.png';
import { Button, LinkButton } from '@components/Button/Button';
import { Flex } from '@components/Flex/Flex';
import { Form } from '@components/Form/Form';
import { InputField } from '@components/Form/InputField';
import { PageLayout } from '@components/PageLayout';
import { Text } from '@components/Text/Text';
import { Navigate, useSearchParams } from 'react-router-dom';
import { resetPasswordMutation } from './ResetPassword.query';
import { toastError, toastSuccess } from '@components/Toast/toast';
import { setSessionToken } from '@src/helpers/auth';
import styled from 'styled-components';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { passwordRule } from '@src/validation/rules';
import { type TFunction } from 'i18next';

type FormValues = { password: string };

const getValidationSchema = (t: TFunction) =>
  z.object({
    password: passwordRule(t),
  });

export const ResetPasswordPage: FC = () => {
  const { t } = useTranslation();
  const [resetPassword] = useMutation(resetPasswordMutation);
  const schema = useMemo(() => getValidationSchema(t), [t]);
  const methods = useForm<FormValues>({
    mode: 'onChange',
    resolver: zodResolver(schema),
  });
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  const handleSubmit = async ({ password }: FormValues) => {
    await resetPassword({
      variables: { password, token },
      onError: (error) => {
        if (error.graphQLErrors.length) {
          const errorCode = error.graphQLErrors[0].extensions?.code;
          if (
            errorCode === 'TOKEN_EXPIRED' ||
            errorCode === 'INVALID_RESET_PASSWORD_TOKEN'
          ) {
            return toastError(t('resetPassword.errorToast.invalidToken'));
          }
        }
        return toastError(t('resetPassword.errorToast.unknown'));
      },
      onCompleted: (data) => {
        //TODO: translate
        toastSuccess(t('resetPassword.successToast'));
        setTimeout(() => {
          setSessionToken(data.resetPassword.token, data.resetPassword.maxAge);
          window.location.href = '/';
        }, 1000);
      },
    });
  };

  return (
    <PageLayout vCenter>
      <Flex flexDirection="column" alignItems="center">
        <img src={Logo} alt="Jamat logo" height={56} />
        <Text size="2xl" weight="bold" mt={32}>
          {t('resetPassword.title')}
        </Text>
        <Text mt={8}>{t('resetPassword.subtitle')}</Text>
        <Form onSubmit={handleSubmit} methods={methods}>
          <Flex flexDirection="column" width={320} mt={32}>
            <InputField
              name="password"
              type="password"
              placeholder={t('form.password.placeholder')}
              required
              size="big"
            />
            <ButtonContainer justifyContent="space-between" gap="24px">
              <LinkButton to="/login" variant="secondary" size="big" mt={8}>
                {t('resetPassword.backButton')}
              </LinkButton>
              <Button type="submit" variant="primary" size="big" mt={8}>
                {t('resetPassword.button')}
              </Button>
            </ButtonContainer>
          </Flex>
        </Form>
      </Flex>
    </PageLayout>
  );
};

const ButtonContainer = styled(Flex)`
  > * {
    flex: 1 1 0;
  }
`;

import { useQuery } from '@apollo/client';
import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Error as ErrorComp } from '@components/Error/Error';
import { Loader } from '@components/Loader/Loader';
import { userContextQuery } from './UserWrapper.query';
import i18n from '@src/i18n/i18n';
import { UserContext } from './context';

export const UserWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { data, loading, error } = useQuery(userContextQuery, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data && data.me.locale && data.me.locale !== i18n.language) {
      i18n.changeLanguage(data.me.locale);
    }
  }, [data?.me.locale]);

  if (error) return <ErrorComp />;
  if (!data || loading) return <Loader centered margin="32px" size={32} />;
  return (
    <UserContext.Provider value={data.me}>{children}</UserContext.Provider>
  );
};

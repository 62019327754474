import { Icon, IconComponent } from './index';

export const TripleDotsIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill="#5B5B5B"
          stroke="#5B5B5B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10 10.833a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666ZM15.833 10.833a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666ZM4.167 10.833a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666Z"
        />
      </svg>
    </Icon>
  );
};

import { formatDate } from '@src/helpers/date';
import { getDueDateColor } from '@src/helpers/tasks';
import { t } from 'i18next';
import styled from 'styled-components';
import { Div } from '../Flex/Div/Div';
import { Flex } from '../Flex/Flex';
import { DateOrNumberToggleField } from '../Form/DateOrNumberToggleField';
import { CalendarIcon } from '../Icon/CalendarIcon';
import { Pill } from '../Pill/Pill';
import { Text } from '../Text/Text';

type TaskDueDateFieldProps = {
  dueDate?: string | null;
  isCustomer: boolean;
};

export const TaskDueDateField: React.FC<TaskDueDateFieldProps> = ({
  isCustomer,
  dueDate,
}) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="center"
      gap="8px"
    >
      <Label size={'xs'} color={'dark600'}>
        {t('plan.taskDetails.dueDate.label')}
      </Label>
      {isCustomer ? (
        <Div marginLeft={'-6px'}>
          <Pill
            Icon={CalendarIcon}
            color={getDueDateColor(dueDate)}
            weight={'medium'}
            disabled={isCustomer}
          >
            {dueDate ? formatDate(dueDate) : '-'}
          </Pill>
        </Div>
      ) : (
        <DateOrNumberToggleField />
      )}
    </Flex>
  );
};

const Label = styled(Text.withComponent('label'))`
  text-wrap: nowrap;
`;

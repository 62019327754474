import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GhostSelect, GhostSelectProps } from '../Select/GhostSelect';
import FormField from './FormField';

type GhostSelectFieldProps = Omit<GhostSelectProps, 'onChange'> & {
  name: string;
  label?: string;
  required?: boolean;
};

type Option = {
  label: string;
  value: string;
};

export const GhostSelectField = ({
  name,
  label,
  required,
  ...props
}: GhostSelectFieldProps) => {
  const { t } = useTranslation();
  const { formState, control } = useFormContext();

  return (
    <FormField name={name} label={label} alignInputLeft={!!label}>
      <Controller
        control={control}
        name={name}
        rules={{
          required: required ? t('shared.input.requiredErrorMessage') : false,
        }}
        defaultValue={formState.defaultValues?.[name]}
        render={({ field }) => (
          <GhostSelect
            {...field}
            {...props}
            required={required}
            value={props.options?.find(
              (option) => (option as Option).value === field.value
            )}
            onChange={(option) => {
              field.onChange((option as Option).value);
            }}
          />
        )}
      />
    </FormField>
  );
};

import { Icon, IconComponent } from './index';

export const FillFormIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 120 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M46.84 20.53H17.89V23.16H46.84V20.53Z" fill="black" />
        <path d="M46.84 25.79H17.89V28.42H46.84V25.79Z" fill="black" />
        <path d="M75.79 38.95H31.05V41.58H75.79V38.95Z" fill="black" />
        <path
          d="M17.89 44.2099H28.42V33.6799H17.89V44.2099ZM20.52 36.3199H25.78V41.5799H20.53L20.52 36.3199Z"
          fill="black"
        />
        <path
          d="M17.89 60H28.42V49.47H17.89V60ZM20.52 52.11H25.78V57.37H20.53L20.52 52.11Z"
          fill="black"
        />
        <path
          d="M17.89 75.79H28.42V65.26H17.89V75.79ZM20.52 67.9H25.78V73.16H20.53L20.52 67.9Z"
          fill="black"
        />
        <path d="M75.78 83.6799H17.89V86.3099H75.78V83.6799Z" fill="black" />
        <path d="M54.73 91.5801H17.89V94.2101H54.73V91.5801Z" fill="black" />
        <path d="M56.13 70.53H31.05V73.16H56.13V70.53Z" fill="black" />
        <path d="M75.79 70.53H74.38L71.75 73.16H75.79V70.53Z" fill="black" />
        <path d="M71.56 54.74H31.05V57.37H68.93L71.56 54.74Z" fill="black" />
        <path
          d="M81.05 107.37H12.63V12.63H81.05V45.25L83.68 42.62V10H10V110H83.68V61.22L81.05 63.86V107.37Z"
          fill="black"
        />
        <path
          d="M108.46 27.1399C107.472 26.1555 106.135 25.6028 104.74 25.6028C103.345 25.6028 102.008 26.1555 101.02 27.1399L60.08 68.0799L57.45 70.7099V78.1499H64.89L67.52 75.5199L82.41 60.6399L84.27 58.7799L108.46 34.5899C109.447 33.6017 110.002 32.2619 110.002 30.8649C110.002 29.4679 109.447 28.1281 108.46 27.1399ZM63.8 75.5199H60.08V71.7999L76.83 55.0599L80.55 58.7799L63.8 75.5199ZM106.6 32.7199L82.41 56.9199L78.69 53.1999L102.88 28.9999C103.124 28.7556 103.414 28.5619 103.733 28.4297C104.053 28.2975 104.395 28.2295 104.74 28.2295C105.085 28.2295 105.427 28.2975 105.747 28.4297C106.066 28.5619 106.356 28.7556 106.6 28.9999C106.844 29.2441 107.038 29.5341 107.17 29.8533C107.302 30.1724 107.37 30.5145 107.37 30.8599C107.37 31.2053 107.302 31.5474 107.17 31.8665C107.038 32.1857 106.844 32.4756 106.6 32.7199Z"
          fill="black"
        />
      </svg>
    </Icon>
  );
};

import type { ReactNode } from 'react';
import clsx from 'clsx';

interface TabsProps<T> {
  options: Array<{ label: string; icon?: ReactNode; value: string }>;
  onChange: (value: T) => void;
  value: string;
}

export const Tabs = <T,>({ options, onChange, value }: TabsProps<T>) => {
  return (
    <div className="flex items-center p-1 rounded-lg bg-white">
      {options.map((option) => (
        <button
          type="button"
          onClick={() => onChange(option.value as T)}
          className={clsx(
            'flex items-center gap-2 bg-transparent border-none py-3 px-4 rounded-md text-purple-500 duration-150',
            {
              ['!bg-purple-900 !text-white']: value === option.value,
            }
          )}
        >
          {option.icon}

          <span>{option.label}</span>
        </button>
      ))}
    </div>
  );
};

import { graphql } from '@graphql/generated';

export const createSubtaskMutation = graphql(`
  mutation createSubtask($taskId: ID!) {
    createSubtask(taskId: $taskId) {
      id
      task {
        id
        subtasks {
          ...Subtask
        }
        plan {
          id
          lastActivityAt
        }
      }
    }
  }
`);

export const updateSubtaskMutation = graphql(`
  mutation updateSubtask($id: ID!, $input: SubtaskInput!) {
    updateSubtask(id: $id, input: $input) {
      ...Subtask
      task {
        id
        subtasks {
          ...Subtask
        }
        plan {
          id
          lastActivityAt
        }
      }
    }
  }
`);

export const assignUserToSubtaskMutation = graphql(`
  mutation assignUserToSubtask($subtaskId: ID!, $userId: ID!) {
    assignUserToSubtask(subtaskId: $subtaskId, userId: $userId) {
      id
      assignees {
        id
      }
      task {
        id
        subtasks {
          ...Subtask
        }
        plan {
          id
          lastActivityAt
        }
      }
    }
  }
`);

export const removeUserFromSubtaskMutation = graphql(`
  mutation removeUserFromSubtask($subtaskId: ID!, $userId: ID!) {
    removeUserFromSubtask(subtaskId: $subtaskId, userId: $userId) {
      id
      assignees {
        id
      }
      task {
        id
        subtasks {
          ...Subtask
        }
        plan {
          id
          lastActivityAt
        }
      }
    }
  }
`);

export const deleteSubtaskMutation = graphql(`
  mutation deleteSubtask($subtaskId: ID!) {
    deleteSubtask(subtaskId: $subtaskId) {
      id
      subtasks {
        ...Subtask
      }
      plan {
        id
        lastActivityAt
      }
    }
  }
`);

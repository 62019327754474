import { Icon, IconComponent } from './index';

export const ChevronDownIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
      >
        <path
          stroke="#888785"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="m5 8 5 5 5-5"
        />
      </svg>
    </Icon>
  );
};

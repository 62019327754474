import { graphql } from '@graphql/generated';

export const getCustomerPlansQuery = graphql(`
  query getCustomerPlans {
    me {
      id
      plans {
        id
        title
      }
    }
  }
`);

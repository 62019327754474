import type { FC } from 'react';
import styled from 'styled-components';
import { colors } from '@style/colors';
import CheckSvg from './check.svg';

type TaskCheckProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
};

export const TaskCheck: FC<TaskCheckProps> = ({ checked, onChange }) => {
  return (
    <Check
      type={'button'}
      checked={checked}
      onClick={(e) => {
        e.stopPropagation();
        onChange(!checked);
      }}
    />
  );
};

// TODO turn this into a checkbox instead of a button
const Check = styled.button<{ checked: boolean }>`
  border: 1px solid
    ${(props) => (props.checked ? colors.dark900_20 : colors.dark200)};
  background-color: ${(props) => (props.checked ? colors.green : colors.white)};
  height: 24px;
  width: 24px;
  border-radius: 12px;
  cursor: pointer;
  flex-shrink: 0;

  ${(props) =>
    props.checked &&
    `
      background-image: url("${CheckSvg}");
      background-repeat: no-repeat;
      background-position: center;
  `};

  transition: box-shadow 180ms ease-out, background-color 180ms ease-out,
    border-color 180ms ease-out;

  &:hover {
    box-shadow: ${(props) =>
      props.checked
        ? `inset 0 0 0 4px ${colors.dark900_20}`
        : `inset 0 0 0 4px ${colors.dark100}`};
  }
`;

import { Flex } from '@components/Flex/Flex';
import { StickerCheckMarkIcon } from '@components/Icon/StickerCheckMarkIcon';
import { PageLayout } from '@components/PageLayout';
import { PillTabItem } from '@components/PillTabs/PillTabItem';
import { PillTabs } from '@components/PillTabs/PillTabs';
import { Text } from '@components/Text/Text';
import { useWorkspaceRouting } from '@graphql/hooks/useWorkspaceRouting';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { ResourcesDashboard } from './components/ResourcesDashboard';
import { TasksDashboard } from './components/TasksDashboard';

export const InsightsPage: FC = () => {
  const { pathTo } = useWorkspaceRouting();
  const { t } = useTranslation();

  return (
    <PageLayout withTopBar backLink={pathTo('/')} width={1200}>
      <Flex justifyContent={'center'} paddingBottom={'24px'}>
        <PillTabs>
          <PillTabItem end to={pathTo(`/insights`)}>
            <StickerCheckMarkIcon size={'small'} />
            <Text size={'sm'}>{t('insights.tabs.tasks')}</Text>
          </PillTabItem>
          <PillTabItem end to={pathTo(`/insights/resources`)}>
            <StickerCheckMarkIcon size={'small'} />
            <Text size={'sm'}>{t('insights.tabs.resources')}</Text>
          </PillTabItem>
        </PillTabs>
      </Flex>

      <Routes>
        <Route index element={<TasksDashboard />} />
        <Route path="resources" element={<ResourcesDashboard />} />
      </Routes>
    </PageLayout>
  );
};

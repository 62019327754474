import { useMutation } from '@apollo/client';
import { setSessionToken } from '@src/helpers/auth';
import { loginMutation } from '../Login.query';

export function useLogin() {
  const [login] = useMutation(loginMutation);

  return async (email: string, password: string) => {
    const { data } = await login({ variables: { email, password } });
    if (!data?.login?.token) {
      throw new Error('Missing token');
    }
    setSessionToken(data?.login.token, data.login.maxAge);
  };
}

import clsx from 'clsx';
import { ChangeEvent } from 'react';

interface InputProps {
  value?: string | null;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  size: 'base' | 'lg' | 'xl';
  className?: string;
  variant?: 'normal' | 'ghost';
}

export const Input = ({
  value,
  onChange,
  placeholder,
  size = 'base',
  // variant = 'normal',
  className,
}: InputProps) => {
  return (
    <input
      value={value || ''}
      onChange={onChange}
      onClick={(e) => e.stopPropagation()}
      placeholder={placeholder}
      size={value?.length}
      className={clsx(
        'w-auto p-2 flex hover:bg-dark-50 duration-200 outline-none hover:resize-y rounded-lg placeholder:text-dark-600 font-general',
        {
          'text-2xl font-bold text-dark-900': size === 'xl',
          'text-lg text-dark-800 font-medium': size === 'lg',
          'text-base text-dark-800 font-[600]': size === 'base',
        },
        className
      )}
    />
  );
};

import { Icon, IconComponent } from './index';

export const CalendarIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        className={props.className}
      >
        <g
          stroke="#F15B5B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          clipPath="url(#a)"
        >
          <path d="M8.667 14H5.333A3.333 3.333 0 0 1 2 10.667V5.333A3.333 3.333 0 0 1 5.333 2h5.334A3.333 3.333 0 0 1 14 5.333v3.334A5.333 5.333 0 0 1 8.667 14Z" />
          <path d="M14 7.333v1.333A1.334 1.334 0 0 1 12.666 10h-1.333a2 2 0 0 0-2 2v.666A1.333 1.333 0 0 1 8 14H5.333M10.666 5.333H5.333" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
};

import { useState, type ReactNode } from 'react';

import { formatDate } from '@src/helpers/date';
import { Typography } from '@src/newComponents/atoms';
import { Popover } from '../popover';
import { Calendar } from '../calendar';

interface DatePickerProps {
  value: string | null;
  onChange: (value: string | null) => void;
  label: string;
  placeholder?: string;
  maxDate?: string;
  minDate?: string;
  icon?: ReactNode;
}

export const DatePicker = ({
  value,
  onChange,
  label,
  placeholder,
  maxDate,
  minDate,
  icon,
}: DatePickerProps) => {
  /* Vars */

  const [isOpenCalendar, setIsOpenCalendar] = useState(false);

  /* Functions */

  const handleChange = (value: string | null) => {
    onChange(value);
    setIsOpenCalendar(false);
  };

  return (
    <Popover
      open={isOpenCalendar}
      onClose={() => setIsOpenCalendar(false)}
      content={
        <Calendar
          value={value}
          setValue={handleChange}
          minDate={minDate}
          maxDate={maxDate}
        />
      }
    >
      <div
        className="flex items-center gap-3 duration-300 hover:bg-dark-100 p-2 rounded-lg cursor-pointer"
        onClick={() => setIsOpenCalendar(true)}
      >
        {icon}

        <div className="flex items-center gap-1">
          <Typography className="text-dark-700">{`${label}:`}</Typography>

          <Typography fontWeight="medium" className="text-dark-700">
            {value ? formatDate(value) : placeholder || '-'}
          </Typography>
        </div>
      </div>
    </Popover>
  );
};

import type { FC } from 'react';
import styled from 'styled-components';
import { colors } from '@style/colors';

type FieldErrorProps = {
  error: string | undefined;
};

export const FieldError: FC<FieldErrorProps> = ({ error }) => {
  if (!error) return null;
  return <Container>{error}</Container>;
};

const Container = styled.div`
  margin-top: 6px;
  font-size: 12px;
  color: ${colors.error};
`;

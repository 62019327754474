import { type ReactNode, type FC } from 'react';
import clsx from 'clsx';

interface DividerProps {
  className?: string;
  children?: ReactNode;
}

export const Divider: FC<DividerProps> = ({ className, children }) => {
  if (children) {
    return (
      <div className={clsx('flex items-center gap-8', className)}>
        <Divider />

        <div>{children}</div>

        <Divider />
      </div>
    );
  }

  return <div className={clsx('h-[1px] bg-beige-300 w-full', className)} />;
};

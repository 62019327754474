import { Icon, IconComponent } from './index';

export const LogoutIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M7.5 17.5H4.167A1.667 1.667 0 0 1 2.5 15.833V4.167A1.667 1.667 0 0 1 4.167 2.5H7.5m5.833 11.667L17.5 10m0 0-4.167-4.167M17.5 10h-10"
          stroke="#344054"
          stroke-width="1.5"
          stroke-linecap="square"
        />
      </svg>
    </Icon>
  );
};

import { format } from 'date-fns';
import formatDistance from 'date-fns/formatDistance';
import type { PlainDate } from '../types';
import i18n from '@src/i18n/i18n';
import { enGB, fr } from 'date-fns/locale';

const localeConfigs: Record<
  string,
  { locale: Locale; format: string; formatShort: string }
> = {
  en: { locale: enGB, format: 'MMMM do', formatShort: 'MMM dd' },
  fr: { locale: fr, format: 'dd MMMM', formatShort: 'dd MMM' },
};

export function formatDate(date?: string | null): string {
  if (!date) {
    return '-';
  }

  const localeConfig = localeConfigs[i18n.language];
  return format(new Date(date), localeConfig.format, {
    locale: localeConfig.locale,
  });
}

export function formatShortDate(date?: string | null): string {
  if (!date) {
    return '-';
  }

  const localeConfig = localeConfigs[i18n.language];
  return format(new Date(date), localeConfig.formatShort, {
    locale: localeConfig.locale,
  });
}

export function formatRelativeDate(
  date: string | null,
  locale: 'fr' | 'en'
): string {
  if (!date) {
    return '-';
  }

  return formatDistance(new Date(date), new Date(), {
    addSuffix: true,
    locale: localeConfigs[locale].locale,
  });
}

export function dateToPlainDate(date: Date | null) {
  if (!date) return date;
  return format(date, 'yyyy-LL-dd') as PlainDate;
}

export function formatTime(date: string) {
  return format(new Date(date), 'HH:mm');
}

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Controller, useFormContext } from 'react-hook-form';
import { useMemo } from 'react';

import {
  StackAvatar,
  Input,
  Textarea,
  Typography,
  useToast,
} from '@src/newComponents';
import { FilePurpose, Plan } from '@src/graphql/generated/graphql';
import { DatePlan, OwnerPlan, SectionCarrousel } from './components';
import { createFileMutation } from '@src/graphql/hooks/useFileUpload.query';
import { uploadFile } from '@src/helpers/file';
import { updateCustomerMutation } from '../../Plan.query';
import { updateWorkspaceMutation } from '@src/contexts/WorkspaceContext/Workspace.query';

export const PlanDetails = ({
  plan,
  isPreviewMode,
}: {
  plan?: Plan;
  isPreviewMode: boolean;
}) => {
  /* Vars */

  const { t } = useTranslation();
  const { control } = useFormContext();
  const { createAsyncToast } = useToast();

  /* Queries */

  const [createFile] = useMutation(createFileMutation);
  const [updateCustomer] = useMutation(updateCustomerMutation);
  const [updateWorkspace] = useMutation(updateWorkspaceMutation);

  /* Memos */

  const avatars = useMemo(() => {
    if (!plan) return [];

    return [plan.customer, plan.workspace].map((item) => ({
      picture: item?.logo || '',
      fullName: item?.name,
      email: item?.id,
      id: item?.id,
    }));
  }, [plan]);

  /* Functions */

  const handleLogoChange = async (file: File, index: number) => {
    if (plan) {
      await createAsyncToast(
        uploadAvatar(file, index === 0 ? 'customer' : 'workspace')
      );
    }
  };

  const uploadAvatar = async (
    file: File,
    logoType: 'customer' | 'workspace'
  ) => {
    const { data } = await createFile({
      variables: {
        input: {
          type: file.type,
          name: file.name,
          purpose: FilePurpose.CompanyLogo,
        },
      },
    });

    if (!data?.createFile) return;

    await uploadFile(data.createFile.uploadUrl, file);

    if (logoType === 'customer') {
      await updateCustomer({
        variables: {
          id: plan!.customer.id,
          input: {
            name: plan!.customer.name,
            website: plan!.customer.website,
            logoFileId: data.createFile.id,
          },
        },
      });
    }

    if (logoType === 'workspace') {
      await updateWorkspace({
        variables: {
          id: plan!.workspace.id,
          input: { logoFileId: data.createFile.id },
        },
      });
    }
  };

  return (
    <div className="flex justify-center">
      <div className="bg-white rounded-3xl p-[24px] w-full max-w-[848px] -mt-[80px] relative">
        <StackAvatar
          size={88}
          avatars={avatars}
          className="absolute top-[-44px] left-1/2 transform -translate-x-1/2"
          onChange={handleLogoChange}
        />

        <div className="flex flex-col gap-[24px] mt-[44px]">
          <div className="flex flex-col gap-8 justify-center">
            {isPreviewMode ? (
              <Typography
                size="2xl"
                fontWeight="semibold"
                textAlign="center"
                className="text-dark-900"
              >
                {plan?.title}
              </Typography>
            ) : (
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <Input size="xl" className="text-center" {...field} />
                )}
              />
            )}

            {isPreviewMode ? (
              <Typography
                size="sm"
                fontWeight="medium"
                className="text-grey-800"
                textAlign="center"
              >
                {(plan?.description || '').split('\n').map((text) => (
                  <>
                    {text}
                    <br />
                  </>
                ))}
              </Typography>
            ) : (
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <Textarea
                    placeholder={t('plan.form.description.placeholder')}
                    className="text-center"
                    {...field}
                  />
                )}
              />
            )}
          </div>

          <OwnerPlan owner={plan?.owner} />

          <DatePlan plan={plan} isPreviewMode={isPreviewMode} />

          <SectionCarrousel
            sections={plan?.sections}
            isPreviewMode={isPreviewMode}
          />
        </div>
      </div>
    </div>
  );
};

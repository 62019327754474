import { format } from 'date-fns';
import { type TFunction } from 'i18next';
import * as z from 'zod';

export const dateRule = (t: TFunction) =>
  z.coerce
    .date({
      errorMap: () => ({
        message: t('validation.date.invalid'),
      }),
    })
    .transform((date) => (date ? format(date, 'yyyy-MM-dd') : date));

import { useMutation } from '@apollo/client';
import { forwardRef, useCallback, useState } from 'react';
import styled from 'styled-components';
import {
  GetTaskQuery,
  SubtaskFragment,
  SubtaskStatus,
} from '@graphql/generated/graphql';
import { getDueDateColor } from '@src/helpers/tasks';
import i18n from '../../i18n/i18n';
import { colors } from '@style/colors';
import { Text } from '../Text/Text';
import { Flex } from '../Flex/Flex';
import { TaskCheck } from '../Select/TaskCheck/TaskCheck';
import { ImageGroup } from '../Image/Group';
import { Avatar } from '../Avatar/Avatar';
import { DragIcon } from '../Icon/DragIcon';
import {
  assignUserToSubtaskMutation,
  deleteSubtaskMutation,
  removeUserFromSubtaskMutation,
  updateSubtaskMutation,
} from './Subtasks.query';
import { CalendarIcon } from '../Icon/CalendarIcon';
import { formatDate } from '@src/helpers/date';
import { Div } from '../Flex/Div/Div';
import { Popup } from '../Popup/Popup';
import { Pill } from '../Pill/Pill';
import { t } from 'i18next';
import { isValidEmail } from '@src/helpers/utils';
import { Button } from '../Button/Button';
import { TransparentButton } from '../Button/TransparentButton';
import { Input } from '../Input/Input';
import { PlusCircle } from '../PlusCircle';
import { Assignee } from './TaskAssignees';
import { addInviteeMutation } from '@src/pages/Plan/Plan.query';
import { CloseIcon } from '../Icon/CloseIcon';
import { DatePickerField } from '../Form/DatePickerField';
import { useForm } from 'react-hook-form';
import { GhostTextareaField } from '../Form/GhostTextareaField';
import { Form } from '../Form/Form';
import { AutoSave } from '../AutoSave/AutoSave';

const DEFAULT_TITLE = i18n.t('planSection.task.title.default');

type FormValues = {
  title: string;
  dueDate: string;
};

type SubtaskRowProps = {
  subtask: SubtaskFragment;
  withDragHandle?: boolean;
  planId: string;
  invitees: GetTaskQuery['task']['plan']['invitees'];
  workspaceUsers: GetTaskQuery['task']['plan']['workspace']['users'];
  isCustomer?: boolean;
};

const useSubtaskUpdate = (id: string) => {
  const [updateSubtask] = useMutation(updateSubtaskMutation);
  const updateStatus = useCallback(
    async (checked: boolean) => {
      await updateSubtask({
        variables: {
          id,
          input: {
            status: checked ? SubtaskStatus.Done : SubtaskStatus.Todo,
          },
        },
      });
    },
    [id, updateSubtask]
  );

  const updateInfos = useCallback(
    async (title: string, dueDate: string | null) => {
      await updateSubtask({
        variables: {
          id,
          input: {
            title: title ?? null,
            dueDate,
          },
        },
      });
    },
    [id, updateSubtask]
  );

  return {
    updateInfos,
    updateStatus,
  };
};

export const SubtaskRow = forwardRef<HTMLDivElement, SubtaskRowProps>(
  (
    {
      subtask: { id: subtaskId, title, status, dueDate, assignees },
      invitees,
      workspaceUsers,
      planId,
      isCustomer,
      withDragHandle = false,
    },
    ref
  ) => {
    const updaters = useSubtaskUpdate(subtaskId);
    // const [date, setDate] = useState<string | null>(dueDate ?? null);
    // const [title, setTitle] = useState<string | null>(propsTitle ?? null);
    const [email, setEmail] = useState<string>('');
    const [assignUserToSubtask] = useMutation(assignUserToSubtaskMutation);
    const [removeUserFromSubtask] = useMutation(removeUserFromSubtaskMutation);
    const [deleteSubtask] = useMutation(deleteSubtaskMutation);
    const [addInvitee] = useMutation(addInviteeMutation);
    const checked = status === SubtaskStatus.Done;
    const handleSubmit = async ({ title, dueDate }: FormValues) => {
      await updaters.updateInfos(title, dueDate);
    };
    const defaultValues: FormValues = {
      title: title || '',
      dueDate: dueDate || '',
    };
    const methods = useForm<FormValues>({
      mode: 'onChange',
      defaultValues,
    });

    const onInviteeSubmit = async () => {
      if (!isValidEmail(email)) {
        return;
      }

      // Send invite
      const { data } = await addInvitee({
        variables: {
          planId,
          email,
        },
      });
      const inviteeId = data?.addPlanInvitee.invitees.find(
        (invitee) => invitee.email === email
      )?.id;
      if (!inviteeId) {
        return;
      }
      await assignUserToSubtask({
        variables: {
          subtaskId,
          userId: inviteeId,
        },
      });
      setEmail('');
    };

    const handleDelete = useCallback(async () => {
      await deleteSubtask({
        variables: {
          subtaskId,
        },
      });
    }, [deleteSubtask, subtaskId]);

    const toggleUserFromTask = useCallback(
      async (userId: string) => {
        const isAssignee = assignees.some((assignee) => assignee.id === userId);
        const options = {
          variables: {
            subtaskId,
            userId,
          },
        };

        return isAssignee
          ? await removeUserFromSubtask(options)
          : await assignUserToSubtask(options);
      },
      [assignUserToSubtask, removeUserFromSubtask, subtaskId, assignees]
    );

    return (
      <Form onSubmit={handleSubmit} methods={methods}>
        <AutoSave<FormValues>
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
        />
        <Container alignItems={'center'} ref={ref}>
          {withDragHandle && !isCustomer && (
            <ReorderTrigger key={subtaskId}>
              <DragIcon size={'small'} color="dark500" />
            </ReorderTrigger>
          )}
          <Content isCustomer={isCustomer} withDragHandle={withDragHandle}>
            <TaskCheck
              checked={checked}
              onChange={(checked) => updaters.updateStatus(checked)}
            />
            <Flex flexDirection="column" flexGrow={1} ml="12px">
              <Grid>
                {!isCustomer ? (
                  <GhostTextareaField
                    name={'title'}
                    size="xsmall"
                    placeholder={DEFAULT_TITLE}
                    {...(!title
                      ? { fontStyle: 'italic', color: 'dark600' }
                      : {})}
                    shouldResize
                    defaultMaxHeight={55}
                  />
                ) : (
                  <Div width={'100%'}>
                    <Text
                      size="xs"
                      {...(checked
                        ? { strikethrough: true, color: 'dark600' }
                        : {})}
                      {...(!title
                        ? { fontStyle: 'italic', color: 'dark600' }
                        : {})}
                    >
                      {title || DEFAULT_TITLE}
                    </Text>
                  </Div>
                )}
                <Flex alignItems="center" gap="12px" flexShrink={0}>
                  <Flex alignItems={'center'}>
                    <Popup
                      trigger={
                        <ImageGroup>
                          {assignees.map((user) => (
                            <Avatar key={user.id} user={user} />
                          ))}
                          <TransparentButton
                            type={'button'}
                            icon={<PlusCircle />}
                          />
                        </ImageGroup>
                      }
                    >
                      <Flex
                        flexDirection={'column'}
                        gap={'2px'}
                        width={'270px'}
                      >
                        <Flex
                          flexDirection={'column'}
                          padding={'6px'}
                          gap={'4px'}
                        >
                          {invitees.map((user) => (
                            <Assignee
                              key={user.id}
                              user={user}
                              isSelected={assignees.some(
                                (assignee) => assignee.id === user.id
                              )}
                              onClick={() => toggleUserFromTask(user.id)}
                            />
                          ))}
                          {workspaceUsers.map((user) => (
                            <Assignee
                              key={user.id}
                              user={user}
                              isSelected={assignees.some(
                                (assignee) => assignee.id === user.id
                              )}
                              onClick={() => toggleUserFromTask(user.id)}
                            />
                          ))}

                          <Flex paddingX={'16px'} gap={'8px'}>
                            <Input
                              name={'invitee'}
                              placeholder={t(
                                'plan.taskDetails.assignees.input.placeholder'
                              )}
                              value={email}
                              onChange={(e) => setEmail(e.currentTarget.value)}
                            />
                            <Button
                              variant={'primary'}
                              onClick={onInviteeSubmit}
                              disabled={!isValidEmail(email)}
                            >
                              {t('plan.taskDetails.assignees.button.label')}
                            </Button>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Popup>
                  </Flex>
                  {!isCustomer ? (
                    <DatePickerField
                      name={'dueDate'}
                      renderer={(date, setDate) => (
                        <Pill
                          Icon={CalendarIcon}
                          onClose={date ? () => setDate(null) : undefined}
                          color={getDueDateColor(dueDate)}
                          weight={'medium'}
                        >
                          <Text
                            size="xs"
                            color={getDueDateColor(dueDate)}
                            weight={'medium'}
                            noWrap
                          >
                            {formatDate(date)}
                          </Text>
                        </Pill>
                      )}
                    />
                  ) : (
                    <Text
                      size="xs"
                      color={getDueDateColor(dueDate)}
                      weight={'medium'}
                    >
                      {formatDate(dueDate)}
                    </Text>
                  )}
                </Flex>
                {!isCustomer && (
                  <CloseContainer onClick={handleDelete}>
                    <CloseIcon size={'small'} color={'dark500'} />
                  </CloseContainer>
                )}
              </Grid>
            </Flex>
          </Content>
        </Container>
      </Form>
    );
  }
);

const Container = styled(Flex)`
  border: 1px solid transparent;
  transition: background-color 180ms ease-out, border-color 180ms ease-out;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 12px;
  &:hover {
    background-color: ${colors.dark50};
  }
`;

const Content = styled(Flex)<{
  withDragHandle?: boolean;
  isCustomer?: boolean;
}>`
  position: relative;
  align-items: center;
  width: 100%;
  margin: 0 -12px;
  padding: 12px;
  ${({ withDragHandle, isCustomer }) =>
    withDragHandle && !isCustomer
      ? `
    width: calc(100% - 12px);
    margin: 0 -24px;
    cursor: pointer;
  `
      : ''};
`;

const Grid = styled(Div)`
  display: grid;
  grid-template-columns: 1fr min-content min-content;
  align-items: center;
`;

const ReorderTrigger = styled.div`
  position: relative;
  left: -24px;
  opacity: 0;
  transition: opacity 180ms ease-out;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }

  ${Container}:hover & {
    opacity: 1;
  }
`;

const CloseContainer = styled.div`
  cursor: pointer;
  margin-left: 4px;
  opacity: 0;
  transition: opacity 180ms ease-out;
  ${Container}:hover & {
    opacity: 1;
  }
`;

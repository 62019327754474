import { FC, ReactNode } from 'react';
import { Tooltip as ReactTooltip, TooltipRefProps } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

interface TooltipProps {
  children: ReactNode;
  text: string;
  placement?: TooltipRefProps['place'];
}

export const Tooltip: FC<TooltipProps> = ({
  children,
  text,
  placement = 'top',
}) => {
  /* Vars */

  const id = uuidv4();

  return (
    <>
      <div data-tooltip-id={id} data-tooltip-content={text}>
        {children}
      </div>

      <ReactTooltip id={id} place={placement} />
    </>
  );
};

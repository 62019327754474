import { graphql } from '@graphql/generated';

export const listWorkspaceUsersQuery = graphql(`
  query listWorkspaceUsers {
    me {
      id
      mainWorkspace {
        id
        users {
          id
          email
          status
        }
      }
    }
  }
`);

export const removeUserFromWorkspaceMutation = graphql(`
  mutation removeUserFromWorkspace($id: ID!) {
    removeUserFromWorkspace(id: $id)
  }
`);

import { useQuery } from '@apollo/client';
import { useUserContext } from '@src/contexts/UserContext/useUserContext';
import { getWorkspaceCustomers } from '@src/pages/Dashboard/components/Weekly/Weekly.query';

export const useCustomerOptions = () => {
  const { locale } = useUserContext();
  const { data: customersData } = useQuery(getWorkspaceCustomers);
  const customers = customersData?.workspace.customers || [];
  const options = customers
    .map((customer) => ({
      value: customer.id,
      label: customer.name,
    }))
    .sort((a, b) =>
      a.label.localeCompare(b.label, locale, { ignorePunctuation: true })
    );
  return options;
};

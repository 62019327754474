import { useSearchParams } from 'react-router-dom';
import { JoinWorkspace } from './components/JoinWorkspace/JoinWorkspace';
import { Error } from '@components/Error/Error';
import { FC } from 'react';

export const JoinWorkspacePage: FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  if (!token) {
    return <Error />;
  }

  return <JoinWorkspace token={token} />;
};

import type { FC } from 'react';
import styled from 'styled-components';
import { colors } from '@style/colors';
import { fontWeights } from '@style/typography';

type CompanyLogoProps = {
  size?: number;
  name: string;
  logo?: string | null;
  round?: boolean;
};

export const CompanyLogo: FC<CompanyLogoProps> = ({
  name,
  logo,
  size = 48,
  round = true,
}) => {
  const initial = name.trim().slice(0, 1);

  if (logo) {
    return (
      <Container size={size} round={round}>
        <img
          src={logo}
          alt={name}
          // prevent displaying the ugly missing image icon
          onError={(event) => (event.currentTarget.style.visibility = 'hidden')}
        />
      </Container>
    );
  }

  return (
    <Container round={round} size={size}>
      {initial}
    </Container>
  );
};

const Container = styled.div<{ size: number; round: boolean }>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  font-size: ${(props) => Math.round(0.6 * props.size)}px;
  border: 1px solid ${colors.dark900_10};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ round }) => (round ? `border-radius: 50%;` : '')}
  font-weight: ${fontWeights.bold};
  background-color: ${colors.dark100};
  color: ${colors.dark700};
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { type Accept, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { toastError } from '@components/Toast/toast';
import { uploadFile } from '@src/helpers/file';
import { FilePurpose } from '../generated/graphql';
import { createFileMutation } from './useFileUpload.query';

type UseFileUploadParams = {
  purpose: FilePurpose;
  accept?: Accept;
  onFileUpload?: (fileId: string, uploadUrl?: string) => void;
};

export function useFileUpload({
  purpose,
  accept,
  onFileUpload,
}: UseFileUploadParams) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [createFile] = useMutation(createFileMutation);

  const handleFileUpload = async (file: File) => {
    setLoading(true);
    try {
      const { data } = await createFile({
        variables: {
          input: {
            type: file.type,
            name: file.name,
            purpose,
          },
        },
      });

      if (!data?.createFile) return;

      await uploadFile(data.createFile.uploadUrl, file);
      onFileUpload?.(data.createFile.id, data.createFile.uploadUrl);
    } catch (e) {
      toastError(t('file.upload.error'));
    } finally {
      setLoading(false);
    }
  };

  const onDrop = useCallback(([file]: File[]) => {
    return handleFileUpload(file);
  }, []);

  const dropzone = useDropzone({
    onDrop,
    accept,
    multiple: false,
  });

  return { loading, ...dropzone };
}

import type { Placement, Strategy } from '@floating-ui/react';
import {
  flip,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
} from '@floating-ui/react';
import { zIndexPopup } from '@style/zIndex';
import type { FC, ReactElement, ReactNode } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Card } from '../Card/Card';

export type PopupProps = {
  trigger: ReactElement;
  placement?: Placement;
  children?: ReactNode | ((close: () => void) => ReactNode);
  onOpenChange?: (state: boolean) => void;
  withFlip?: boolean;
  strategy?: Strategy;
};

export const Popup: FC<PopupProps> = ({
  placement = 'bottom-start',
  trigger,
  children,
  onOpenChange,
  withFlip,
  strategy = 'absolute',
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: (openState) => {
      onOpenChange?.(openState);
      setIsOpen(openState);
    },
    placement,
    middleware: [offset(20), ...(withFlip ? [flip()] : [])],
    strategy,
  });
  const click = useClick(context);
  const focus = useFocus(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    focus,
    dismiss,
  ]);

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {trigger}
      </div>
      <CardContainer>
        <Card
          floating
          ref={refs.setFloating}
          style={{
            ...floatingStyles,
            // We hide but not remove from the document to avoid resetting children states
            // (e.g. React Select selected options).
            // Because children elements can overlap (e.g. React Select filters), we give to the visible the greatest z-index.
            zIndex: isOpen ? zIndexPopup : -1,
            visibility: isOpen ? 'visible' : 'hidden',
            top: 0,
            left: 0,
            width: 'max-content',
          }}
          {...getFloatingProps()}
        >
          {typeof children === 'function'
            ? children(() => setIsOpen(false))
            : children}
        </Card>
      </CardContainer>
    </>
  );
};

const CardContainer = styled.div`
  position: relative;
`;

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Card, Typography, CircleProgressBar } from '@src/newComponents';
import { PlanSection } from '@src/graphql/generated/graphql';
import { CheckIcon } from '@src/components/Icon/CheckIcon';
import ScrollToAnchor from '@src/newComponents/components/ScrollToAnchor';

export const SectionCarrousel = ({
  sections = [],
  isPreviewMode,
}: {
  sections?: PlanSection[];
  isPreviewMode: boolean;
}) => {
  /* Vars */

  const { t } = useTranslation();

  return (
    <>
      <div className="flex gap-6 overflow-x-auto">
        {sections.map((section) => (
          <Card
            className="min-w-[226px] h-[172px]"
            backgroundColor={
              section.completion.completionRate === 100
                ? 'green-300'
                : 'beige-200'
            }
          >
            <div className="flex items-center justify-between gap-4">
              <Typography
                size="base"
                fontWeight="medium"
                className="text-purple-900"
              >
                {section.title}
              </Typography>

              {isPreviewMode === false && (
                <Link to={`#${section.id}`}>
                  <Typography
                    size="sm"
                    fontWeight="medium"
                    className="text-grey-600 hover:text-grey-800 duration-200"
                  >
                    {t('common.see')}
                  </Typography>
                </Link>
              )}
            </div>

            <div className="flex justify-center mt-10">
              {section.completion.completionRate === 100 ? (
                <div className="h-[64px] w-[64px] rounded-full bg-green-700 flex justify-center items-center border-4 border-black-opacity-3">
                  <CheckIcon size="large" color="white" />
                </div>
              ) : (
                <CircleProgressBar
                  percentage={section.completion.completionRate}
                  text={`${section.completion.completedTasks}/${section.completion.totalTasks}`}
                  size="lg"
                />
              )}
            </div>
          </Card>
        ))}
      </div>

      <ScrollToAnchor />
    </>
  );
};

import styled, { css } from 'styled-components';
import { colors } from '@style/colors';
import { Button } from './Button';

export const resetButtonStyles = css`
  padding: 0;
  border: none;
  background: transparent;
`;

export const TransparentButton = styled(Button)`
  ${resetButtonStyles};
  color: ${colors.dark700};

  &:hover {
    color: ${colors.dark900};
  }
`;

import { useQuery } from '@apollo/client';
import { getSleekplanSSOTokenQuery } from './Sleekplan.query';
import { useEffect } from 'react';

export const useInitSleekplan = () => {
  const { data } = useQuery(getSleekplanSSOTokenQuery);
  const token = data?.sleekplanSSOToken.token;

  useEffect(() => {
    if (token) (window as any).$sleek.setUser({ token });
  }, [token]);
};

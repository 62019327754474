import { Flex } from '../Flex/Flex';
import { Text } from '../Text/Text';
import { useTranslation } from 'react-i18next';
import { FileIcon } from '../Icon/FileIcon';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { colors } from '@style/colors';
import { DropHereMask } from '../DropHereMask/DropHereMask';
import { uploadFile } from '../../helpers/file';
import { toastError } from '../Toast/toast';
import { useDropzone } from 'react-dropzone';
import { Loader } from '../Loader/Loader';
import { Asset } from '../Asset/Asset';
import { useMutation } from '@apollo/client';
import {
  createElementToProvideFileMutation,
  createElementToProvideLinkMutation,
  deleteElementToProvideFileMutation,
  deleteElementToProvideLinkMutation,
} from '../TaskPanel/TaskDetails.query';
import { ElementToProvideType, FilePurpose } from '@graphql/generated/graphql';
import { Input } from '../Input/Input';
import { formatLink, isValidHttpUrl } from '../../helpers/utils';
import { Button } from '../Button/Button';
import { getPlanResourcesQuery } from '../Plan/PlanResources.query';

type ElementsToProvideProps = {
  elementToProvide: {
    id: string;
    type: ElementToProvideType;
    name: string;
    isRequired?: boolean;
    task: {
      plan: {
        id: string;
      };
    };
    file?: {
      id: string;
      name: string;
      url: string;
    } | null;
    link?: {
      id: string;
      url: string;
    } | null;
  };
};

export const ElementsToProvide = ({
  elementToProvide,
}: ElementsToProvideProps) => {
  const { t } = useTranslation();
  const { name, isRequired } = elementToProvide;

  return (
    <Flex
      position={'relative'}
      padding={'12px'}
      gap={'12px'}
      flexDirection={'column'}
      borderRadius={'12px'}
      border={`1px solid ${colors.dark100}`}
    >
      <Flex justifyContent={'space-between'}>
        <Text size={'sm'}>{name}</Text>
        {isRequired ? (
          <Text size={'xs'} color={'dark700'}>
            {t('plan.taskDetails.elementsToProvide.required.label')}
          </Text>
        ) : null}
      </Flex>
      {elementToProvide.type === ElementToProvideType.File && (
        <FileElement elementToProvide={elementToProvide} />
      )}
      {elementToProvide.type === ElementToProvideType.Link && (
        <LinkElement elementToProvide={elementToProvide} />
      )}
    </Flex>
  );
};

const FileElement = ({ elementToProvide }: ElementsToProvideProps) => {
  const { t } = useTranslation();
  const [createElementToProvideFile] = useMutation(
    createElementToProvideFileMutation,
    {
      refetchQueries: [
        {
          query: getPlanResourcesQuery,
          variables: { planId: elementToProvide.task.plan.id },
          context: {
            headers: {
              'X-Preview': true,
            },
          },
        },
      ],
    }
  );
  const [deleteElementToProvideFile] = useMutation(
    deleteElementToProvideFileMutation,
    {
      refetchQueries: [
        {
          query: getPlanResourcesQuery,
          variables: { planId: elementToProvide.task.plan.id },
          context: {
            headers: {
              'X-Preview': true,
            },
          },
        },
      ],
    }
  );
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const handleUploadFail = useCallback(
    async (fileId: string) => {
      await deleteElementToProvideFile({
        variables: { fileId, elementToProvideId: elementToProvide.id },
      });
    },
    [elementToProvide.id]
  );

  const handleFileUpload = useCallback(
    async (file: File) => {
      const { data } = await createElementToProvideFile({
        variables: {
          elementToProvideId: elementToProvide.id,
          input: {
            purpose: FilePurpose.ElementToProvide,
            name: file.name,
            type: file.type,
          },
        },
      });

      if (!data) return;

      const {
        createElementToProvideFile: { id, uploadUrl },
      } = data;

      try {
        await uploadFile(uploadUrl, file);
      } catch (e) {
        toastError(t('plan.elementsToProvide.fileUploadError'));
        await handleUploadFail(id);
      }
    },
    [createElementToProvideFile, elementToProvide.id, handleUploadFail]
  );
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setIsUploading(true);
      await Promise.all(acceptedFiles.map((file) => handleFileUpload(file)));
      setIsUploading(false);
    },
    [handleFileUpload]
  );
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
  });

  const { file } = elementToProvide;

  if (file) {
    return (
      <Asset
        isLoading={isUploading}
        title={file.name}
        rightText={file.url}
        href={file.url}
        handleRemoveClick={async () => {
          try {
            await handleUploadFail(file.id);
          } catch (e) {
            toastError(t('plan.taskDetails.files.deleteError'));
          }
        }}
      />
    );
  }

  return (
    <Container
      paddingY={'30px'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={'4px'}
      borderRadius={'12px'}
      position={'relative'}
      onClick={open}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <DropHereMask isHidden={!isDragActive} />
      {isUploading ? (
        <Loader size={18} />
      ) : (
        <>
          <FileIcon />
          <Text size={'sm'}>
            {t('plan.taskDetails.elementsToProvide.uploadZone.label')}
          </Text>
        </>
      )}
    </Container>
  );
};

const Container = styled(Flex)`
  border: 1px solid ${colors.dark100};
  transition: border-color 180ms ease-in-out;
  cursor: pointer;
  &:hover {
    border-color: ${colors.dark200};
  }
`;

const LinkElement = ({ elementToProvide }: ElementsToProvideProps) => {
  const { t } = useTranslation();
  const [createElementToProvideLink] = useMutation(
    createElementToProvideLinkMutation
  );
  const [deleteElementToProvideLink] = useMutation(
    deleteElementToProvideLinkMutation
  );
  const [linkValue, setLinkValue] = useState('');
  const submit = useCallback(
    async (url: string) => {
      if (!isValidHttpUrl(url)) {
        return;
      }
      await createElementToProvideLink({
        variables: {
          elementToProvideId: elementToProvide.id,
          input: {
            url,
            description: `Link to ${elementToProvide.name}`,
          },
        },
      });
      setLinkValue('');
    },
    [linkValue]
  );
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const link = formatLink(e.currentTarget.value);
    setLinkValue(link);
  }, []);

  const { id, link } = elementToProvide;

  if (link) {
    return (
      <Asset
        title={link.url}
        rightIcon={<FileIcon />}
        href={link.url}
        handleRemoveClick={async () => {
          try {
            await deleteElementToProvideLink({
              variables: {
                elementToProvideId: id,
              },
            });
          } catch (e) {
            toastError(t('plan.taskDetails.links.deleteError'));
          }
        }}
      />
    );
  }

  return (
    <Flex flexDirection={'column'} gap="8px">
      <Input
        name={elementToProvide.name}
        value={linkValue}
        onChange={handleChange}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            submit(linkValue);
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        placeholder={t('plan.taskDetails.elementsToProvide.linkPlaceholder')}
      />
      <div>
        <StyledButton
          variant="primary"
          type="button"
          disabled={!isValidHttpUrl(linkValue)}
          onClick={() => submit(linkValue)}
        >
          {t('plan.taskDetails.elementsToProvide.linkSubmitButton.label')}
        </StyledButton>
      </div>
    </Flex>
  );
};

const StyledButton = styled(Button)``;

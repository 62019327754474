import { Loader } from '@src/components/Loader/Loader';
import { useTasksDashboard } from '../hooks/useTasksDashboard';
import { Dashboard } from './Dashboard';

export const TasksDashboard = () => {
  const { url, loading } = useTasksDashboard();

  if (loading) return <Loader />;
  if (!url) return null;
  return <Dashboard url={url} />;
};

import React from 'react';
import styled from 'styled-components';
import { PlanFragment } from '@graphql/generated/graphql';
import { Flex } from '../Flex/Flex';
import { CloseIcon } from '../Icon/CloseIcon';
import { Text } from '../Text/Text';
import { Avatar } from '../Avatar/Avatar';
import { useUserContext } from '@src/contexts/UserContext/useUserContext';

type InviteeListProps = {
  invitees?: PlanFragment['invitees'];
  onDelete: (id: string) => void;
};

export const InviteeList = ({ invitees, onDelete }: InviteeListProps) => {
  if (invitees?.length === 0) {
    return null;
  }
  const currentUser = useUserContext();
  const isCurrentUser = (user: PlanFragment['invitees'][0]) =>
    user.id === currentUser?.id;

  return (
    <Flex flexDirection={'column'} gap={'16px'} paddingX={'16px'}>
      {invitees?.map((user) => (
        <Container
          key={user.id}
          gap={'12px'}
          alignItems={'center'}
          borderRadius={'12px'}
        >
          <Avatar size={'medium'} user={user} />
          <Flex flexDirection={'column'} flex={1}>
            <Text size={'sm'}>{user.displayName}</Text>
            <Text size={'xs'} color={'dark500'}>
              {user.email}
            </Text>
          </Flex>
          {onDelete && !isCurrentUser(user) && (
            <CloseContainer onClick={() => onDelete(user.id)}>
              <CloseIcon size={'small'} color={'dark500'} />
            </CloseContainer>
          )}
        </Container>
      ))}
    </Flex>
  );
};

const Container = styled(Flex)``;

const CloseContainer = styled.div`
  cursor: pointer;
  margin-left: 4px;
  opacity: 0;
  transition: opacity 180ms ease-out;

  ${Container}:hover & {
    opacity: 1;
  }
`;

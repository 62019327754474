import { useMutation } from '@apollo/client';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Button/Button';
import { Flex } from '@components/Flex/Flex';
import { Form } from '@components/Form/Form';
import { InputField } from '@components/Form/InputField';
import { toastError, toastSuccess } from '@components/Toast/toast';
import { inviteUserToWorkspaceMutation } from './InviteUserForm.query';

type FormValues = {
  email: string;
};

export const InviteUserForm: FC = () => {
  const { t } = useTranslation();
  const methods = useForm<FormValues>({
    defaultValues: { email: '' },
    mode: 'onSubmit',
  });
  const [inviteUserToWorkspace, { loading }] = useMutation(
    inviteUserToWorkspaceMutation,
    { refetchQueries: ['listWorkspaceUsers'] }
  );

  const handleSubmit = async ({ email }: FormValues) => {
    try {
      await inviteUserToWorkspace({ variables: { email } });
      toastSuccess(t('inviteUserForm.success'));
      methods.reset();
    } catch (e) {
      toastError(t('shared.error.unexpectedError'));
    }
  };

  return (
    <Form<FormValues> methods={methods} onSubmit={handleSubmit}>
      <Flex gap="12px" maxWidth="300px">
        <InputField
          name="email"
          type="email"
          required
          placeholder={t('inviteUserForm.email.placeholder')}
        />
        <Button type="submit" variant="primary" loading={loading}>
          {t('inviteUserForm.submit')}
        </Button>
      </Flex>
    </Form>
  );
};

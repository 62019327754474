import { Icon, IconComponent } from './index';

export const GearIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0004 12.5C11.3811 12.5 12.5004 11.3807 12.5004 10C12.5004 8.61925 11.3811 7.5 10.0004 7.5C8.61969 7.5 7.50043 8.61925 7.50043 10C7.50043 11.3807 8.61969 12.5 10.0004 12.5Z"
          stroke="#5B5B5B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.74129 16.1426L8.22833 17.238C8.37314 17.564 8.60939 17.8411 8.90848 18.0355C9.20764 18.2299 9.55673 18.3334 9.91348 18.3333C10.2703 18.3334 10.6194 18.2299 10.9185 18.0355C11.2176 17.8411 11.4539 17.564 11.5987 17.238L12.0857 16.1426C12.2591 15.7539 12.5507 15.4299 12.9191 15.2167C13.2897 15.0028 13.7185 14.9117 14.1441 14.9565L15.3357 15.0833C15.6905 15.1208 16.0485 15.0547 16.3663 14.8927C16.6841 14.7309 16.9482 14.4802 17.1265 14.1713C17.305 13.8625 17.39 13.5086 17.3712 13.1524C17.3525 12.7962 17.2308 12.4532 17.0209 12.1648L16.3154 11.1953C16.0641 10.8476 15.9299 10.429 15.9321 10C15.932 9.57216 16.0675 9.15533 16.3191 8.80925L17.0246 7.83981C17.2345 7.55146 17.3562 7.20839 17.375 6.85222C17.3937 6.49606 17.3086 6.14211 17.1301 5.83333C16.9519 5.52436 16.6879 5.27374 16.37 5.11186C16.0521 4.94997 15.6941 4.88377 15.3395 4.9213L14.1478 5.04815C13.7222 5.09284 13.2934 5.00176 12.9228 4.78796C12.5537 4.57354 12.262 4.2478 12.0895 3.85741L11.5987 2.76203C11.4539 2.43597 11.2176 2.15893 10.9185 1.9645C10.6194 1.77006 10.2703 1.66661 9.91348 1.66666C9.55673 1.66661 9.20764 1.77006 8.90848 1.9645C8.60939 2.15893 8.37314 2.43597 8.22833 2.76203L7.74129 3.85741C7.56873 4.2478 7.27702 4.57354 6.90795 4.78796C6.5373 5.00176 6.10851 5.09284 5.68295 5.04815L4.48759 4.9213C4.13286 4.88377 3.77487 4.94997 3.45701 5.11186C3.13916 5.27374 2.8751 5.52436 2.69685 5.83333C2.51836 6.14211 2.43333 6.49606 2.45207 6.85222C2.4708 7.20839 2.5925 7.55146 2.8024 7.83981L3.50795 8.80925C3.75957 9.15533 3.89507 9.57216 3.895 10C3.89507 10.4278 3.75957 10.8447 3.50795 11.1907L2.8024 12.1602C2.5925 12.4485 2.4708 12.7916 2.45207 13.1477C2.43333 13.5039 2.51836 13.8579 2.69685 14.1667C2.87528 14.4755 3.13937 14.726 3.45718 14.8878C3.77499 15.0497 4.13289 15.116 4.48759 15.0787L5.67925 14.9518C6.10481 14.9072 6.53359 14.9982 6.90425 15.212C7.27469 15.4258 7.56776 15.7517 7.74129 16.1426Z"
          stroke="#5B5B5B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
};

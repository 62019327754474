import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import fr from './fr.json';

export const i18nResources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
};

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: i18nResources,
    lng: 'en',
    keySeparator: false,
    supportedLngs: ['en', 'fr'],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import { useMutation } from '@apollo/client';
import type { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@components/Button/Button';
import { Flex } from '@components/Flex/Flex';
import { AsyncSelectField } from '@components/Form/AsyncSelectField';
import { Form } from '@components/Form/Form';
import { PageLayout } from '@components/PageLayout';
import { Error } from '@components/Error/Error';
import { Text } from '@components/Text/Text';
import { useWorkspaceRouting } from '@graphql/hooks/useWorkspaceRouting';
import {
  createCustomerMutation,
  createDefaultPlanMutation,
  duplicatePlanMutation,
} from './NewPlan.query';
import { NewPlanFormValues } from './types';
import { useLoadCustomerGroupedOptions } from './hooks/useLoadCustomerGroupedOptions';
import { Logo } from './components/Logo/Logo';

type NewPlanProps = {
  duplicate?: boolean;
};

export const NewPlanPage: FC<NewPlanProps> = ({ duplicate = false }) => {
  const { planId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathTo } = useWorkspaceRouting();
  const methods = useForm<NewPlanFormValues>({ mode: 'onChange' });
  const [createCustomer] = useMutation(createCustomerMutation);
  const [createDefaultPlan] = useMutation(createDefaultPlanMutation);
  const [duplicatePlan] = useMutation(duplicatePlanMutation);
  const listCustomers = useLoadCustomerGroupedOptions();

  const handleSubmit = async (values: NewPlanFormValues) => {
    let customerId: string;
    if ('id' in values.customer) {
      customerId = values.customer.id;
    } else {
      const { data } = await createCustomer({
        variables: { input: values.customer },
      });
      customerId = data!.createCustomer.id;
    }
    if (duplicate) {
      const { data } = await duplicatePlan({
        // this doesn't feel good and comfort me in my opinion that we should
        // extract components from pages
        variables: { customerId, planId: planId! },
      });
      navigate(pathTo(`/plans/${data!.duplicatePlan.id}`));
      return;
    }

    const { data } = await createDefaultPlan({ variables: { customerId } });
    navigate(pathTo(`/plans/${data!.createDefaultPlan.id}`));
  };

  if (duplicate && !planId) {
    return <Error />;
  }

  return (
    <PageLayout withTopBar backLink={pathTo('/')}>
      <Flex flexDirection="column" alignItems="center">
        <Text size="2xl" weight="bold">
          {t('newPlan.title')}
        </Text>
        <Form onSubmit={handleSubmit} methods={methods}>
          <Logo />
          <Flex flexDirection="column" width={276} gap="8px">
            <AsyncSelectField
              name="customer"
              size="small"
              placeholder={t('newPlan.form.customer.placeholder')}
              required
              loadOptions={async (query: string) => {
                return await listCustomers(query);
              }}
            />
            <Button
              type="submit"
              variant="primary"
              mt={16}
              fullWidth
              disabled={
                !methods.formState.isDirty || !methods.formState.isValid
              }
            >
              {t('newPlan.form.submit')}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </PageLayout>
  );
};

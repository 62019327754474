import { useUrlMetaData } from '@src/helpers/hooks/useUrlMetaData';
import styled from 'styled-components';
import { Card } from './Card/Card';
import { Text } from './Text/Text';

type URLPreviewProps = {
  url: string;
};

export const URLPreview = ({ url }: URLPreviewProps) => {
  const metaData = useUrlMetaData(url);

  if (!metaData?.image || !metaData?.title) return null;
  return (
    <a href={url} target="_blank">
      <PreviewCard floating>
        <Image src={metaData.image} title={metaData.title} />
        <TextContainer>
          <Text weight="bold" size="sm">
            {metaData.title}
          </Text>
          {metaData.description && (
            <Text size="sm">{metaData.description}</Text>
          )}
        </TextContainer>
      </PreviewCard>
    </a>
  );
};

const PreviewCard = styled(Card)`
  display: grid;
  grid-template-columns: 30% 1fr;
  grid-template-rows: max-content;
  row-gap: 18px;
  padding: 18px 24px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 0 12px;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 4px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
`;

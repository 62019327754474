import { debounce } from 'lodash';
import { useCallback } from 'react';
import {
  DeepPartialSkipArrayKey,
  FieldValues,
  SubmitHandler,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useDeepCompareEffect } from 'react-use';

type AutoSaveProps<FormValues extends FieldValues> = {
  onSubmit: SubmitHandler<FormValues>;
  defaultValues: DeepPartialSkipArrayKey<FormValues>;
};

// Inspired by https://codesandbox.io/s/react-hook-form-auto-save-forked-qqhv2n?file=/src/AutoSave.tsx
// Use this component inside a <Form> component (form context)
export function AutoSave<FormValues extends FieldValues>({
  onSubmit,
  defaultValues,
}: AutoSaveProps<FormValues>) {
  /* Vars */

  const methods = useFormContext<FormValues>();
  const watchedData = useWatch({
    control: methods.control,
    defaultValue: defaultValues,
  });

  /* Callbacks */

  const triggerSave = useCallback(
    debounce(methods.handleSubmit(onSubmit), 1000),
    [methods.handleSubmit, onSubmit]
  );

  // TODO
  // WARNING: This effect will trigger a save on every form change
  // ATM triggerSave is not included in the dependency array to avoid infinite loops
  // due to onSubmit or methods.handleSubmit being a new function on every render
  // We should look into why this is happening and how to fix it
  useDeepCompareEffect(() => {
    if (methods.formState.isDirty) {
      triggerSave();
    }
  }, [watchedData, methods.formState.isDirty]);

  return null;
}

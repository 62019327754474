import styled from 'styled-components';
import { colors } from '@style/colors';
import { FC } from 'react';
import { FilePurpose } from '@graphql/generated/graphql';
import { useFileUpload } from '@graphql/hooks/useFileUpload';
import { Image, ImageSize } from '../Image/Image';

type LogoUploadProps = {
  logo?: string | null;
  onUpload: (fileId: string) => Promise<void>;
  purpose: FilePurpose;
  size?: ImageSize;
};

export const LogoUpload: FC<LogoUploadProps> = ({
  logo,
  onUpload,
  purpose,
  size = 'large',
}) => {
  const { open: onFileUpload } = useFileUpload({
    purpose,
    accept: { 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] },
    onFileUpload: onUpload,
  });

  return (
    <PlusContainer hasPicture={!!logo}>
      <Image size={size} src={logo || ''} onClick={onFileUpload} />
    </PlusContainer>
  );
};

const PlusContainer = styled.div<{ hasPicture: boolean }>`
  cursor: pointer;
  position: relative;
  border-radius: 50%;

  ${({ hasPicture }) => {
    const after = `&::after {
        content: "+";
        color: ${colors.dark400};
        position: absolute;
        width:20px;
        height:20px;
        bottom: 0;
        right: 0;
        background-color: ${colors.dark50};
        text-align: center;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
      }`;
    return hasPicture
      ? `&:hover {
        ${after}

      }`
      : `border: 1px solid ${colors.dark50}; ${after}`;
  }}
`;

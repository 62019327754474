import { Icon, IconComponent } from './index';

export const FileUploadIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 121 151"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M92.2247 72.0717C81.1761 72.0717 72.1907 81.0424 72.1907 92.0725C72.1909 103.1 81.1761 112.073 92.2244 112.073C103.27 112.073 112.258 103.1 112.258 92.0725C112.259 81.0422 103.27 72.0718 92.2247 72.0717ZM92.2247 114.432C79.8739 114.432 69.8274 104.403 69.8274 92.0725C69.8274 79.7421 79.8738 69.7124 92.2244 69.7124C104.575 69.7124 114.621 79.7422 114.621 92.0725C114.622 104.403 104.575 114.433 92.2247 114.432Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M98.4339 87.1741C98.1309 87.1741 97.828 87.0587 97.599 86.8277L92.2252 81.4623L86.8514 86.8277C86.3911 87.2897 85.6423 87.2898 85.1795 86.8277C84.7194 86.3681 84.7191 85.621 85.1795 85.159L91.3883 78.9604C91.6099 78.7395 91.9124 78.6166 92.225 78.6166C92.5374 78.6166 92.838 78.7395 93.0596 78.9607L99.2708 85.1588C99.7311 85.6207 99.7309 86.3681 99.2706 86.8277C99.0392 87.0585 98.7364 87.1742 98.4339 87.1741Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M92.2247 99.1037C91.5722 99.1037 91.0428 98.5778 91.0428 97.924L91.0433 79.7961C91.0433 79.1422 91.5721 78.6165 92.2244 78.6165C92.8772 78.6163 93.4061 79.1424 93.4061 79.7961V97.9239C93.4061 98.5778 92.8769 99.1038 92.2247 99.1037Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M99.2503 105.528H85.1991C84.5466 105.528 84.017 105 84.017 104.349C84.017 103.698 84.5463 103.169 85.1987 103.169L99.2503 103.169C99.9028 103.169 100.432 103.698 100.432 104.349C100.432 105 99.9027 105.529 99.2503 105.528Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M95.3049 72.2933C94.6523 72.2933 94.123 71.7675 94.123 71.1137L94.1234 22.8298C94.1234 21.7017 93.2047 20.7827 92.0723 20.7827H43.8369C43.1844 20.7827 42.6551 20.2543 42.6551 19.603C42.6555 18.9518 43.1843 18.4234 43.8367 18.4234L92.0728 18.4231C94.5073 18.4231 96.4862 20.3993 96.4862 22.8299L96.4863 71.1135C96.4863 71.7674 95.9595 72.2934 95.3049 72.2933ZM92.2252 114.433H22.8675C20.4332 114.433 18.4534 112.455 18.4534 110.024L18.4535 43.7645C18.4535 43.1131 18.9827 42.5849 19.6352 42.5849C20.288 42.5848 20.8168 43.1133 20.8168 43.7646L20.817 110.024C20.817 111.154 21.7375 112.073 22.8674 112.073L92.2252 112.073C92.8777 112.073 93.4066 112.602 93.4066 113.253C93.4066 113.904 92.8774 114.433 92.2252 114.433Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.4856 42.5845H40.2153C41.5595 42.5845 42.6548 41.4909 42.6548 40.149L42.6552 22.451L22.4856 42.5845ZM40.2153 44.9438H19.6353C19.1578 44.9438 18.7266 44.6565 18.5419 44.2165C18.3597 43.7764 18.4607 43.2679 18.7979 42.9311L43.0019 18.7671C43.3392 18.4303 43.8487 18.3297 44.2893 18.5115C44.73 18.6958 45.0181 19.126 45.0181 19.6028V40.1489C45.0181 42.7935 42.864 44.944 40.2153 44.9438Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M74.9264 68.3215H29.4455C28.7931 68.3215 28.2635 67.7955 28.2635 67.1442C28.2635 66.4903 28.7928 65.9645 29.4451 65.9645L74.9264 65.9644C75.5789 65.9644 76.1078 66.4904 76.1078 67.1442C76.1079 67.7953 75.5786 68.3215 74.9264 68.3215ZM65.2565 79.2851H29.4455C28.7931 79.2851 28.2635 78.7568 28.2635 78.1055C28.2635 77.4541 28.7928 76.9258 29.4451 76.9258H65.2565C65.909 76.9258 66.438 77.4541 66.438 78.1055C66.438 78.7567 65.9087 79.2852 65.2565 79.2851ZM61.9974 90.2463H29.4455C28.7931 90.2463 28.2635 89.7181 28.2635 89.0667C28.2635 88.4154 28.7928 87.8871 29.4451 87.8871L61.9974 87.8869C62.6498 87.8869 63.1787 88.4155 63.1787 89.0667C63.1789 89.7179 62.6494 90.2465 61.9974 90.2463ZM62.908 101.21H29.4455C28.7931 101.21 28.2635 100.682 28.2635 100.03C28.2635 99.379 28.7928 98.8508 29.4451 98.8508L62.908 98.8506C63.5605 98.8506 64.0895 99.3792 64.0895 100.03C64.09 100.682 63.5602 101.21 62.908 101.21Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M85.4949 57.3601H29.4457C28.7932 57.3601 28.2637 56.8318 28.2637 56.1805C28.2637 55.5292 28.7929 55.0008 29.4452 55.0008L85.4949 55.0007C86.1496 55.0007 86.6763 55.5292 86.6763 56.1805C86.6763 56.8316 86.1494 57.3601 85.4949 57.3601Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.6353 102.377H10.7924C8.358 102.377 6.37836 100.401 6.37836 97.9709V10.7746C6.37836 8.34402 8.35766 6.36813 10.7924 6.36813L79.9971 6.36792C82.432 6.36792 84.4135 8.34416 84.4135 10.7748L84.4139 19.6026C84.4139 20.254 83.8842 20.7825 83.2319 20.7825C82.5772 20.7825 82.0503 20.254 82.0503 19.6028L82.0504 10.7746C82.0504 9.64651 81.1295 8.72753 79.9971 8.72753L10.7924 8.72746C9.66237 8.72746 8.7416 9.64671 8.7416 10.7748L8.74181 97.971C8.74181 99.0991 9.66223 100.018 10.7924 100.018H19.6353C20.2877 100.018 20.8165 100.547 20.8165 101.198C20.8168 101.849 20.2872 102.378 19.6353 102.377Z"
          fill="black"
        />
      </svg>
    </Icon>
  );
};

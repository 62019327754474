import { IconComponent } from '@src/components/Icon';
import { ChecklistIcon } from '@src/components/Icon/ChecklistIcon';
import { FileDownloadIcon } from '@src/components/Icon/FileDownloadIcon';
import { FileUploadIcon } from '@src/components/Icon/FileUploadIcon';
import { FillFormIcon } from '@src/components/Icon/FillFormIcon';
import { ScheduleIcon } from '@src/components/Icon/ScheduleIcon';
import { ActionType } from '@src/graphql/generated/graphql';
import { useTranslation } from 'react-i18next';

export const useActionTypeMap = () => {
  const { t } = useTranslation();

  const actionTypeMap: Record<
    ActionType,
    { label: string; cta: string; description: string; Icon: IconComponent }
  > = {
    [ActionType.SendFile]: {
      label: t('plan.taskAction.sendFile.label'),
      cta: t('plan.taskAction.sendFile.cta'),
      description: t('plan.taskAction.sendFile.description'),
      Icon: FileDownloadIcon,
    },
    [ActionType.AskFile]: {
      label: t('plan.taskAction.askFile.label'),
      cta: t('plan.taskAction.askFile.cta'),
      description: t('plan.taskAction.askFile.description'),
      Icon: FileUploadIcon,
    },
    [ActionType.SendLink]: {
      label: t('plan.taskAction.sendLink.label'),
      cta: t('plan.taskAction.sendLink.cta'),
      description: t('plan.taskAction.sendLink.description'),
      Icon: FileUploadIcon,
    },
    [ActionType.BookDemo]: {
      label: t('plan.taskAction.bookMeeting.label'),
      cta: t('plan.taskAction.bookMeeting.cta'),
      description: t('plan.taskAction.bookMeeting.description'),
      Icon: ScheduleIcon,
    },
    [ActionType.Checklist]: {
      label: t('plan.taskAction.checklist.label'),
      cta: t('plan.taskAction.checklist.cta'),
      description: t('plan.taskAction.checklist.description'),
      Icon: ChecklistIcon,
    },
    [ActionType.FillForm]: {
      label: t('plan.taskAction.fillForm.label'),
      cta: t('plan.taskAction.fillForm.cta'),
      description: t('plan.taskAction.fillForm.description'),
      Icon: FillFormIcon,
    },
  };

  return actionTypeMap;
};

import React, { type FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { hasSessionToken } from '../helpers/auth';
import { UserWrapper } from '@src/contexts/UserContext/UserWrapper';
import { TrackingProvider } from '@src/contexts/TrackingContext/TrackingProvider';

export const AuthenticatedRoot: FC = () => {
  const location = useLocation();

  if (!hasSessionToken()) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return (
    <UserWrapper>
      <TrackingProvider>
        <Outlet />
      </TrackingProvider>
    </UserWrapper>
  );
};

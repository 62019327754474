import type { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '@assets/images/jamat-logo.png';
import { Button } from '@components/Button/Button';
import { Form } from '@components/Form/Form';
import { InputField } from '@components/Form/InputField';
import { PageLayout } from '@components/PageLayout';
import { ErrorCode, hasErrorCode } from '@src/helpers/errors';
import { colors } from '@style/colors';
import { Text } from '@components/Text/Text';
import { Flex } from '@components/Flex/Flex';
import { useLogin } from './hooks/useLogin';

type FormValues = {
  email: string;
  password: string;
};

export const LoginPage: FC = () => {
  const { t } = useTranslation();
  const login = useLogin();
  const navigate = useNavigate();
  const methods = useForm<FormValues>({ mode: 'onChange' });

  const handleSubmit = async ({ email, password }: FormValues) => {
    try {
      await login(email, password);
      navigate('/');
    } catch (error) {
      if (hasErrorCode(error, ErrorCode.INVALID_CREDENTIALS)) {
        methods.setError('password', { message: 'Invalid email or password' });
        return;
      }
      throw error;
    }
  };

  return (
    <PageLayout vCenter>
      <Container>
        <img src={Logo} alt="Jamat logo" height={56} />
        <Title>{t('login.title')}</Title>
        <Subtitle>{t('login.subtitle')}</Subtitle>
        <Form onSubmit={handleSubmit} methods={methods}>
          <FormContent>
            <InputField
              name="email"
              placeholder={t('form.email.placeholder')}
              required
              size="big"
            />
            <InputField
              type="password"
              name="password"
              placeholder={t('form.password.placeholder')}
              required
              size="big"
            />
            <Flex justifyContent="center">
              <Link to="/forgot-password">
                <Text color="dark400" size="sm">
                  {t('login.forgotPasswordLink')}
                </Text>
              </Link>
            </Flex>
            <Button type="submit" variant="primary" size="big" mt={8}>
              {t('login.button')}
            </Button>
            <SubButtonLink>
              <Trans
                i18nKey="login.signupLink"
                components={[<Link to="/signup" />]}
              />
            </SubButtonLink>
          </FormContent>
        </Form>
      </Container>
    </PageLayout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  margin: 32px 0 0;
  font-size: 24px;
  font-weight: 600;
`;

const Subtitle = styled.p`
  color: ${colors.dark700};
  margin: 8px 0 0;
`;

const FormContent = styled.div`
  margin-top: 32px;
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SubButtonLink = styled.div`
  font-size: 12px;
  color: ${colors.dark700};
  margin-top: 8px;
  text-align: center;
`;

import { Icon, IconComponent } from './index';

export const PlusIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
      >
        <path
          stroke="#9F9D99"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8 3.333v9.333M3.333 8h9.333"
        />
      </svg>
    </Icon>
  );
};

import { useMutation } from '@apollo/client';
import { FC, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Logo from '../../assets/images/jamat-logo.png';
import { Button } from '@components/Button/Button';
import { Flex } from '@components/Flex/Flex';
import { Form } from '@components/Form/Form';
import { InputField } from '@components/Form/InputField';
import { PageLayout } from '@components/PageLayout';
import { Text } from '@components/Text/Text';
import { toastError, toastSuccess } from '@components/Toast/toast';
import { setSessionToken } from '../../helpers/auth';
import { ErrorCode, hasErrorCode } from '../../helpers/errors';
import {
  loginByTokenMutation,
  requestLoginLinkMutation,
} from './CustomerLoginPage.query';

type FormValues = { email: string };

export const CustomerLoginPage: FC = () => {
  const mutated = useRef(false);
  const { t } = useTranslation();
  const [requestLoginLink] = useMutation(requestLoginLinkMutation);
  const methods = useForm<FormValues>({ mode: 'onChange' });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [loginByToken] = useMutation(loginByTokenMutation);

  useEffect(() => {
    if (!token) return;
    if (mutated.current) return;

    mutated.current = true;

    (async () => {
      try {
        const { data } = await loginByToken({ variables: { token } });
        setSessionToken(data!.loginByToken.token, data!.loginByToken.maxAge);
        navigate('/customer');
      } catch (e) {
        if (hasErrorCode(e, ErrorCode.TOKEN_EXPIRED)) {
          // We disable sending the email for now
          // toastError(
          //   "Your token has expired. We've just sent you another one!"
          // );
        } else {
          toastError('Unexpected error');
        }
      }
    })();
  }, []);

  const handleSubmit = async ({ email }: FormValues) => {
    await requestLoginLink({ variables: { email } });
    toastSuccess(t('customerLogin.successToast'));
  };

  return (
    <PageLayout vCenter>
      <Flex flexDirection="column" alignItems="center">
        <img src={Logo} alt="Jamat logo" height={56} />
        <Text size="2xl" weight="bold" mt={32}>
          {t('customerLogin.title')}
        </Text>
        <Text mt={8}>{t('customerLogin.subtitle')}</Text>
        <Form onSubmit={handleSubmit} methods={methods}>
          <Flex flexDirection="column" width={320} mt={32}>
            <InputField
              name="email"
              placeholder={t('form.email.placeholder')}
              required
              size="big"
            />
            <Button type="submit" variant="primary" size="big" mt={8}>
              {t('customerLogin.button')}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </PageLayout>
  );
};

import styled from 'styled-components';
import { Text, TextSize } from '../Text/Text';
import { Flex } from '../Flex/Flex';
import { computeStateColor } from '../Plan/Infos/Progression/Progression';
import { ProgressionCircle } from '../ProgressionCircle/ProgressionCircle';
import { FC } from 'react';

type ProgressionNumberProps = {
  done: number;
  total: number;
  size: number;
  textSize?: TextSize;
  percent?: number;
};

export const ProgressionNumber: FC<ProgressionNumberProps> = ({
  done,
  total,
  size,
  textSize = 'xs',
  percent,
}) => {
  const computedPercent = percent || (done * 100) / total || 0;
  return (
    <CircleContainer>
      <ProgressionCircle
        size={size}
        percent={computedPercent}
        progressColor={computeStateColor(computedPercent)}
      />
      <StyledText
        baseSize={size}
        done={done}
        total={total}
        size={textSize}
        color={computeStateColor(computedPercent)}
      >
        {`${done}/${total}`}
      </StyledText>
    </CircleContainer>
  );
};

const CircleContainer = styled(Flex)`
  position: relative;
`;

const StyledText = styled(Text)<{
  done: number;
  total: number;
  baseSize: number;
}>`
  ${({ baseSize, done, total }) => `
  position:absolute;
  display:flex;
  width: ${baseSize}px;
  height: ${baseSize}px;
  justify-content: center;
  align-items: center;
  font-weight: 500; 
  ${
    done >= 10 || total >= 10
      ? `
      font-size: ${Math.floor(baseSize / 3)}px;
      line-height: ${Math.floor(baseSize / 3)}px;
    `
      : ``
  }
  ${
    done >= 10 && total >= 10
      ? `
      font-size: ${Math.floor(baseSize / 3.75)}px;
      line-height: ${Math.floor(baseSize / 3.75)}px;
    `
      : `
    `
  }
`}
`;

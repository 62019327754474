export const isValidEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

// See tests for examples
export function findChangedElementPosition<T>(
  arr1: T[],
  arr2: T[]
): [number, number] | undefined {
  if (arr1.length !== arr2.length) {
    return;
  }

  let lowestIndex, highestIndex;

  // Find the first and last index where the arrays differ
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      if (lowestIndex === undefined) {
        lowestIndex = i;
      }
      highestIndex = i;
    }
  }

  // Figure out if the element moved left or right
  if (lowestIndex !== undefined && highestIndex !== undefined) {
    if (arr1[lowestIndex] === arr2[highestIndex]) {
      return [lowestIndex, highestIndex];
    }

    if (arr1[highestIndex] === arr2[lowestIndex]) {
      return [highestIndex, lowestIndex];
    }
  }
}

export const formatLink = (link: string) => {
  if ('https://'.includes(link) || 'http://'.includes(link)) {
    return link;
  }
  if (!link.startsWith('http://') && !link.startsWith('https://')) {
    return `https://${link}`;
  }

  return link;
};

export const isValidHttpUrl = (url: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );
  return pattern.test(url);
};

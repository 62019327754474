export const youtubeUrlToEmbed = (url: string): string => {
  if (!url.startsWith('https://www.youtube.com/'))
    throw new Error(
      'Invalid Youtube URL. It should start with https://www.youtube.com/'
    );

  const videoId = url.match(/(?<=watch\?v=)[\w-]+/)?.[0];
  return videoId ? `https://www.youtube.com/embed/${videoId}` : url;
};

export async function getUrlMimeType(url: string) {
  try {
    const response = await fetch(url, { method: 'HEAD' });
    const contentType = response.headers.get('Content-Type');

    return contentType;
  } catch (error) {
    console.error('Error fetching resource:', error);
    return null;
  }
}

type Metadata = Record<string, string | null | undefined>;

export const fetchUrlMetaData = async (
  url: string
): Promise<Metadata | undefined> => {
  try {
    const response = await fetch(url);
    const html = await response.text();

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const metaTags = doc.querySelectorAll('meta[property^="og:"]');
    const data: Metadata = {};

    metaTags.forEach((tag) => {
      const property = tag.getAttribute('property')?.replace('og:', '');
      const content = tag.getAttribute('content');
      if (property && content) data[property] = content;
    });

    return data;
  } catch (error) {
    console.error('Error fetching metadata for URL preview:', error);
  }
};

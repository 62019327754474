import { Ring } from '@uiball/loaders';
import type { FC } from 'react';
import type { MarginProps } from 'styled-system';
import { Colors, colors } from '@style/colors';
import { Flex } from '../Flex/Flex';

type LoaderProps = {
  size?: number;
  centered?: boolean;
  color?: Colors;
} & MarginProps;

export const Loader: FC<LoaderProps> = ({
  size = 24,
  centered = false,
  color = 'dark700',
  ...marginProps
}) => {
  return (
    <Flex
      {...(centered ? { justifyContent: 'center', alignItems: 'center' } : {})}
      {...marginProps}
    >
      <Ring color={colors[color]} size={size} />
    </Flex>
  );
};

import { type ReactNode, type FC, useMemo } from 'react';
import clsx from 'clsx';

interface TypographyProps {
  children: ReactNode;
  textAlign?: 'left' | 'center' | 'right';
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl';
  fontWeight?: 'regular' | 'medium' | 'semibold' | 'bold';
  className?: string;
}

export const Typography: FC<TypographyProps> = ({
  children,
  textAlign = 'left',
  size = 'sm',
  fontWeight = 'regular',
  className,
}) => {
  /* Memos */

  const textSize = useMemo(() => {
    switch (size) {
      case 'xs':
        return 'text-xs';
      case 'sm':
        return 'text-sm';
      case 'base':
        return 'text-base';
      case 'lg':
        return 'text-lg';
      case 'xl':
        return 'text-xl';
      case '2xl':
        return 'text-2xl';
      default:
        return 'text-sm';
    }
  }, [size]);

  const weight = useMemo(() => {
    if (fontWeight === 'medium') {
      return 500;
    }

    if (fontWeight === 'semibold') {
      return 600;
    }

    return fontWeight;
  }, [fontWeight]);

  return (
    <span
      className={clsx('font-general', textSize, className)}
      style={{ textAlign, fontWeight: weight }}
    >
      {children}
    </span>
  );
};

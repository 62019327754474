import type { HTMLProps } from 'react';
import { forwardRef } from 'react';
import styled from 'styled-components';
import type { GhostInputSize } from '../Input/GhostInput';
import { baseGhostInputStyle } from '../Input/inputMixins';
import { ForwardRefComponent } from 'framer-motion';

type GhostTextareaProps = {
  size?: GhostInputSize;
  alignLeft?: boolean;
} & Pick<
  HTMLProps<HTMLTextAreaElement>,
  'placeholder' | 'name' | 'value' | 'rows' | 'cols' | 'onChange' | 'disabled'
>;

export const GhostTextarea: ForwardRefComponent<
  HTMLTextAreaElement,
  GhostTextareaProps
> = forwardRef<HTMLTextAreaElement, GhostTextareaProps>(function GhostTextarea(
  { size, ...rest },
  ref
) {
  return <StyledGhostTextarea ref={ref} $size={size} {...rest} />;
});

const StyledGhostTextarea = styled.textarea<{
  $size?: GhostInputSize;
  alignLeft?: boolean;
}>`
  ${baseGhostInputStyle};
  resize: none;
`;

import toast from 'react-hot-toast';
import { colors } from '@style/colors';

export function toastSuccess(text: string) {
  return toast(text, {
    duration: 3000,
    position: 'top-center',
    style: {
      color: colors.white,
      background: colors.success,
    },
  });
}

export function toastError(text: string) {
  return toast(text, {
    duration: 3000,
    position: 'top-center',
    style: {
      color: colors.white,
      background: colors.error,
    },
  });
}

export function createAsyncToast(
  promise: Promise<any>,
  loadingText: string,
  errorText: string,
  successText: string
) {
  return toast.promise(
    promise,
    {
      loading: loadingText,
      success: successText,
      error: successText,
    },
    {
      position: 'bottom-right',
    }
  );
}

import { useInbox } from '@trycourier/react-hooks';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Message } from './Message/Message';
import { useWorkspaceRouting } from '@src/graphql/hooks/useWorkspaceRouting';
import { Button } from '../Button/Button';
import { colors } from '@src/style/colors';
import styled from 'styled-components';
import { Loader } from '../Loader/Loader';
import { Text } from '../Text/Text';

export const Notifications = () => {
  /* Vars */
  const inbox = useInbox();
  const { t } = useTranslation();
  const { pathTo } = useWorkspaceRouting();
  const rootRef = useRef<HTMLDivElement>(null);
  const notificationText = `${t('notifications.title')} ${
    inbox.unreadMessageCount ? `(${inbox.unreadMessageCount})` : ''
  }`;

  /* Effects */

  useEffect(() => {
    inbox.fetchMessages();
    inbox.getUnreadMessageCount();
  }, []);

  /* Callbacks */

  const markAllAsRead = useCallback(() => {
    if (inbox.unreadMessageCount ?? 0 > 0) {
      inbox.markAllAsRead();
    }
  }, [inbox.unreadMessageCount, inbox.markAllAsRead]);

  return (
    <Container ref={rootRef}>
      <NotificationHeader>
        <Text size="lg" weight="bold">
          {notificationText}
        </Text>

        <Button size="small" variant="invisible" onClick={markAllAsRead}>
          <Text size="xs" weight="semibold">
            {t('notifications.comment.markAllAsRead')}
          </Text>
        </Button>
      </NotificationHeader>

      {inbox.isLoading && (
        <EmptyContainer>
          <Loader centered />
        </EmptyContainer>
      )}

      {!inbox.isLoading && inbox.messages?.length === 0 && (
        <EmptyContainer>
          <Text size="sm" color="dark300">
            {t('notifications.empty')}
          </Text>
        </EmptyContainer>
      )}

      {!inbox.isLoading &&
        inbox.messages?.map((message) => (
          <Message
            isVisible={false}
            rootRef={rootRef}
            onClosed={close}
            pathTo={pathTo}
            key={message.messageId}
            message={message}
          />
        ))}
    </Container>
  );
};

const NotificationHeader = styled.div`
  padding: 8px 12px;
  border-bottom: solid 1px ${colors.dark100};
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EmptyContainer = styled.div`
  width: 100%;
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  position: relative;
  width: 400px;
  height: 360px;
  overflow-y: auto;
`;

import { PageLayout } from '@components/PageLayout';
import type { FC } from 'react';
import { ActivePlans } from './components/ActivePlans/ActivePlans';
import { Weekly } from './components/Weekly/Weekly';

export const Dashboard: FC = () => {
  return (
    <PageLayout withTopBar>
      <Weekly />
      <ActivePlans />
    </PageLayout>
  );
};

import { useMutation } from '@apollo/client';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PlanFragment, PlanStatus } from '@graphql/generated/graphql';
import { isValidEmail } from '../../helpers/utils';
import {
  addInviteeMutation,
  publishPlanMutation,
  removeInviteeMutation,
  unpublishPlanMutation,
} from '../../pages/Plan/Plan.query';
import { Divider } from '../Divider/Divider';
import { Flex } from '../Flex/Flex';
import { Input } from '../Input/Input';
import { Text } from '../Text/Text';
import { Toggle } from '../Toggle';
import { InviteeList } from './InviteeList';
import { Button, useToast } from '@src/newComponents';

type InvitePopupProps = {
  planId: string;
  invitees?: PlanFragment['invitees'];
  status?: PlanFragment['status'];
  isCustomer?: boolean;
};

export function InvitePopup({
  planId,
  invitees,
  status,
  isCustomer,
}: InvitePopupProps) {
  /* Vars */

  const { t } = useTranslation();
  const { createAsyncToast } = useToast();
  const [isPublished, setIsPublished] = useState(
    status === PlanStatus.Published
  );
  const [invitee, setInvitee] = useState('');

  /* Queries */

  const [addInvitee] = useMutation(addInviteeMutation);
  const [removeInvitee] = useMutation(removeInviteeMutation);
  const [publishPlan] = useMutation(publishPlanMutation);
  const [unpublishPlan] = useMutation(unpublishPlanMutation);

  /* Functions */

  const onPublishedChange = async () => {
    const variables = {
      id: planId,
    };

    if (status === PlanStatus.Published) {
      createAsyncToast(
        unpublishPlan({ variables, update: () => setIsPublished(false) })
      );
    }

    if (status === PlanStatus.Unpublished) {
      createAsyncToast(
        publishPlan({ variables, update: () => setIsPublished(true) })
      );
    }
  };

  const onInviteeSubmit = async () => {
    if (!isValidEmail(invitee)) {
      return;
    }

    // Send invite
    createAsyncToast(
      addInvitee({
        variables: {
          planId,
          email: invitee,
        },
      })
    );

    setInvitee('');
  };

  const handleInviteeDelete = async (userId: string) => {
    createAsyncToast(
      removeInvitee({
        variables: {
          planId,
          userId,
        },
      })
    );
  };

  return (
    <Flex
      flexDirection="column"
      gap="16px"
      paddingTop="16px"
      paddingBottom="16px"
      maxWidth="330px"
      width="max-content"
    >
      {!isCustomer && (
        <Fragment>
          <Flex alignItems="center" gap="6px" paddingX="16px">
            <Flex flexDirection="column" flex={1}>
              <Text size="sm" weight="medium">
                {t('plan.sharePopup.publish.title')}
              </Text>
              <Text size="xs">{t('plan.sharePopup.publish.subtitle')}</Text>
            </Flex>
            <Toggle checked={isPublished} onChange={onPublishedChange} />
          </Flex>
          <Divider />
        </Fragment>
      )}

      <Flex flexDirection="column" paddingX="16px">
        <Text size="sm" weight="medium">
          {t('plan.sharePopup.invitees.title')}
        </Text>

        <Text size="xs">{t('plan.sharePopup.invitees.subtitle')}</Text>
      </Flex>

      <Flex paddingX="16px" gap="8px">
        <Input
          name="invitee"
          placeholder={t('plan.sharePopup.input.placeholder')}
          value={invitee}
          onChange={(e) => setInvitee(e.currentTarget.value)}
        />

        <Button
          variant="primary"
          onClick={onInviteeSubmit}
          disabled={!isValidEmail(invitee)}
        >
          {t('plan.sharePopup.shareButton.label')}
        </Button>
      </Flex>

      <InviteeList invitees={invitees} onDelete={handleInviteeDelete} />
    </Flex>
  );
}

import styled from 'styled-components';
import { colors } from '@style/colors';

export const PillTabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  border: 1px solid ${colors.dark100};
  border-radius: 15px;
  padding: 4px;
`;

import { colors } from '@src/style/colors';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface OverlayProps {
  onClose: () => void;
}

export const Overlay: React.FC<PropsWithChildren<OverlayProps>> = ({
  children,
  onClose,
}) => {
  return (
    <StyledOverlay onClick={onClose}>
      <div onClick={(e) => e.stopPropagation()}>{children}</div>
    </StyledOverlay>
  );
};

const StyledOverlay = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
  animation: fadein 0.5s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  z-index: 5;
  background-color: ${colors.dark25}9F;
`;

import { Icon, IconComponent } from '..';

export const PriorityMediumIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="rgb(136, 135, 133)"
        width="14"
        height="14"
      >
        <path d="m6,15v6c0,1.654-1.346,3-3,3s-3-1.346-3-3v-6c0-1.654,1.346-3,3-3s3,1.346,3,3ZM24,3v18c0,1.654-1.346,3-3,3s-3-1.346-3-3V3c0-1.654,1.346-3,3-3s3,1.346,3,3Zm-1,0c0-1.103-.897-2-2-2s-2,.897-2,2v18c0,1.103.897,2,2,2s2-.897,2-2V3Zm-11,3c-1.654,0-3,1.346-3,3v12c0,1.654,1.346,3,3,3s3-1.346,3-3v-12c0-1.654-1.346-3-3-3Z" />
      </svg>
    </Icon>
  );
};

import { type ReactNode, type FC } from 'react';
import clsx from 'clsx';

interface IconButtonProps {
  icon: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  withBackground?: boolean;
}

export const IconButton: FC<IconButtonProps> = ({
  icon,
  onClick,
  className,
  withBackground,
}) => {
  return (
    <button
      type="button"
      className={clsx(
        'cursor-pointer rounded-lg p-3 duration-300',
        {
          'hover:bg-dark-50': !withBackground,
          'hover:bg-dark-100 bg-dark-50': withBackground,
        },
        className
      )}
      onClick={onClick}
      onMouseMove={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
    >
      {icon}
    </button>
  );
};

import { useState, useEffect } from 'react';
import { fetchUrlMetaData } from '../url';

export const useUrlMetaData = (url: string) => {
  const [metaData, setMetaData] = useState<Awaited<
    ReturnType<typeof fetchUrlMetaData>
  > | null>(null);

  useEffect(() => {
    fetchUrlMetaData(url).then(setMetaData);
  }, []);

  return metaData;
};

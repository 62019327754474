import {
  components,
  type GroupBase,
  type SelectComponentsConfig,
} from 'react-select';
import { Flex } from '../Flex/Flex';
import type { SelectOption } from './Select';

type CustomComponentsType<
  Value,
  IsMulti extends boolean
> = SelectComponentsConfig<
  SelectOption<Value>,
  IsMulti,
  GroupBase<SelectOption<Value>>
>;

export function getCustomSelectComponents<
  Value,
  IsMulti extends boolean
>(): CustomComponentsType<Value, IsMulti> {
  return {
    Option: ({ children, ...rest }) => {
      return (
        <components.Option {...rest}>
          <Flex alignItems="center" gap="8px">
            {rest.data.leftContent}
            <div>{children}</div>
          </Flex>
        </components.Option>
      );
    },
  };
}

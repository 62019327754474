import type { FC } from 'react';
import { Colors, colors } from '@style/colors';

type ProgressionCircleType = {
  percent: number;
  size: number;
  trackColor?: Colors;
  progressColor?: Colors;
  strokeWidth?: number;
  className?: string;
};

/** Inspired from https://nikitahl.com/svg-circle-progress */
export const ProgressionCircle: FC<ProgressionCircleType> = ({
  percent,
  size,
  trackColor = 'dark900_12',
  progressColor = 'green',
  strokeWidth = 2,
  className,
}) => {
  const radius = Math.floor(size / 2 - strokeWidth);
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      style={{ transform: 'rotate(-90deg)' }}
    >
      <circle
        r={radius}
        cx="50%"
        cy="50%"
        fill="transparent"
        stroke={colors[trackColor]}
        strokeWidth={strokeWidth}
      />
      <circle
        r={radius}
        cx="50%"
        cy="50%"
        fill="transparent"
        stroke={colors[progressColor]}
        strokeWidth={strokeWidth}
        strokeDasharray={Math.round(2 * Math.PI * radius)}
        strokeDashoffset={Math.round(
          2 * Math.PI * radius * ((100 - percent) / 100)
        )}
        strokeLinecap="round"
      />
    </svg>
  );
};

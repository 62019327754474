import { Icon, IconComponent } from './index';

export const CloseIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
      >
        <path
          stroke="#888785"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="m12 4-8 8m0-8 8 8"
        />
      </svg>
    </Icon>
  );
};

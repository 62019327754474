import styled from 'styled-components';
import type {
  HeightProps,
  MarginProps,
  PaddingProps,
  WidthProps,
  PositionProps,
} from 'styled-system';
import { height, margin, padding, position, width } from 'styled-system';

type DivProps = PaddingProps &
  MarginProps &
  WidthProps &
  HeightProps &
  PositionProps;

export const Div = styled.div<DivProps>`
  ${position}
  ${padding}
  ${margin}
  ${width}
  ${height}
`;

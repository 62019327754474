import { graphql } from '@graphql/generated';

export const createTaskMutation = graphql(`
  mutation createTask(
    $planId: ID!
    $planSectionId: ID!
    $actionType: ActionType!
  ) {
    createTask(
      planId: $planId
      planSectionId: $planSectionId
      actionType: $actionType
    ) {
      id
      actionType
      section {
        id
        completion {
          totalTasks
          completedTasks
          completionRate
        }
        tasks {
          id
          files {
            id
          }
          links {
            id
          }
          elementsToProvide {
            id
          }
        }
      }
      plan {
        completion {
          completionRate
        }
        lastActivityAt
      }
    }
  }
`);

export const createSectionMutation = graphql(`
  mutation createSection($planId: ID!, $index: Int!) {
    createSection(planId: $planId, index: $index) {
      id
      plan {
        id
        lastActivityAt
        sections {
          id
          index
          tasks {
            id
            title
          }
        }
      }
    }
  }
`);

export const updateSectionMutation = graphql(`
  mutation updateSection($id: ID!, $input: PlanSectionInput!) {
    updateSection(id: $id, input: $input) {
      id
      title
      description
      index
      plan {
        id
        lastActivityAt
        sections {
          id
          index
        }
      }
    }
  }
`);

export const deleteSectionMutation = graphql(`
  mutation deleteSection($id: ID!) {
    deleteSection(id: $id) {
      id
      lastActivityAt
      sections {
        id
      }
    }
  }
`);

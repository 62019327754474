import { useTranslation } from 'react-i18next';

import { Card, Typography, Avatar, Button } from '@src/newComponents';
import { User } from '@src/graphql/generated/graphql';

export const OwnerPlan = ({ owner }: { owner?: User }) => {
  /* Vars */

  const { t } = useTranslation();

  return (
    <Card backgroundColor="beige-300">
      <div className="flex gap-6">
        <Avatar
          picture={owner?.avatarUrl}
          fullName={owner?.displayName}
          email={owner?.email}
        />

        <div className="flex flex-col gap-4">
          <div className="flex gap-8">
            <div className="flex flex-col">
              <Typography fontWeight="semibold" className="text-dark-1000">
                {owner?.displayName}
              </Typography>

              <Typography size="xs" className="text-grey-700">
                {owner?.email}
              </Typography>
            </div>

            {owner?.calendarUrl && (
              <div>
                <Button
                  onClick={() =>
                    window.open(owner.calendarUrl as string, '_blank')
                  }
                  variant="secondary"
                  size="xs"
                >
                  {t('plan.owner.see-calendar')}
                </Button>
              </div>
            )}
          </div>

          <Typography size="xs" className="text-grey-800">
            {(owner?.introduction || '').split('\n').map((text) => (
              <>
                {text}
                <br />
              </>
            ))}
          </Typography>
        </div>
      </div>
    </Card>
  );
};

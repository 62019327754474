import { FC } from 'react';
import { components } from 'react-select';
import { Flex } from '../Flex/Flex';

export const IconOption: FC = (props: any) => {
  const {
    data: { label, Icon },
  } = props;

  return (
    <components.Option {...props}>
      <Flex alignItems="flex-end" gap="8px">
        {Icon && <Icon />}
        <span>{label}</span>
      </Flex>
    </components.Option>
  );
};

import { type ReactNode, type FC } from 'react';
import clsx from 'clsx';

interface CardProps {
  children: ReactNode;
  backgroundColor?: string;
  borderColor?: string;
  isDoubleBorder?: boolean;
  className?: string;
}

export const Card: FC<CardProps> = ({
  children,
  backgroundColor = 'white',
  borderColor = 'black-opacity-3',
  className,
  isDoubleBorder,
}) => {
  return (
    <div
      className={clsx(
        'rounded-2xl border',
        `bg-${backgroundColor}`,
        `border-${borderColor}`,
        { 'p-10': !isDoubleBorder },
        className
      )}
    >
      <div
        className={clsx({
          'border-[2px] border-white p-10 rounded-2xl': isDoubleBorder,
        })}
      >
        {children}
      </div>
    </div>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '@style/colors';
import { Flex } from '@components/Flex/Flex';
import { ProgressionCircle } from '@components/ProgressionCircle/ProgressionCircle';
import { Text } from '@components/Text/Text';

type PlanProgressionType = {
  percent: number;
};

function Progression({ percent }: PlanProgressionType) {
  const { t } = useTranslation();
  const stateColor = computeStateColor(percent);

  return (
    <Flex alignItems={'center'} gap={'6px'}>
      <ProgressionCircle
        size={20}
        percent={percent}
        progressColor={stateColor}
      />
      <Text size="sm" weight="medium" color={stateColor}>
        {t('progression.text', { percentage: percent })}
      </Text>
    </Flex>
  );
}

export function computeStateColor(percent: number): Colors {
  if (percent < 25) {
    return 'error';
  }

  if (percent < 50) {
    return 'warning';
  }

  return 'success';
}

export default Progression;

import { graphql } from '@graphql/generated';

export const genTasksDashboard = graphql(`
  query tasksDashboard {
    tasksDashboard {
      url
    }
  }
`);

export const genResourcesDashboard = graphql(`
  query resourcesDashboard {
    resourcesDashboard {
      url
    }
  }
`);

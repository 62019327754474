import React, { FC, Fragment, useCallback, useState } from 'react';
import { GetTaskQuery } from '@src/graphql/generated/graphql';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@style/colors';
import { resetButtonStyles } from '../Button/TransparentButton';
import { PlusCircle } from '../PlusCircle';
import { Text } from '../Text/Text';
import { createSubtaskMutation, updateSubtaskMutation } from './Subtasks.query';
import { Flex } from '../Flex/Flex';
import { Reorder } from 'framer-motion';
import { Divider } from '../Divider/Divider';
import { findChangedElementPosition } from '@src/helpers/utils';
import { SubtaskRow } from './SubtaskRow';

type AddSubTaskProps = {
  taskId: string;
  onTaskCreated?: (subtasks?: GetTaskQuery['task']['subtasks']) => void;
};

export const AddSubTask: FC<AddSubTaskProps> = ({ taskId, onTaskCreated }) => {
  const { t } = useTranslation();
  const [createSubTask] = useMutation(createSubtaskMutation, {});
  const handleCreateTask = async () => {
    await createSubTask({
      variables: {
        taskId,
      },
    }).then((res) => {
      onTaskCreated && onTaskCreated(res.data?.createSubtask?.task.subtasks);
    });
  };

  return (
    <AddTaskButton type={'button'} onClick={handleCreateTask}>
      <Flex gap="12px" alignItems="center">
        <PlusCircle />
        <Text size={'sm'} weight={'medium'} color={'dark500'}>
          {t('plan.taskDetails.subtasks.addButton.label')}
        </Text>
      </Flex>
    </AddTaskButton>
  );
};

const AddTaskButton = styled.button`
  ${resetButtonStyles};
  padding: 12px;
  margin: 0 -12px;
  border-radius: 12px;
  transition: background-color 180ms ease-out, border-color 180ms ease-out;
  cursor: pointer;
  &:hover {
    --iconColor: ${colors.dark700};
    background-color: ${colors.dark50};
  }
`;

type SubtasksProps = {
  taskId: string;
  subtasks: GetTaskQuery['task']['subtasks'];
  invitees: GetTaskQuery['task']['plan']['invitees'];
  workspaceUsers: GetTaskQuery['task']['plan']['workspace']['users'];
  planId: string;
  isCustomer?: boolean;
};
export const Subtasks: FC<SubtasksProps> = ({
  subtasks,
  taskId,
  isCustomer,
  invitees,
  workspaceUsers,
  planId,
}) => {
  const [updateSubtask] = useMutation(updateSubtaskMutation);
  const { t } = useTranslation();

  const [currentSubtasksOrders, setCurrentSubtasksOrders] = useState<string[]>(
    subtasks.map((subtask) => subtask.id)
  );

  const handleDrop = useCallback(async () => {
    const oldSubtasksOrders = subtasks.map((section) => section.id);
    const indexUpdates = findChangedElementPosition<string>(
      oldSubtasksOrders,
      currentSubtasksOrders
    );

    if (!indexUpdates) return;

    const [oldIndex, newIndex] = indexUpdates;
    const subtaskId = oldSubtasksOrders[oldIndex];

    await updateSubtask({
      variables: {
        id: subtaskId,
        input: {
          index: newIndex + 1,
        },
      },
    });
  }, [currentSubtasksOrders, subtasks]);

  const orderedSubtasks = subtasks.toSorted(
    (a, b) =>
      currentSubtasksOrders.indexOf(a.id) - currentSubtasksOrders.indexOf(b.id)
  );
  return (
    <Flex flexDirection="column" gap="8px">
      <Text size={'xs'} color={'dark600'}>
        {t('plan.taskDetails.subtasks.label')}
      </Text>
      <Reorder.Group
        axis={'y'}
        values={currentSubtasksOrders}
        onReorder={(newOrder) => setCurrentSubtasksOrders(newOrder)}
      >
        {orderedSubtasks.map((subtask) => (
          <Fragment key={subtask.id}>
            <Reorder.Item
              role="row"
              key={subtask.id}
              value={subtask.id}
              dragListener={!isCustomer}
              draggable={!isCustomer}
              onDragEnd={handleDrop}
            >
              <SubtaskRow
                withDragHandle
                isCustomer={isCustomer}
                planId={planId}
                subtask={subtask}
                invitees={invitees}
                workspaceUsers={workspaceUsers}
              />
            </Reorder.Item>
            <Divider />
          </Fragment>
        ))}
      </Reorder.Group>
      {!isCustomer && (
        <AddSubTask
          taskId={taskId}
          onTaskCreated={(newSubtasks) => {
            if (!newSubtasks) return;
            setCurrentSubtasksOrders(
              newSubtasks?.map((subtask) => subtask.id) || []
            );
          }}
        />
      )}
    </Flex>
  );
};

import { Trans, useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';
import clsx from 'clsx';

import {
  PlanSection as PlanSectionTypes,
  Task,
  TaskVisibility,
} from '@src/graphql/generated/graphql';
import {
  CircleProgressBar,
  Collapse,
  Textarea,
  Typography,
  Input,
  useToast,
  AutoSave,
  MoreActions,
  Tag,
  Divider,
} from '@src/newComponents';
import { SectionTask } from './components';
import { PlusIcon } from '@src/components/Icon/PlusIcon';
import {
  deleteSectionMutation,
  updateSectionMutation,
} from '@src/components/Plan/PlanSection.query';
import { TaskCreateModal } from '@src/components/TaskCreateModal/TaskCreateModal';
import { CheckIcon } from '@src/components/Icon/CheckIcon';
import {
  Dialog,
  DialogContent,
  DialogHeading,
  DialogDescription,
  DialogClose,
  DialogConfirm,
} from '@src/components/Dialog/Dialog';
import { Flex } from '@src/components/Flex/Flex';
import { DragIcon } from '@src/components/Icon/DragIcon';
import { updateTaskMutation } from '@src/components/TaskPanel/TaskDetails.query';

interface PlanSectionProps {
  section: PlanSectionTypes;
  planId: string;
  sections: PlanSectionTypes[];
  isPreviewMode?: boolean;
  refetch: () => void;
}

interface FormValues {
  index: number;
  title: string;
  description: string;
}

export const PlanSection = ({
  section,
  planId,
  sections,
  isPreviewMode,
  refetch,
}: PlanSectionProps) => {
  /* Vars */

  const { t } = useTranslation();
  const { createAsyncToast } = useToast();

  const [isShowCreateTaskModal, setIsShowCreateTaskModal] = useState(false);
  const [isShowDeleteSectionModal, setIsShowDeleteSectionModal] =
    useState(false);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [createdTaskId, setCreatedaskId] = useState<string | null>(null);

  /* Form */

  const sectionValues = {
    title: section.title || '',
    description: section.description || '',
    index: section.index,
  };

  const methods = useForm<FormValues>({
    defaultValues: sectionValues,
    values: sectionValues,
    mode: 'onChange',
  });

  /* Effects */

  useEffect(() => {
    setTasks(section.tasks);
  }, [section.tasks]);

  /* Memos */

  const tasksList = useMemo(() => {
    return tasks.filter(
      (task) => !isPreviewMode || task.visibility === TaskVisibility.Shared
    );
  }, [tasks, isPreviewMode]);

  /* Queries */

  const [updateSection] = useMutation(updateSectionMutation);
  const [deleteSection] = useMutation(deleteSectionMutation);
  const [updateTask] = useMutation(updateTaskMutation);

  /* Functions */

  const handleDeleteSection = () => {
    if (!isPreviewMode) {
      createAsyncToast(
        deleteSection({
          variables: {
            id: section.id,
          },
        })
      );
    }
  };

  const onSubmit = (data: FormValues) => {
    createAsyncToast(
      updateSection({
        variables: {
          id: section.id,
          input: data,
        },
      })
    );
  };

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    const taskIdMoved = tasks[oldIndex].id;
    const newTaskIndex = newIndex + 1;

    createAsyncToast(
      updateTask({
        variables: {
          id: taskIdMoved,
          input: {
            index: newTaskIndex,
          },
        },
      })
    );

    setTasks(arrayMoveImmutable(tasks, oldIndex, newIndex));
  };

  return (
    <>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
        id={section.id || ''}
        className="w-full max-w-[848px] px-[24px] group"
      >
        <div className="flex gap-4">
          {!isPreviewMode && (
            <SortableKnob>
              <div className="h-[36px] pt-5 cursor-grab opacity-0 group-hover:opacity-100 duration-200">
                <DragIcon size={'small'} color="dark400" />
              </div>
            </SortableKnob>
          )}

          <Collapse
            defaultOpened={section.completion.completionRate !== 100}
            title={
              <div
                className={clsx('flex gap-4 items-center', {
                  'mt-2': isPreviewMode,
                })}
              >
                {isPreviewMode ? (
                  <Typography size="lg" fontWeight="medium">
                    {section.title}
                  </Typography>
                ) : (
                  <Controller
                    name="title"
                    control={methods.control}
                    render={({ field }) => (
                      <Input
                        size="lg"
                        placeholder={t('planSection.section.title.default')}
                        {...field}
                      />
                    )}
                  />
                )}

                <Tag color="brown" size="sm">
                  <Typography
                    size="xs"
                    fontWeight="semibold"
                    className="text-grey-800"
                    textAlign="center"
                  >
                    {tasksList.length}
                  </Typography>
                </Tag>
              </div>
            }
            description={
              isPreviewMode ? (
                <Typography
                  size="sm"
                  fontWeight="medium"
                  className="text-grey-800"
                >
                  {(section.description || '').split('\n').map((text) => (
                    <>
                      {text}
                      <br />
                    </>
                  ))}
                </Typography>
              ) : (
                <Controller
                  name="description"
                  control={methods.control}
                  render={({ field }) => (
                    <Textarea
                      placeholder={t('planSection.section.description.default')}
                      {...field}
                    />
                  )}
                />
              )
            }
            contentRight={
              <div className="flex justify-end items-center gap-4 relative -mr-[40px]">
                {section.completion.completionRate < 100 ? (
                  <>
                    <Typography fontWeight="medium" className="text-dark-500">
                      {`${section.completion.completedTasks}/${section.completion.totalTasks}`}
                    </Typography>

                    <CircleProgressBar
                      percentage={section.completion.completionRate}
                      size="sm"
                    />
                  </>
                ) : (
                  <Tag color="green">
                    <div className="flex items-center gap-4 text-green-800">
                      <CheckIcon size="small" />

                      <Typography
                        size="sm"
                        fontWeight="semibold"
                        className="text-green-800"
                      >
                        {t('planSection.completed')}
                      </Typography>
                    </div>
                  </Tag>
                )}

                {!isPreviewMode && (
                  <MoreActions
                    actions={[
                      {
                        label: t('planSection.deleteModal.confirm.label'),
                        onClick: () => setIsShowDeleteSectionModal(true),
                      },
                    ]}
                  />
                )}
              </div>
            }
          >
            <div className="flex flex-col gap-4">
              <SortableList
                onSortEnd={onSortEnd}
                className="tasks-list"
                draggedItemClassName="dragged-list"
                lockAxis="y"
                allowDrag={!isPreviewMode}
              >
                {tasksList.map((task) => (
                  <SortableItem key={task.id}>
                    <div className="flex gap-4 items-center">
                      {!isPreviewMode && (
                        <div className="py-5 cursor-grab opacity-0 group-hover:opacity-100 duration-200">
                          <SortableKnob>
                            <DragIcon size={'small'} color="dark400" />
                          </SortableKnob>
                        </div>
                      )}

                      <div
                        className="flex-1"
                        onMouseMove={(e) => e.stopPropagation()}
                        onMouseUp={(e) => e.stopPropagation()}
                      >
                        <SectionTask
                          key={task.id}
                          task={task}
                          sections={sections}
                          refetch={refetch}
                          createdTaskId={createdTaskId}
                          isPreviewMode={isPreviewMode}
                        />
                      </div>
                    </div>
                  </SortableItem>
                ))}
              </SortableList>

              {!isPreviewMode && (
                <div
                  className="flex items-center gap-6 hover:bg-dark-50 hover:px-4 py-4 rounded-lg duration-300 cursor-pointer"
                  onClick={() => setIsShowCreateTaskModal(true)}
                >
                  <div className="h-[24px] w-[24px] rounded-full border border-dark-200 flex justify-center items-center">
                    <PlusIcon color="dark400" />
                  </div>

                  <Typography
                    size="base"
                    fontWeight="semibold"
                    className="text-dark-500"
                  >
                    {t('planSection.newTaskButton.label')}
                  </Typography>
                </div>
              )}
            </div>
          </Collapse>
        </div>

        <AutoSave
          onSubmit={onSubmit}
          defaultValues={sectionValues}
          methods={methods}
        />
      </form>

      <TaskCreateModal
        visible={isShowCreateTaskModal}
        onClose={() => setIsShowCreateTaskModal(false)}
        onCreated={setCreatedaskId}
        planId={planId}
        planSectionId={section.id}
      />

      <Dialog
        open={isShowDeleteSectionModal}
        onOpenChange={setIsShowDeleteSectionModal}
      >
        <DialogContent>
          <DialogHeading mt="8px" mx="24px">
            {t('planSection.deleteModal.title')}
          </DialogHeading>

          <DialogDescription my="6px" mx="24px">
            <Trans
              i18nKey="planSection.deleteModal.subtitle"
              components={{ 1: <br /> }}
            />
          </DialogDescription>

          <Divider />

          <Flex my="6px" mx="24px" justifyContent="center" gap="16px">
            <DialogClose>
              {t('planSection.deleteModal.cancel.label')}
            </DialogClose>

            <DialogConfirm onConfirm={async () => await handleDeleteSection()}>
              {t('planSection.deleteModal.confirm.label')}
            </DialogConfirm>
          </Flex>
        </DialogContent>
      </Dialog>
    </>
  );
};

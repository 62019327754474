import { Cookies } from 'react-cookie';
import { SECURE_BACKEND } from '../config';

const cookies = new Cookies();

const SESSION_TOKEN_COOKIE_NAME = 'token';

export function setSessionToken(token: string, maxAge: number) {
  cookies.set(SESSION_TOKEN_COOKIE_NAME, token, {
    path: '/',
    sameSite: 'lax',
    secure: SECURE_BACKEND,
    maxAge,
  });
}

export function removeSessionToken() {
  cookies.remove(SESSION_TOKEN_COOKIE_NAME, { path: '/' });
}

export function getSessionToken(): string | undefined {
  return cookies.get<string | undefined>(SESSION_TOKEN_COOKIE_NAME);
}

export function hasSessionToken(): boolean {
  return !!getSessionToken();
}

import { FileIcon } from '../Icon/FileIcon';
import { Text } from '../Text/Text';
import React from 'react';
import styled from 'styled-components';
import { colors } from '@style/colors';
import { useTranslation } from 'react-i18next';
import { zIndexMask } from '@style/zIndex';

export const DropHereMask = ({
  canSeeThrough,
  isHidden,
}: {
  canSeeThrough?: boolean;
  isHidden?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Container canSeeThrough={canSeeThrough} isHidden={isHidden}>
      <FileIcon />
      <Text size={'sm'}>{t('plan.taskDetails.files.dropZoneText')}</Text>
    </Container>
  );
};

const Container = styled.div<{ isHidden?: boolean; canSeeThrough?: boolean }>`
  position: absolute;
  z-index: ${zIndexMask};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px;
  border-radius: 8px;
  background: ${colors.dark50};
  ${({ canSeeThrough }) =>
    canSeeThrough &&
    `
      backdrop-filter: blur(2px);
      background: ${colors.dark50}33;
  `};
  ${({ isHidden }) => isHidden && 'display: none;'};
`;

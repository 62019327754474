import { graphql } from '@graphql/generated';

export const getNextTasks = graphql(`
  query getNextTasks(
    $userIds: [ID!]!
    $customerIds: [ID!]!
    $offset: Int
    $limit: Int
  ) {
    nextTasks(
      userIds: $userIds
      customerIds: $customerIds
      limit: $limit
      offset: $offset
    ) {
      __typename
      ... on Task {
        ...TaskRow
        plan {
          id
          title
          startDate
          workspace {
            id
            name
          }
          customer {
            name
          }
        }
      }
    }
  }
`);

export const getWorkspaceUsers = graphql(`
  query getWorkspaceUsers {
    workspace {
      id
      users {
        id
        firstName
        displayName
      }
    }
  }
`);

export const getWorkspaceCustomers = graphql(`
  query getWorkspaceCustomers {
    workspace {
      id
      customers {
        id
        name
      }
    }
  }
`);

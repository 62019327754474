import { useState, type FC } from 'react';
// import { FileUploader } from 'react-drag-drop-files';
import clsx from 'clsx';
import { SketchPicker } from 'react-color';

import { IconButton } from '../iconButton';
import { Popover } from '../popover';
import { ImageIcon } from '@src/components/Icon/ImageIcon';

interface BannerProps {
  background: string;
  onChange: (background: string | File) => void;
  className?: string;
  readOnly?: boolean;
}

export const Banner: FC<BannerProps> = ({
  background,
  onChange,
  className,
  readOnly,
}) => {
  /* Vars */

  const [isOpenPicker, setIsOpenPicker] = useState(false);

  return (
    <div
      className={clsx(
        'h-[240px] w-full bg-cover bg-center p-10 flex items-end justify-end',
        className
      )}
      style={{ backgroundColor: background }}
    >
      {!readOnly && (
        <Popover
          position="end"
          open={isOpenPicker}
          onClose={() => setIsOpenPicker(false)}
          content={
            <div className="p-2">
              <SketchPicker
                color={background}
                onChangeComplete={(color) => onChange(color.hex)}
                className="!p-[0px] !shadow-none"
              />
            </div>
          }
        >
          <IconButton
            icon={<ImageIcon color="white" size="large" />}
            className="bg-white-opacity-5"
            onClick={() => setIsOpenPicker(!isOpenPicker)}
          />
        </Popover>
      )}
    </div>
  );
};

/*<div className="flex flex-col gap-8 p-6">
  <div className="flex flex-col gap-2">
    <Typography size="base" fontWeight="semibold">
      {imageLabel}
    </Typography>

    <FileUploader
      handleChange={(file: File) => onChange(file)}
      name="file"
      types={fileTypes}
      maxSize={5}
    >
      <div className="h-[100px] w-[200px] flex justify-center items-center border border-dashed rounded-lg border-dark-500">
        <div className="flex flex-col gap-2 items-center">
          <Icon
            name="camera-outline"
            size={24}
            className="text-dark-500"
          />

          <Typography size="sm" className="text-dark-500">
            {imagePlaceholder}
          </Typography>
        </div>
      </div>
    </FileUploader>
    </div>

    {colorsOptions && (
      <div className="flex flex-col gap-2">
        <Typography size="base" fontWeight="semibold">
          {colorLabel}
        </Typography>

        <div className="flex gap-4">
          {colorsOptions?.map((color) => (
            <button
              type="button"
              key={color}
              onClick={() => onChange(color)}
              className="h-8 w-8 rounded"
              style={{ backgroundColor: color }}
            />
          ))}
        </div>
      </div>
    )}
  </div>*/

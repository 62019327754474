import { GetTaskQuery } from '@graphql/generated/graphql';
import { useTranslation } from 'react-i18next';
import { Asset } from '../Asset/Asset';
import { TransparentButton } from '../Button/TransparentButton';
import { Flex } from '../Flex/Flex';
import { GlobeIcon } from '../Icon/GlobeIcon';
import { PlusCircle } from '../PlusCircle';
import { Text } from '../Text/Text';
import { toastError } from '../Toast/toast';

interface TaskFilesProps {
  files: GetTaskQuery['task']['files'];
  uploadingFiles: string[];
  openUploadDialog: () => void;
  handleFileDelete: (fileId: string) => Promise<void>;
  isCustomer?: boolean;
  isActionType: boolean;
}

export const TaskFiles = ({
  files,
  uploadingFiles,
  openUploadDialog,
  handleFileDelete,
  isCustomer,
  isActionType,
}: TaskFilesProps) => {
  const { t } = useTranslation();
  const onDelete = async (fileId: string) => {
    try {
      await handleFileDelete(fileId);
    } catch (e) {
      toastError(t('plan.taskDetails.files.deleteError'));
    }
  };

  return (
    <div>
      <Flex flexDirection={'column'} alignItems={'flex-start'} gap={'8px'}>
        <Text size={'xs'} color={'dark600'}>
          {t('plan.taskDetails.files.label')}
        </Text>
        {(files.length || 0) > 0 && (
          <Flex
            flexDirection={'column'}
            alignSelf={'stretch'}
            ml={'-6px'}
            mb={'8px'}
            gap={'8px'}
          >
            {files.map((file) => (
              <Asset
                isLoading={uploadingFiles.includes(file.id)}
                key={file.id}
                icon={GlobeIcon}
                title={file.name}
                rightText={file.url}
                href={file.url}
                handleRemoveClick={
                  isCustomer ? undefined : () => onDelete(file.id)
                }
              />
            ))}
          </Flex>
        )}
        {!isCustomer && (!isActionType || !files.length) && (
          <TransparentButton
            type={'button'}
            icon={<PlusCircle />}
            mt={'4px'}
            onClick={openUploadDialog}
          >
            <Text size={'sm'}>
              {t('plan.taskDetails.files.addButton.label')}
            </Text>
          </TransparentButton>
        )}
      </Flex>
    </div>
  );
};

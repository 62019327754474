import { useMutation } from '@apollo/client';
import {
  ActionType,
  FilePurpose,
  GetPlanQuery,
  GetTaskQuery,
  TaskCompanyAssignee,
  TaskStatus,
  TaskVisibility,
} from '@graphql/generated/graphql';
import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import { PROD } from '@src/config';
import { Priority } from '@src/helpers/priority';
import { dateRule } from '@src/validation/rules/dateRule';
import { positiveIntegerRule } from '@src/validation/rules/positiveIntegerRule';
import { type TFunction } from 'i18next';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { ZodTypeAny, z } from 'zod';
import { uploadFile } from '../../helpers/file';
import { AutoSave } from '../AutoSave/AutoSave';
import { Button } from '../Button/Button';
import { CompanyLogo } from '../CompanyLogo/CompanyLogo';
import {
  Dialog,
  DialogClose,
  DialogConfirm,
  DialogContent,
  DialogDescription,
  DialogHeading,
} from '../Dialog/Dialog';
import { Divider } from '../Divider/Divider';
import { DropButton } from '../Drop/DropButton';
import { DropCard } from '../Drop/DropCard';
import { DropHereMask } from '../DropHereMask/DropHereMask';
import { Div } from '../Flex/Div/Div';
import { Flex } from '../Flex/Flex';
import { CompanyAssigneeField } from '../Form/CompanyAssigneeField';
import { Form } from '../Form/Form';
import { GhostInputField } from '../Form/GhostInputField';
import { GhostSelectField } from '../Form/GhostSelectField';
import { GhostTextareaField } from '../Form/GhostTextareaField';
import { TripleDotsIcon } from '../Icon/TripleDotsIcon';
import { getPlanGanttQuery } from '../Plan/PlanGantt.query';
import { getPlanResourcesQuery } from '../Plan/PlanResources.query';
import { sectionTitlePlaceholder } from '../Plan/PlanSection';
import { updateTaskStatusMutation } from '../Plan/TaskRow.query';
import { Popup } from '../Popup/Popup';
import { IconOption } from '../Select/IconOption';
import { IconSingleValue } from '../Select/IconSingleValue';
import { TaskCheck } from '../Select/TaskCheck/TaskCheck';
import { Text } from '../Text/Text';
import { toastError, toastSuccess } from '../Toast/toast';
import { Subtasks } from './Subtasks';
import { TaskAssignees } from './TaskAssignees';
import {
  createTaskFileMutation,
  deleteTaskFileMutation,
  deleteTaskMutation,
  sendReminderMutation,
  updateTaskMutation,
} from './TaskDetails.query';
import { TaskDueDateField } from './TaskDueDateField';
import { TaskElementsToProvide } from './TaskElementsToProvide';
import { TaskFiles } from './TaskFiles';
import { TaskLinks } from './TaskLinks';
import { TaskStartDateField } from './TaskStartDateField';
import { getPriorityIcon } from './getPriorityIcon';
import { isNil } from 'lodash';
import { TaskComments } from './TaskComments';
import { formatTime, formatDate } from '@src/helpers/date';
import { Tooltip } from '../Tooltip/Tooltip';

interface TaskDetailsProps {
  task: GetTaskQuery['task'];
  sections: Pick<GetPlanQuery['plan']['sections'][number], 'id' | 'title'>[];
  closeTaskPanel: () => void;
  isCustomer?: boolean;
  refetchTask: () => void;
}

export type DueDateMode = 'dueDate' | 'duration';

export type FormValues = {
  title: string;
  section: string;
  description?: string;
  startDate?: string;
  dueDate?: string;
  dueDateMode: DueDateMode;
  duration: number | null;
  visibility: TaskVisibility;
  note: string;
  priority: Priority;
  companyAssignee: TaskCompanyAssignee;
};

const getBaseSchema = (t: TFunction) =>
  z.object({
    startDate: dateRule(t),
  });

const getValidationSchema = (t: TFunction) =>
  z.union([
    getBaseSchema(t)
      .merge(
        z.object<
          Record<
            Extract<keyof FormValues, 'dueDate' | 'dueDateMode'>,
            ZodTypeAny
          >
        >({
          dueDateMode: z.literal('dueDate'),
          dueDate: dateRule(t),
        })
      )
      .passthrough()
      .refine(
        (schema) =>
          schema.startDate &&
          schema.dueDate &&
          new Date(schema.startDate) <= new Date(schema.dueDate),
        {
          path: ['dueDate'],
          message: t('validation.startDateAfterDueDate.invalid'),
        }
      ),
    z
      .object<
        Record<
          Extract<keyof FormValues, 'duration' | 'dueDateMode'>,
          ZodTypeAny
        >
      >({
        dueDateMode: z.literal('duration'),
        duration: positiveIntegerRule(t),
      })
      .merge(getBaseSchema(t))
      .passthrough(),
  ]);

export const TaskDetails: FC<TaskDetailsProps> = ({
  task,
  sections,
  closeTaskPanel,
  isCustomer,
  refetchTask,
}) => {
  /* Vars */

  const [deleteTaskModalOpen, setDeleteTaskModalOpen] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState<string[]>([]);
  const { t } = useTranslation();
  const defaultValues: FormValues = {
    title: task.title || '',
    section: task.section.id,
    description: task.description || '',
    startDate: task.startDate || '',
    dueDate: task.dueDate || '',
    dueDateMode: task.duration ? 'duration' : 'dueDate',
    duration: task.duration || null,
    note: task.note || '',
    visibility: task.visibility || TaskVisibility.Shared,
    priority: task.priority,
    companyAssignee: task.companyAssignee,
  };

  /* Mutations */

  const [updateTask] = useMutation(updateTaskMutation);

  const [deleteTask] = useMutation(deleteTaskMutation, {
    refetchQueries: [
      {
        query: getPlanGanttQuery,
        variables: { planId: task.plan.id },
        context: {
          headers: {
            ...(isCustomer && { 'X-Preview': true }),
          },
        },
      },
    ],
  });

  const [updateStatus] = useMutation(updateTaskStatusMutation);

  const [createTaskFile] = useMutation(createTaskFileMutation, {
    refetchQueries: [
      {
        query: getPlanResourcesQuery,
        variables: { planId: task.plan.id },
        context: {
          headers: {
            ...(isCustomer && { 'X-Preview': true }),
          },
        },
      },
    ],
  });

  const [deleteTaskFile] = useMutation(deleteTaskFileMutation, {
    refetchQueries: [
      {
        query: getPlanResourcesQuery,
        variables: { planId: task.plan.id },
        context: {
          headers: {
            ...(isCustomer && { 'X-Preview': true }),
          },
        },
      },
    ],
  });

  const [sendReminder, { loading: isSendReminderLoading }] =
    useMutation(sendReminderMutation);

  /* Memos */

  const schema = useMemo(() => getValidationSchema(t), [t]);

  /* Form */

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(schema),
  });

  /* Callbacks */

  const handleUploadFail = useCallback(
    async (fileId: string) => {
      await deleteTaskFile({ variables: { fileId, taskId: task.id } });
    },
    [task.id]
  );

  const handleTaskDelete = useCallback(async () => {
    await deleteTask({
      variables: {
        taskId: task.id,
      },
      onCompleted: () => {
        closeTaskPanel();
      },
    });
  }, [deleteTask, task.id, closeTaskPanel]);

  const handleSubmit = useCallback(
    async ({
      title,
      section,
      description,
      startDate,
      dueDate,
      duration,
      visibility,
      note,
      priority,
      companyAssignee,
    }: FormValues) => {
      const dueDateMode = methods.getValues('dueDateMode');
      await updateTask({
        variables: {
          id: task.id,
          input: {
            title,
            description,
            startDate,
            ...(dueDateMode === 'dueDate' ? { dueDate, duration: null } : {}),
            ...(dueDateMode === 'duration' ? { dueDate: null, duration } : {}),
            visibility,
            note,
            sectionId: section,
            priority,
            companyAssignee,
          },
        },
        refetchQueries: [getPlanGanttQuery],
      });
    },
    [task, updateTask, methods]
  );

  /* Functions */

  const handleFileUpload = async (taskId: string, file: File) => {
    const { data } = await createTaskFile({
      variables: {
        taskId,
        input: { purpose: FilePurpose.Task, name: file.name, type: file.type },
      },
    });
    if (!data) return;
    const {
      createTaskFile: { id, uploadUrl },
    } = data;

    setUploadingFiles((prev) => [...prev, id]);
    try {
      await uploadFile(uploadUrl, file);
    } catch (e) {
      toastError(t('plan.taskDetails.fileUploadError'));
      await handleUploadFail(id);
    }
    setUploadingFiles((prev) => prev.filter((fileId) => fileId !== id));
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.map((file) => handleFileUpload(task.id, file));
    },
    [task.id]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true, // prevent opening file dialog on any click
  });

  const handleSendReminder = async () => {
    try {
      await sendReminder({ variables: { taskId: task.id } });

      refetchTask();

      toastSuccess(t('plan.taskDetails.send-reminder-success'));
    } catch (e) {
      toastError(t('plan.taskDetails.send-reminder-error'));
    }
  };

  return (
    <Form onSubmit={handleSubmit} methods={methods}>
      <Dialog open={deleteTaskModalOpen} onOpenChange={setDeleteTaskModalOpen}>
        <DialogContent>
          <DialogHeading mt="8px" mx="24px">
            {t('plan.taskDetails.dropdown.deleteModal.title')}
          </DialogHeading>
          <DialogDescription my="6px" mx="24px">
            <Trans
              i18nKey="plan.taskDetails.dropdown.deleteModal.subtitle"
              components={{ 1: <br /> }}
            />
          </DialogDescription>
          <Divider my="8px" />
          <Flex my="6px" mx="24px" justifyContent="center" gap="16px">
            <DialogClose>
              {t('plan.taskDetails.dropdown.deleteModal.cancel.label')}
            </DialogClose>
            <DialogConfirm onConfirm={handleTaskDelete}>
              {t('plan.taskDetails.dropdown.deleteModal.confirm.label')}
            </DialogConfirm>
          </Flex>
        </DialogContent>
      </Dialog>
      {!PROD && <DevTool control={methods.control} />}
      <AutoSave<FormValues>
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
      />
      <Flex flexDirection={'column'} gap={'24px'}>
        <Flex alignItems={'center'} gap={'8px'}>
          <TaskCheck
            checked={task.status === TaskStatus.Done}
            onChange={async (checked) => {
              await updateStatus({
                variables: {
                  id: task.id,
                  status: checked ? TaskStatus.Done : TaskStatus.Todo,
                },
              });
            }}
          />
          <GhostInputField
            name={'title'}
            placeholder={t('plan.taskDetails.title.label')}
            disabled={isCustomer}
            autoFocus
          />
          {!isCustomer && (
            <Popup
              trigger={
                <Button
                  type={'button'}
                  size={'small'}
                  icon={<TripleDotsIcon />}
                />
              }
              placement="bottom-end"
            >
              {(closePopup) => (
                <DropCard minWidth={145}>
                  <DropButton
                    onClick={() => {
                      closePopup();
                      setDeleteTaskModalOpen(true);
                    }}
                  >
                    {t('plan.taskDetails.dropdown.deleteButton.label')}
                  </DropButton>
                </DropCard>
              )}
            </Popup>
          )}
        </Flex>
        {!isCustomer && (
          <GhostSelectField
            required
            name={'section'}
            label={t('plan.taskDetails.section.label')}
            defaultValue={defaultValues.section}
            options={sections.map((section) => ({
              value: section.id,
              label: section.title || sectionTitlePlaceholder,
            }))}
          />
        )}
        <GhostTextareaField
          name={'description'}
          label={t('plan.taskDetails.description.label')}
          size={'small'}
          placeholder={t('plan.taskDetails.description.placeholder')}
          disabled={isCustomer}
          shouldResize
          moreContent
        />

        <Flex gap="32px">
          <TaskStartDateField
            isCustomer={!!isCustomer}
            startDate={task.startDate}
          />

          <TaskDueDateField
            isCustomer={!!isCustomer}
            dueDate={task.computedDueDate}
          />

          <Flex alignItems="flex-end">
            <Tooltip
              text={
                task.lastReminderSendAt &&
                t('plan.taskDetails.send-reminder-at', {
                  date: formatDate(task.lastReminderSendAt),
                  time: formatTime(task.lastReminderSendAt),
                })
              }
              placement="bottom"
            >
              <Button
                onClick={handleSendReminder}
                size="big"
                type="button"
                loading={isSendReminderLoading}
              >
                {t('plan.taskDetails.send-reminder')}
              </Button>
            </Tooltip>
          </Flex>
        </Flex>

        {isCustomer ? (
          <Flex flexDirection={'column'} alignItems={'flex-start'} gap={'8px'}>
            <Label size={'xs'} color={'dark600'}>
              {t('plan.taskDetails.companyAssignee.label')}
            </Label>
            <CompanyLogo
              name={task.companyAssignee}
              logo={task.companyAssigneeLogo}
              size={40}
            />
          </Flex>
        ) : (
          <CompanyAssigneeField
            csmCompany={{
              name: task.plan.workspace.name,
              logo: task.plan.workspace.logo || undefined,
            }}
            customerCompany={{
              name: task.plan.customer.name,
              logo: task.plan.customer.logo || undefined,
            }}
          />
        )}
        <TaskAssignees
          planId={task.plan.id}
          taskId={task.id}
          assignees={task.assignees}
          invitees={task.plan.invitees}
          workspaceUsers={task.plan.workspace.users}
          isCustomer={isCustomer}
        />
        {!isCustomer && (
          <GhostSelectField
            required
            name={'visibility'}
            label={t('plan.taskDetails.visibility.label')}
            options={[
              {
                value: TaskVisibility.Shared,
                label: t('plan.taskDetails.visibility.shared'),
              },
              {
                value: TaskVisibility.Private,
                label: t('plan.taskDetails.visibility.private'),
              },
            ]}
          />
        )}
        <GhostSelectField
          required
          name={'priority'}
          label={t('plan.taskDetails.priority.label')}
          components={{ Option: IconOption, SingleValue: IconSingleValue }}
          options={[
            {
              value: Priority.NO_PRIORITY,
              label: t('plan.taskDetails.priority.noPriority'),
              Icon: getPriorityIcon(Priority.NO_PRIORITY),
            },
            {
              value: Priority.LOW,
              label: t('plan.taskDetails.priority.low'),
              Icon: getPriorityIcon(Priority.LOW),
            },
            {
              value: Priority.MEDIUM,
              label: t('plan.taskDetails.priority.medium'),
              Icon: getPriorityIcon(Priority.MEDIUM),
            },
            {
              value: Priority.HIGH,
              label: t('plan.taskDetails.priority.high'),
              Icon: getPriorityIcon(Priority.HIGH),
            },
            {
              value: Priority.URGENT,
              label: t('plan.taskDetails.priority.urgent'),
              Icon: getPriorityIcon(Priority.URGENT),
            },
          ]}
        />
        {(isNil(task.actionType) ||
          task.actionType === ActionType.Checklist) && (
          <Subtasks
            planId={task.plan.id}
            subtasks={task.subtasks}
            taskId={task.id}
            isCustomer={isCustomer}
            invitees={task.plan.invitees}
            workspaceUsers={task.plan.workspace.users}
          />
        )}
        {(isNil(task.actionType) ||
          task.actionType === ActionType.SendLink ||
          task.actionType === ActionType.BookDemo ||
          task.actionType === ActionType.FillForm) && (
          <TaskLinks
            planId={task.plan.id}
            taskId={task.id}
            links={task.links}
            isCustomer={isCustomer}
            isActionType={!!task.actionType}
          />
        )}
        {(isNil(task.actionType) ||
          task.actionType === ActionType.SendFile) && (
          <Div position={'relative'} {...getRootProps()}>
            {!isCustomer && (
              <>
                <DropHereMask canSeeThrough isHidden={!isDragActive} />
                <input {...getInputProps()} />
              </>
            )}
            <TaskFiles
              files={task.files}
              uploadingFiles={uploadingFiles}
              openUploadDialog={open}
              handleFileDelete={handleUploadFail}
              isCustomer={isCustomer}
              isActionType={!!task.actionType}
            />
          </Div>
        )}
        {(isNil(task.actionType) || task.actionType === ActionType.AskFile) && (
          <TaskElementsToProvide
            planId={task.plan.id}
            taskId={task.id}
            elementsToProvide={task.elementsToProvide}
            isCustomer={isCustomer}
            isActionType={!!task.actionType}
          />
        )}
        <GhostTextareaField
          name={'note'}
          label={t('plan.taskDetails.note.label')}
          size={'small'}
          placeholder={t('plan.taskDetails.note.placeholder')}
          disabled={isCustomer}
          shouldResize
          moreContent
        />
      </Flex>
      <TaskComments taskId={task.id} comments={task.comments} />
    </Form>
  );
};

const Label = Text.withComponent('label');

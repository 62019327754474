import { Icon, IconComponent } from './index';

export const CheckIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          stroke="#262626"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10 3 4.5 8.5 2 6"
        />
      </svg>
    </Icon>
  );
};

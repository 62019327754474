import { type FC, type ReactElement } from 'react';
import ReactPopover from 'react-popover';

type Position =
  | 'above'
  | 'right'
  | 'below'
  | 'left'
  | 'row'
  | 'column'
  | 'start'
  | 'end';

interface PopoverProps {
  children: ReactElement | string;
  content: ReactElement;
  position?: Position;
  onClose?: () => void;
  open?: boolean;
  className?: string;
}

export const Popover: FC<PopoverProps> = ({
  children,
  content,
  position = 'below',
  onClose,
  open,
  className,
}) => {
  return (
    <ReactPopover
      isOpen={open}
      tipSize={0.01}
      preferPlace={position}
      onOuterAction={onClose}
      body={
        <div className="bg-white rounded-xl border min-w-[100px] border-dark-100 shadow-sm mt-6 p-3">
          {content}
        </div>
      }
      className={className}
    >
      {children}
    </ReactPopover>
  );
};

import { graphql } from '@graphql/generated';

export const listActivePlansQuery = graphql(`
  query listActivePlans {
    workspace {
      id
      plans {
        ...HomePagePlan
      }
    }
  }
`);

graphql(`
  fragment HomePagePlan on Plan {
    id
    title
    startDate
    completion {
      completionRate
      totalTasks
      completedTasks
    }
    owner {
      ...UserAvatar
    }
    nextTask {
      id
      title
      computedDueDate
      assignees {
        ...UserAvatar
      }
    }
    status
    endDate
    customer {
      id
      name
      logo
    }
  }
`);

export const deletePlanMutation = graphql(`
  mutation deletePlan($id: ID!) {
    deletePlan(id: $id) {
      id
      plans {
        ...HomePagePlan
      }
    }
  }
`);

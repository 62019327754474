import { FC } from 'react';
import styled from 'styled-components';
import { ImageIcon } from '@components/Icon/ImageIcon';
import { CompanyLogo } from '@components/CompanyLogo/CompanyLogo';
import { colors } from '@style/colors';
import { useFormContext } from 'react-hook-form';
import { NewPlanFormValues } from '../../types';

const isClearbitLogo = (logo: string) => logo.includes('clearbit');

export const Logo: FC = () => {
  const { watch } = useFormContext<NewPlanFormValues>();

  const logo = watch('customer')?.logo;

  if (!logo)
    return (
      <LogoContainer>
        <ImageIcon />
      </LogoContainer>
    );

  if (isClearbitLogo(logo)) {
    return (
      <LogoContainer>
        <img src={`${logo}?size=80`} />
      </LogoContainer>
    );
  }

  return (
    <LogoContainer>
      <CompanyLogo name={'existing-company'} logo={logo} size={80} />
    </LogoContainer>
  );
};
const LogoContainer = styled.div`
  margin: 48px auto;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 1px solid ${colors.dark900_10};
  background-color: ${colors.dark25};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.dark500};
`;

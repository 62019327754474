import { TripleDotsIcon } from '@src/components/Icon/TripleDotsIcon';
import { Popover } from '../popover';
import { useState } from 'react';
import { IconButton } from '../iconButton';

interface MoreActionsProps {
  actions: Action[];
  className?: string;
}

interface Action {
  label: string;
  onClick: () => void;
}

export const MoreActions = ({ actions, className }: MoreActionsProps) => {
  /* Vars */

  const [isOpen, setIsOpen] = useState(false);

  /* Functions */

  const handleClick = (action: Action) => {
    action.onClick();
    setIsOpen(false);
  };

  const handleToggleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <Popover
      open={isOpen}
      onClose={() => setIsOpen(false)}
      content={
        <div className="flex flex-col gap-2">
          {actions.map((action, index) => (
            <button
              key={index}
              className="w-full py-2 px-3 text-left text-dark-900 font-medium font-general hover:bg-dark-50 duration-200 rounded-lg"
              onClick={() => handleClick(action)}
            >
              {action.label}
            </button>
          ))}
        </div>
      }
    >
      <IconButton
        onClick={handleToggleClick}
        icon={<TripleDotsIcon size="normal" color="dark700" />}
        className={className}
      />
    </Popover>
  );
};

import styled from 'styled-components';
import { position, space } from 'styled-system';
import type { PositionProps, SpaceProps } from 'styled-system';
import { colors } from '@style/colors';

export const Divider = styled.hr<SpaceProps & PositionProps>`
  margin: 0;
  padding: 0;

  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-bottom-width: thin;
  border-color: ${colors.dark100};

  ${position}
  ${space};
`;

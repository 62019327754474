import { Icon, IconComponent } from './index';

export const ClockIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_42_137)">
          <path
            d="M5.70461 2.45797C8.76527 1.18997 12.2739 2.64397 13.5419 5.70464C14.8099 8.76531 13.3559 12.274 10.2953 13.542C7.23461 14.81 3.72594 13.356 2.45794 10.2953C1.19061 7.23464 2.64394 3.72597 5.70461 2.45797Z"
            stroke="#5B5B5B"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.81189 5.32324V8.42391L10.2492 9.90991"
            stroke="#5B5B5B"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_42_137">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
};

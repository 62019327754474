import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Card } from '@src/components/Card/Card';
import { ActionType } from '@src/graphql/generated/graphql';
import { useActionTypeMap } from '@src/helpers/hooks/useActionTypeMap';
import { colors } from '@src/style/colors';
import { createTaskMutation } from '../Plan/PlanSection.query';
import { Text } from '../Text/Text';
import { getPlanGanttQuery } from '../Plan/PlanGantt.query';
import { useToast } from '@src/newComponents';
import { Overlay } from '../Overlay/Overlay';

interface TaskCreateModalProps {
  planId: string;
  planSectionId: string;
  onCreated?: (taskId: string | null) => void;
  visible?: boolean;
  onClose: () => void;
}

export const TaskCreateModal: React.FC<TaskCreateModalProps> = ({
  planId,
  planSectionId,
  onCreated,
  visible,
  onClose,
}) => {
  /* Vars */

  const { t } = useTranslation();
  const { createAsyncToast } = useToast();
  const actionTypeMap = useActionTypeMap();

  /* Queries */

  const [createTask] = useMutation(createTaskMutation, {
    refetchQueries: [getPlanGanttQuery],
  });

  const onActionTypeClick = async (actionType: ActionType) => {
    onClose();

    createAsyncToast(createNewTask(actionType));
  };

  /* Functions */

  const createNewTask = async (actionType: ActionType) => {
    const { data } = await createTask({
      variables: {
        planId,
        planSectionId,
        actionType,
      },
    });

    onCreated && onCreated(data?.createTask?.id || null);
  };

  return (
    visible && (
      <Overlay onClose={onClose}>
        <Modal onClick={(e) => e.stopPropagation()}>
          <Text size="2xl" weight="bold">
            {t('plan.taskAction.createModal.title')}
          </Text>

          <Grid>
            {Object.entries(actionTypeMap).map(
              ([actionType, { label, description, Icon }]) => (
                <ActionCard
                  key={actionType}
                  onClick={() => onActionTypeClick(actionType as ActionType)}
                  actionType={actionType as ActionType}
                >
                  <IconContainer>
                    {<Icon size="2xl" color={actionType as ActionType} />}
                  </IconContainer>
                  <Content>
                    <ActionTitle size="lg" weight="bold">
                      {label}
                    </ActionTitle>
                    <ActionDescription size="sm" color="dark800">
                      {description}
                    </ActionDescription>
                  </Content>
                </ActionCard>
              )
            )}
          </Grid>
        </Modal>
      </Overlay>
    )
  );
};

const Modal = styled(Card)`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: max-content;
  min-height: 20vh;

  display: flex;
  flex-direction: column;
  gap: 44px;
  padding: 56px 60px;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background-color: ${colors.dark25};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  grid-template-rows: repeat(2, minmax(200px, 1fr));
  grid-gap: 32px;
  place-items: stretch;
`;

const ActionCard = styled(Card)<{ actionType: ActionType }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  border: 1px solid ${(props) => colors[props.actionType]};

  &:hover {
    background-color: ${(props) => colors[props.actionType]}0F;
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: start;
  gap: 8px;
`;

const ActionTitle = styled(Text)`
  text-align: center;
`;

const ActionDescription = styled(Text)`
  text-align: center;
  text-wrap: pretty;
`;

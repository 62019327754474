import { Text } from '../Text/Text';
import { formatDate } from '@src/helpers/date';
import { getDueDateColor } from '@src/helpers/tasks';
import { t } from 'i18next';
import styled from 'styled-components';
import { Div } from '../Flex/Div/Div';
import { DatePickerField } from '../Form/DatePickerField';
import { CalendarIcon } from '../Icon/CalendarIcon';
import { Pill } from '../Pill/Pill';
import { Flex } from '../Flex/Flex';

type TaskStartDateFieldProps = {
  startDate?: string | null;
  isCustomer: boolean;
};

export const TaskStartDateField: React.FC<TaskStartDateFieldProps> = ({
  isCustomer,
  startDate,
}) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="center"
      gap="8px"
    >
      <Label size={'xs'} color={'dark600'}>
        {t('plan.taskDetails.startDate.label')}
      </Label>
      {isCustomer ? (
        <Div marginLeft={'-6px'}>
          <Pill
            Icon={CalendarIcon}
            color={getDueDateColor(startDate)}
            weight={'medium'}
            disabled={isCustomer}
          >
            {startDate ? formatDate(startDate) : '-'}
          </Pill>
        </Div>
      ) : (
        <DatePickerFieldWrapper>
          <DatePickerField
            name="startDate"
            renderer={(date, setDate) => (
              <Pill
                Icon={CalendarIcon}
                onClose={date ? () => setDate(null) : undefined}
                color={getDueDateColor(date)}
                weight={'medium'}
              >
                <DateText>
                  {date
                    ? formatDate(date)
                    : t('plan.taskDetails.dueDate.placeholder')}
                </DateText>
              </Pill>
            )}
          />
        </DatePickerFieldWrapper>
      )}
    </Flex>
  );
};

const Label = styled(Text.withComponent('label'))`
  text-wrap: nowrap;
`;
const DatePickerFieldWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;
const DateText = styled.div`
  text-wrap: nowrap;
`;

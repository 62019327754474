import { useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { removeSessionToken } from '../auth';

export function useLogout() {
  const navigate = useNavigate();
  const client = useApolloClient();

  return async () => {
    removeSessionToken();
    await client.clearStore();
    (window as any).$sleek.resetUser();
    navigate('/');
  };
}

import { Loader } from '@src/components/Loader/Loader';
import { useResourcesDashboard } from '../hooks/useResourcesDashboard';
import { Dashboard } from './Dashboard';

export const ResourcesDashboard = () => {
  const { url, loading } = useResourcesDashboard();

  if (loading) return <Loader />;
  if (!url) return null;
  return <Dashboard url={url} />;
};

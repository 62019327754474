import { Icon, IconComponent } from './index';

export const TargetIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
      >
        <g
          stroke="#5B5B5B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          clipPath="url(#a)"
        >
          <path d="M9.36 6.64 7.687 8.313M9.333 4v2.667H12L14.667 4l-1.778-.889L12 1.333 9.333 4Z" />
          <path d="M7.333 2.65a5.68 5.68 0 1 0 6.018 6.017" />
          <path d="M10.807 8.667a3.15 3.15 0 1 1-3.474-3.474" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
};

import { css } from 'styled-components';
import { colors } from '@style/colors';
import type { TextWeight } from '@style/typography';
import { fontWeights } from '@style/typography';
import { textSizeMap } from '../Text/Text';
import type { GhostInputSize } from './GhostInput';

export const baseInputStyle = css`
  // Reset
  box-sizing: border-box;
  outline: none;
  width: 100%;

  // Common styles
  transition: background-color 180ms ease-out, border-color 180ms ease-out;
  color: ${colors.dark900};
  &::placeholder {
    color: ${colors.dark500};
  }
`;

export const alignInputStyle = css<{ alignLeft?: boolean }>`
  ${({ alignLeft }) => alignLeft && `margin-left: -6px`}
`;

const DEFAULT_SIZE: GhostInputSize = 'normal';

export const baseGhostInputStyle = css<{ $size?: GhostInputSize }>`
  ${baseInputStyle};
  ${alignInputStyle};

  border: none;
  background: none;
  ${({ $size = DEFAULT_SIZE }) => `
    padding: ${SizeStyles[$size].padding};
    font-size: ${textSizeMap[SizeStyles[$size].textSize].fontSize}px;
    font-weight: ${fontWeights[SizeStyles[$size].textWeight]};
    line-height: ${textSizeMap[SizeStyles[$size].textSize].lineHeight}px;
    border-radius: ${SizeStyles[$size].borderRadius};
  `}
  &:hover,
  &:active,
  &:focus {
    background-color: ${colors.dark25};
  }

  &:disabled {
    background: none;
  }
`;

const SizeStyles: Record<
  GhostInputSize,
  {
    padding: string;
    textSize: keyof typeof textSizeMap;
    textWeight: TextWeight;
    borderRadius: string;
  }
> = {
  xsmall: {
    padding: '6px',
    textSize: 'xs',
    textWeight: 'medium',
    borderRadius: '6px',
  },
  small: {
    padding: '6px',
    textSize: 'sm',
    textWeight: 'medium',
    borderRadius: '8px',
  },
  normal: {
    padding: '6px',
    textSize: 'base',
    textWeight: 'semibold',
    borderRadius: '12px',
  },
  big: {
    padding: '8px',
    textSize: '2xl',
    textWeight: 'bold',
    borderRadius: '12px',
  },
};

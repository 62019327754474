import type { ReactNode } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';
import styled from 'styled-components';
import type { FlexGrowProps } from 'styled-system';

type FormProps<T extends FieldValues> = {
  methods: UseFormReturn<T>;
  onSubmit: (data: T) => void;
  children: ReactNode | ((methods: UseFormReturn<T>) => ReactNode);
} & FlexGrowProps;

export function Form<T extends FieldValues>({
  children,
  onSubmit,
  methods,
  flexGrow,
}: FormProps<T>) {
  return (
    <FormProvider {...methods}>
      <StyledForm
        onSubmit={methods.handleSubmit(onSubmit)}
        $flexGrow={flexGrow}
      >
        {typeof children === 'function' ? children(methods) : children}
      </StyledForm>
    </FormProvider>
  );
}

const StyledForm = styled.form<{ $flexGrow?: FlexGrowProps['flexGrow'] }>`
  flex-grow: ${(props) => props.$flexGrow};
`;

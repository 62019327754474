import styled from 'styled-components';
import {
  fontStyle,
  type FontStyleProps,
  maxWidth,
  type MaxWidthProps,
  space,
  type SpaceProps,
  textAlign,
  type TextAlignProps,
} from 'styled-system';
import type { Colors } from '@style/colors';
import { colors } from '@style/colors';
import type { TextWeight } from '@style/typography';
import { fontWeights } from '@style/typography';

export const TextSizes = ['2xl', 'xl', 'lg', 'base', 'sm', 'xs'] as const;
export type TextSize = (typeof TextSizes)[number];
const DEFAULT_TEXT_SIZE: TextSize = 'base';

const DEFAULT_TEXT_WEIGHT = 'regular';

export type TextProps = {
  size?: TextSize;
  weight?: TextWeight;
  color?: Colors;
  inline?: boolean;
  strikethrough?: boolean;
  noWrap?: boolean;
};

export const Text = styled.div<
  TextProps & SpaceProps & FontStyleProps & MaxWidthProps & TextAlignProps
>`
  ${({ size = DEFAULT_TEXT_SIZE }) => `
    font-size: ${textSizeMap[size].fontSize}px;
    line-height: ${textSizeMap[size].lineHeight}px;
  `}
  ${({ weight = DEFAULT_TEXT_WEIGHT }) => `
    font-weight: ${fontWeights[weight]};
  `}
  ${({ color }) =>
    color &&
    `
    color: ${colors[color]};
  `}
  ${({ strikethrough }) => strikethrough && `text-decoration: line-through;`}
  ${({ inline }) => inline && `display: inline-block;`}
  ${({ noWrap }) => noWrap && `white-space: nowrap;`}
  ${space};
  ${fontStyle};
  ${maxWidth}
  ${textAlign}
`;

export const textSizeMap: Record<
  TextSize,
  { fontSize: number; lineHeight: number }
> = {
  '2xl': {
    fontSize: 24,
    lineHeight: 32,
  },
  xl: {
    fontSize: 20,
    lineHeight: 28,
  },
  lg: {
    fontSize: 18,
    lineHeight: 28,
  },
  base: {
    fontSize: 16,
    lineHeight: 24,
  },
  sm: {
    fontSize: 14,
    lineHeight: 20,
  },
  xs: {
    fontSize: 12,
    lineHeight: 16,
  },
};

type Env = 'development' | 'production' | 'staging';

export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;
export const GRAPHQL_URL = BACKEND_URL + '/graphql';
export const SECURE_BACKEND = BACKEND_URL?.startsWith('https://');
export const HOTJAR_HJID = import.meta.env.VITE_HOTJAR_HJID;
export const ENV: Env = import.meta.env.VITE_ENV;
export const PROD = import.meta.env.PROD;
export const CRISP_WEBSITE_ID = import.meta.env.VITE_CRISP_WEBSITE_ID;
export const PENDO_API_KEY = import.meta.env.VITE_PENDO_API_KEY;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const DASHBOARD_EMBED_ORIGIN = import.meta.env
  .VITE_DASHBOARD_EMBED_ORIGIN;
export const SYNCFUSION_LICENSE_KEY = import.meta.env
  .VITE_SYNCFUSION_LICENSE_KEY;

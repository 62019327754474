import { useMutation } from '@apollo/client';
import { GetTaskQuery } from '@graphql/generated/graphql';
import { colors } from '@style/colors';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatLink } from '../../helpers/utils';
import { Asset } from '../Asset/Asset';
import { Button } from '../Button/Button';
import { TransparentButton } from '../Button/TransparentButton';
import { Flex } from '../Flex/Flex';
import { GlobeIcon } from '../Icon/GlobeIcon';
import { GhostInput } from '../Input/GhostInput';
import { getPlanResourcesQuery } from '../Plan/PlanResources.query';
import { PlusCircle } from '../PlusCircle';
import { Popup } from '../Popup/Popup';
import { Text } from '../Text/Text';
import { toastError } from '../Toast/toast';
import {
  createTaskLinkMutation,
  deleteTaskLinkMutation,
} from './TaskDetails.query';
import { UrlEmbed } from '../UrlEmbed';
import { URLPreview } from '../UrlPreview';

interface TaskLinksProps {
  planId: string;
  taskId: string;
  links: GetTaskQuery['task']['links'];
  isCustomer?: boolean;
  isActionType: boolean;
}

export const TaskLinks = ({
  planId,
  taskId,
  links,
  isCustomer,
  isActionType,
}: TaskLinksProps) => {
  const { t } = useTranslation();
  const [deleteTaskLink] = useMutation(deleteTaskLinkMutation, {
    refetchQueries: [
      {
        query: getPlanResourcesQuery,
        variables: { planId },
        context: {
          headers: {
            ...(isCustomer && { 'X-Preview': true }),
          },
        },
      },
    ],
  });
  const onDelete = async (linkId: string) => {
    try {
      await deleteTaskLink({
        variables: {
          linkId,
        },
      });
    } catch (e) {
      toastError(t('plan.taskDetails.links.deleteError'));
    }
  };

  return (
    <Flex flexDirection={'column'} alignItems={'flex-start'} gap={'8px'}>
      <Text size={'xs'} color={'dark600'}>
        {t('plan.taskDetails.links.label')}
      </Text>
      {links.length > 0 && (
        <Flex
          flexDirection={'column'}
          alignSelf={'stretch'}
          ml={'-6px'}
          mb={'8px'}
          gap={'8px'}
        >
          {links.map((link) => (
            <Asset
              key={link.id}
              icon={GlobeIcon}
              title={link.description}
              rightText={link.url}
              href={link.url}
              handleRemoveClick={
                isCustomer ? undefined : () => onDelete(link.id)
              }
            />
          ))}
        </Flex>
      )}

      {!isCustomer && (!isActionType || !links.length) && (
        <Popup
          trigger={
            <TransparentButton type={'button'} icon={<PlusCircle />} mt={'4px'}>
              <Text size={'sm'}>
                {t('plan.taskDetails.links.addButton.label')}
              </Text>
            </TransparentButton>
          }
        >
          {(close) => (
            <PopupOpenedContainer>
              <LinkForm planId={planId} taskId={taskId} close={close} />
            </PopupOpenedContainer>
          )}
        </Popup>
      )}

      {isActionType && links.length === 1 && (
        <UrlDisplayContainer>
          <URLPreview url={links[0].url} />
          <UrlEmbedContainer>
            <UrlEmbed url={links[0].url} description={links[0].description} />
          </UrlEmbedContainer>
        </UrlDisplayContainer>
      )}
    </Flex>
  );
};

const PopupOpenedContainer = styled.div`
  width: max-content;
`;

const UrlDisplayContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const UrlEmbedContainer = styled.div`
  display: flex;
  place-content: center;
`;

const LinkForm = ({
  planId,
  taskId,
  close,
}: {
  planId: string;
  taskId: string;
  close: () => void;
}) => {
  const { t } = useTranslation();
  const [createTaskLink] = useMutation(createTaskLinkMutation, {
    refetchQueries: [
      {
        query: getPlanResourcesQuery,
        variables: { planId },
      },
    ],
  });
  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const isValid = url.length > 0 && description.length > 0;
  const onSubmit = useCallback(async () => {
    if (isValid) {
      return await createTaskLink({
        variables: {
          taskId,
          input: {
            url,
            description,
          },
        },
        onCompleted: () => close(),
      });
    }
  }, [url, description]);

  return (
    <>
      <Flex
        flexDirection={'column'}
        padding={'6px'}
        gap={'4px'}
        borderBottom={`1px solid ${colors.dark100}`}
      >
        <GhostInput
          size={'small'}
          value={url}
          onChange={(e) => setUrl(formatLink(e.currentTarget.value))}
          placeholder={'https://example.com'}
        />
        <GhostInput
          size={'small'}
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
          placeholder={'Add a description'}
        />
      </Flex>
      <Flex padding={'6px'} flexDirection={'column'}>
        <Button
          type={'submit'}
          disabled={!isValid}
          onClick={async (e) => {
            e.preventDefault(); // Prevent parent form from submitting
            await onSubmit();
          }}
        >
          {t('plan.taskDetails.links.form.submit.label')}
        </Button>
      </Flex>
    </>
  );
};

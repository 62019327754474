import { Icon, IconComponent } from './index';

export const FoldersIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
      >
        <g
          stroke="#5B5B5B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          clipPath="url(#a)"
        >
          <path d="M12.618 14.546H3.382a1.454 1.454 0 0 1-1.447-1.31L1.462 8.51a1.455 1.455 0 0 1 1.447-1.6h10.182a1.454 1.454 0 0 1 1.447 1.6l-.473 4.727a1.454 1.454 0 0 1-1.447 1.31ZM5.09 1.454h5.82M3.636 4.182h8.728" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
};

import { createGlobalStyle } from 'styled-components';
import { colors } from './colors';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    font-family: 'Inter', sans-serif;
    background-color: ${colors.dark50};
    color: ${colors.primary}; 
    
  }
  

  input, textarea {
    font-family: 'Inter', sans-serif;
  }
  
  a {
    text-decoration: none;
    color: ${colors.primary};
  }

  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .gantt-marker {
    border-color: ${colors.secondary} !important;
    border-width: 2px !important;
  }
  // Gantt's hscroll arrows don't work
  div[id^=hscroll_][id$=_nav_left], div[id^=hscroll_][id$=_nav_right] {
    display: none;
  }

  p {
    margin: 0;
  }

`;

export const CONTENT_WIDTH = 800;

import { useQuery } from '@apollo/client';
import { PageLayout } from '@components/PageLayout';
import { useMemo, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getActivities } from './activities.query';
import { getActivitiesGroupedByDay } from './helpers/getActivitiesGroupedByDay';
import { TimelineActivities } from '@src/components/TimelineActivities';
import { Loader } from '@src/components/Loader/Loader';
import { Text } from '@src/components/Text/Text';
import type { Activity } from '@src/graphql/generated/graphql';

export const ActivitiesPage: FC = () => {
  /* Vars */

  const { data, loading } = useQuery(getActivities, {
    fetchPolicy: 'no-cache',
  });
  const { t } = useTranslation();

  /* Memos */

  const activitiesGrouped = useMemo(() => {
    if (data?.activities) {
      return getActivitiesGroupedByDay(data.activities as Activity[]);
    }

    return [];
  }, [data]);

  return (
    <PageLayout withTopBar>
      <Text size="2xl" weight="bold" marginBottom={24}>
        {t('activities.title')}
      </Text>

      {loading ? (
        <Loader centered />
      ) : (
        <TimelineActivities activities={activitiesGrouped} />
      )}
    </PageLayout>
  );
};

import { colors } from '@src/style/colors';
import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

interface ButtonProps extends React.HTMLAttributes<HTMLSpanElement> {
  active: boolean;
  reversed?: boolean;
}

export const Button = React.forwardRef<
  HTMLSpanElement,
  PropsWithChildren<ButtonProps>
>(({ className, active, reversed, children, ...props }, ref) => (
  <StyledButton active={active} reversed={reversed} {...props} ref={ref}>
    {children}
  </StyledButton>
));

const StyledButton = styled.span<ButtonProps>`
  ${({ active, reversed }) => css`
    cursor: pointer;
    color: ${reversed
      ? active
        ? 'white'
        : colors.dark300
      : active
      ? 'black'
      : colors.dark600};
  `}
`;

export const Icon = React.forwardRef<
  HTMLSpanElement,
  PropsWithChildren<React.HTMLAttributes<HTMLSpanElement>>
>(({ className, children, ...props }, ref) => (
  <StyledIcon className={`material-symbols-outlined`} {...props} ref={ref}>
    {children}
  </StyledIcon>
));

const StyledIcon = styled.span`
  font-size: 18px;
  vertical-align: text-bottom;
`;

const Menu = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
>(({ children, ...props }, ref) => (
  <StyledMenu {...props} ref={ref}>
    {children}
  </StyledMenu>
));

const StyledMenu = styled.div`
  & > * {
    display: inline-block;
  }

  & > * + * {
    margin-left: 15px;
  }
`;

export const Toolbar = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
>(({ children, ...props }, ref) => (
  <StyledToolbar {...props} ref={ref}>
    {children}
  </StyledToolbar>
));

export const StyledToolbar = styled(Menu)`
  padding: 10px 18px 10px;
  border-top: 2px solid #eee;
  border-bottom: 2px solid #eee;
  margin-bottom: 20px;
`;

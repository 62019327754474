import { useMutation } from '@apollo/client';
import { Form } from '@components/Form/Form';
import { FilePurpose } from '@graphql/generated/graphql';
import { useFileUpload } from '@graphql/hooks/useFileUpload';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUserContext } from '@src/contexts/UserContext/useUserContext';
import { requiredRule } from '@src/validation/rules';
import { type TFunction } from 'i18next';
import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { z } from 'zod';
import { Avatar } from './Avatar/Avatar';
import { Button } from './Button/Button';
import { Flex } from './Flex/Flex';
import { GhostTextareaField } from './Form/GhostTextareaField';
import { InputField } from './Form/InputField';
import { Loader } from './Loader/Loader';
import { updateProfileMutation } from './ProfileForm.query';
import { toastError, toastSuccess } from './Toast/toast';

type FormValues = {
  firstName: string;
  lastName: string;
  calendarUrl: string;
  introduction: string;
};

const getValidationSchema = (t: TFunction) =>
  z.object({
    firstName: requiredRule(t),
    lastName: requiredRule(t),
    calendarUrl: requiredRule(t),
    introduction: requiredRule(t).max(500, t('validation.number.max-500')),
  });

export const ProfileForm: FC = () => {
  const { t } = useTranslation();
  const user = useUserContext();
  const [updateProfile, { loading: updateProfileLoading }] = useMutation(
    updateProfileMutation
  );
  const schema = useMemo(() => getValidationSchema(t), [t]);
  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
    defaultValues: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      calendarUrl: user?.calendarUrl ?? '',
      introduction: user?.introduction ?? '',
    },
  });

  const { getRootProps, getInputProps, isDragActive, loading } = useFileUpload({
    purpose: FilePurpose.UserAvatar,
    accept: { 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] },
    onFileUpload: async (fileId) => {
      try {
        await updateProfile({ variables: { input: { avatarFileId: fileId } } });
        toastSuccess(t('profileForm.updateProfile.success'));
      } catch (e) {
        toastError(t('profileForm.updateProfile.error'));
      }
    },
  });

  const handleSubmit = async ({
    firstName,
    lastName,
    calendarUrl,
    introduction,
  }: FormValues) =>
    await updateProfile({
      variables: { input: { firstName, lastName, calendarUrl, introduction } },
    });

  return (
    <div>
      <div {...getRootProps()}>
        <AvatarContainer isDragActive={isDragActive} loading={loading}>
          <input {...getInputProps()} />
          <Avatar user={user} size="large" />
          {loading && (
            <LoadingContainer>
              <Loader />
            </LoadingContainer>
          )}
        </AvatarContainer>
      </div>
      <Form onSubmit={handleSubmit} methods={methods}>
        <Flex
          width={'100%'}
          flexDirection={'column'}
          gap="16px"
          marginTop="16px"
          alignContent="center"
        >
          <div>
            <InputField
              required
              name="firstName"
              placeholder={t('form.firstName.placeholder')}
              size={'small'}
            />
          </div>
          <div>
            <InputField
              required
              name="lastName"
              placeholder={t('form.lastName.placeholder')}
              size={'small'}
            />
          </div>
          <div>
            <InputField
              required
              name="calendarUrl"
              placeholder={t('form.calendarUrl.placeholder')}
              size={'small'}
            />
          </div>
          <div>
            <GhostTextareaField
              size="small"
              name="introduction"
              placeholder={t('settings.users.introduction.default')}
              shouldResize
              moreContent
            />
          </div>
          <Button
            type="submit"
            variant="primary"
            size="small"
            loading={updateProfileLoading}
          >
            {t('settings.profileForm.submitButton.label')}
          </Button>
        </Flex>
      </Form>
    </div>
  );
};

const AvatarContainer = styled.div<{
  isDragActive: boolean;
  loading: boolean;
}>`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  ${(props) =>
    (props.isDragActive || props.loading) &&
    css`
      &:after {
        content: '';
        position: absolute;
        inset: 0;
        background-color: rgba(255, 255, 255, 0.5);
      }
    `}
`;

const LoadingContainer = styled.div`
  position: absolute;
`;

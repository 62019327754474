import { useMutation, useQuery } from '@apollo/client';
import { Button, LinkButton } from '@components/Button/Button';
import { CompanyLogo } from '@components/CompanyLogo/CompanyLogo';
import {
  Dialog,
  DialogClose,
  DialogConfirm,
  DialogContent,
  DialogDescription,
  DialogHeading,
} from '@components/Dialog/Dialog';
import { Divider } from '@components/Divider/Divider';
import { DropButton } from '@components/Drop/DropButton';
import { DropCard } from '@components/Drop/DropCard';
import { Div } from '@components/Flex/Div/Div';
import { Flex } from '@components/Flex/Flex';
import { DotIcon } from '@components/Icon/DotIcon';
import { TripleDotsIcon } from '@components/Icon/TripleDotsIcon';
import { Loader } from '@components/Loader/Loader';
import { Popup } from '@components/Popup/Popup';
import { TargetDate } from '@components/TargetDate';
import { Text } from '@components/Text/Text';
import { UnstyledLink } from '@components/UnstyledLink';
import { ListActivePlansQuery } from '@graphql/generated/graphql';
import { useWorkspaceRouting } from '@graphql/hooks/useWorkspaceRouting';
import { Avatar } from '@src/components/Avatar/Avatar';
import { CalendarIcon } from '@src/components/Icon/CalendarIcon';
import { computeStateColor } from '@src/components/Plan/Infos/Progression/Progression';
import { ProgressionNumber } from '@src/components/ProgressionNumbers/ProgressionNumber';
import { formatDate } from '@src/helpers/date';
import { getDueDateColor } from '@src/helpers/tasks';
import { PlanStatusPill } from '@src/pages/Plan/components/PlanStatusPill';
import { colors } from '@style/colors';
import { t } from 'i18next';
import type { FC } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { deletePlanMutation, listActivePlansQuery } from './ActivePlans.query';

export const ActivePlans: FC = () => {
  const { t } = useTranslation();
  const { pathTo } = useWorkspaceRouting();
  const { data, loading } = useQuery(listActivePlansQuery);
  const hasCreatedPlans = data && data.workspace.plans.length > 0;
  const plans = data?.workspace.plans ?? [];

  return (
    <Div paddingY={'24px'}>
      <Flex alignItems="center" justifyContent="space-between" mb="12px">
        <Text size="xl" weight="semibold">
          {t('dashboard.activePlans')}
        </Text>
        <LinkButton to={pathTo('/plans/new')} data-pendo="new-plan-button">
          {t('dashboard.newPlan')}
        </LinkButton>
      </Flex>
      <Flex
        flexDirection="column"
        data-pendo={hasCreatedPlans ? '' : 'no-plan-created'}
      >
        {loading && <Loader />}
        {plans.map((plan) => (
          <ActivePlan key={plan.id} plan={plan} />
        ))}
      </Flex>
    </Div>
  );
};

const DeletePlanModal: FC<{
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onDelete: (e: React.MouseEvent) => void;
}> = ({ isOpen, onDelete, onOpenChange }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeading mt="8px" mx="24px">
          {t('dashboard.activePlans.dropdown.deleteModal.title')}
        </DialogHeading>
        <DialogDescription my="6px" mx="24px">
          <Trans
            i18nKey="dashboard.activePlans.dropdown.deleteModal.subtitle"
            components={{ 1: <br /> }}
          />
        </DialogDescription>
        <Divider my="8px" />
        <Flex my="6px" mx="24px" justifyContent="center" gap="16px">
          <DialogClose>
            {t('dashboard.activePlans.dropdown.deleteModal.cancel.label')}
          </DialogClose>
          <DialogConfirm onConfirm={(e) => onDelete(e)}>
            {t('dashboard.activePlans.dropdown.deleteModal.confirm.label')}
          </DialogConfirm>
        </Flex>
      </DialogContent>
    </Dialog>
  );
};

const ActivePlan: FC<{
  plan: ListActivePlansQuery['workspace']['plans'][number];
}> = ({ plan }) => {
  const navigate = useNavigate();
  const { pathTo } = useWorkspaceRouting();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [deletePlan] = useMutation(deletePlanMutation, {
    variables: { id: plan.id },
  });

  return (
    <>
      <Container to={pathTo(`/plans/${plan.id}`)} data-pendo="plan-link">
        <DeletePlanModal
          isOpen={isDeleteModalOpen}
          onOpenChange={setIsDeleteModalOpen}
          onDelete={async (e) => {
            e.preventDefault();
            await deletePlan();
            setIsDeleteModalOpen(false);
          }}
        />
        <Flex alignItems="center" gap="16px">
          <CompanyLogo
            name={plan.customer.name}
            logo={plan.customer.logo}
            size={40}
          />
          <Flex flexDirection="column" gap="2px">
            <Text weight="bold">{plan.title}</Text>
            <Flex marginLeft={-1} alignItems="center" gap="12px">
              <PlanStatusPill status={plan.status} />
              <DotIcon size="small" color="dark200" />
              <Flex alignItems={'center'} gap={'6px'}>
                <ProgressionNumber
                  size={30}
                  percent={plan.completion.completionRate}
                  done={plan.completion.completedTasks}
                  total={plan.completion.totalTasks}
                />
                <Text
                  noWrap
                  size="sm"
                  weight="medium"
                  color={computeStateColor(plan.completion.completionRate)}
                >
                  {t('progression.text', {
                    percentage: plan.completion.completionRate,
                  })}
                </Text>
              </Flex>
              <DotIcon size="small" color="dark200" />
              <TargetDate date={plan.endDate} />
              <DotIcon size="small" color="dark200" />
              <TextWithEllipsis size="sm" color="dark700">
                {t('dashboard.activePlans.details.owner')}:{' '}
                {plan.owner.displayName || plan.owner.email}
              </TextWithEllipsis>
            </Flex>
            <Flex alignItems={'center'} gap="4px">
              {!plan.nextTask && (
                <Text size="sm" color="dark700">
                  No next task
                </Text>
              )}
              {plan.nextTask && (
                <>
                  <Text size="sm" color="dark700">
                    {t('dashboard.activePlans.details.nextTask')}:{' '}
                    {plan.nextTask?.title || 'No title'}
                  </Text>
                  <DotIcon size="small" color="dark200" />
                  <Flex alignItems={'center'} gap={'6px'}>
                    <CalendarIcon
                      color={getDueDateColor(plan.nextTask.computedDueDate)}
                    />
                    <Text
                      size="sm"
                      color={getDueDateColor(plan.nextTask.computedDueDate)}
                    >
                      {formatDate(plan.nextTask.computedDueDate)}
                    </Text>
                  </Flex>
                  <DotIcon size="small" color="dark200" />
                  {plan.nextTask?.assignees.map((assignee, index) => (
                    <Avatar key={index} user={assignee} />
                  ))}
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Popup
          onOpenChange={setIsMenuOpen}
          trigger={
            <StyledTrigger
              isOpen={isMenuOpen}
              onClick={(e) => {
                e.preventDefault();
              }}
              type={'button'}
              size={'small'}
              icon={<TripleDotsIcon />}
            />
          }
        >
          <DropCard minWidth={145}>
            <DropButton
              onClick={(e) => {
                e.preventDefault();
                navigate(pathTo(`/plans/${plan.id}/duplicate`));
              }}
            >
              {t('dashboard.activePlans.menu.duplicatePlan.label')}
            </DropButton>
            <DropButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsMenuOpen(false);
                setIsDeleteModalOpen(true);
              }}
            >
              {t('dashboard.activePlans.menu.deletePlan.label')}
            </DropButton>
          </DropCard>
        </Popup>
      </Container>
      <Divider my="8px" />
    </>
  );
};

const Container = styled(UnstyledLink)`
  padding: 16px;
  margin: 0 -16px;
  border-radius: 16px;
  transition: background-color 180ms ease-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  &:hover {
    background-color: ${colors.dark50};
  }
`;

const StyledTrigger = styled(Button)<{ isOpen: boolean }>`
  opacity: 0;
  color: ${colors.dark900};
  ${({ isOpen }) => isOpen && `opacity:1;`}
  ${Container}:hover & {
    opacity: 1;
  }
`;

const TextWithEllipsis = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

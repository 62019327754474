import { graphql } from '@graphql/generated';

export const getActivities = graphql(`
  query getActivities {
    activities {
      createdAt
      type
      params {
        task_title
        plan_title
        status
        user {
          displayName
          avatarUrl
          email
        }
      }
    }
  }
`);

import { FC, useEffect } from 'react';

type ScriptProps = {
  content: string;
  onLoad?: (loaded: boolean) => void;
};
export const Script: FC<ScriptProps> = ({ content, ...props }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = content;
    document.body.appendChild(script);
    props?.onLoad?.(true);
    return () => {
      document.body.removeChild(script);
      props?.onLoad?.(false);
    };
  }, [content]);

  return null;
};

import { FC, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { Flex } from '../Flex/Flex';
import { Text } from '../Text/Text';
import { Point } from './Point';
import { Avatar } from '../Avatar/Avatar';
import { type Activity, ActivityType } from '@graphql/generated/graphql';
import { formatTime } from '@src/helpers/date';
import { colors } from '@src/style/colors';

interface ActivityComponentProps {
  activity: Activity;
}

export const ActivityComponent: FC<ActivityComponentProps> = ({ activity }) => {
  /* Vars */

  const { t } = useTranslation();

  /* Memos */

  const activityI18nKey = useMemo(() => {
    switch (activity.type) {
      case ActivityType.TaskStatus:
        return `activities.task.status.${activity.params?.status}`;

      case ActivityType.TaskComment:
        return 'activities.task.comment';

      case ActivityType.TaskAddFile:
        return 'activities.task.file';

      case ActivityType.TaskReminder:
        return 'activities.task.reminder';

      case ActivityType.PlanStatus:
        return `activities.plan.${activity.params?.status}`;

      default:
        return '';
    }
  }, [activity]);

  return (
    <Flex
      gap="16px"
      position="relative"
      borderWidth={1}
      borderColor={colors.dark100}
      borderStyle="solid"
      alignItems="center"
      padding={10}
      borderRadius={6}
    >
      <Point activity={activity} />

      <Avatar
        // @ts-expect-error - user is optional
        user={activity.params?.user}
        size="medium"
      />

      <Text style={{ flex: 1 }}>
        <Trans
          t={t}
          // @ts-expect-error - Dynamic i18n key
          i18nKey={activityI18nKey}
          values={{
            taskTitle: activity.params?.task_title,
            planTitle: activity.params?.plan_title,
            displayName: `${activity.params?.user?.displayName}`,
          }}
        />
      </Text>

      <Text color="dark400" size="xs">
        {formatTime(activity.createdAt)}
      </Text>
    </Flex>
  );
};

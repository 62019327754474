import React, { useCallback, useImperativeHandle, useMemo } from 'react';
import {
  Editable,
  withReact,
  useSlate,
  Slate,
  type RenderElementProps,
  type RenderLeafProps,
} from 'slate-react';
import {
  Editor,
  Transforms,
  createEditor,
  type Descendant,
  Element as SlateElement,
  type BaseEditor,
} from 'slate';
import { withHistory } from 'slate-history';

import { Button, Icon, Toolbar } from './components';
import styled from 'styled-components';
import { Text } from '../Text/Text';
import { fontWeights } from '@src/style/typography';
import { colors } from '@src/style/colors';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

export type RichTextEditorRef = {
  resetForm: () => void;
};

type RichTextEditorProps = {
  initialValue?: Descendant[];
  readOnly: boolean;
  onValueChange?: (value: Descendant[]) => void;
  editorRef?: React.RefObject<RichTextEditorRef>;
};

export const RichTextEditor = ({
  initialValue = [
    {
      children: [{ text: '' }],
    },
  ],
  readOnly,
  onValueChange,
  editorRef,
}: RichTextEditorProps) => {
  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    []
  );
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  );
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  useImperativeHandle(
    editorRef,
    () => {
      return {
        resetForm: () => {
          editor.children = initialValue;
        },
      };
    },
    []
  );
  return (
    <Slate
      onValueChange={onValueChange}
      editor={editor}
      initialValue={initialValue}
    >
      {!readOnly && (
        <Toolbar>
          <MarkButton format="bold" icon="format_bold" />
          <MarkButton format="italic" icon="format_italic" />
          <MarkButton format="underline" icon="format_underlined" />
          <MarkButton format="code" icon="code" />
          <BlockButton format="heading-one" icon="looks_one" />
          <BlockButton format="heading-two" icon="looks_two" />
          <BlockButton format="block-quote" icon="format_quote" />
          <BlockButton format="numbered-list" icon="format_list_numbered" />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" />
          <BlockButton format="left" icon="format_align_left" />
          <BlockButton format="center" icon="format_align_center" />
          <BlockButton format="right" icon="format_align_right" />
          <BlockButton format="justify" icon="format_align_justify" />
        </Toolbar>
      )}
      <StyledEditor
        itemRef="div"
        rows={5}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck
        readOnly={readOnly}
      />
    </Slate>
  );
};

const StyledEditor = styled(Editable)<{ readOnly: boolean }>`
  padding: 15px;
  resize: none;
  min-height: 100px;
  outline: none;
  background-color: ${colors.dark50};
  ${({ readOnly }) =>
    readOnly
      ? `
    min-height: 0;
    border: solid 1px ${colors.dark100};
    // background-color: ${colors.white};
    background-color: ${colors.dark25};
    border-radius: 12px;
  `
      : ''}
  font-weight: ${fontWeights.regular};

  & bold {
    font-weight: ${fontWeights.bold};
  }

  & code {
    font-family: monospace;
    background-color: ${colors.dark200};
    padding: 0 3px;
    border-radius: 3px;
  }

  & p {
    margin: 0;
  }

  & ul,
  ol {
    margin: 0;
    padding-left: 40px;
  }

  & ul li {
    list-style: disc;
  }

  & ol li {
    list-style: decimal;
  }

  & blockquote {
    padding: 15px;
    & > span {
      padding: 15px;
      background: #eee;
      border-radius: 5px;
    }
  }
`;

const toggleBlock = (editor: any, format: string) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      //@ts-expect-error bad types
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: any;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor: BaseEditor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (
  editor: BaseEditor,
  format: string,
  blockType = 'type'
) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        //@ts-expect-error bad types
        n[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor: BaseEditor, format: string) => {
  const marks = Editor.marks(editor);
  //@ts-expect-error bad types
  return marks ? marks[format] === true : false;
};

//@ts-expect-error bad types
const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <Text size="xl" style={style} {...attributes}>
          {children}
        </Text>
      );
    case 'heading-two':
      return (
        <Text size="lg" style={style} {...attributes}>
          {children}
        </Text>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <Text size="sm" style={style} {...attributes}>
          {children}
        </Text>
      );
  }
};

const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }: any) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      )}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const MarkButton = ({ format, icon }: any) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { GetPlanQuery } from '@graphql/generated/graphql';
import { Error } from '../Error/Error';
import { Loader } from '../Loader/Loader';
import { TaskDetails } from './TaskDetails';
import { getTaskQuery } from './TaskDetails.query';

interface TaskPanelProps {
  selectedTaskId: string;
  sections: Pick<GetPlanQuery['plan']['sections'][number], 'id' | 'title'>[];
  closeTaskPanel: () => void;
  isCustomer?: boolean;
}

export const TaskPanel = ({
  selectedTaskId,
  sections,
  closeTaskPanel,
  isCustomer,
}: TaskPanelProps) => {
  const {
    data,
    loading,
    error,
    refetch: refetchTask,
  } = useQuery(getTaskQuery, {
    variables: {
      id: selectedTaskId,
    },
  });

  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        closeTaskPanel();
      }
    });
    return () => {
      window.removeEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
          closeTaskPanel();
        }
      });
    };
  }, []);

  if (loading) {
    return <Loader centered />;
  }

  if (error || !data?.task) {
    return <Error />;
  }

  const task = data.task;

  return (
    <TaskDetails
      task={task}
      sections={sections}
      closeTaskPanel={closeTaskPanel}
      isCustomer={isCustomer}
      refetchTask={refetchTask}
    />
  );
};

import { Icon, IconComponent } from './index';

export const BellIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.79517 17.5C8.38276 18.0187 9.15468 18.3333 10 18.3333C10.8454 18.3333 11.6173 18.0187 12.2048 17.5M15 6.66666C15 5.34058 14.4733 4.06881 13.5356 3.13113C12.5978 2.19345 11.3261 1.66666 10 1.66666C8.67393 1.66666 7.40218 2.19345 6.4645 3.13113C5.52681 4.06881 5.00003 5.34058 5.00003 6.66666C5.00003 9.24183 4.35042 11.005 3.62475 12.1712C3.01264 13.1549 2.70658 13.6467 2.7178 13.784C2.73023 13.9359 2.76241 13.9938 2.88484 14.0847C2.99541 14.1667 3.49385 14.1667 4.49074 14.1667H15.5093C16.5062 14.1667 17.0047 14.1667 17.1152 14.0847C17.2377 13.9938 17.2698 13.9359 17.2823 13.784C17.2935 13.6467 16.9874 13.1549 16.3753 12.1712C15.6496 11.005 15 9.24183 15 6.66666Z"
          stroke="#5B5B5B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
};

import { Icon, IconComponent } from './index';

export const StickerCheckMarkIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
      >
        <g
          stroke="#262626"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          clipPath="url(#a)"
        >
          <path d="M8.667 14H5.333A3.333 3.333 0 0 1 2 10.667V5.333A3.333 3.333 0 0 1 5.333 2h5.334A3.333 3.333 0 0 1 14 5.333v3.334A5.333 5.333 0 0 1 8.667 14Z" />
          <path d="M14 6.667v.667a1.333 1.333 0 0 1-1.333 1.333h-1.334a2.667 2.667 0 0 0-2.666 2.667v1.333A1.333 1.333 0 0 1 7.333 14h-.666M7.333 5.333 5.667 7l-1-1" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
};

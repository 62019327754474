import { CSSProperties, useMemo, type FC } from 'react';
import clsx from 'clsx';

import { Typography } from '../../atoms';
import { generateHSL, HSLtoString } from '@src/helpers/colors';

interface AvatarProps {
  picture?: string | null;
  fullName?: string | null;
  email?: string | null;
  size?: number;
  isBorder?: boolean;
  onChange?: (file: File) => void;
  className?: string;
  style?: CSSProperties;
}

export const Avatar: FC<AvatarProps> = ({
  picture,
  size = 48,
  isBorder,
  fullName,
  email,
  onChange,
  className,
  style,
}) => {
  /* Vars */

  const names = (fullName || '').split(' ');
  const initials = `${names[0][0]}${names[names.length - 1][0]}`.toUpperCase();

  /* Memos */

  const backgroundStyle = useMemo(() => {
    if (picture) {
      return { backgroundImage: `url(${picture})` };
    }

    return { backgroundColor: HSLtoString(generateHSL(email || '')) };
  }, [email, picture]);

  return (
    <div
      style={{ width: size, height: size, ...backgroundStyle, ...style }}
      className={clsx(
        'rounded-full bg-cover flex items-center justify-center relative -mt-[1px]',
        {
          ['border border-black-opacity-3']: isBorder || !picture,
        },
        className
      )}
    >
      {!picture && (
        <Typography
          size={size < 48 ? 'xs' : 'lg'}
          fontWeight={size < 56 ? 'medium' : 'bold'}
          className="text-white"
        >
          {initials}
        </Typography>
      )}

      {onChange && (
        <input
          type="file"
          accept="image/*"
          onChange={(e) => onChange(e.target.files![0])}
          className="absolute opacity-0 top-1 left-1 right-1 bottom-1 cursor-pointer"
        />
      )}
    </div>
  );
};

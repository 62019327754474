import { graphql } from '@graphql/generated';

export const userContextQuery = graphql(`
  query userContext {
    me {
      id
      email
      status
      firstName
      lastName
      displayName
      calendarUrl
      introduction
      avatarUrl
      locale
      courierAuthToken
    }
  }
`);

import type { FC } from 'react';
import { Calendar as RawCalendar } from 'react-calendar';
import { dateToPlainDate } from '@src/helpers/date';
import type { PlainDate } from '@src/types';
import './Calendar.css';

type CalendarProps = {
  value: PlainDate | null;
  setValue: (v: PlainDate | null) => void;
  maxDate?: PlainDate;
};

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

export const Calendar: FC<CalendarProps> = ({ maxDate, value, setValue }) => {
  return (
    <RawCalendar
      selectRange={false}
      value={value}
      onChange={(date: Value) => {
        setValue(dateToPlainDate(date as ValuePiece));
      }}
      maxDate={maxDate ? new Date(maxDate) : undefined}
      minDetail="month"
      prev2Label={null}
      next2Label={null}
    />
  );
};

import clsx from 'clsx';
import { useEffect, useRef } from 'react';

interface TextareaProps {
  value?: string | null;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
}

export const Textarea = ({
  value,
  onChange,
  placeholder,
  className,
}: TextareaProps) => {
  /* Vars */

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  /* Effects */

  useEffect(() => {
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = !value ? '30px' : `${textarea.scrollHeight}px`;
    }
  }, [value]);

  return (
    <textarea
      ref={textareaRef}
      value={value || ''}
      onChange={(e) => onChange(e.target.value)}
      onClick={(e) => e.stopPropagation()}
      rows={1}
      placeholder={placeholder}
      className={clsx(
        'w-full p-2 flex hover:bg-dark-50 duration-200 outline-none resize-none hover:resize-y rounded-lg placeholder:text-dark-600 text-dark-800 text-sm font-medium font-general',
        className
      )}
    />
  );
};

import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { AppRouter } from './AppRouter';
import { GraphQLProvider } from './initApollo';
import { GlobalStyle } from './style/globalStyle';
import { theme } from './style/theme';
import { Toaster } from 'react-hot-toast';
import { initSentry } from './initSentry';
import * as Sentry from '@sentry/react';
import { PROD, SYNCFUSION_LICENSE_KEY } from './config';
import { registerLicense } from '@syncfusion/ej2-base';

import './style/global.css';

if (PROD) {
  initSentry();
}
registerLicense(SYNCFUSION_LICENSE_KEY);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Sentry.ErrorBoundary fallback={<p>Une erreur est survenue.</p>}>
    <React.StrictMode>
      <Toaster />
      <GlobalStyle />
      <GraphQLProvider>
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
      </GraphQLProvider>
    </React.StrictMode>
  </Sentry.ErrorBoundary>
);

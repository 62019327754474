import { graphql } from '@graphql/generated';

export const updateProfileMutation = graphql(`
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      id
      avatarUrl
      firstName
      lastName
      calendarUrl
      introduction
      displayName
    }
  }
`);

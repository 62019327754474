import { FC } from 'react';

import { Flex } from '../Flex/Flex';
import { DayActivities } from './DayActivities';
import type { ActivityGroup } from '@src/@types/activities';

interface TimelineActivitiesProps {
  activities: ActivityGroup[];
}

export const TimelineActivities: FC<TimelineActivitiesProps> = ({
  activities,
}) => {
  return (
    <Flex flexDirection="column">
      {activities.map((activityGroup, index) => (
        <DayActivities
          key={`activitygroup-${index}`}
          groupIndex={index}
          activityGroup={activityGroup}
          isLast={activities.length - 1 === index}
        />
      ))}
    </Flex>
  );
};

import type { StylesConfig } from 'react-select';
import { colors } from '@style/colors';
import type { SelectSize } from './Select';

type SelectStylesParams<Option, IsMulti extends boolean = false> = {
  size?: SelectSize;
  styles?: StylesConfig<Option, IsMulti>;
};

export function getSelectStyles<Option, IsMulti extends boolean = false>({
  size,
  styles = {},
}: SelectStylesParams<Option, IsMulti>): StylesConfig<Option, IsMulti> {
  return {
    container: (baseStyles) => ({
      ...baseStyles,
      fontFamily: "'Inter', sans-serif",
      fontSize: '14px',
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      outline: 'none',
      borderRadius: '12px',
      borderColor: state.isFocused ? colors.dark200 : colors.dark100,
      backgroundColor: colors.white,
      transition:
        'background-color 180ms ease-out, border-color 180ms ease-out',
      '&:hover': {
        outline: 'none',
        borderColor: colors.dark200,
        backgroundColor: colors.dark25,
      },
    }),
    valueContainer: (baseStyle) => ({
      ...baseStyle,
      paddingLeft: 12,
      paddingTop: size === 'small' ? 2 : 10,
      paddingBottom: size === 'small' ? 2 : 10,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (baseStyles, state) => ({
      ...baseStyles,
      color: state.hasValue ? colors.dark900 : undefined,
    }),
    indicatorsContainer: (baseStyle) => ({
      ...baseStyle,
      paddingRight: size === 'small' ? 4 : 8,
    }),
    menu: (baseStyle) => ({
      ...baseStyle,
      borderRadius: 12,
      boxShadow:
        '0px 4px 24px rgba(38, 38, 38, 0.1), 0px 2px 6px rgba(38, 38, 38, 0.04), 0px 0px 0px 1px rgba(38, 38, 38, 0.08)',
    }),
    menuList: (baseStyle) => ({
      ...baseStyle,
      padding: 6,
    }),
    multiValue: (baseStyle) => ({
      ...baseStyle,
      backgroundColor: colors.dark50,
    }),
    option: (baseStyle, state) => ({
      ...baseStyle,
      color: colors.primary,
      borderRadius: 12,
      backgroundColor:
        state.isSelected || state.isFocused ? colors.dark50 : colors.white,
      '&:not(:first-of-type)': {
        marginTop: 4,
      },
      '&:not(:last-child)': {
        marginBottom: 4,
      },
      transition: 'background-color 180ms ease-out',
      '&:hover': {
        backgroundColor: colors.dark50,
      },
    }),
    ...styles,
  };
}

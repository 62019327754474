import { FC, Fragment, PropsWithChildren, useEffect, useState } from 'react';
import { hotjar } from 'react-hotjar';
import { useUserContext } from '@src/contexts/UserContext/useUserContext';
import { Crisp } from 'crisp-sdk-web';
import { Script } from '@components/Script/Script';
import { HOTJAR_HJID, ENV, PENDO_API_KEY } from '@src/config';
import { TrackingContext } from './context';

const PENDO_SCRIPT = `(function(apiKey){ 
  (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];

v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){ 

o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]); 

y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js'; 

z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo'); 
})('${PENDO_API_KEY}');`;

export const TrackingProvider: FC<PropsWithChildren> = ({ children }) => {
  const { id, email, firstName } = useUserContext();
  const [isPendoLoaded, setIsPendoLoaded] = useState(false);

  useEffect(() => {
    if (ENV === 'production') {
      Crisp.user.setEmail(email);
      if (firstName) {
        Crisp.user.setNickname(firstName);
      }
    }
  }, [email, firstName]);

  useEffect(() => {
    if (ENV === 'production') {
      hotjar.initialize({ id: HOTJAR_HJID, sv: 6 });

      if (hotjar.initialized()) {
        hotjar.identify(id, { email });
      }
    }
  }, [id, hotjar.initialized()]);

  useEffect(() => {
    if (ENV === 'production') {
      isPendoLoaded &&
        window?.pendo.initialize({
          visitor: {
            id,
            email,
            firstName,
          },
          account: {
            id,
          },
        });
    }
  }, [id, email, firstName, isPendoLoaded]);

  const tracking = {
    userId: id,
    trackEvent: (event: string) => {
      if (process.env.NODE_ENV === 'production') {
        hotjar.event(event);
      }
    },
  };

  return (
    <Fragment>
      <Script content={PENDO_SCRIPT} onLoad={setIsPendoLoaded} />
      <TrackingContext.Provider value={tracking}>
        {children}
      </TrackingContext.Provider>
    </Fragment>
  );
};

import * as React from 'react';
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  FloatingFocusManager,
  FloatingOverlay,
  useId,
} from '@floating-ui/react';
import styled from 'styled-components';
import { Button, ButtonProps } from '../Button/Button';
import { Text } from '../Text/Text';
import { Divider } from '../Divider/Divider';
import { colors } from '@style/colors';

interface DialogOptions {
  initialOpen?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export function useDialog({
  initialOpen = false,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}: DialogOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);
  const [labelId, setLabelId] = React.useState<string | undefined>();
  const [descriptionId, setDescriptionId] = React.useState<
    string | undefined
  >();

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    open,
  });

  const context = data.context;

  const click = useClick(context, {
    enabled: controlledOpen === null,
  });
  const dismiss = useDismiss(context, { outsidePressEvent: 'click' });
  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [open, setOpen, interactions, data, labelId, descriptionId]
  );
}

type ContextType =
  | (ReturnType<typeof useDialog> & {
      setLabelId: React.Dispatch<React.SetStateAction<string | undefined>>;
      setDescriptionId: React.Dispatch<
        React.SetStateAction<string | undefined>
      >;
    })
  | null;

const DialogContext = React.createContext<ContextType>(null);

export const useDialogContext = () => {
  const context = React.useContext(DialogContext);

  if (context === null) {
    throw new Error('Dialog components must be wrapped in <Dialog />');
  }

  return context;
};

export function Dialog({
  children,
  ...options
}: {
  children: React.ReactNode;
} & DialogOptions) {
  const dialog = useDialog(options);
  return (
    <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>
  );
}

interface DialogTriggerProps {
  children: React.ReactNode;
  asChild?: boolean;
}

export const DialogTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & DialogTriggerProps
>(function DialogTrigger({ children, asChild = false, ...props }, propRef) {
  const context = useDialogContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      })
    );
  }

  return (
    <button
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
      onClick={() => context.setOpen(true)}
    >
      {children}
    </button>
  );
});

export const DialogContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function DialogContent(props, propRef) {
  const { context: floatingContext, ...context } = useDialogContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!floatingContext.open) return null;

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <FloatingPortal>
      <DialogOverlay lockScroll onMouseDown={onClick}>
        <FloatingFocusManager context={floatingContext}>
          <StyledContent
            ref={ref}
            aria-labelledby={context.labelId}
            aria-describedby={context.descriptionId}
            {...context.getFloatingProps(props)}
          >
            {props.children}
          </StyledContent>
        </FloatingFocusManager>
      </DialogOverlay>
    </FloatingPortal>
  );
});

export const DialogHeading = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof Text>
>(function DialogHeading({ children, ...props }, ref) {
  const { setLabelId } = useDialogContext();
  const id = useId();

  React.useLayoutEffect(() => {
    setLabelId(id);
    return () => setLabelId(undefined);
  }, [id, setLabelId]);

  return (
    <Text ref={ref} size="lg" weight="medium" {...props}>
      {children}
    </Text>
  );
});

export const DialogDescription = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof Text>
>(function DialogDescription({ children, ...props }, ref) {
  const { setDescriptionId } = useDialogContext();
  const id = useId();

  React.useLayoutEffect(() => {
    setDescriptionId(id);
    return () => setDescriptionId(undefined);
  }, [id, setDescriptionId]);

  return (
    <React.Fragment>
      <Divider my="8px" />
      <Text size="sm" ref={ref} id={id} my="20px" {...props}>
        {children}
      </Text>
    </React.Fragment>
  );
});

export const DialogConfirm = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<ButtonProps> & {
    onConfirm: (e: React.MouseEvent) => void;
  }
>(function DialogConfirm({ onConfirm, ...props }, ref) {
  const { setOpen } = useDialogContext();
  return (
    <Button
      ref={ref}
      variant="primary"
      {...props}
      onClick={async (e) => {
        await onConfirm(e);
        setOpen(false);
      }}
    />
  );
});

export const DialogClose = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<ButtonProps>
>(function DialogConfirm(props, ref) {
  const { setOpen } = useDialogContext();
  return (
    <Button
      ref={ref}
      variant="secondary"
      {...props}
      onClick={(e) => {
        e.preventDefault();
        setOpen(false);
      }}
    />
  );
});

const DIALOG_Z_INDEX = 7;

const DialogOverlay = styled(FloatingOverlay)`
  background: rgba(0, 0, 0, 0.15);
  display: grid;
  place-items: center;
  z-index: ${DIALOG_Z_INDEX};
`;

const StyledContent = styled.div`
  background-color: white;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 48px;
  border: 1px solid ${colors.dark100};
`;

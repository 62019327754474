import type {
  ButtonHTMLAttributes,
  ComponentType,
  FC,
  PropsWithChildren,
  ReactNode,
} from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import type { MarginProps } from 'styled-system';
import { space } from 'styled-system';
import { Colors, colors } from '@style/colors';
import { Loader } from '@components/Loader/Loader';
import { Text } from '@components/Text/Text';
import { resetStyle } from './buttonMixins';
import { ActionType } from '@src/graphql/generated/graphql';

export const ButtonVariants = [
  'primary',
  'secondary',
  'light',
  'invisible',
  ...Object.values(ActionType),
] as const;
type ButtonVariant = (typeof ButtonVariants)[number];
type ButtonSize = 'small' | 'normal' | 'big';
const LOADER_SIZE = 20;

type ButtonStyleProps = {
  variant?: ButtonVariant;
  size?: ButtonSize;
  fullWidth?: boolean;
};

export type ButtonProps = {
  icon?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: ComponentType<any>;
  ref?: React.Ref<HTMLButtonElement>;
  loading?: boolean;
} & ButtonStyleProps &
  Pick<
    ButtonHTMLAttributes<HTMLButtonElement>,
    'type' | 'disabled' | 'onClick'
  > &
  MarginProps;

export const LinkButton: FC<
  PropsWithChildren<Omit<ButtonProps, 'as'> & LinkProps>
> = (props) => {
  return <Button {...props} as={Link} />;
};

export const Button: FC<PropsWithChildren<ButtonProps>> = ({
  icon,
  children,
  variant = 'secondary',
  size = 'normal',
  fullWidth = false,
  type,
  disabled,
  loading,
  ref,
  ...rest
}) => {
  const styleProps = { variant, size, $fullWidth: fullWidth };
  const htmlProps = { type, disabled };

  return (
    <StyledButton ref={ref} {...styleProps} {...htmlProps} {...rest}>
      {loading && (
        <LoaderContainer>
          <Loader size={LOADER_SIZE} color={VariantStyles[variant].color} />
        </LoaderContainer>
      )}
      <ButtonContent visible={!loading}>
        {icon && <IconContainer>{icon}</IconContainer>}
        {children && (
          <Text size="sm" weight="semibold">
            {children}
          </Text>
        )}
      </ButtonContent>
    </StyledButton>
  );
};

const LoaderContainer = styled.div`
  position: absolute;
  top: calc(50% - ${LOADER_SIZE}px / 2);
  left: calc(50% - ${LOADER_SIZE}px / 2);
`;

const ButtonContent = styled.div<{ visible: boolean }>`
  ${(props) => !props.visible && 'opacity: 0'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled('button')<Required<ButtonStyleProps> & MarginProps>`
  // Reset
  ${resetStyle};

  // Helpers
  ${space};

  // For loader positioning
  position: relative;

  // Button
  padding: ${(props) => Sizes[props.size].padding};
  border-radius: 12px;
  background-color: ${(props) => VariantStyles[props.variant].background};
  border: 1px solid ${(props) => VariantStyles[props.variant].border};
  ${({ fullWidth: $fullWidth }) => $fullWidth && 'width: 100%;'}
  transition: background-color 180ms ease-out, border-color 180ms ease-out, color 180ms ease-out;

  // Text
  color: ${(props) => colors[VariantStyles[props.variant].color]};
  text-decoration: none;

  // Hover
  &:hover,
  &:focus {
    background-color: ${(props) =>
      VariantStyles[props.variant].backgroundHover};
    border-color: ${(props) => VariantStyles[props.variant].borderHover};
    color: ${(props) => VariantStyles[props.variant].colorHover};
  }

  // Disabled
  &:disabled {
    pointer-events: none;
    cursor: inherit; // Reset cursor
    opacity: 0.5;
  }
`;

const IconContainer = styled.div`
  margin-right: 8px;

  &:only-child {
    margin-right: 0;
  }
`;

const Sizes: Record<ButtonSize, { padding: string }> = {
  small: {
    padding: '7px 7px',
  },
  normal: {
    padding: '8px 12px',
  },
  big: {
    padding: '14px 16px',
  },
};

const VariantStyles: Record<
  ButtonVariant | ActionType,
  {
    background: string;
    backgroundHover: string;
    border: string;
    borderHover: string;
    color: Colors;
    colorHover: string;
  }
> = {
  primary: {
    background: colors.dark800,
    backgroundHover: colors.dark900,
    border: colors.dark900,
    borderHover: colors.dark900,
    color: 'white',
    colorHover: colors.white,
  },
  secondary: {
    background: colors.white,
    backgroundHover: colors.white,
    border: colors.dark100,
    borderHover: colors.dark200,
    color: 'dark700',
    colorHover: colors.dark900,
  },
  light: {
    background: colors.dark50,
    backgroundHover: colors.dark100,
    border: colors.dark50,
    borderHover: colors.dark100,
    color: 'dark700',
    colorHover: colors.dark900,
  },
  invisible: {
    background: 'transparent',
    backgroundHover: 'transparent',
    border: 'transparent',
    borderHover: 'transparent',
    color: 'dark700',
    colorHover: colors.dark900,
  },
  [ActionType.BookDemo]: {
    background: colors['BOOK_DEMO_0F'],
    backgroundHover: colors[ActionType.BookDemo],
    border: colors[ActionType.BookDemo],
    borderHover: colors[ActionType.BookDemo],
    color: 'dark900',
    colorHover: colors.dark900,
  },
  [ActionType.Checklist]: {
    background: colors['CHECKLIST_0F'],
    backgroundHover: colors[ActionType.Checklist],
    border: colors[ActionType.Checklist],
    borderHover: colors[ActionType.Checklist],
    color: 'dark900',
    colorHover: colors.dark900,
  },

  [ActionType.SendLink]: {
    background: colors['SEND_LINK_0F'],
    backgroundHover: colors[ActionType.SendLink],
    border: colors[ActionType.SendLink],
    borderHover: colors[ActionType.SendLink],
    color: 'dark900',
    colorHover: colors.dark900,
  },
  [ActionType.SendFile]: {
    background: colors['SEND_FILE_0F'],
    backgroundHover: colors[ActionType.SendFile],
    border: colors[ActionType.SendFile],
    borderHover: colors[ActionType.SendFile],
    color: 'dark900',
    colorHover: colors.dark900,
  },
  [ActionType.FillForm]: {
    background: colors['FILL_FORM_0F'],
    backgroundHover: colors[ActionType.FillForm],
    border: colors[ActionType.FillForm],
    borderHover: colors[ActionType.FillForm],
    color: 'dark900',
    colorHover: colors.dark900,
  },
  [ActionType.AskFile]: {
    background: colors['ASK_FILE_0F'],
    backgroundHover: colors[ActionType.AskFile],
    border: colors[ActionType.AskFile],
    borderHover: colors[ActionType.AskFile],
    color: 'dark900',
    colorHover: colors.dark900,
  },
};

import { FC } from 'react';
import { Filter } from './Filter';
import { useUserOptions } from './useUserOptions';

type UsersFilterProps = {
  name: string;
  defaultUserIds?: string[];
  onChange: (ids: string[]) => void;
};

export const UsersFilter: FC<UsersFilterProps> = ({
  name,
  defaultUserIds,
  onChange,
}) => {
  const usersOptions = useUserOptions();
  const defaultValue = defaultUserIds
    ? usersOptions.find((option) => defaultUserIds.includes(option.value))
    : undefined;

  // This guarantees that the default value is passed as prop on the first React Select's render.
  if (!defaultValue) return null;
  return (
    <Filter
      name={name}
      defaultValue={[defaultValue]}
      options={usersOptions}
      onChange={(newOptions) => {
        onChange(newOptions.map((opt) => opt.value));
      }}
    />
  );
};

import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Div } from '@components/Flex/Div/Div';
import { Flex } from '@components/Flex/Flex';
import { PageLayout } from '@components/PageLayout';
import { ProfileForm } from '@components/ProfileForm';
import { Text } from '@components/Text/Text';
import { useUserContext } from '@src/contexts/UserContext/useUserContext';
import { useMutation, useQuery } from '@apollo/client';
import {
  getMainWorkspaceQuery,
  updateWorkspaceMutation,
} from '@src/contexts/WorkspaceContext/Workspace.query';
import { LogoUpload } from '@components/LogoUpload/LogoUpload';
import { FilePurpose } from '@graphql/generated/graphql';
import { UserList } from './components/UserList/UserList';
import { InviteUserForm } from './components/InviteUserForm/InviteUserForm';

export const SettingsPage: FC = () => {
  const { t } = useTranslation();
  const user = useUserContext();
  const { data } = useQuery(getMainWorkspaceQuery);
  const [updateWorkspace] = useMutation(updateWorkspaceMutation);

  const onWorkspaceLogoUpload = useCallback(
    async (fileId: string) => {
      if (!data || !data.me.mainWorkspace?.id) {
        return;
      }
      await updateWorkspace({
        variables: {
          id: data.me.mainWorkspace.id,
          input: { logoFileId: fileId },
        },
      });
    },
    [data, updateWorkspace]
  );

  return (
    <PageLayout withTopBar>
      <Text size="2xl" weight="bold">
        {t('settings.title')}
      </Text>
      <Text size="sm" color="dark500">
        {t('settings.connectedAs', { email: user.email })}
      </Text>
      <Text size="xl" weight="medium" mt="32px">
        {t('settings.workspace.title')}
      </Text>
      <Text size="sm" color="dark700">
        {t('settings.workspace.subtitle')}
      </Text>
      <Div mt={16}>
        <LogoUpload
          logo={data?.me.mainWorkspace?.logo}
          onUpload={onWorkspaceLogoUpload}
          purpose={FilePurpose.CompanyLogo}
        />
      </Div>

      <Text size="xl" weight="medium" mt="32px">
        {t('settings.profile.title')}
      </Text>
      <Text size="sm" color="dark700">
        {t('settings.profile.subtitle')}
      </Text>

      <Div mt={16}>
        <ProfileForm />
      </Div>

      <Flex alignItems="center" justifyContent="space-between" mt="32px">
        <Text size="xl" weight="medium">
          {t('settings.users.title')}
        </Text>
        <InviteUserForm />
      </Flex>
      <UserList />
    </PageLayout>
  );
};

import { useMutation } from '@apollo/client';
import {
  TaskCompanyAssignee,
  TaskRowFragment,
  TaskStatus,
  TaskVisibility,
} from '@graphql/generated/graphql';
import { useActionTypeMap } from '@src/helpers/hooks/useActionTypeMap';
import { getDueDateColor } from '@src/helpers/tasks';
import { colors } from '@style/colors';
import { forwardRef } from 'react';
import styled from 'styled-components';
import { formatDate } from '../../helpers/date';
import i18n from '../../i18n/i18n';
import { Avatar } from '../Avatar/Avatar';
import { Button } from '../Button/Button';
import { CompanyLogo } from '../CompanyLogo/CompanyLogo';
import { Flex } from '../Flex/Flex';
import { ArrowRightIcon } from '../Icon/ArrowRightIcon';
import { CalendarIcon } from '../Icon/CalendarIcon';
import { DragIcon } from '../Icon/DragIcon';
import { NotVisibleIcon } from '../Icon/NotVisibleIcon';
import { ImageGroup } from '../Image/Group';
import { TaskCheck } from '../Select/TaskCheck/TaskCheck';
import { Text } from '../Text/Text';
import { updateTaskStatusMutation } from './TaskRow.query';

const DEFAULT_TITLE = i18n.t('planSection.task.title.default');

type TaskRowProps = {
  task: Omit<
    TaskRowFragment,
    'subtasks' | 'files' | 'links' | 'elementsToProvide' | 'priority'
  >;
  subtitle?: string | null;
  isCustomer?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  arrowAlwaysVisible?: boolean;
  hideArrow?: boolean;
  planTitle?: string;
  withDragHandle?: boolean;
  planStartDate?: string;
  workspaceName: string;
  customerName: string;
};

export const TaskRow = forwardRef<HTMLDivElement, TaskRowProps>(
  (
    {
      task: {
        id,
        actionType,
        title,
        status,
        computedDueDate,
        assignees,
        visibility,
        companyAssignee,
        companyAssigneeLogo,
      },
      workspaceName,
      customerName,
      isCustomer,
      subtitle,
      isSelected,
      hideArrow,
      onClick,
      arrowAlwaysVisible,
      planTitle,
      withDragHandle = false,
    },
    ref
  ) => {
    const actionTypeMap = useActionTypeMap();
    const checked = status === TaskStatus.Done;
    const [updateStatus] = useMutation(updateTaskStatusMutation);
    const isPrivate = visibility === TaskVisibility.Private;

    const getCompanyAssigneeName = () => {
      switch (companyAssignee) {
        case TaskCompanyAssignee.Csm:
          return workspaceName;
        case TaskCompanyAssignee.Customer:
          return customerName;
      }
    };

    return (
      <Container
        alignItems={'center'}
        isSelected={isSelected}
        ref={ref}
        hideArrow={hideArrow}
      >
        {withDragHandle && (
          <ReorderTrigger key={id}>
            <DragIcon size={'small'} color="dark500" />
          </ReorderTrigger>
        )}
        <Content withDragHandle={withDragHandle}>
          <TaskCheck
            checked={checked}
            onChange={(checked) =>
              // Should maybe be stored in a local state (form?)
              // before running the mutation, autosave style
              updateStatus({
                variables: {
                  id,
                  status: checked ? TaskStatus.Done : TaskStatus.Todo,
                },
              })
            }
          />
          <Flex flexDirection="column" flexGrow={1} ml="12px" onClick={onClick}>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              flexGrow={1}
            >
              <Flex alignItems="baseline" gap="6px">
                <Text
                  size="base"
                  weight="semibold"
                  {...(checked
                    ? { strikethrough: true, color: 'dark600' }
                    : {})}
                  {...(!title ? { fontStyle: 'italic', color: 'dark600' } : {})}
                >
                  {title || DEFAULT_TITLE}
                </Text>
                {subtitle !== undefined && (
                  <Text size="sm" weight="medium" color="dark500">
                    - {subtitle || DEFAULT_TITLE}
                  </Text>
                )}
              </Flex>
              <Right
                slideOnHover={!arrowAlwaysVisible || hideArrow}
                alignItems="center"
                gap="12px"
              >
                {actionType && (
                  <Button variant={actionType}>
                    {actionTypeMap[actionType].cta}
                  </Button>
                )}
                {computedDueDate && (
                  <Flex alignItems={'center'} gap={'6px'}>
                    <CalendarIcon color={getDueDateColor(computedDueDate)} />
                    <Text color={getDueDateColor(computedDueDate)}>
                      {formatDate(computedDueDate)}
                    </Text>
                  </Flex>
                )}
                <Flex alignItems={'center'}>
                  <ImageGroup>
                    <CompanyLogo
                      name={getCompanyAssigneeName()}
                      logo={companyAssigneeLogo}
                      size={22}
                    />
                  </ImageGroup>
                </Flex>
                {assignees.length > 0 && (
                  <Flex alignItems={'center'}>
                    <ImageGroup>
                      {assignees.map((user) => (
                        <Avatar key={user.id} user={user} />
                      ))}
                    </ImageGroup>
                  </Flex>
                )}
                {isPrivate && !isCustomer && (
                  <NotVisibleIcon color={'dark500'} />
                )}
                <Arrow alwaysVisible={arrowAlwaysVisible} color="dark700" />
              </Right>
            </Flex>
            {planTitle && (
              <Text size="sm" weight="regular" color="dark500">
                {planTitle}
              </Text>
            )}
          </Flex>
        </Content>
      </Container>
    );
  }
);

const Arrow = styled(ArrowRightIcon)<{ alwaysVisible?: boolean }>`
  ${({ alwaysVisible }) =>
    !alwaysVisible &&
    `
      opacity: 0;
      transition: opacity 180ms ease-out;
  `}
`;

const Right = styled(Flex)<{ slideOnHover?: boolean }>`
  ${({ slideOnHover }) =>
    slideOnHover &&
    `
      transform: translateX(32px);
      transition: transform 180ms ease-out;
  `}
`;

const Container = styled(Flex)<{ isSelected?: boolean; hideArrow?: boolean }>`
  margin: 0px -12px;
  border: 1px solid
    ${(props) => (props.isSelected ? colors.dark200 : 'transparent')};
  transition: background-color 180ms ease-out, border-color 180ms ease-out;
  border-radius: 12px;
  &:hover {
    background-color: ${colors.dark50};
    ${Right} {
      ${({ hideArrow }) =>
        hideArrow
          ? `transform: translateX(32px);`
          : `transform: translateX(0);`}
      ${Arrow} {
        ${({ hideArrow }) => !hideArrow && `opacity: 1;`}
      }
    }
  }
`;

const Content = styled(Flex)<{
  isSelected?: boolean;
  withDragHandle?: boolean;
}>`
  position: relative;
  width: 100%;
  align-items: center;
  ${({ withDragHandle }) =>
    withDragHandle
      ? `
    width: calc(100% - 6px);
    margin: 0 -18px;
  `
      : ''};
  cursor: pointer;
  padding: 12px 8px;
`;

const ReorderTrigger = styled.div`
  position: relative;
  left: -24px;
  opacity: 0;
  transition: opacity 180ms ease-out;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  ${Container}:hover & {
    opacity: 1;
  }
`;

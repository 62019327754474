import type { ReactNode } from 'react';
import { useMemo } from 'react';
import RawSelect, { type Props } from 'react-select';
import { getCustomSelectComponents } from './customSelectComponents';
import { getSelectStyles } from './selectStyles';

export type SelectSize = 'small' | 'normal';

export type SelectOption<Value = string> = {
  label: string;
  value: Value;
  leftContent?: ReactNode;
};

export type SelectProps<Value, IsMulti extends boolean = false> = Props<
  SelectOption<Value>,
  IsMulti
> & {
  size?: SelectSize;
};

export function Select<Value, IsMulti extends boolean = false>({
  size = 'normal',
  styles,
  ...rest
}: SelectProps<Value, IsMulti>) {
  const components = useMemo(
    () => getCustomSelectComponents<Value, IsMulti>(),
    []
  );

  return (
    <RawSelect
      components={components}
      styles={getSelectStyles<SelectOption<Value>, IsMulti>({ size, styles })}
      {...rest}
    />
  );
}

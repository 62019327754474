import { graphql } from '@graphql/generated';

export const getTaskQuery = graphql(`
  query getTask($id: ID!) {
    task(id: $id) {
      id
      title
      actionType
      description
      visibility
      startDate
      dueDate
      duration
      computedDueDate
      status
      note
      priority
      companyAssignee
      companyAssigneeLogo
      assignees {
        ...UserAvatar
      }
      files {
        id
        name
        url
      }
      plan {
        id
        startDate
        invitees {
          ...UserAvatar
        }
        workspace {
          id
          name
          logo
          users {
            ...UserAvatar
          }
        }
        customer {
          name
          logo
        }
      }
      section {
        id
        title
      }
      links {
        id
        url
        description
      }
      subtasks {
        ...Subtask
      }
      elementsToProvide {
        id
        name
        type
        isRequired
        task {
          plan {
            id
          }
        }
        file {
          id
          name
          url
        }
        link {
          id
          url
          description
        }
      }
      comments {
        ...Comment
      }
      lastReminderSendAt
    }
  }
`);

export const SubtaskFragment = graphql(`
  fragment Subtask on Subtask {
    id
    title
    status
    index
    dueDate
    assignees {
      ...UserAvatar
    }
  }
`);

export const CommentFragment = graphql(`
  fragment Comment on Comment {
    id
    author {
      ...UserAvatar
    }
    createdAt
    updatedAt
    content
  }
`);

export const updateTaskMutation = graphql(`
  mutation updateTask($id: ID!, $input: TaskInput!) {
    updateTask(id: $id, input: $input) {
      id
      title
      description
      visibility
      status
      startDate
      dueDate
      duration
      computedDueDate
      note
      priority
      companyAssignee
      companyAssigneeLogo
      plan {
        id
        lastActivityAt
        sections {
          id
          tasks {
            id
            index
          }
        }
      }
      # Needed so that a task's "Section" select value stay up to date while navigating
      section {
        id
      }
    }
  }
`);

export const assignUserToTaskMutation = graphql(`
  mutation assignUserToTask($taskId: ID!, $userId: ID!) {
    assignUserToTask(taskId: $taskId, userId: $userId) {
      id
      assignees {
        id
      }
    }
  }
`);

export const removeUserFromTaskMutation = graphql(`
  mutation removeUserFromTask($taskId: ID!, $userId: ID!) {
    removeUserFromTask(taskId: $taskId, userId: $userId) {
      id
      assignees {
        id
      }
    }
  }
`);

export const createTaskFileMutation = graphql(`
  mutation createTaskFile($taskId: ID!, $input: FileInput!) {
    createTaskFile(taskId: $taskId, input: $input) {
      task {
        id
        files {
          id
          name
          url
        }
        plan {
          id
          lastActivityAt
        }
      }
      id
      uploadUrl
    }
  }
`);

export const deleteTaskFileMutation = graphql(`
  mutation deleteTaskFile($taskId: ID!, $fileId: ID!) {
    deleteTaskFile(taskId: $taskId, fileId: $fileId) {
      id
      plan {
        id
        lastActivityAt
      }
      files {
        id
      }
    }
  }
`);

export const createTaskLinkMutation = graphql(`
  mutation createTaskLink($taskId: ID!, $input: LinkInput!) {
    createTaskLink(taskId: $taskId, input: $input) {
      task {
        id
        links {
          id
          url
          description
        }
        plan {
          id
          lastActivityAt
        }
      }
      id
    }
  }
`);

export const deleteTaskLinkMutation = graphql(`
  mutation deleteTaskLink($linkId: ID!) {
    deleteTaskLink(linkId: $linkId) {
      id
      links {
        id
      }
      plan {
        id
        lastActivityAt
      }
    }
  }
`);

export const deleteTaskMutation = graphql(`
  mutation deleteTask($taskId: ID!) {
    deleteTask(taskId: $taskId) {
      id
      lastActivityAt
      sections {
        id
        tasks {
          id
        }
      }
    }
  }
`);

export const createElementToProvideMutation = graphql(`
  mutation createElementToProvide(
    $taskId: ID!
    $input: ElementToProvideInput!
  ) {
    createElementToProvide(taskId: $taskId, input: $input) {
      id
      elementsToProvide {
        id
        name
        type
      }
      plan {
        id
        lastActivityAt
      }
    }
  }
`);

export const deleteElementToProvideMutation = graphql(`
  mutation deleteElementToProvide($elementToProvideId: ID!) {
    deleteElementToProvide(elementToProvideId: $elementToProvideId) {
      id
      elementsToProvide {
        id
      }
      plan {
        id
        lastActivityAt
      }
    }
  }
`);

export const createElementToProvideFileMutation = graphql(`
  mutation createElementToProvideFile(
    $elementToProvideId: ID!
    $input: FileInput!
  ) {
    createElementToProvideFile(
      elementToProvideId: $elementToProvideId
      input: $input
    ) {
      elementToProvide {
        id
        file {
          id
          name
          url
        }
        link {
          id
          url
          description
        }
      }
      id
      uploadUrl
    }
  }
`);

export const deleteElementToProvideFileMutation = graphql(`
  mutation deleteElementToProvideFile($elementToProvideId: ID!, $fileId: ID!) {
    deleteElementToProvideFile(
      elementToProvideId: $elementToProvideId
      fileId: $fileId
    ) {
      id
      file {
        id
      }
    }
  }
`);

export const createElementToProvideLinkMutation = graphql(`
  mutation setElementToProvideLink(
    $elementToProvideId: ID!
    $input: LinkInput!
  ) {
    createElementToProvideLink(
      elementToProvideId: $elementToProvideId
      input: $input
    ) {
      id
      link {
        id
        url
        description
      }
    }
  }
`);

export const deleteElementToProvideLinkMutation = graphql(`
  mutation deleteElementToProvideLink($elementToProvideId: ID!) {
    deleteElementToProvideLink(elementToProvideId: $elementToProvideId) {
      id
      link {
        id
      }
    }
  }
`);

export const createCommentMutation = graphql(`
  mutation createComment($taskId: ID!, $input: CommentInput!) {
    createComment(taskId: $taskId, input: $input) {
      id
      comments {
        ...Comment
      }
    }
  }
`);

export const updateCommentMutation = graphql(`
  mutation updateComment($id: ID!, $input: CommentInput!) {
    updateComment(id: $id, input: $input) {
      ...Comment
    }
  }
`);

export const deleteCommentMutation = graphql(`
  mutation deleteComment($id: ID!) {
    deleteComment(id: $id) {
      id
      comments {
        ...Comment
      }
    }
  }
`);

export const sendReminderMutation = graphql(`
  mutation sendReminder($taskId: ID!) {
    sendReminder(taskId: $taskId) {
      success
    }
  }
`);

import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { colors } from '@style/colors';

export const TabItem = styled(NavLink)`
  position: relative;
  padding: 8px;

  &:hover {
    color: ${colors.dark700};
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 1px;
  }

  &.active:after {
    background-color: ${colors.dark900};
  }

  &:not(.active) {
    color: ${colors.dark600};
  }
`;

import { CheckIcon } from '@src/components/Icon/CheckIcon';
import { UnreadIcon } from '@src/components/Icon/UnreadIcon';
import { colors } from '@src/style/colors';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

type ReadButtonProps = {
  isRead: boolean;
  handleRead: VoidFunction;
  handleUnread: VoidFunction;
  style: React.CSSProperties;
};

export const ReadButton = forwardRef<HTMLDivElement, ReadButtonProps>(
  ({ isRead, handleRead, handleUnread, ...rest }, ref) => {
    return (
      <IconContainer ref={ref} {...rest}>
        {!isRead && (
          <div
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
              handleRead();
            }}
          >
            <CheckIcon color="dark500" />
          </div>
        )}
        {isRead && (
          <div
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
              handleUnread();
            }}
          >
            <Unread />
          </div>
        )}
      </IconContainer>
    );
  }
);

const IconContainer = styled.div`
  cursor: pointer;
  z-index: 1;
`;

const Unread = styled(UnreadIcon)`
  & > svg {
    fill: ${colors.dark500};
  }
  cursor: pointer;
`;

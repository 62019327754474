import { Calendar as RawCalendar } from 'react-calendar';

import { dateToPlainDate } from '@src/helpers/date';
import type { PlainDate } from '@src/types';

import './calendar.css';

type CalendarProps = {
  value: PlainDate | null;
  setValue: (v: PlainDate | null) => void;
  minDate?: PlainDate;
  maxDate?: PlainDate;
};

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

export const Calendar = ({
  maxDate,
  minDate,
  value,
  setValue,
}: CalendarProps) => {
  return (
    <RawCalendar
      selectRange={false}
      value={value}
      onChange={(date: Value) => setValue(dateToPlainDate(date as ValuePiece))}
      minDate={minDate ? new Date(minDate) : undefined}
      maxDate={maxDate ? new Date(maxDate) : undefined}
      minDetail="month"
      prev2Label={null}
      next2Label={null}
    />
  );
};

import { formatDate } from '@src/helpers/date';
import { getDueDateColor } from '@src/helpers/tasks';
import { colors } from '@src/style/colors';
import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Flex } from '../Flex/Flex';
import { CalendarIcon } from '../Icon/CalendarIcon';
import { Pill } from '../Pill/Pill';
import { PillTabs } from '../PillTabs/PillTabs';
import { Text } from '../Text/Text';
import { DatePickerField } from './DatePickerField';
import { InputField } from './InputField';

export const DateOrNumberToggleField = () => {
  const { watch, setValue } = useFormContext();
  const dueDateMode = watch('dueDateMode');

  return (
    <Flex>
      <TaskPillTabs>
        <Tab
          isSelected={dueDateMode === 'dueDate'}
          onClick={() => {
            setValue('dueDateMode', 'dueDate');
          }}
        >
          <DatePickerField
            name={'dueDate'}
            renderer={(date, setDate) => (
              <Pill
                Icon={CalendarIcon}
                onClose={date ? () => setDate(null) : undefined}
                color={getDueDateColor(date)}
                weight={'medium'}
              >
                <DateText>
                  {date
                    ? formatDate(date)
                    : t('plan.taskDetails.dueDate.placeholder')}
                </DateText>
              </Pill>
            )}
          />
        </Tab>

        <Tab
          isSelected={dueDateMode === 'duration'}
          onClick={() => {
            setValue('dueDateMode', 'duration');
          }}
        >
          <DurationFieldContainer>
            <InputField
              name="duration"
              size="small"
              type="number"
              min={1}
              onChange={() => {
                // We can click on the up or down arrow controls to change the value of the duration field.
                // However this won't trigger the onClick event of the Tab component, so we activate the tab manually.
                setValue('dueDateMode', 'duration', {
                  // Options necessary to trigger the validation on a first render.
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
            />
          </DurationFieldContainer>
          <DurationText size="sm">
            {t('plan.taskDetails.duration.text')}
          </DurationText>
        </Tab>
      </TaskPillTabs>
    </Flex>
  );
};

const Tab = styled(Flex)<{ isSelected?: boolean }>`
  align-items: center;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 12px;
  transition: background-color 180ms ease-out, border-color 180ms ease-out;
  gap: 6px;
  ${(props) => props.isSelected && `background-color: ${colors.dark50};`};
`;

const TaskPillTabs = styled(PillTabs)`
  width: max-content;
  background-color: white;
  gap: 8px;
`;

const DateText = styled.div`
  text-wrap: nowrap;
`;

const DurationText = styled(Text)`
  text-wrap: nowrap;
  color: ${colors.dark700};
`;

const DurationFieldContainer = styled.div`
  width: 7ch;
`;

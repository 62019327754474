import { graphql } from '@graphql/generated';

export const FileFragment = graphql(`
  fragment FileFragment on File {
    id
    name
    url
  }
`);

export const LinkFragment = graphql(`
  fragment LinkFragment on Link {
    id
    description
    url
  }
`);

export const getPlanResourcesQuery = graphql(`
  query GetPlanResources($planId: ID!) {
    plan(id: $planId) {
      id
      workspace {
        id
        name
      }
      customer {
        name
      }
      files {
        ...FileFragment
        task {
          id
          title
        }
      }
      links {
        ...LinkFragment
        task {
          id
          title
        }
      }
      elementsToProvide {
        id
        type
        task {
          id
          title
        }
        file {
          ...FileFragment
        }
        link {
          ...LinkFragment
        }
      }
    }
  }
`);

import { useMutation } from '@apollo/client';
import { computeStateColor } from '@components/Plan/Infos/Progression/Progression';
import { PlanFragment, TaskStatus } from '@graphql/generated/graphql';
import { colors } from '@style/colors';
import { Reorder, motion, useDragControls } from 'framer-motion';
import type { FC } from 'react';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { findChangedElementPosition } from '../../helpers/utils';
import i18n from '../../i18n/i18n';
import type { ArrayElement } from '../../types';
import { AutoSave } from '../AutoSave/AutoSave';
import { Button } from '../Button/Button';
import { resetButtonStyles } from '../Button/TransparentButton';
import {
  Dialog,
  DialogClose,
  DialogConfirm,
  DialogContent,
  DialogDescription,
  DialogHeading,
} from '../Dialog/Dialog';
import { Divider } from '../Divider/Divider';
import { Flex } from '../Flex/Flex';
import { Form } from '../Form/Form';
import { GhostInputField } from '../Form/GhostInputField';
import { ChevronDownIcon } from '../Icon/ChevronDownIcon';
import { DragIcon } from '../Icon/DragIcon';
import { TrashIcon } from '../Icon/TrashIcon';
import { Overlay } from '../Overlay/Overlay';
import { PlusCircle } from '../PlusCircle';
import { ProgressionNumber } from '../ProgressionNumbers/ProgressionNumber';
import { TaskCreateModal } from '../TaskCreateModal/TaskCreateModal';
import { updateTaskMutation } from '../TaskPanel/TaskDetails.query';
import { Text } from '../Text/Text';
import {
  deleteSectionMutation,
  updateSectionMutation,
} from './PlanSection.query';
import { PlanSectionDescription } from './PlanSectionDescription';
import { TaskRow } from './TaskRow';

type PlanSectionProps = {
  planId: string;
  planStartDate?: string;
  section: ArrayElement<PlanFragment['sections']>;
  selectedTask: string | null;
  setSelectedTask: (t: string | null) => void;
  isCustomer: boolean;
  onDragEnd: () => void;
  taskRefs: React.MutableRefObject<Record<string, HTMLDivElement | null>>;
  workspaceName: string;
  customerName: string;
};

export const sectionTitlePlaceholder = i18n.t(
  'planSection.section.title.default'
);

type FormValues = {
  title: string;
};

export const PlanSection: FC<PlanSectionProps> = ({
  planId,
  planStartDate,
  section: { id, title, tasks, description },
  selectedTask,
  setSelectedTask,
  isCustomer,
  onDragEnd,
  taskRefs,
  workspaceName,
  customerName,
}) => {
  const { t } = useTranslation();
  const controls = useDragControls();
  const [deleteSectionModalOpen, setDeleteSectionModalOpen] = useState(false);
  const [updateSection] = useMutation(updateSectionMutation);
  const [deleteSection] = useMutation(deleteSectionMutation);
  const [updateTask] = useMutation(updateTaskMutation);
  const [currentTasksOrders, setCurrentTasksOrders] = useState<string[]>(
    tasks.map((task) => task.id)
  );
  const [isDraggingTasks, setIsDraggingTasks] = useState(false);
  const [taskCreateModalOpen, setTaskCreateModalOpen] = useState(false);
  const handleDeleteSection = useCallback(async () => {
    await deleteSection({
      variables: {
        id,
      },
      update: (cache, { data }) => {
        const deletedSectionId = data?.deleteSection;
        if (deletedSectionId) {
          cache.evict({
            id: cache.identify({
              __typename: 'PlanSection',
              id: deletedSectionId,
            }),
          });
          cache.gc();
        }
      },
    });
  }, [id]);
  const checked = tasks.filter(
    (task) => task.status === TaskStatus.Done
  ).length;
  const total = tasks.length;
  const completed = total > 0 && checked === total;
  const completionRate = Math.round((checked / total) * 100) || 0;

  const [collapsed, setCollapsed] = useState<boolean>(completed);
  const defaultValues = {
    title: title || '',
  };
  const methods = useForm<FormValues>({
    defaultValues,
    mode: 'onChange',
  });
  const handleSubmit = async ({ title }: FormValues) => {
    await updateSection({
      variables: {
        id,
        input: {
          title,
        },
      },
    });
  };

  useEffect(() => {
    setCurrentTasksOrders(tasks.map((task) => task.id));
  }, [tasks]);

  const handleDrop = useCallback(async () => {
    const oldTasksOrders = tasks.map((section) => section.id);
    const indexUpdates = findChangedElementPosition<string>(
      oldTasksOrders,
      currentTasksOrders
    );

    if (!indexUpdates) return;

    const [oldIndex, newIndex] = indexUpdates;
    const TaskId = oldTasksOrders[oldIndex];

    await updateTask({
      variables: {
        id: TaskId,
        input: {
          index: newIndex + 1,
        },
      },
    });
  }, [currentTasksOrders, tasks]);

  const orderedTasks = [...tasks].sort(
    (a, b) =>
      currentTasksOrders.indexOf(a.id) - currentTasksOrders.indexOf(b.id)
  );
  if (isCustomer && !tasks.length) {
    return null;
  }

  return (
    <Container
      value={id}
      onDragEnd={onDragEnd}
      dragListener={false}
      dragControls={controls}
    >
      <Dialog
        open={deleteSectionModalOpen}
        onOpenChange={setDeleteSectionModalOpen}
      >
        <DialogContent>
          <DialogHeading mt="8px" mx="24px">
            {t('planSection.deleteModal.title')}
          </DialogHeading>
          <DialogDescription my="6px" mx="24px">
            <Trans
              i18nKey="planSection.deleteModal.subtitle"
              components={{ 1: <br /> }}
            />
          </DialogDescription>
          <Divider my="8px" />
          <Flex my="6px" mx="24px" justifyContent="center" gap="16px">
            <DialogClose>
              {t('planSection.deleteModal.cancel.label')}
            </DialogClose>
            <DialogConfirm onConfirm={async () => await handleDeleteSection()}>
              {t('planSection.deleteModal.confirm.label')}
            </DialogConfirm>
          </Flex>
        </DialogContent>
      </Dialog>
      {!isCustomer && (
        <ReorderTrigger onPointerDown={(e) => controls.start(e)}>
          <DragIcon size={'small'} color="dark500" />
        </ReorderTrigger>
      )}
      <Header onClick={() => setCollapsed(!collapsed)}>
        <Flex alignItems="center" justifyContent={'space-between'} gap="32px">
          {isCustomer ? (
            <Text weight="medium" size="2xl">
              {title}
            </Text>
          ) : (
            <Flex gap={'4px'} flexGrow={1}>
              <Form onSubmit={handleSubmit} methods={methods} flexGrow={1}>
                <AutoSave
                  defaultValues={defaultValues}
                  onSubmit={handleSubmit}
                />
                <GhostInputField
                  onClick={(e) => {
                    // Prevent section collapse on input click
                    e.stopPropagation();
                  }}
                  size={'big'}
                  name={'title'}
                  placeholder={sectionTitlePlaceholder}
                />
              </Form>
              <FadingButton
                type={'button'}
                size={'small'}
                icon={<TrashIcon size={'small'} />}
                onClick={async (e) => {
                  e.stopPropagation(); // Prevent event from bubbling up
                  setDeleteSectionModalOpen(true);
                }}
              />
            </Flex>
          )}
          <Flex alignItems="center" justifyContent="flex-end" gap="16px">
            {completed ? (
              <Text weight="medium" size="sm" color="green">
                {t('planSection.completed')}
              </Text>
            ) : (
              <>
                <ProgressionNumber done={checked} total={total} size={30} />
                <Text
                  size="sm"
                  weight="medium"
                  color={computeStateColor(completionRate)}
                >
                  {t('progression.text', { percentage: completionRate })}
                </Text>
              </>
            )}
            <Chevron collapsed={collapsed} color="dark500" />
          </Flex>
        </Flex>
      </Header>

      {((isCustomer && description) || !isCustomer) && (
        <PlanSectionDescription
          sectionId={id}
          description={description}
          isCustomer={isCustomer}
        />
      )}

      <motion.div
        animate={{
          height: collapsed ? 0 : 'auto',
          opacity: collapsed ? 0 : 1,
          transitionEnd: {
            overflow: collapsed ? 'hidden' : 'visible',
          },
        }}
        initial={false}
        style={{
          margin: '0 -12px',
        }}
      >
        <Flex flexDirection="column" gap="8px" padding="12px">
          <Reorder.Group
            axis="y"
            values={currentTasksOrders}
            onReorder={(newOrder) => setCurrentTasksOrders(newOrder)}
          >
            {orderedTasks.map((task) => (
              <Fragment key={task.id}>
                <Reorder.Item
                  role="row"
                  key={task.id}
                  value={task.id}
                  dragListener={!isCustomer}
                  draggable={!isCustomer}
                  onDragStart={() => setIsDraggingTasks(true)}
                  onDragEnd={() => {
                    setIsDraggingTasks(false);
                    handleDrop();
                  }}
                >
                  <TaskRow
                    withDragHandle={!isCustomer}
                    ref={(el) => {
                      taskRefs.current[task.id] = el;
                    }}
                    hideArrow={isDraggingTasks}
                    task={task}
                    planStartDate={planStartDate}
                    isCustomer={isCustomer}
                    onClick={() => setSelectedTask(task.id)}
                    isSelected={task.id === selectedTask}
                    workspaceName={workspaceName}
                    customerName={customerName}
                  />
                </Reorder.Item>
                <Divider />
              </Fragment>
            ))}
          </Reorder.Group>
          {!isCustomer && (
            <AddTaskButton
              type={'button'}
              onClick={() => setTaskCreateModalOpen(true)}
            >
              <Flex gap="12px" alignItems="center">
                <PlusCircle />
                <Text size={'base'} weight={'semibold'} color={'dark500'}>
                  {t('planSection.newTaskButton.label')}
                </Text>
              </Flex>
            </AddTaskButton>
          )}
        </Flex>
      </motion.div>

      {taskCreateModalOpen && (
        <Overlay onClose={() => setTaskCreateModalOpen(false)}>
          <TaskCreateModal
            planId={planId}
            planSectionId={id}
            onClose={() => setTaskCreateModalOpen(false)}
          />
        </Overlay>
      )}
    </Container>
  );
};

const Container = styled(Reorder.Item)`
  position: relative;
  margin-top: 8px;
  user-select: none;
`;

const Chevron = styled(ChevronDownIcon)<{ collapsed: boolean }>`
  transform: rotate(${(props) => (props.collapsed ? '-90deg' : 0)});
  transition: transform 180ms ease-out;
`;

const AddTaskButton = styled.button`
  ${resetButtonStyles};
  padding: 12px;
  margin: 0 -12px;
  border-radius: 12px;
  transition: background-color 180ms ease-out, border-color 180ms ease-out;
  cursor: pointer;
  &:hover {
    --iconColor: ${colors.dark700};
    background-color: ${colors.dark50};
  }
`;

const FadingButton = styled(Button)`
  opacity: 0;
  transition: opacity 180ms ease-out;
`;

const Header = styled.div`
  width: 100%;
  cursor: pointer;

  &:hover {
    ${FadingButton} {
      opacity: 1;
    }
  }
`;

const ReorderTrigger = styled.div`
  position: absolute;
  top: 8px;
  left: -32px;
  padding: 8px;
  opacity: 0;
  transition: opacity 180ms ease-out;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  ${Container}:hover & {
    opacity: 1;
  }
`;

import styled from 'styled-components';
import { Image, ImageSize } from './Image';

const Props: Record<ImageSize, { gap: string; boxShadow: string }> = {
  xsmall: {
    gap: '-1px',
    boxShadow: '0 0 0 1px white',
  },
  small: {
    gap: '-2px',
    boxShadow: '0 0 0 1px white',
  },
  normal: {
    gap: '-2px',
    boxShadow: '0 0 0 2px white',
  },
  medium: {
    gap: '-4px',
    boxShadow: '0 0 0 3px white',
  },
  large: {
    gap: '-8px',
    boxShadow: '0 0 0 4px white',
  },
  xl: {
    gap: '-10px',
    boxShadow: '0 0 0 4px white',
  },
};

const DEFAULT_SIZE = 'normal';

export const ImageGroup = styled.div<{ size?: ImageSize }>`
  display: flex;
  align-items: center;
  gap: ${({ size = DEFAULT_SIZE }) => Props[size].gap};

  & > ${Image}:not(:first-child) {
    box-shadow: inset 0 0 0 2px rgba(38, 38, 38, 0.05),
      ${({ size = DEFAULT_SIZE }) => Props[size].boxShadow};
  }
`;

import { Flex } from '../Flex/Flex';
import type { FC, PropsWithChildren } from 'react';
import React from 'react';
import { Text } from '../Text/Text';
import { useFormContext } from 'react-hook-form';
import type { FlexboxProps } from 'styled-system';

type FormFieldProps = {
  name: string;
  label?: string;
  alignInputLeft?: boolean;
  justifyContent?: FlexboxProps['justifyContent'];
};

const FormField: FC<PropsWithChildren<FormFieldProps & FlexboxProps>> = ({
  label,
  name,
  alignInputLeft,
  justifyContent,
  children,
  ...rest
}) => {
  const { getFieldState, formState } = useFormContext();
  const { error } = getFieldState(name, formState);

  return (
    <Flex
      flexDirection={'column'}
      alignItems={'flex-start'}
      gap={'2px'}
      {...rest}
    >
      {label && (
        <Label htmlFor={name} size={'xs'} color={'dark600'}>
          {label}
        </Label>
      )}
      <Flex marginLeft={alignInputLeft ? '-6px' : undefined} width={'100%'}>
        {children}
      </Flex>
      {!!error && (
        <Text size={'xs'} color={'error'} pl={'6px'}>
          {error.message}
        </Text>
      )}
    </Flex>
  );
};

const Label = Text.withComponent('label');

export default FormField;

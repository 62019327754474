import type { FC, HTMLProps } from 'react';
import { forwardRef } from 'react';
import styled from 'styled-components';
import { colors } from '@style/colors';
import { textSizeMap } from '../Text/Text';
import { baseInputStyle } from './inputMixins';

export const InputSizes = ['small', 'big'] as const;
export type InputSize = (typeof InputSizes)[number];
const DEFAULT_SIZE: InputSize = 'small';

type InputProps = {
  size?: InputSize;
} & Pick<
  HTMLProps<HTMLInputElement>,
  | 'type'
  | 'placeholder'
  | 'name'
  | 'value'
  | 'onChange'
  | 'disabled'
  | 'onBlur'
  | 'onKeyUp'
  | 'min'
>;

export const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  function Input({ size = DEFAULT_SIZE, ...rest }, ref) {
    return <StyledInput ref={ref} $size={size} {...rest} />;
  }
);

const StyledInput = styled.input<
  Omit<InputProps, 'size'> & { $size?: InputSize }
>`
  ${baseInputStyle};

  background: ${colors.white};
  border: 1px solid ${colors.dark100};
  border-radius: 12px;
  ${({ $size = DEFAULT_SIZE }) => `
    padding: ${SizeStyles[$size].padding};
    border-radius: ${SizeStyles[$size].borderRadius};
  `}
  font-size: ${textSizeMap['sm'].fontSize}px;
  line-height: ${textSizeMap['sm'].lineHeight}px;

  &:disabled {
    background-color: ${colors.dark100};
    cursor: not-allowed;
  }

  &:not:disabled {
    &:hover {
      background-color: ${colors.dark25};
    }

    &:hover,
    &:active,
    &:focus {
      border-color: ${colors.dark200};
    }
  }
`;

const SizeStyles: Record<InputSize, { padding: string; borderRadius: string }> =
  {
    small: {
      padding: '7px 12px',
      borderRadius: '8px',
    },
    big: {
      padding: '13px 16px',
      borderRadius: '12px',
    },
  };

import { type FC } from 'react';
import clsx from 'clsx';

import { Avatar } from '../avatar';

interface AvatarType {
  picture?: string;
  fullName?: string;
  email?: string;
  id?: string;
}

interface StackAvatarProps {
  avatars: AvatarType[];
  size?: number;
  className?: string;
  onChange?: (file: File, index: number) => void;
}

export const StackAvatar: FC<StackAvatarProps> = ({
  avatars,
  size = 48,
  className,
  onChange,
}) => {
  return (
    <div className={clsx('flex', className)}>
      {avatars.map((avatar, index) => (
        <Avatar
          key={index}
          {...avatar}
          size={size}
          className={clsx('ring-white', {
            'ring-2': size <= 48,
            ring: size > 48,
          })}
          style={{ marginLeft: index > 0 ? -size / 4 : 0 }}
          onChange={(file) => onChange && onChange(file, index)}
        />
      ))}
    </div>
  );
};

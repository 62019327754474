import { FC } from 'react';
import styled from 'styled-components';

import { Flex } from '../Flex/Flex';
import { colors } from '@src/style/colors';
import { Day } from './Day';
import { ActivityComponent } from './Activity';
import { Divider } from '../Divider/Divider';
import type { ActivityGroup } from '@src/@types/activities';

interface DayActivitiesProps {
  activityGroup: ActivityGroup;
  groupIndex: number;
  isLast: boolean;
}

export const DayActivities: FC<DayActivitiesProps> = ({
  activityGroup,
  groupIndex,
  isLast,
}) => {
  return (
    <Flex gap="36px">
      <Day date={activityGroup.date} />

      <VerticalLine />

      <Flex flexDirection="column" gap="22px">
        {activityGroup.activities.map((activity, index) => (
          <ActivityComponent
            key={`group-${groupIndex}-activity-${index}`}
            activity={activity}
          />
        ))}

        {isLast === false && <Divider marginBottom={22} />}
      </Flex>
    </Flex>
  );
};

const VerticalLine = styled.div`
  width: 1px;
  background-color: ${colors.dark100};
`;

import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ElementToProvideType, GetTaskQuery } from '@graphql/generated/graphql';
import { colors } from '@style/colors';
import { Asset } from '../Asset/Asset';
import { Button } from '../Button/Button';
import { TransparentButton } from '../Button/TransparentButton';
import { Flex } from '../Flex/Flex';
import { FileIcon } from '../Icon/FileIcon';
import { LinkIcon } from '../Icon/LinkIcon';
import { GhostInput } from '../Input/GhostInput';
import { PlusCircle } from '../PlusCircle';
import { Popup } from '../Popup/Popup';
import { GhostSelect } from '../Select/GhostSelect';
import { Text } from '../Text/Text';
import { toastError } from '../Toast/toast';
import {
  createElementToProvideMutation,
  deleteElementToProvideMutation,
} from './TaskDetails.query';
import { ElementsToProvide } from '../ElementsToProvide/ElementsToProvide';
import { getPlanResourcesQuery } from '../Plan/PlanResources.query';
import styled from 'styled-components';

interface TaskElementsToProvideProps {
  planId: string;
  taskId: string;
  elementsToProvide: GetTaskQuery['task']['elementsToProvide'];
  isCustomer?: boolean;
  isActionType: boolean;
}

export const TaskElementsToProvide = ({
  planId,
  taskId,
  elementsToProvide,
  isCustomer,
  isActionType,
}: TaskElementsToProvideProps) => {
  const { t } = useTranslation();
  const [deleteElementToProvide] = useMutation(deleteElementToProvideMutation, {
    refetchQueries: [
      {
        query: getPlanResourcesQuery,
        variables: { planId },
        context: {
          headers: {
            ...(isCustomer && { 'X-Preview': true }),
          },
        },
      },
    ],
  });

  return (
    <Flex flexDirection={'column'} alignItems={'flex-start'} gap={'8px'}>
      <Text size={'xs'} color={'dark600'}>
        {t('plan.taskDetails.elementsToProvide.label')}
      </Text>

      {!!elementsToProvide.length && (
        <Flex
          flexDirection={'column'}
          alignSelf={'stretch'}
          ml={'-6px'}
          mb={'8px'}
          gap={'8px'}
        >
          {elementsToProvide.map((elementToProvide) => {
            if (isCustomer) {
              return (
                <ElementsToProvide
                  key={elementToProvide.id}
                  elementToProvide={elementToProvide}
                />
              );
            }

            return (
              <Asset
                key={elementToProvide.id}
                icon={
                  elementToProvide.type === ElementToProvideType.File
                    ? FileIcon
                    : LinkIcon
                }
                title={elementToProvide.name}
                rightText={
                  elementToProvide.isRequired
                    ? t('plan.taskDetails.elementsToProvide.required.label')
                    : t('plan.taskDetails.elementsToProvide.optional.label')
                }
                handleRemoveClick={async () => {
                  try {
                    await deleteElementToProvide({
                      variables: {
                        elementToProvideId: elementToProvide.id,
                      },
                    });
                  } catch (e) {
                    toastError(
                      t('plan.taskDetails.elementsToProvide.deleteError')
                    );
                  }
                }}
              />
            );
          })}
        </Flex>
      )}

      {!isCustomer && (!isActionType || !elementsToProvide.length) && (
        <Popup
          trigger={
            <TransparentButton type={'button'} icon={<PlusCircle />} mt={'4px'}>
              <Text size={'sm'}>
                {t('plan.taskDetails.elementsToProvide.addButton.label')}
              </Text>
            </TransparentButton>
          }
        >
          {(close) => (
            <PopupOpenedContainer>
              <ElementToProvideForm
                planId={planId}
                taskId={taskId}
                close={close}
              />
            </PopupOpenedContainer>
          )}
        </Popup>
      )}
    </Flex>
  );
};

const PopupOpenedContainer = styled.div`
  width: max-content;
`;

type ElementToProvideFormProps = {
  planId: string;
  taskId: string;
  close: () => void;
};

const options = [
  { value: ElementToProvideType.File, label: 'File' },
  { value: ElementToProvideType.Link, label: 'Link' },
];

type ValueProps = {
  label: string;
  value: ElementToProvideType;
};

const ElementToProvideForm = ({
  planId,
  taskId,
  close,
}: ElementToProvideFormProps) => {
  const { t } = useTranslation();
  const [createElementToProvide] = useMutation(createElementToProvideMutation, {
    refetchQueries: [
      {
        query: getPlanResourcesQuery,
        variables: { planId },
      },
    ],
  });
  const [name, setName] = useState('');
  const [type, setType] = useState<ElementToProvideType>(
    ElementToProvideType.File
  );
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const isValid = !!type && name.length > 0;
  const onSubmit = async () => {
    await createElementToProvide({
      variables: {
        taskId,
        input: {
          name,
          type,
          isRequired,
        },
      },
    });
    close();
  };

  return (
    <>
      <Flex
        flexDirection={'column'}
        padding={'6px'}
        gap={'4px'}
        borderBottom={`1px solid ${colors.dark100}`}
      >
        <GhostSelect
          name={'type'}
          value={options.find((option) => option.value === type)}
          onChange={(newValue) => setType((newValue as ValueProps).value)}
          isMulti={false}
          options={options}
        />
        <GhostInput
          size={'small'}
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          placeholder={'Ex: Employees list'}
        />
        {/*TODO: Update with real design*/}
        <label htmlFor="isRequired">
          <input
            id={'isRequired'}
            name={'isRequired'}
            type="checkbox"
            checked={isRequired}
            onChange={(e) => setIsRequired(e.target.checked)}
          />
          <Text inline size={'sm'}>
            {t('plan.taskDetails.elementsToProvide.isRequired.label')}
          </Text>
        </label>
      </Flex>
      <Flex padding={'6px'} flexDirection={'column'}>
        <Button
          type={'submit'}
          size={'small'}
          disabled={!isValid}
          onClick={async (e) => {
            e.preventDefault(); // Prevent parent form from submitting
            await onSubmit();
          }}
        >
          {t('plan.taskDetails.links.form.submit.label')}
        </Button>
      </Flex>
    </>
  );
};

import { useQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import SortableList, { SortableItem } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';

import { Error } from '@components/Error/Error';
import { FoldersIcon } from '@components/Icon/FoldersIcon';
import { StickerCheckMarkIcon } from '@components/Icon/StickerCheckMarkIcon';
import { PageLayout } from '@components/PageLayout';
import { useWorkspaceRouting } from '@graphql/hooks/useWorkspaceRouting';
import { getPlanQuery, updatePlanMutation } from './Plan.query';
import {
  Banner,
  Divider,
  Tabs,
  Typography,
  useToast,
  AutoSave,
} from '@src/newComponents';
import { PlanDetails, PlanSection } from './newComponents';
import { Plan } from '@src/graphql/generated/graphql';
import { Form } from '@src/components/Form/Form';
import { PlanSection as PlanSectionTypes } from '@src/graphql/generated/graphql';
import { PlusIcon } from '@src/components/Icon/PlusIcon';
import {
  createSectionMutation,
  updateSectionMutation,
} from '@components/Plan/PlanSection.query';
import { PlanResources } from '@src/components/Plan/PlanResources';

interface FormValues {
  title?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  banner?: string;
}

type TabsType = 'tasks' | 'resources';

const COLORS = ['#635df4', '#344054', '#bbe1c5', '#5c5369', '#f15b5b'];
const TAB_OPTIONS = [
  {
    label: 'Tasks',
    value: 'tasks',
    icon: <StickerCheckMarkIcon />,
  },
  {
    label: 'Ressources',
    value: 'resources',
    icon: <FoldersIcon />,
  },
];

export const PlanPage = ({
  isPreview = false,
  isCustomerLink = false,
}: {
  isPreview?: boolean;
  isCustomerLink?: boolean;
}) => {
  /* Vars */

  const [currentTab, setCurrentTab] = useState<TabsType>('tasks');
  const [isPreviewMode, setIsPreviewMode] = useState(
    isPreview || isCustomerLink
  );
  const [sections, setSections] = useState<PlanSectionTypes[]>([]);

  const { pathTo } = !isCustomerLink
    ? useWorkspaceRouting()
    : { pathTo: () => '' };

  const { planId } = useParams();
  const { t } = useTranslation();
  const { createAsyncToast } = useToast();

  /* Queries */

  const { data, refetch } = useQuery(getPlanQuery, {
    variables: { id: planId! },
  });

  const [updatePlan] = useMutation(updatePlanMutation);
  const [createSection] = useMutation(createSectionMutation);
  const [updateSection] = useMutation(updateSectionMutation);

  /* Form */

  const planValues = {
    title: data?.plan?.title || undefined,
    description: data?.plan?.description || undefined,
    startDate: data?.plan?.startDate || undefined,
    endDate: data?.plan?.endDate || undefined,
    banner: data?.plan?.banner || COLORS[0],
  };

  const methods = useForm<FormValues>({
    defaultValues: {},
    values: planValues,
    mode: 'onChange',
  });

  /* Effects */

  useEffect(() => {
    setSections((data?.plan?.sections || []) as PlanSectionTypes[]);
  }, [data?.plan?.sections]);

  /* Functions */

  const onSubmit = (values: FormValues) => {
    if (!isPreviewMode) {
      createAsyncToast(
        updatePlan({
          variables: {
            id: planId!,
            input: values,
          },
        })
      );
    }
  };

  const addNewSection = (newSectionIndex: number) => {
    if (!isPreviewMode) {
      if (!data?.plan?.id) return;

      createAsyncToast(
        createSection({
          variables: {
            planId: data?.plan?.id,
            index: newSectionIndex + 1,
          },
        })
      );
    }
  };

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    if (!isPreviewMode) {
      const sectionIdMoved = sections[oldIndex].id;
      const newSectionIndex = newIndex + 1;

      createAsyncToast(
        updateSection({
          variables: {
            id: sectionIdMoved,
            input: {
              index: newSectionIndex,
            },
          },
        })
      );

      setSections(arrayMoveImmutable(sections, oldIndex, newIndex));
    }
  };

  const handleRefresh = () => {
    refetch({ id: planId! });
  };

  /* Render */

  if (!planId) return <Error />;

  return (
    <>
      <PageLayout
        withTopBar={!isCustomerLink}
        topBarProps={
          !isCustomerLink
            ? {
                plan: data?.plan,
                isPreviewMode,
                togglePreviewMode: setIsPreviewMode,
              }
            : {}
        }
        backLink={pathTo('/')}
        width="100%"
        className={clsx({
          'pt-10': isCustomerLink,
        })}
      >
        <Form onSubmit={onSubmit} methods={methods}>
          <div className="flex flex-col gap-[40px]">
            <div className="relative">
              <Controller
                name="banner"
                control={methods.control}
                render={({ field }) => (
                  <Banner
                    background={field.value as string}
                    readOnly={isPreviewMode}
                    {...field}
                  />
                )}
              />

              <div className="flex justify-center absolute top-[24px] left-[0px] right-[0px]">
                <Tabs<TabsType>
                  options={TAB_OPTIONS}
                  onChange={setCurrentTab}
                  value={currentTab}
                />
              </div>

              {currentTab === 'tasks' && (
                <PlanDetails
                  plan={data?.plan as Plan}
                  isPreviewMode={isPreviewMode}
                />
              )}

              {currentTab === 'resources' && (
                <div className="p-10 flex justify-center">
                  <PlanResources planId={planId} isCustomer={isCustomerLink} />
                </div>
              )}
            </div>

            {currentTab === 'tasks' && (
              <>
                <Divider />

                <SortableList
                  onSortEnd={onSortEnd}
                  className="sections-list"
                  draggedItemClassName="dragged"
                  lockAxis="y"
                  allowDrag={!isPreviewMode}
                >
                  {sections.map((section, index) => (
                    <SortableItem key={section.id}>
                      <div className="flex flex-col items-center w-full">
                        <PlanSection
                          section={section as PlanSectionTypes}
                          planId={planId}
                          sections={sections as PlanSectionTypes[]}
                          isPreviewMode={isPreviewMode}
                          refetch={handleRefresh}
                        />

                        {index + 1 < sections.length && (
                          <Divider className="my-[30px]" />
                        )}
                      </div>
                    </SortableItem>
                  ))}
                </SortableList>
              </>
            )}
          </div>

          {currentTab === 'tasks' && isPreviewMode === false && (
            <Divider className="mt-[30px] mb-[40px]">
              <div
                className="flex items-center gap-6 hover:bg-dark-50 p-4 rounded-lg -ml-4 duration-300 cursor-pointer"
                onClick={() => addNewSection(sections.length)}
              >
                <div className="h-[24px] w-[24px] rounded-full border border-dark-200 flex justify-center items-center">
                  <PlusIcon color="dark400" />
                </div>

                <Typography size="sm" className="text-dark-500 text-nowrap">
                  {t('planSection.newSectionButton.label')}
                </Typography>
              </div>
            </Divider>
          )}

          <AutoSave
            onSubmit={onSubmit}
            defaultValues={planValues}
            methods={methods}
          />
        </Form>
      </PageLayout>
    </>
  );
};

/* eslint import/named: 0 */
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  defaultDataIdFromObject,
  InMemoryCache,
  Operation,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import type { FC, PropsWithChildren } from 'react';
import { GRAPHQL_URL } from './config';
import { getSessionToken, removeSessionToken } from './helpers/auth';
import { ErrorCode } from './helpers/errors';

const authLink = setContext((_, { headers }) => {
  const token = getSessionToken();
  const workspaceSlug = extractWorkspaceSlugFromUrl();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      ...(workspaceSlug && { 'X-Workspace': workspaceSlug }),
    },
  };
});

const WORKSPACE_SLUG_REGEX = /^\/([a-z0-9-]+-[a-f0-9]{4})/;
function extractWorkspaceSlugFromUrl(): string | undefined {
  const result = WORKSPACE_SLUG_REGEX.exec(window.location.pathname);
  return result?.[1];
}

const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
});

const errorLink = onError((error) => {
  const { operation, graphQLErrors, networkError } = error;

  if (
    graphQLErrors?.some(
      ({ extensions }) => extensions?.code === ErrorCode.LOGIN_REQUIRED
    )
  ) {
    console.warn('Login required, redirecting...');
    removeSessionToken();

    if (location.pathname.startsWith('/customer')) {
      location.assign('/customer/login');
    } else {
      location.assign('/login');
    }
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const isMutation = isMutationOperation(operation);
      console.error(
        `[GraphQL] "${operation.operationName}" ${
          isMutation ? 'mutation' : 'query'
        } failed`,
        `(${error.extensions?.code}):`,
        error.message
      );
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case 'Plan':
          return responseObject.preview
            ? `Plan:preview:${responseObject.id}`
            : `Plan:${responseObject.id}`;

        default:
          return defaultDataIdFromObject(responseObject);
      }
    },
  }),
  link: ApolloLink.from([authLink, errorLink, httpLink]),
});

export const GraphQLProvider: FC<PropsWithChildren> = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

function isMutationOperation(operation: Operation): boolean {
  return operation.query.definitions.some(
    (def) => 'operation' in def && def.operation === 'mutation'
  );
}

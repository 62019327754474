import { useQuery } from '@apollo/client';
import type { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { getMainWorkspaceQuery } from './Workspace.query';

export const RedirectToMainWorkspace: FC = () => {
  const { data, loading, error } = useQuery(getMainWorkspaceQuery, {
    fetchPolicy: 'network-only',
  });

  if (loading) return null;
  if (error) return <div>Error</div>;

  const mainWorkspace = data!.me.mainWorkspace;

  if (!mainWorkspace) {
    return <Navigate to={`/workspace/new`} replace />;
  }

  return <Navigate to={`/${mainWorkspace.slug}`} replace />;
};

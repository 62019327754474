import { FC, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { colors } from '../style/colors';

type SwitchProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>;

export const Toggle: FC<SwitchProps> = ({ ...props }) => {
  return <StyledInput {...props} type="checkbox" />;
};

// TODO: disabled state
const StyledInput = styled.input`
  width: 44px;
  height: 24px;
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: ${colors.white};
    box-shadow: 0 2px 6px 0 ${colors.dark900_04};
    top: 2px;
    left: 2px;
    transition: transform 180ms ease-out;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-color: ${colors.dark100};
    position: absolute;
    transition: background-color 180ms ease-out;
  }

  &:checked {
    &:after {
      transform: translateX(20px);
    }
    &:before {
      background-color: ${colors.green};
    }
  }
`;

import React from 'react';
import { Image, ImageSize } from '@components/Image/Image';
import { Text, TextSize } from '@components/Text/Text';
import { generateHSL, HSLtoString } from '@src/helpers/colors';
import { UserAvatarFragment } from '@graphql/generated/graphql';

type Props = {
  user: UserAvatarFragment;
  size?: ImageSize;
};

const imageSizeToTextSize = (size: ImageSize): TextSize => {
  switch (size) {
    case 'small':
      return 'xs';
    case 'normal':
      return 'xs';
    case 'medium':
      return 'sm';
    case 'large':
      return 'base';
    case 'xl':
      return 'xl';
    default:
      return 'base';
  }
};

export const Avatar = ({ user, size = 'normal' }: Props) => {
  if (user.avatarUrl) {
    return <Image src={user.avatarUrl} size={size} />;
  }

  const names = user.displayName.split(' ');
  const initials = `${names[0][0]}${names[names.length - 1][0]}`.toUpperCase();
  const bgColor = HSLtoString(generateHSL(user.email));

  return (
    <Image size={size} backgroundColor={bgColor}>
      <Text
        size={imageSizeToTextSize(size)}
        weight={'semibold'}
        color={'white'}
      >
        {initials}
      </Text>
    </Image>
  );
};

import { CourierProvider } from '@trycourier/react-provider';
import { FC, PropsWithChildren } from 'react';
import { useUserContext } from '../UserContext/useUserContext';

export const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const { id, courierAuthToken } = useUserContext();
  return (
    <CourierProvider userId={id} authorization={courierAuthToken ?? undefined}>
      {children}
    </CourierProvider>
  );
};

import type { FC, PropsWithChildren } from 'react';
import { useRef } from 'react';
import styled from 'styled-components';
import { useOutsideClick } from '../../helpers/hooks/useOutsideClick';
import { colors } from '@style/colors';
import { UnstyledButton } from '../Button/UnstyledButton';
import { Card } from '../Card/Card';
import { ArrowRightIcon } from '../Icon/ArrowRightIcon';

type RightPanelProps = {
  isOpen: boolean;
  close: () => void;
  excludeRef?: HTMLElement | null;
};

export const RightPanel: FC<PropsWithChildren<RightPanelProps>> = ({
  isOpen,
  close,
  children,
  excludeRef,
}) => {
  const ref = useRef(null);

  useOutsideClick(ref, () => close(), excludeRef);

  return (
    <Container floating ref={ref} isOpen={isOpen}>
      <ArrowContainer>
        <UnstyledButton
          type="button"
          padding="8px"
          onClick={() => close()}
          width="100%"
        >
          <ArrowRightIcon color="dark700" />
        </UnstyledButton>
      </ArrowContainer>
      <ContentScrollWrapper>
        <Content>{children}</Content>
      </ContentScrollWrapper>
    </Container>
  );
};

const Container = styled(Card)<{ isOpen: boolean }>`
  overflow: visible;
  position: fixed;
  right: 16px;
  top: 16px;
  bottom: 16px;
  width: 60vw;
  z-index: 6;
  transform: ${(props) =>
    props.isOpen ? `translate(0)` : `translate(calc(100% + 128px))`};
  transition: transform 180ms ease-out;
`;

const ArrowContainer = styled.div`
  background-color: ${colors.white};
  border-radius: 12px 0 0 12px;
  position: fixed;
  top: 24px;
  left: -36px;
  z-index: -1;
`;

const ContentScrollWrapper = styled.div`
  height: 100%;
  overflow: hidden;
`;

const Content = styled.div`
  overflow-y: auto;
  max-height: calc(100% - 48px);
  padding: 24px;
`;

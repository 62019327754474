import { FC } from 'react';
import { Filter } from './Filter';
import { useCustomerOptions } from './useCustomerOptions';

type CustomersFilterProps = {
  name: string;
  onChange: (ids: string[]) => void;
  defaultCustomerIds?: string[];
};

export const CustomersFilter: FC<CustomersFilterProps> = ({
  name,
  onChange,
  defaultCustomerIds,
}) => {
  const options = useCustomerOptions();
  const defaultValue = defaultCustomerIds
    ? options.filter((option) => defaultCustomerIds.includes(option.value))
    : [];

  // This guarantees that the default value is passed as prop on the first React Select's render.
  if (!defaultValue) return null;
  return (
    <Filter
      name={name}
      defaultValue={defaultValue}
      options={options}
      onChange={(newOptions) => {
        onChange(newOptions.map((opt) => opt.value));
      }}
    />
  );
};

import { useQuery } from '@apollo/client';
import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Loader } from '@components/Loader/Loader';
import { getMainWorkspaceQuery } from './Workspace.query';
import { WorkspaceContext } from './context';

export const WorkspaceWrapper = () => {
  const { workspaceSlug } = useParams();
  const { data, loading } = useQuery(getMainWorkspaceQuery, {
    fetchPolicy: 'network-only',
  });

  if (!workspaceSlug) {
    throw new Error('Missing workspace slug');
  }

  if (loading) return <Loader centered margin="32px" size={32} />;

  if (data?.me.mainWorkspace?.slug !== workspaceSlug) {
    throw new Error('This workspace does not exist');
  }

  const workspace = data.me.mainWorkspace;

  return (
    <WorkspaceContext.Provider
      value={{ slug: workspaceSlug, id: workspace.id, name: workspace.name }}
    >
      <Outlet />
    </WorkspaceContext.Provider>
  );
};

import type { ReactElement } from 'react';
import styled from 'styled-components';
import type { Colors } from '@style/colors';
import { colors } from '@style/colors';

type IconSize = 'xsmall' | 'small' | 'normal' | 'large' | '2xl';
const sizeMap: Record<IconSize, number> = {
  xsmall: 12,
  small: 16,
  normal: 20,
  large: 24,
  '2xl': 60,
};

export type IconComponent = (props: IconProps) => ReactElement;

type IconProps = {
  color?: Colors;
  size?: IconSize;
  className?: string;
};

export const Icon = styled.div<IconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ size }) => sizeMap[size ?? 'normal']}px;
  width: ${({ size }) => sizeMap[size ?? 'normal']}px;
  svg {
    * {
      stroke: ${(props) =>
        props.color ? colors[props.color] : 'currentColor'};
    }
  }
`;

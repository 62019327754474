import { type ReactNode } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import { Typography } from '../../atoms';
import { Icon } from '../icon';

interface ButtonProps {
  children: ReactNode;
  onClick?: () => void;
  to?: string;
  variant?: 'primary' | 'secondary' | 'danger' | 'outlined';
  disabled?: boolean;
  size?: 'xs' | 'sm';
  leftIcon?: string;
}

export const Button = ({
  children,
  onClick,
  to,
  variant = 'primary',
  disabled,
  size = 'sm',
  leftIcon,
}: ButtonProps) => {
  /* Vars */

  const navigate = useNavigate();

  /* Functions */

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    if (to) {
      navigate(to);
    }
  };

  return (
    <button
      onClick={handleClick}
      type="button"
      className={clsx(
        'border border-dark-100 hover:opacity-80 flex items-center gap-2',
        {
          'py-5 px-6 rounded-xl': size === 'sm',
          'py-3 px-6 rounded-lg': size === 'xs',
          'bg-transparent border-none text-grey-800': variant === 'outlined',
          'bg-red-500 text-white': variant === 'danger',
          'border-none text-dark-1000 bg-white': variant === 'secondary',
          'text-dark-700 bg-white': variant === 'primary',
          'opacity-50 cursor-not-allowed hover:opacity-50': disabled,
        }
      )}
      disabled={disabled}
    >
      {leftIcon && <Icon name={leftIcon} />}

      <Typography fontWeight="semibold" size={size === 'xs' ? 'xs' : 'sm'}>
        {children}
      </Typography>
    </button>
  );
};

import { graphql } from '@graphql/generated';

export const updateTaskStatusMutation = graphql(`
  mutation updateTaskStatus($id: ID!, $status: TaskStatus!) {
    updateTask(id: $id, input: { status: $status }) {
      id
      status
      plan {
        id
        completion {
          completionRate
        }
        lastActivityAt
      }
    }
  }
`);

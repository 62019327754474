import { graphql } from '@graphql/generated';

export const getPlanQuery = graphql(`
  query getPlan($id: ID!) {
    plan(id: $id) {
      ...Plan
    }
  }
`);

export const CustomerFragment = graphql(`
  fragment Customer on Customer {
    id
    name
    website
    logo
  }
`);

export const PlanFragment = graphql(`
  fragment Plan on Plan {
    id
    preview
    title
    description
    startDate
    endDate
    banner
    completion {
      totalTasks
      completedTasks
      completionRate
    }
    lastActivityAt
    status
    sections {
      id
      title
      description
      index
      tasks {
        ...TaskRow
      }
      completion {
        totalTasks
        completedTasks
        completionRate
      }
    }
    workspace {
      id
      name
      logo
    }
    customer {
      ...Customer
    }
    invitees {
      ...UserAvatar
    }
    owner {
      ...UserAvatar
    }
  }
`);

export const TaskRowFragment = graphql(`
  fragment TaskRow on Task {
    id
    actionType
    title
    status
    startDate
    computedDueDate
    visibility
    index
    priority
    companyAssignee
    companyAssigneeLogo
    files {
      id
    }
    links {
      id
    }
    elementsToProvide {
      id
    }
    subtasks {
      id
      status
      title
    }
    assignees {
      ...UserAvatar
    }
  }
`);

export const UserAvatarFragment = graphql(`
  fragment UserAvatar on User {
    id
    email
    displayName
    avatarUrl
    calendarUrl
    introduction
  }
`);

export const updatePlanMutation = graphql(`
  mutation updatePlan($id: ID!, $input: PlanInput!) {
    updatePlan(id: $id, input: $input) {
      id
      title
      description
      startDate
      endDate
      lastActivityAt
    }
  }
`);

export const addInviteeMutation = graphql(`
  mutation addInvitee($email: String!, $planId: ID!) {
    addPlanInvitee(email: $email, planId: $planId) {
      id
      invitees {
        ...UserAvatar
      }
    }
  }
`);

export const removeInviteeMutation = graphql(`
  mutation removeInvitee($userId: ID!, $planId: ID!) {
    removePlanInvitee(userId: $userId, planId: $planId) {
      id
      invitees {
        id
      }
    }
  }
`);

export const publishPlanMutation = graphql(`
  mutation publishPlan($id: ID!) {
    publishPlan(id: $id) {
      id
      status
    }
  }
`);

export const unpublishPlanMutation = graphql(`
  mutation unpublishPlan($id: ID!) {
    unpublishPlan(id: $id) {
      id
      status
    }
  }
`);

export const updateCustomerMutation = graphql(`
  mutation updateCustomer($id: ID!, $input: UpdateCustomerInput!) {
    updateCustomer(id: $id, input: $input) {
      ...Customer
    }
  }
`);

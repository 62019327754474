import { graphql } from '@graphql/generated';

export const getMainWorkspaceQuery = graphql(`
  query GetMainWorkspace {
    me {
      id
      mainWorkspace {
        id
        name
        slug
        logo
      }
    }
  }
`);

export const updateWorkspaceMutation = graphql(`
  mutation updateWorkspace($id: ID!, $input: UpdateWorkspaceInput!) {
    updateWorkspace(id: $id, input: $input) {
      id
      name
      logo
    }
  }
`);

import { useQuery } from '@apollo/client';
import type { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Error } from '@components/Error/Error';
import { Flex } from '@components/Flex/Flex';
import { Loader } from '@components/Loader/Loader';
import { PageLayout } from '@components/PageLayout';
import { Text } from '@components/Text/Text';
import { UserStatus } from '@graphql/generated/graphql';
import { decodeWorkspaceInvitationTokenQuery } from '../../JoinWorkspace.query';
import { JoinWorkspaceForm } from '../JoinWorspaceForm/JoinWorkspaceForm';

export const JoinWorkspace: FC<{ token: string }> = ({ token }) => {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(
    decodeWorkspaceInvitationTokenQuery,
    {
      variables: { token },
    }
  );

  if (error) return <Error />;
  if (loading || !data) return <Loader />;

  const { workspace, user } = data.decodeWorkspaceInvitationToken;

  if (user.status === UserStatus.Joined) {
    return <Navigate to={`/${workspace.slug}`} />;
  }

  return (
    <PageLayout vCenter>
      <Flex flexDirection="column" alignItems="center">
        <Text size="2xl" weight="bold" mb="32px">
          {t('joinWorkspace.title')}
        </Text>
        <Text>
          <Trans
            i18nKey="joinWorkspace.subtitle1"
            values={{ workspace: workspace.name }}
            components={{
              strong: <Text weight="bold" inline />,
            }}
          />
        </Text>
        <Text>{t('joinWorkspace.subtitle2')}</Text>
        <JoinWorkspaceForm user={user} token={token} />
      </Flex>
    </PageLayout>
  );
};

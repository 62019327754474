import { useFormContext } from 'react-hook-form';
import type { AsyncSelectProps } from '../Select/AsyncSelect';
import { AsyncSelect } from '../Select/AsyncSelect';
import { FieldError } from './FieldError';
import { useTranslation } from 'react-i18next';

type AsyncSelectFieldProps<Value> = {
  name: string;
  required?: boolean;
} & Pick<AsyncSelectProps<Value>, 'size' | 'placeholder' | 'loadOptions'>;

export function AsyncSelectField<Value>({
  name,
  placeholder,
  required,
  size,
  loadOptions,
}: AsyncSelectFieldProps<Value>) {
  const { t } = useTranslation();
  const { register, formState, getFieldState, setValue } = useFormContext();
  const { error } = getFieldState(name, formState);

  const { ref, ...registerProps } = register(name, {
    required: required ? t('shared.input.requiredErrorMessage') : false,
  });

  return (
    <>
      <AsyncSelect<Value>
        customRef={ref}
        {...registerProps}
        placeholder={placeholder}
        size={size}
        loadOptions={loadOptions}
        onChange={(newValue) =>
          setValue(name, newValue?.value ?? null, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          })
        }
      />
      {error && <FieldError error={error.message} />}
    </>
  );
}

import { Icon, IconComponent } from '..';

export const PriorityUrgentIcon: IconComponent = (props) => {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="rgb(136, 135, 133)"
        width="14"
        height="14"
      >
        <path d="M23.64,18.1L14.24,2.28c-.47-.8-1.3-1.28-2.24-1.28s-1.77,.48-2.23,1.28L.36,18.1h0c-.47,.82-.47,1.79,0,2.6s1.31,1.3,2.24,1.3H21.41c.94,0,1.78-.49,2.24-1.3s.46-1.78-.01-2.6Zm-1.72,1.6c-.05,.09-.2,.29-.51,.29H2.59c-.31,0-.46-.21-.51-.29s-.15-.32,0-.59L11.49,3.29c.15-.26,.41-.29,.51-.29s.35,.03,.51,.3l9.4,15.82c.16,.26,.05,.5,0,.59ZM11,8h2v6h-2v-6Zm0,8h2v2h-2v-2Z" />
      </svg>
    </Icon>
  );
};

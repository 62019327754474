import styled from 'styled-components';
import type { MarginProps, PaddingProps, WidthProps } from 'styled-system';
import { margin, padding, width } from 'styled-system';
import { resetStyle } from './buttonMixins';

export const UnstyledButton = styled.button<
  MarginProps & PaddingProps & WidthProps
>`
  ${resetStyle}
  ${margin}
  ${padding}
  ${width}
`;

import { ActionType } from '@src/graphql/generated/graphql';
import { rgba } from 'polished';

const baseTaskActionColors: Record<ActionType, string> = {
  [ActionType.BookDemo]: '#F3B178',
  [ActionType.Checklist]: '#9E9CCD',
  [ActionType.SendFile]: '#B16B8D',
  [ActionType.FillForm]: '#97AC9A',
  [ActionType.AskFile]: '#8C6D5F',
  [ActionType.SendLink]: '#4f98c9',
};
const transparentTaskActionColors = Object.entries(baseTaskActionColors).reduce(
  (colors, [actionType, color]) => ({
    ...colors,
    [`${actionType}_0F`]: `${color}0F`,
  }),
  {} as Record<`${ActionType}_0F`, string>
);

const baseColors = {
  dark900: '#262626',
  dark800: '#3F3F3F',
  dark700: '#5B5B5B',
  dark600: '#707070',
  dark500: '#888785',
  dark400: '#9F9D99',
  dark300: '#B6B5B3',
  dark200: '#CECAC5',
  dark100: '#EBE6DF',
  dark50: '#F7F4EF',
  dark25: '#FBF9F7',
  success: '#059669',
  warning: '#CA8A04',
  error: '#F15B5B',
  green: '#059669',
  white: '#FFF',
  transparent: 'transparent',
};

export type Colors = keyof typeof colors;
export const colors = {
  ...baseColors,

  primary: baseColors.dark900,
  secondary: baseColors.dark100,

  // Opacity variations
  dark900_04: rgba(baseColors.dark900, 0.04),
  dark900_10: rgba(baseColors.dark900, 0.1),
  dark900_12: rgba(baseColors.dark900, 0.12),
  dark900_20: rgba(baseColors.dark900, 0.2),
  green_25: rgba(baseColors.green, 0.25),
  green_10: rgba(baseColors.green, 0.1),

  ...baseTaskActionColors,
  ...transparentTaskActionColors,
};

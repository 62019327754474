import type { FC } from 'react';
import { forwardRef } from 'react';
import RawSelect, { type Props, type StylesConfig } from 'react-select';
import { colors } from '@style/colors';

export type GhostSelectProps = Props & {
  alignLeft?: boolean;
};

export const GhostSelect: FC<GhostSelectProps> = forwardRef<
  HTMLSelectElement,
  Props
>(function GhostSelect({ styles, ...rest }, ref) {
  const customStyles: StylesConfig = {
    container: (baseStyles) => ({
      ...baseStyles,
      display: 'inline-flex',
      fontFamily: "'Inter', sans-serif",
      fontSize: '14px',
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      outline: 'none',
      borderRadius: '12px',
      borderColor: state.isFocused ? colors.dark200 : 'transparent',
      backgroundColor: 'transparent',
      transition:
        'background-color 180ms ease-out, border-color 180ms ease-out',
      '&:hover': {
        outline: 'none',
        borderColor: 'transparent',
        backgroundColor: colors.dark25,
      },
    }),
    valueContainer: (baseStyle) => ({
      ...baseStyle,
      flexWrap: 'nowrap',
      padding: '2px 4px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (baseStyles, state) => ({
      ...baseStyles,
      color: state.hasValue ? colors.dark900 : undefined,
    }),
    indicatorsContainer: (baseStyle) => ({
      ...baseStyle,
      paddingRight: 0,
    }),
    menu: (baseStyle) => ({
      ...baseStyle,
      width: 'auto',
      borderRadius: 12,
      boxShadow:
        '0px 4px 24px rgba(38, 38, 38, 0.1), 0px 2px 6px rgba(38, 38, 38, 0.04), 0px 0px 0px 1px rgba(38, 38, 38, 0.08)',
    }),
    menuList: (baseStyle) => ({
      ...baseStyle,
      padding: 6,
    }),
    option: (baseStyle, state) => ({
      ...baseStyle,
      whiteSpace: 'nowrap',
      color: colors.primary,
      borderRadius: 12,
      backgroundColor: state.isSelected ? colors.dark50 : colors.white,
      '&:not(:first-child)': {
        marginTop: 4,
      },
      '&:not(:last-child)': {
        marginBottom: 4,
      },
      transition: 'background-color 180ms ease-out',
      '&:hover': {
        backgroundColor: colors.dark50,
      },
    }),
    ...styles,
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <RawSelect ref={ref} styles={customStyles} {...rest} />;
});

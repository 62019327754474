import { Colors } from '../style/colors';

export function getDueDateColor(dueDate?: string | null): Colors {
  if (dueDate) {
    const now = new Date();
    const due = new Date(dueDate);

    // Task is due today
    const isToday = due.toDateString() === now.toDateString();
    if (isToday) {
      return 'warning';
    }

    if (due < now) {
      return 'error';
    }
  }
  return 'dark900';
}

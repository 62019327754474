import type { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { colors } from '../style/colors';
import { CONTENT_WIDTH } from '../style/globalStyle';
import { TopBar } from './topBar';
import { NotificationProvider } from '@src/contexts/NotificationProvider/NotificationProvider';

type PageLayoutProps = {
  withTopBar?: boolean;
  topBarProps?: { [key: string]: any };
  backLink?: string;
  vCenter?: boolean;
  width?: number | string;
  children: ReactNode;
  className?: string;
};

export const PageLayout: FC<PageLayoutProps> = ({
  withTopBar,
  topBarProps,
  backLink,
  vCenter,
  width = CONTENT_WIDTH,
  children,
  className,
}) => {
  return (
    <Container className={className}>
      {withTopBar && (
        <NotificationProvider>
          <TopBar backLink={backLink} {...topBarProps} />
        </NotificationProvider>
      )}

      <ContentContainer vCenter={vCenter}>
        <CenteredContent width={width}>{children}</CenteredContent>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-x: hidden; // useful for RightPanel
`;

const ContentContainer = styled.div<{ vCenter?: boolean }>`
  flex-grow: 1;
  background-color: ${colors.white};
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  ${(props) => props.vCenter && `align-items: center`};
  position: relative;
  margin: 0 16px 16px 16px;
`;

const CenteredContent = styled.div<{ width: number | string }>`
  width: ${(props) =>
    typeof props.width === 'string' ? props.width : `${props.width}px`};
`;

import type { FC } from 'react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import type { PlainDate } from '../../types';
import { Calendar } from '../Calendar/Calendar';
import { Div } from '../Flex/Div/Div';
import { Popup, PopupProps } from '../Popup/Popup';
import FormField from './FormField';

type DatePickerFieldProps = {
  name: string;
  label?: string;
  renderer: (
    date: PlainDate,
    setDate: (date: PlainDate | null) => void
  ) => JSX.Element;
  placement?: PopupProps['placement'];
};

export const DatePickerField: FC<DatePickerFieldProps> = ({
  name,
  label,
  renderer,
  placement = 'bottom-start',
}) => {
  const { watch, setValue } = useFormContext();
  const date: PlainDate = watch(name);
  const setDate = (value: PlainDate | null) =>
    setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

  return (
    <FormField name={name} label={label} alignInputLeft={!!label}>
      <Popup placement={placement} trigger={renderer(date, setDate)} withFlip>
        {(close) => (
          <Div padding="12px">
            <Calendar
              value={date}
              setValue={(value) => {
                setDate(value);
                close();
              }}
            />
          </Div>
        )}
      </Popup>
    </FormField>
  );
};

export type NotificationData = {
  type: 'comment';
  taskId: string;
  planId: string;
  commentId: string;
};

export const isNotificationData = (data: unknown): data is NotificationData => {
  if (typeof data !== 'object' || data === null) {
    return false;
  }
  const dataObj = data as Record<string, unknown>;
  if (dataObj.type === 'comment') {
    return (
      typeof dataObj.taskId === 'string' && typeof dataObj.planId === 'string'
    );
  }
  return false;
};

export const getNotificationLinkUrl = (linkData: unknown) => {
  if (!isNotificationData(linkData)) {
    return null;
  }
  if (linkData.type === 'comment') {
    return `/plans/${linkData.planId}/?task=${linkData.taskId}&anchor=comments`;
  }
  return null;
};

import React, { type FC } from 'react';
import clsx from 'clsx';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';

interface CircleProgressBarProps {
  percentage: number;
  size?: 'sm' | 'lg';
  text?: string;
}

export const CircleProgressBar: FC<CircleProgressBarProps> = ({
  size = 'lg',
  percentage,
  text,
}) => {
  return (
    <div
      className={clsx('w-[16px] h-[16px]', {
        ['w-[72px] h-[72px]']: size === 'lg',
      })}
    >
      <CircularProgressbar
        value={percentage}
        text={text}
        strokeWidth={size === 'lg' ? 6 : 12}
        styles={buildStyles({
          pathColor: '#7850ff',
          textColor: '#262626',
          textSize: 16,
        })}
      />
    </div>
  );
};

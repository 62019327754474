import { rgba } from 'polished';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import type { MinWidthProps, WidthProps } from 'styled-system';
import { minWidth } from 'styled-system';
import { colors } from '@style/colors';

type DropCardProps = MinWidthProps & WidthProps;

export const DropCard: FC<PropsWithChildren<DropCardProps>> = ({
  children,
  ...props
}) => {
  return <Container {...props}>{children}</Container>;
};

const Container = styled.div`
  border-radius: 12px;
  padding: 6px;
  box-shadow: 0 0 0 1px ${rgba(colors.dark100, 0.7)}),
    0 2px 6px 0 ${rgba(colors.dark900, 0.04)},
    0 4px 24px 0 ${rgba(colors.dark900, 0.1)};

  display: flex;
  flex-direction: column;
  gap: 4px;

  ${minWidth}
`;

export async function uploadFile(url: string, file: File) {
  const result = await fetch(url, {
    method: 'PUT',
    body: file,
  });

  if (!result.ok) {
    // Handle error better
    console.error(`Upload failed with status ${result.status}: ${result.body}`);
    throw new Error(`Upload failed with status ${result.status}`);
  }

  try {
    return await result.json();
  } catch (e) {
    // Body might not be JSON
    return result;
  }
}
